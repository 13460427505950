import { connect } from "react-redux";
import ThematicCarousel from "../ThematicCarousel";
import store from "../store";

const mapStateToProps = state => ({
    dispatch: store.dispatch,
    thematicCategoriesData: state.fullscreen.thematicCategoriesData,
    lang: state.lang
});

export default connect(mapStateToProps)(ThematicCarousel);
