import React, { useState, useEffect, useRef } from "react";
import Swiper from "react-id-swiper";
import { connect } from "react-redux";


const TIME_ON_SLIDE = 10000;

const HeroCarousel = ({ items, slideComponent, inParams, modalIsClosed }) => {
  const [mounted, setMounted] = useState(false);
  const [autoplay, setAutoplay] = useState(true);
  const refSlider = useRef();

  useEffect(() => {

    setMounted(true)
    return () => setMounted(false)
  }, [])

  useEffect(() => {

    const interval = setInterval(() => {
      if (autoplay) {
        // we use a conditional to stop the slider on hover and restart on leave
        refSlider.current.swiper.slideNext(); // or slidePrev()
      }
    }, TIME_ON_SLIDE);
    return () => clearInterval(interval);

  }, [autoplay]);

  useEffect(() => {

    if (mounted) {
      setAutoplay(modalIsClosed);
    }
  }, [modalIsClosed]);

  const overrideParams = inParams || {};
  const _params = {
    mousewheel: {
      releaseOnEdges: true,
      forceToAxis: true,
      invert: true,
    },
    preloadImages: false,
    lazy: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    slidesPerView: 5.5,
    slidesPerGroup: 5,
    roundLengths: true,
    keyboard: true,
    centerInsufficientSlides: true,
    spaceBetween: 10, // Big screens, e.g., 4k
    breakpoints: {
      // when window width is <= 480px
      480: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      // when window width is <= 640px
      2000: {
        spaceBetween: 25,
      },
    },
  };

  // const paginatorBullets = items.map(
  //   (item) => `${item.heroData.paginatorBullet.uri}?w=621`
  // );

  const heroParams = {
    slidesPerView: 1,
    slidesPerGroup: 1,
    centeredSlides: true,
    initialSlide: 0,
    loop: true,
    effect: "fade",
    // Do not use 'speed' with 'fade'
    // speed: 30,
    // Historical - mooted by the timer in the useEffect(()=>{},[autoplay])
    // autoplay:
    // {
    //   delay: 6000,
    //   disableOnInteraction: false,
    //   pauseOnMouseEnter: true,
    //   stopOnLastSlide: false

    // },

    pagination: {
      el: ".swiper-pagination.custom-mycustom",
      type: "bullets",
      clickable: true,
      bulletClass: "custom-mybullet",
      // renderBullet: function (index, className) {
      //   return `<span class=${className} style="margin-right:1rem;background-image: url('${paginatorBullets[index]}')"></span>`;
      // },
    },
    spaceBetween: 50,
  };

  //Until we sort the final parameters....
  // eslint-disable-next-line
  const params = { ..._params, ...overrideParams };

  const SlideMapper = (items) => {
    return items.map((item, index) => slideComponent(item, index));
  };
  return (
    <Swiper ref={refSlider} {...heroParams}>
      {SlideMapper(items)}
    </Swiper>
  );
};

const mapStateToProps = (state) => ({
  modalIsClosed: state.modal === null,
});

// export default HeroCarousel;

export default connect(mapStateToProps)(HeroCarousel);
