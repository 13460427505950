import React, { useState, useEffect } from "react";
import _ from "lodash";
import store from "../store";
import ImgLazyLoad from "../App/ImgLazyLoad";
import { moodPathClicked } from "../actions";
import WordBubbleSVG from "./WordBubbleSVG";

const MoodTiles = props => {
    const [isShown, setIsShown] = useState(false);
    const [itemToDisplay, setItemToDisplay] = useState("Default");
    const [locked, setLocked] = useState(true);

    useEffect(() => {
        // for initial load, halt popup effect
        const timer = setTimeout(() => {
            setLocked(false);
        }, 1000);

        // clean up
        return () => {
            clearTimeout(timer);
        };
    }, []);

    function handleMouseEnter(item) {
        if (!locked) {
            setIsShown(true);
            setItemToDisplay(item);
        }
    }

    function handleMoodClick(mood, id) {
        // console.log(mood, "mood");
        // console.log(id, "id");
        const viewer_motivation = mood;
        const tmsId = id;
        store.dispatch(moodPathClicked({ tmsId, viewer_motivation }, id));
    }

    function handleImageClick(action) {
        store.dispatch(action);
    }

    const { items, graphs, moodOfOrigin } = props;
    // console.log(items, "items");
    // console.log(graphs, "graphs");
    // console.log(moodOfOrigin, "moodOfOrigin");

    let tiles = [];
    let popups = {};
    let progMap = {};
    let counter = 0; // used to construct 'displayMap'
    let displayMap = {};
    let bumpOrientation = {};

    for (const property of graphs) {
        // console.log(property);
        // console.log(Object.keys(graphs)[counter], "gct");

        // get data from tmsId
        const prog = _.find(items, ["tmsId", `${property.tmsId}`]);
        // console.log(prog, "prog");
        if (prog) {
            progMap[prog.tmsId] = prog;

            // get motivations pathways from adjacency graph
            // note: remove moodOfOrigin, i.e., ensure current tile moods
            // do not have either the current mood nor > 3 items in each.
            const lessGraphs = property.items.filter(
                word => word !== moodOfOrigin.category
            );

            // limit 3 for now
            const graphsToDisplay = lessGraphs.slice(0, 3);
            //console.log(graphsToDisplay, "gtd");

            // construct display map: always the same for 6 tile design
            switch (counter) {
                // top left
                case 0:
                    displayMap[property.tmsId] = {
                        lines: {
                            line1: {
                                x1: "-1",
                                y1: "22",
                                x2: "50",
                                y2: "50"
                            },
                            line2: {
                                x1: "24",
                                y1: "88",
                                x2: "50",
                                y2: "50"
                            },
                            line3: {
                                x1: "101",
                                y1: "40",
                                x2: "50",
                                y2: "50"
                            }
                        },
                        circles: {
                            yellow: { cx: "-1", cy: "22" },
                            blue: { cx: "24", cy: "88" },
                            orange: { cx: "101", cy: "40" }
                        },
                        words: [
                            {
                                x: "101",
                                y: "40"
                            },
                            {
                                x: "-1",
                                y: "22"
                            },
                            {
                                x: "24",
                                y: "88"
                            }
                        ]
                    };
                    bumpOrientation[property.tmsId] = "top-left";
                    break;
                case 1:
                    // top middle
                    displayMap[property.tmsId] = {
                        lines: {
                            line1: {
                                x1: "-1",
                                y1: "33",
                                x2: "50",
                                y2: "50"
                            },
                            line2: {
                                x1: "50",
                                y1: "88",
                                x2: "50",
                                y2: "50"
                            },
                            line3: {
                                x1: "101",
                                y1: "33",
                                x2: "50",
                                y2: "50"
                            }
                        },
                        circles: {
                            yellow: { cx: "-1", cy: "33" },
                            blue: { cx: "50", cy: "88" },
                            orange: { cx: "101", cy: "33" }
                        },
                        words: [
                            {
                                x: "101",
                                y: "33"
                            },
                            {
                                x: "-1",
                                y: "33"
                            },
                            {
                                x: "50",
                                y: "88"
                            }
                        ]
                    };
                    bumpOrientation[property.tmsId] = "top-middle";
                    break;
                case 2:
                    // top right
                    displayMap[property.tmsId] = {
                        lines: {
                            line1: {
                                x1: "101",
                                y1: "22",
                                x2: "50",
                                y2: "50"
                            },
                            line2: {
                                x1: "76",
                                y1: "88",
                                x2: "50",
                                y2: "50"
                            },
                            line3: {
                                x1: "-1",
                                y1: "40",
                                x2: "50",
                                y2: "50"
                            }
                        },
                        circles: {
                            yellow: { cx: "101", cy: "22" },
                            blue: { cx: "76", cy: "88" },
                            orange: { cx: "-1", cy: "40" }
                        },
                        words: [
                            {
                                x: "-1",
                                y: "40"
                            },
                            {
                                x: "101",
                                y: "22"
                            },
                            {
                                x: "76",
                                y: "88"
                            }
                        ]
                    };
                    bumpOrientation[property.tmsId] = "top-right";
                    break;
                case 3:
                    // bottom left
                    displayMap[property.tmsId] = {
                        lines: {
                            line1: {
                                x1: "-1",
                                y1: "78",
                                x2: "50",
                                y2: "50"
                            },
                            line2: {
                                x1: "24",
                                y1: "12",
                                x2: "50",
                                y2: "50"
                            },
                            line3: {
                                x1: "101",
                                y1: "60",
                                x2: "50",
                                y2: "50"
                            }
                        },
                        circles: {
                            yellow: { cx: "-1", cy: "78" },
                            blue: { cx: "24", cy: "12" },
                            orange: { cx: "101", cy: "60" }
                        },
                        words: [
                            {
                                x: "101",
                                y: "60"
                            },
                            {
                                x: "-1",
                                y: "78"
                            },
                            {
                                x: "24",
                                y: "12"
                            }
                        ]
                    };
                    bumpOrientation[property.tmsId] = "bottom-left";
                    break;
                case 4:
                    // bottom middle
                    displayMap[property.tmsId] = {
                        lines: {
                            line1: {
                                x1: "-1",
                                y1: "66",
                                x2: "50",
                                y2: "50"
                            },
                            line2: {
                                x1: "50",
                                y1: "12",
                                x2: "50",
                                y2: "50"
                            },
                            line3: {
                                x1: "101",
                                y1: "66",
                                x2: "50",
                                y2: "50"
                            }
                        },
                        circles: {
                            yellow: { cx: "-1", cy: "66" },
                            blue: { cx: "50", cy: "12" },
                            orange: { cx: "101", cy: "66" }
                        },
                        words: [
                            {
                                x: "101",
                                y: "66"
                            },
                            {
                                x: "-1",
                                y: "66"
                            },
                            {
                                x: "50",
                                y: "12"
                            }
                        ]
                    };
                    bumpOrientation[property.tmsId] = "bottom-middle";
                    break;
                case 5:
                    // bottom right
                    displayMap[property.tmsId] = {
                        lines: {
                            line1: {
                                x1: "101",
                                y1: "78",
                                x2: "50",
                                y2: "50"
                            },
                            line2: {
                                x1: "76",
                                y1: "12",
                                x2: "50",
                                y2: "50"
                            },
                            line3: {
                                x1: "-1",
                                y1: "60",
                                x2: "50",
                                y2: "50"
                            }
                        },
                        circles: {
                            yellow: { cx: "101", cy: "78" },
                            blue: { cx: "76", cy: "12" },
                            orange: { cx: "-1", cy: "60" }
                        },
                        words: [
                            {
                                x: "-1",
                                y: "60"
                            },
                            {
                                x: "101",
                                y: "78"
                            },
                            {
                                x: "76",
                                y: "12"
                            }
                        ]
                    };
                    bumpOrientation[property.tmsId] = "bottom-right";
                    break;
                default:
                    break;
            }

            const popup = (
                <WordBubbleSVG
                    key={itemToDisplay}
                    data={{
                        programId: itemToDisplay,
                        orientation: bumpOrientation[itemToDisplay],
                        displayMap: displayMap[itemToDisplay],
                        action: handleMoodClick,
                        modalAction: handleImageClick,
                        modalPayload:
                            (progMap[itemToDisplay] &&
                                progMap[itemToDisplay].action) ||
                            null
                    }}
                >
                    {graphsToDisplay}
                </WordBubbleSVG>
            );
            popups[property.tmsId] = popup;

            // overlay
            const clsTile = isShown ? `mood-tile bump` : `mood-tile`;
            const ready =
                isShown &&
                progMap[itemToDisplay] &&
                prog.tmsId === itemToDisplay;
            const tile = (
                <div
                    key={prog.tmsId}
                    className={clsTile}
                    onMouseOver={() => handleMouseEnter(prog.tmsId)}
                >
                    {ready ? <div className="tile-insert" /> : null}
                    <ImgLazyLoad src={prog.uri} alt="landscape poster" />
                    {ready ? (
                        <div
                            className="mood-tile-overlay"
                            onMouseEnter={() => setIsShown(true)}
                        >
                            {popups[itemToDisplay]}
                        </div>
                    ) : (
                        <div />
                    )}
                </div>
            );
            tiles.push(tile);
            counter += 1;
        }
    }

    // console.log(isShown, "isShown");
    // console.log(popups, "popups");
    // console.log(progMap, "progMap");
    // console.log(itemToDisplay, "iitemToDisplay");
    // console.log(progMap[itemToDisplay], "progMap[itemToDisplay]");

    return (
        <div
            className="mood-tiles-container"
            onMouseLeave={() => setIsShown(false)}
        >
            {tiles.map(tile => {
                return tile;
            })}
        </div>
    );
};

export default MoodTiles;
