import React, { Component } from "react";
import store from "../store";
import { bubbleClicked } from "../actions";
import { TAILS } from "../api/profile";
import BubbleCSS from "./TasteProfile.module.scss";

export default class Bubble extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.bubbleRef = React.createRef();
    }
    handleClick() {
        const element = this.bubbleRef.current;

        /*
         * Even though the classes have been namespaced by dint of being in a module, we can stil use
         * them as if they were strings. (Which makes sense, when you think about it.)
         */
        if (element) {
            element.classList.toggle(BubbleCSS.clicked);

            store.dispatch(bubbleClicked(this.props.id));
        }
    }
    render() {
        const face = this.props.currentFace;
        /*
         * :^| - prettier will insert spaces around the '-' in the class name, so use the array reference.
         */
        let className = BubbleCSS["flip-container"];

        if (this.props.size) {
            className += ` ${BubbleCSS[this.props.size]}`;
        }

        if (face === TAILS) {
            className += ` ${BubbleCSS.clicked}`;
        }

        if (this.props.category) {
            className += ` ${BubbleCSS[this.props.category]}`;
        }

        /*
         * collect the faces.
         */
        const faces = this.props.faces;

        return (
            <div
                ref={this.bubbleRef}
                onClick={this.handleClick}
                className={className}
            >
                <div className={BubbleCSS.flipper}>
                    <div className={`${BubbleCSS.bubble} ${BubbleCSS.front}`}>
                        <p
                            className={`${BubbleCSS.category} ${BubbleCSS[this.props.textColor]
                                }`}
                        >
                            {faces[0]}
                        </p>
                    </div>
                    <div className={`${BubbleCSS.bubble} ${BubbleCSS.back}`}>
                        <p
                            className={`${BubbleCSS.category} ${BubbleCSS[this.props.textColor]
                                }`}
                        >
                            {faces[1]}
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}
