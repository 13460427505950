const data = {
    "Feel Lighthearted": "#07C6A0",
    "Be A Part Of A Love Story": "#B21DAC",
    Chill: "#00AEEF",
    "Face Reality": "#8DC63F",
    "Discover Something New": "#BB4803",
    Cry: "#F47709",
    "Be Afraid": "#FFB100",
    "Be On The Edge Of My Seat": "#DD0014"
};

const exportable = {};

for (let key in data) {
    exportable[[key.toLowerCase().replace(/\s/g, "")]] = data[key];
}

export default exportable;
