import React, { createRef } from "react";
import store from "../store";
import { moodHomeCenterCircleClicked } from "../actions";

class MoodHomeSVG extends React.Component {
    constructor(props) {
        super(props);
        this.spotlightElement = createRef();
    }
    state = {
        showSvgLocal: false // used for the click action in this view only
    };

    render = () => {
        const { children, action, showSVG } = this.props;
        let { showSvgLocal } = this.state;
        // global state of show svg overrides local
        // facilitates:
        //  - add a state 0 home view state, without svg, just the background image grid.
        // - click grid to get state 1: home view with svg
        // (Home from Tiles breadcrumb menu uses this override; Global home defers to local state)
        showSvgLocal = showSVG ? showSVG : showSvgLocal;
        // just grab any of the children's gradients
        const gradient =
            (children && children.length > 0 && children[0].gradient) || null;

        // for spotlight effect
        const changeMask = (s, o) => {
            this.spotlightElement.current.setAttribute("cx", o.x);
            this.spotlightElement.current.setAttribute("cy", o.y);
            this.spotlightElement.current.setAttribute("r", o.r);
        };

        // for center circle to flip set
        const handleMoodHomeClick = () => {
            store.dispatch(moodHomeCenterCircleClicked(true));
        };

        return showSvgLocal ? (
            <svg viewBox="0 0 240 135" xmlns="http://www.w3.org/2000/svg">
                <rect
                    id="spotlightRect"
                    mask="url(#spotlightMask)"
                    x="0"
                    y="0"
                    height="100%"
                    width="100%"
                />

                {children && children.length > 0 ? (
                    <>
                        <line
                            x1={25 * 2.4}
                            y1={16.29 * 1.35}
                            x2={50 * 2.4}
                            y2={40 * 1.35}
                            stroke="white"
                            strokeWidth=".2"
                            strokeDasharray="1"
                        />
                        <line
                            x1={12.5 * 2.4}
                            y1={50.37 * 1.35}
                            x2={50 * 2.4}
                            y2={40 * 1.35}
                            stroke="white"
                            strokeWidth=".2"
                            strokeDasharray="1"
                        />
                        <line
                            x1={37.5 * 2.4}
                            y1={66.6 * 1.35}
                            x2={50 * 2.4}
                            y2={40 * 1.35}
                            stroke="white"
                            strokeWidth=".2"
                            strokeDasharray="1"
                        />
                        <line
                            x1={75 * 2.4}
                            y1={66.6 * 1.35}
                            x2={50 * 2.4}
                            y2={40 * 1.35}
                            stroke="white"
                            strokeWidth=".2"
                            strokeDasharray="1"
                        />
                        <line
                            x1={87.5 * 2.4}
                            y1={33.3 * 1.35}
                            x2={50 * 2.4}
                            y2={40 * 1.35}
                            stroke="white"
                            strokeWidth=".2"
                            strokeDasharray="1"
                        />
                        <line
                            x1={62.5 * 2.4}
                            y1={17 * 1.35}
                            x2={50 * 2.4}
                            y2={40 * 1.35}
                            stroke="white"
                            strokeWidth=".2"
                            strokeDasharray="1"
                        />
                        <circle
                            cx={50 * 2.4}
                            cy={40 * 1.35}
                            r="10.2"
                            stroke="white"
                            strokeWidth=".2"
                            fill="#FFFFFF"
                            opacity="1"
                        />
                    </>
                ) : (
                    <>
                        <circle
                            cx={50 * 2.4}
                            cy={40 * 1.35}
                            r="10.2"
                            stroke="white"
                            strokeWidth=".2"
                            fill="#000000"
                            opacity="1"
                        />
                        <text
                            y={37.5 * 1.35}
                            fontSize="0.15em"
                            fill="black"
                            dominantBaseline="middle"
                            textAnchor="middle"
                            fontFamily="Roboto, sans-serif"
                            fontStyle="normal"
                            fontWeight="bold"
                            pointerEvents="none"
                        >
                            <tspan x={50 * 2.4}>What's</tspan>
                            <tspan x={50 * 2.4} dy="3">
                                Your
                            </tspan>
                            <tspan x={50 * 2.4} dy="3">
                                Mood?
                            </tspan>
                        </text>
                    </>
                )}
                <image
                    x={25 * 2.4}
                    y={15 * 1.35}
                    width={50 * 2.4}
                    height={50 * 1.35}
                    href={gradient}
                    clipPath="url(#GradientCircle)"
                />
                <defs>
                    <clipPath id="GradientCircle">
                        <circle
                            cx={50 * 2.4}
                            cy={40 * 1.35}
                            r="10"
                            fill="#000000"
                        />
                    </clipPath>
                    <mask id="spotlightMask" x="0" y="0">
                        <rect
                            rect=""
                            x={-45 * 2.4}
                            y="0"
                            width="200%"
                            height="100%"
                            fill="#FFF"
                            opacity="0.8"
                        />
                        <circle
                            ref={this.spotlightElement}
                            id="spotlight"
                            cx="0"
                            cy="0"
                            r="0"
                        />
                    </mask>
                </defs>
                <circle
                    cx={50 * 2.4}
                    cy={40 * 1.35}
                    r="11"
                    stroke="white"
                    strokeWidth=".2"
                    fill="#000000"
                    opacity="0"
                    onClick={() => handleMoodHomeClick()}
                    cursor="pointer"
                />
                <text
                    y={37.5 * 1.35}
                    fontSize="0.15em"
                    fill="white"
                    dominantBaseline="middle"
                    textAnchor="middle"
                    fontFamily="Roboto, sans-serif"
                    fontStyle="normal"
                    fontWeight="bold"
                    pointerEvents="none"
                >
                    <tspan x={50 * 2.4}>What's</tspan>
                    <tspan x={50 * 2.4} dy="3">
                        Your
                    </tspan>
                    <tspan x={50 * 2.4} dy="3">
                        Mood?
                    </tspan>
                </text>
                {children &&
                    children.length > 0 &&
                    children.map((m, idx) => (
                        <circle
                            key={`circle_${idx}`}
                            onMouseOver={() =>
                                changeMask("spotlight", {
                                    x: `${m.region.x * 2.4}`,
                                    y: `${m.region.y * 1.35}`,
                                    r: 20
                                })
                            }
                            onMouseOut={() =>
                                changeMask("spotlight", { x: 0, y: 0, r: 0 })
                            }
                            onClick={e =>
                                action({
                                    tmsId: children[idx].tmsId,
                                    viewer_motivation:
                                        children[idx].viewer_motivation
                                }) || e.preventDefault()
                            }
                            id={`circle_${idx}`}
                            cx={m.region.x * 2.4}
                            cy={m.region.y * 1.35}
                            r="9"
                            stroke="white"
                            strokeWidth=".2"
                            fill={m.color}
                            cursor="pointer"
                        >
                            <set
                                attributeName="r"
                                to="9"
                                begin={`circle_${idx}`.mouseover}
                                end={`circle_${idx}`.mouseout}
                            />
                        </circle>
                    ))}
                {children &&
                    children.length > 0 &&
                    children.map((m, idx) => {
                        const fill = "white";
                        const mots = m.viewer_motivation.split(" ");
                        return (
                            <text
                                key={`${m.region.x}_${m.region.y}`}
                                x="0"
                                y={m.region.y * 1.35}
                                fontSize="0.15em"
                                fill={fill}
                                dominantBaseline="middle"
                                textAnchor="middle"
                                fontFamily="Roboto, sans-serif"
                                fontStyle="normal"
                                pointerEvents="none"
                            >
                                {m.viewer_motivation ===
                                "Be On The Edge Of My Seat" ? (
                                    <tspan>
                                        <tspan
                                            key={`key1`}
                                            x={m.region.x * 2.4}
                                            dy={"-2.5"}
                                        >
                                            {`Be On The`}
                                        </tspan>
                                        <tspan
                                            key={`key2`}
                                            x={m.region.x * 2.4}
                                            dy="3"
                                        >
                                            {`Edge Of`}
                                        </tspan>
                                        <tspan
                                            key={`key3`}
                                            x={m.region.x * 2.4}
                                            dy="3"
                                        >
                                            {`My Seat`}
                                        </tspan>
                                    </tspan>
                                ) : m.viewer_motivation ===
                                  "Be A Part Of A Love Story" ? (
                                    <tspan>
                                        <tspan
                                            key={`key1`}
                                            x={m.region.x * 2.4}
                                            dy={"-3"}
                                        >
                                            {`Be A Part`}
                                        </tspan>
                                        <tspan
                                            key={`key2`}
                                            x={m.region.x * 2.4}
                                            dy="3"
                                        >
                                            {`Of A`}
                                        </tspan>
                                        <tspan
                                            key={`key3`}
                                            x={m.region.x * 2.4}
                                            dy="3"
                                        >
                                            {`Love Story`}
                                        </tspan>
                                    </tspan>
                                ) : mots.length === 2 ? (
                                    mots.map((mm, i) => {
                                        if (i === 0) {
                                            return (
                                                <tspan
                                                    key={`${i}`}
                                                    x={m.region.x * 2.4}
                                                    dy={"-1.5"}
                                                >
                                                    {mm}
                                                </tspan>
                                            );
                                        } else {
                                            return (
                                                <tspan
                                                    key={`${i}`}
                                                    x={m.region.x * 2.4}
                                                    dy="3"
                                                >
                                                    {mm}
                                                </tspan>
                                            );
                                        }
                                    })
                                ) : mots.length > 2 ? (
                                    mots.map((mm, i) => {
                                        if (i === 0) {
                                            return (
                                                <tspan
                                                    key={`${i}`}
                                                    x={m.region.x * 2.4}
                                                    dy={"-2.5"}
                                                >
                                                    {mm}
                                                </tspan>
                                            );
                                        } else {
                                            return (
                                                <tspan
                                                    key={`${i}`}
                                                    x={m.region.x * 2.4}
                                                    dy="3"
                                                >
                                                    {mm}
                                                </tspan>
                                            );
                                        }
                                    })
                                ) : (
                                    <tspan x={m.region.x * 2.4} dy="0">
                                        {m.viewer_motivation}
                                    </tspan>
                                )}
                            </text>
                        );
                    })}
            </svg>
        ) : (
            <div
                style={{ height: "80vh", width: "90vw", cursor: "pointer" }}
                onClick={() => {
                    this.setState({ showSvgLocal: true });
                }}
            />
        );
    };
}

export default MoodHomeSVG;
