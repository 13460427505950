export default {
    "Chaos|Escape|Evil|Injustice|Suspenseful|Thrilling": [55, 28, 16, 80, 95],
    "Chaos|Duel|Evil|Injustice|Suspenseful|Thrilling": [95, 46, 55, 80, 28],
    "Escape|Evil|Injustice|Schemes|Suspenseful|Thrilling": [55, 28, 30, 16, 95],
    "Duel|Evil|Injustice|Schemes|Suspenseful|Thrilling": [95, 30, 46, 55, 78],
    "Challenge|Chaos|Escape|Injustice|Suspenseful|Thrilling": [
        16,
        55,
        28,
        30,
        27
    ],
    "Challenge|Chaos|Duel|Injustice|Suspenseful|Thrilling": [
        95,
        30,
        16,
        55,
        78
    ],
    "Challenge|Escape|Injustice|Schemes|Suspenseful|Thrilling": [
        16,
        30,
        55,
        28,
        78
    ],
    "Challenge|Duel|Injustice|Schemes|Suspenseful|Thrilling": [
        30,
        78,
        95,
        16,
        55
    ],
    "Chaos|Discovery|Escape|Evil|Suspenseful|Thrilling": [28, 16, 75, 55, 80],
    "Chaos|Discovery|Duel|Evil|Suspenseful|Thrilling": [75, 80, 28, 95, 26],
    "Discovery|Escape|Evil|Schemes|Suspenseful|Thrilling": [28, 16, 75, 55, 30],
    "Discovery|Duel|Evil|Schemes|Suspenseful|Thrilling": [75, 28, 95, 26, 30],
    "Challenge|Chaos|Discovery|Escape|Suspenseful|Thrilling": [
        16,
        28,
        75,
        55,
        27
    ],
    "Challenge|Chaos|Discovery|Duel|Suspenseful|Thrilling": [
        16,
        75,
        27,
        80,
        28
    ],
    "Challenge|Discovery|Escape|Schemes|Suspenseful|Thrilling": [
        16,
        28,
        30,
        75,
        55
    ],
    "Challenge|Discovery|Duel|Schemes|Suspenseful|Thrilling": [
        30,
        16,
        75,
        78,
        27
    ],
    "Chaos|Escape|Evil|Injustice|Mysterious|Thrilling": [55, 80, 28, 65, 16],
    "Chaos|Duel|Evil|Injustice|Mysterious|Thrilling": [80, 95, 26, 46, 55],
    "Escape|Evil|Injustice|Mysterious|Schemes|Thrilling": [55, 28, 30, 78, 65],
    "Duel|Evil|Injustice|Mysterious|Schemes|Thrilling": [78, 95, 26, 30, 46],
    "Challenge|Chaos|Escape|Injustice|Mysterious|Thrilling": [
        16,
        55,
        80,
        28,
        13
    ],
    "Challenge|Chaos|Duel|Injustice|Mysterious|Thrilling": [78, 80, 95, 26, 13],
    "Challenge|Escape|Injustice|Mysterious|Schemes|Thrilling": [
        30,
        16,
        55,
        78,
        11
    ],
    "Challenge|Duel|Injustice|Mysterious|Schemes|Thrilling": [
        78,
        30,
        11,
        95,
        26
    ],
    "Chaos|Discovery|Escape|Evil|Mysterious|Thrilling": [75, 80, 28, 55, 16],
    "Chaos|Discovery|Duel|Evil|Mysterious|Thrilling": [75, 80, 26, 13, 28],
    "Discovery|Escape|Evil|Mysterious|Schemes|Thrilling": [75, 28, 55, 16, 80],
    "Discovery|Duel|Evil|Mysterious|Schemes|Thrilling": [75, 26, 78, 80, 13],
    "Challenge|Chaos|Discovery|Escape|Mysterious|Thrilling": [
        16,
        75,
        80,
        28,
        13
    ],
    "Challenge|Chaos|Discovery|Duel|Mysterious|Thrilling": [75, 80, 26, 13, 78],
    "Challenge|Discovery|Escape|Mysterious|Schemes|Thrilling": [
        16,
        75,
        28,
        13,
        30
    ],
    "Challenge|Discovery|Duel|Mysterious|Schemes|Thrilling": [
        75,
        78,
        26,
        13,
        30
    ],
    "Chaos|Escape|Evil|Horrific|Injustice|Suspenseful": [28, 55, 80, 46, 65],
    "Chaos|Duel|Evil|Horrific|Injustice|Suspenseful": [46, 80, 28, 95, 26],
    "Escape|Evil|Horrific|Injustice|Schemes|Suspenseful": [28, 55, 30, 40, 46],
    "Duel|Evil|Horrific|Injustice|Schemes|Suspenseful": [46, 28, 95, 26, 30],
    "Challenge|Chaos|Escape|Horrific|Injustice|Suspenseful": [
        28,
        16,
        55,
        80,
        30
    ],
    "Challenge|Chaos|Duel|Horrific|Injustice|Suspenseful": [80, 28, 95, 26, 30],
    "Challenge|Escape|Horrific|Injustice|Schemes|Suspenseful": [
        28,
        30,
        16,
        55,
        11
    ],
    "Challenge|Duel|Horrific|Injustice|Schemes|Suspenseful": [
        30,
        78,
        11,
        28,
        95
    ],
    "Chaos|Discovery|Escape|Evil|Horrific|Suspenseful": [28, 80, 55, 16, 75],
    "Chaos|Discovery|Duel|Evil|Horrific|Suspenseful": [80, 28, 26, 46, 75],
    "Discovery|Escape|Evil|Horrific|Schemes|Suspenseful": [28, 55, 16, 80, 75],
    "Discovery|Duel|Evil|Horrific|Schemes|Suspenseful": [28, 26, 46, 80, 75],
    "Challenge|Chaos|Discovery|Escape|Horrific|Suspenseful": [
        28,
        16,
        80,
        55,
        75
    ],
    "Challenge|Chaos|Discovery|Duel|Horrific|Suspenseful": [80, 28, 26, 16, 75],
    "Challenge|Discovery|Escape|Horrific|Schemes|Suspenseful": [
        28,
        16,
        83,
        30,
        55
    ],
    "Challenge|Discovery|Duel|Horrific|Schemes|Suspenseful": [
        28,
        26,
        30,
        78,
        16
    ],
    "Chaos|Escape|Evil|Horrific|Injustice|Mysterious": [80, 28, 55, 65, 26],
    "Chaos|Duel|Evil|Horrific|Injustice|Mysterious": [80, 26, 46, 65, 28],
    "Escape|Evil|Horrific|Injustice|Mysterious|Schemes": [28, 55, 65, 80, 11],
    "Duel|Evil|Horrific|Injustice|Mysterious|Schemes": [26, 46, 78, 65, 80],
    "Challenge|Chaos|Escape|Horrific|Injustice|Mysterious": [
        80,
        28,
        55,
        16,
        15
    ],
    "Challenge|Chaos|Duel|Horrific|Injustice|Mysterious": [80, 26, 78, 15, 11],
    "Challenge|Escape|Horrific|Injustice|Mysterious|Schemes": [
        11,
        28,
        30,
        55,
        78
    ],
    "Challenge|Duel|Horrific|Injustice|Mysterious|Schemes": [
        78,
        11,
        26,
        30,
        80
    ],
    "Chaos|Discovery|Escape|Evil|Horrific|Mysterious": [80, 28, 75, 26, 36],
    "Chaos|Discovery|Duel|Evil|Horrific|Mysterious": [80, 26, 75, 28, 36],
    "Discovery|Escape|Evil|Horrific|Mysterious|Schemes": [28, 80, 75, 26, 36],
    "Discovery|Duel|Evil|Horrific|Mysterious|Schemes": [26, 80, 75, 28, 36],
    "Challenge|Chaos|Discovery|Escape|Horrific|Mysterious": [
        80,
        28,
        16,
        75,
        26
    ],
    "Challenge|Chaos|Discovery|Duel|Horrific|Mysterious": [80, 26, 75, 13, 28],
    "Challenge|Discovery|Escape|Horrific|Mysterious|Schemes": [
        28,
        16,
        80,
        75,
        11
    ],
    "Challenge|Discovery|Duel|Horrific|Mysterious|Schemes": [26, 78, 80, 75, 11]
};
