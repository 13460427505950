import React from "react";
const IconConceptSourceSVG = (props) => {
    const color = props.color || "black";
    const { style } = props
    return (
        <div style={style}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.83 7.36"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1">
                <path fill={color} d="M7.17,3.76l.92-.92V5.89A1.39,1.39,0,0,1,8,6.46a1.7,1.7,0,0,1-.32.47,1.65,1.65,0,0,1-.46.32,1.56,1.56,0,0,1-.57.11H1.47A1.56,1.56,0,0,1,.9,7.25a1.56,1.56,0,0,1-.47-.32,1.67,1.67,0,0,1-.31-.47A1.39,1.39,0,0,1,0,5.89V1.48A1.39,1.39,0,0,1,.12.91,1.54,1.54,0,0,1,.43.44,1.54,1.54,0,0,1,.9.13,1.39,1.39,0,0,1,1.47,0H6.72L5.8.93H1.47a.55.55,0,0,0-.55.55V5.89a.55.55,0,0,0,.55.55H6.62A.53.53,0,0,0,7,6.28a.54.54,0,0,0,.17-.39Zm.55-1.58L4.6,5.29,3,5.81l.52-1.57L6.66,1.12Zm-1-.27a.1.1,0,0,0,0-.17.1.1,0,0,0-.16,0L4.15,4.18a.1.1,0,0,0,0,.17s0,0,.08,0a.08.08,0,0,0,.07,0ZM8.7.57a.48.48,0,0,1,.13.32.45.45,0,0,1-.13.32l-.46.44L7.19.59,7.63.14A.45.45,0,0,1,8,0a.4.4,0,0,1,.31.14l.22.21L8.7.57Z" /></g></g></svg>
        </div>
    );
}

export default IconConceptSourceSVG;
