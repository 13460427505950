import React from "react";
import styled from "styled-components";

const ALPHA = "80"

const DescriptorsSummary = styled.div`
        width: max-content;
        margin: 1rem 0 1rem 0;
        color: white;
        display: flex;
        justify-content: space-between;`
const Pills = styled.div`
        width: auto;
        padding: 0.7rem 1.4rem;
        font-size: 1.15rem;
        box-shadow: inset 0 0.5px 1.5px 0 rgb(0 0 0 / 50%);
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        font-stretch: normal;
        border-radius: 20px;
        letter-spacing: 0.71px;
        background-color: ${props => `${props.paletteColor}${ALPHA}`};
        margin-right: 2rem;`
const DescriptorSummary = ({ rawDescriptors, paletteColor, style }) => {

    const descriptors = rawDescriptors
        .filter((d) => { return d.category === "Scenario" || d.category === "Video Mood" || d.category === "Setting - Place" || d.category === "Theme"; });
    const cookedDescriptors = [];
    descriptors && descriptors.forEach(e => e.keywords[0].weight === 9 && cookedDescriptors.push(e.keywords[0].tag))

    return cookedDescriptors ? (
        <DescriptorsSummary style={style || {}}>
            {cookedDescriptors.sort(() => Math.random() - 0.5).slice(0, 3).map((i) => (
                <Pills paletteColor={paletteColor.primary} key={i.replaceAll(" ", ""
                )}>
                    {i}
                </Pills>
            ))}
        </DescriptorsSummary>
    ) : null;
}

export default DescriptorSummary;
