import React from "react";
import { connect } from "react-redux";
import ReactGA from "react-ga";
import store from "../store";
import { SEARCH_RESULT_CLICKED } from "../constants";
import ImgOnLoad from "./ImgOnLoad";
import { StringSimple } from "../i18n/strings";
import PopularityIconSVG from "./PopularityIconSVG";
import { Parser } from "html-to-react";
import { DEBUGLOG } from "../debug/debuglog";

/**
 * Helpme is a functional component
 * @param {Object} props visible, true or false, what to display as children, the classname that styles the popup
 */
const Helpme = ({ className, visible, children }) => {
    const style = { display: visible ? "inline" : "none" };
    return (
        <span className={className} style={style}>
            {children}
        </span>
    );
};

function mapStateToProps(state) {
    return {
        lang: state.lang,
        term: state.term
    };
}

const SEARCHCSS = "search-highlight";

const START = "´´´";
const END = "¨¨¨";
const STARTRE = new RegExp(START, "g");
const ENDRE = new RegExp(END, "g");

const STARTSPAN = `<span className="${SEARCHCSS}">`;
const ENDSPAN = "</span>";

const ISCAST = key => /cast/.test(key);
const ISCREW = key => /crew/.test(key);

class SearchResult extends React.Component {
    constructor(props) {
        super(props);
        this.state = { visible: false, tmsId: "" };
        this.prog = {};
        this.parser = new Parser();
    }
    handleSearchResultClick = ev => {
        if (process.env.REACT_APP_GTAG) {
            ReactGA.event({
                category: "Search",
                action: `Search Result Clicked`
            });
        }
        ev.preventDefault();
        if (Object.keys(this.prog).length !== 0) {
            store.dispatch({
                type: SEARCH_RESULT_CLICKED,
                prog: this.prog
            });
        } else return;
    };

    enter = ev => {
        this.setState({ visible: true });
        ev.preventDefault();
    };

    leave = ev => {
        this.setState({ visible: false });
        ev.preventDefault();
    };

    /**
     * Create the actual highlighted span. Consider the whole search term first, then the individual pieces.
     * We find what matches, flag them with START and END, the replace START and END with STARTSPAN and ENDSPAN
     */
    highlight = (find, inTarget, key, domKey) => {
        const findRe = new RegExp(find, "i");
        const matches = {};
        const exactMatch = this.props.item.matched_queries.includes(
            "multi_match_title_cast_crew_hard"
        );

        let execResult = findRe.exec(inTarget);

        if (execResult && exactMatch) {
            matches[`${find.toLowerCase().replace(/ /g, "_")}`] = execResult[0];
        } else {
            const wordAndExpressions = new Map();
            if (exactMatch) {
                wordAndExpressions.set(
                    find.toLowerCase(),
                    new RegExp(find, "ig")
                );
            } else {
                find.trim()
                    .split(" ")
                    .forEach(word => {
                        wordAndExpressions.set(
                            word.toLowerCase(),
                            new RegExp(word, "ig")
                        );
                    });
            }

            for (const [word, regex] of wordAndExpressions) {
                execResult = regex.exec(inTarget);
                if (execResult) {
                    if (!matches.hasOwnProperty(`${word}`)) {
                        matches[`${word}`] = "";
                    }

                    matches[`${word}`] = execResult[0];

                    while (execResult !== null) {
                        execResult = regex.exec(inTarget);

                        if (execResult && matches[`${word}`]) {
                            matches[`${word}`] = execResult[0];
                        }
                    }
                }
            }
        }

        const keys = Object.keys(matches);

        if (keys.length) {
            let building = inTarget;
            const seen = new Set();
            const tagMatches = tgt => {
                if (!seen.has(tgt)) {
                    let re = new RegExp(tgt, "gi");
                    building = building.replace(re, `${START}${tgt}${END}`);
                    seen.add(tgt);
                }
            };

            for (let match in keys) {
                tagMatches(matches[keys[match]]);
            }

            building = building
                .replace(STARTRE, STARTSPAN)
                .replace(ENDRE, ENDSPAN);

            let role = "";
            if (ISCAST(key) || ISCREW(key)) {
                if (ISCAST(key)) {
                    role = this.props.item.tms.cast[key].role;
                } else {
                    role = this.props.item.tms.crew[key].role;
                }

                role = `<span> (${role})</span>`;
            }

            let replaced = `<span key="${domKey}">${building}${role}</span>`;

            let parsed = this.parser.parse(replaced);

            return parsed;
        }

        return null;
    };

    /**
     * Return all the spans that match the search term
     */
    highlightedResults = () => {
        let search = this.props.item.search;
        let term = this.props.term;
        let highlights = { cast: {}, crew: {} };

        for (let key of Object.keys(search)) {
            let value = search[key];

            let domKey = `r_${key}_${this.props.item.tms.tmsId}`;
            let result = this.highlight(term, value, key, domKey);
            if (result) {
                if (ISCAST(key)) {
                    highlights.cast[key] = result;
                } else if (ISCREW(key)) {
                    highlights.crew[key] = result;
                } else {
                    highlights[key] = result;
                }
            }
        }

        if (!Object.keys(highlights.cast).length) {
            delete highlights.cast;
        }

        if (!Object.keys(highlights.crew).length) {
            delete highlights.crew;
        }

        return highlights;
    };

    // from https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-line-clamp#Example
    // reason for using code rather than regular css: on chrome "-webkit-box-orient": "vertical",
    // is not being rendered after a page reload, if it's in the css only...!
    twoLineStyle = () => {
        return {
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: "2",
            overflow: "hidden"
        };
    };

    render() {
        const item = this.props.item;
        this.prog = item;
        let CookedTitle = <span>{item.search.title}</span>;
        let CastAndCrew = null;
        let extent = false;

        let highlights = this.highlightedResults();

        DEBUGLOG(
            item.score,
            item.ads.vps,
            item.tms.tmsId,
            item.tms.title,
            item.matched_queries.toString()
        );

        // Will log what keys the result should have - cast and/or crew wise.
        //console.log(item.search.title, "Should have:", Object.keys(highlights));

        if (highlights.hasOwnProperty("title")) {
            CookedTitle = highlights.title;
        }

        const hasCast = highlights.hasOwnProperty("cast");
        const hasCrew = highlights.hasOwnProperty("crew");
        let Line1 = null;
        let Line2 = null;
        let Break = null;

        if (hasCast || hasCrew) {
            let castKeys = (hasCast && Object.keys(highlights.cast)) || [];
            let nCast = castKeys.length;
            let crewKeys = (hasCrew && Object.keys(highlights.crew)) || [];
            let nCrew = crewKeys.length;
            let castNum = castKeys.map(k => ({
                i: parseInt(k.replace(/[^0-9]./g, "")),
                r: k
            }));
            let crewNum = crewKeys.map(k => ({
                i: parseInt(k.replace(/[^0-9]./g, "")),
                r: k
            }));

            /*
             * If cast, and no crew, set the first line to the first cast match,
             * and the second line to the second cast match, if present.
             */
            if (hasCast && !hasCrew) {
                Line1 = highlights.cast[castKeys[0]];
                if (nCast > 1) {
                    extent = true;
                    Line2 = highlights.cast[castKeys[1]];
                    Break = <br />;
                }
            }

            /*
             * If crew and no cast, set the first line to the first crew match,
             * and the second line to the second crew match, if present.
             */
            if (hasCrew && !hasCast) {
                Line1 = highlights.crew[crewKeys[0]];
                if (nCrew > 1) {
                    Line2 = highlights.crew[crewKeys[1]];
                    extent = true;
                    Break = <br />;
                }
            }

            /*
             * There is at least one crew and one cast match.
             */

            if (hasCrew && hasCast) {
                /*
                 * Line break guaranteed.
                 */
                Break = <br />;

                extent = true;

                /*
                 * put all of the matches into an array, crew then cast (so index conflicts resolve in
                 * favor of cast).
                 *
                 * Filter the undefined values, then take the first two
                 */
                let a = [];
                crewNum.forEach(c => (a[c.i] = c.r));

                castNum.forEach(c => (a[c.i] = c.r));

                let f = a.filter(i => i !== undefined);

                if (ISCAST(f[0])) {
                    Line1 = highlights.cast[f[0]];
                } else {
                    Line1 = highlights.crew[f[0]];
                }

                if (ISCAST(f[1])) {
                    Line2 = highlights.cast[f[1]];
                } else {
                    Line2 = highlights.crew[f[1]];
                }
            }

            CastAndCrew = (
                <div key={this.props.item.tms.tmsId} className="cast-and-crew">
                    {Line1}
                    {Break}
                    {Line2}
                </div>
            );
        }

        let Popscore = this.popscore(item);

        let showTheOneWeWant =
            item.tms && item.tms.lPreferredImage && item.tms.lPreferredImage.uri
                ? true
                : false;

        return (
            <div
                key={`${item.tms.tmsId}_searchResult`}
                className="search-result"
                onClick={this.handleSearchResultClick}
            >
                <div className="media-holder">
                    <div className="media">
                        {showTheOneWeWant ? (
                            <ImgOnLoad
                                className="tile_img"
                                image={
                                    (item.tms.lPreferredImage &&
                                        item.tms.lPreferredImage.uri) ||
                                    null
                                }
                                placeholder={"/ads_placeholder.png"}
                                alt={item.stitle}
                            />
                        ) : (
                            <div>
                                <ImgOnLoad
                                    className="tile_img_background"
                                    image={
                                        (item.tms.preferredImage &&
                                            item.tms.preferredImage.uri) ||
                                        null
                                    }
                                    placeholder={"/ads_placeholder.png"}
                                    alt={item.stitle}
                                />
                                <ImgOnLoad
                                    className="tile_img"
                                    image={
                                        (item.tms.preferredImage &&
                                            item.tms.preferredImage.uri) ||
                                        null
                                    }
                                    placeholder={"/ads_placeholder.png"}
                                    alt={item.stitle}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div key={`${item.tms.tmsId}_info`} className="info">
                    <div key="infoTms" className="tms">
                        <div className="firstline">
                            <div key="tmsTitle" className="title">
                                {CookedTitle}
                            </div>
                            <div key="tmsYear" className="year">
                                {item.tms.releaseYear}
                            </div>
                        </div>
                        {CastAndCrew}
                        {extent ? (
                            <div className="desc-trunc">
                                <p style={this.twoLineStyle()}>
                                    {item.tms.shortDescription}
                                </p>
                            </div>
                        ) : (
                            <div key="tmsDesc" className="desc">
                                {item.tms.shortDescription}
                            </div>
                        )}
                    </div>
                    <div className="ads">{Popscore}</div>
                </div>
            </div>
        );
    }

    popscore(item) {
        return item.ads.vps === 0 || item.tms.lang !== "en" ? (
            <div className={`popscore lang ${item.tms.lang || "en"}`} />
        ) : (
            <div className="popscore">
                <PopularityIconSVG
                    color="#444"
                    onMouseEnter={this.enter}
                    onMouseLeave={this.leave}
                    className="popularity-icon"
                    alt="Popularity Icon"
                />

                <span onMouseEnter={this.enter} onMouseLeave={this.leave}>
                    &#x00A0;{item.ads.vps}
                </span>

                <Helpme className="helpme" visible={this.state.visible}>
                    <StringSimple
                        which="popularityScore"
                        lang={this.props.lang}
                    />
                </Helpme>
            </div>
        );
    }
}

export default connect(mapStateToProps)(SearchResult);
