import React, { useState } from "react";
import styled from "styled-components";
import { RTWTypeIcon } from "./ContentItemDetails/ReasonToWatchIncludes";
import { UI } from "./constants";
import HTMLParse from "./HTMLParse";

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
`;

const Stacked = styled.div`
  position: relative;
  z-index: ${(props) => props.z};
  grid-area: 1 / 1;
`;
/*
$aspectW: 1.25vw;
$aspectH: 2vw;
*/
const aspectW = "1vw";
const aspectH = "1vw";
const aspectFraction = 9 / 16;
const width = 24;
const height = width * aspectFraction;
//259
//1vw = 19.2
//0.56250

const CarouselPosterDiv = styled(Stacked)`
  cursor: pointer;
  filter: drop-shadow(0 0.2rem 0.2rem rgba(0, 0, 0, 0.5));
  background: url(${(props) => props.background}) no-repeat;
  background-size: contain;
  margin-bottom: 5px;
  border-radius: 5px;
  width: calc(${width} * ${aspectW});
  height: calc(${height} * ${aspectH});
  pointer-events: auto;
  display: inline-block;
`;

const CarouselPosterScrim = styled(CarouselPosterDiv)`
  filter: none;
  background: ${(props) => props.background};
`;

const CarouselPosterSwapDiv = styled(CarouselPosterDiv)`
  transition-delay: 0.1s;
  transition-timing-function: cubic-bezier(0, 1, 1, 0);
  &:hover {
    background: url(${(props) => props.swapImg});
    background-size: contain;
  }
`;

const HoveredContents = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  margin: 0 0 0 0.5rem;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
`;

const HoveredContentsItem = styled.div`
  display: flex;
  align-items: center;
`;
const HoveredContentsItemText = styled.span`
    color:white;
    font-weight:${UI.font.weight.regular};
    font-size:1.4rem;
    margin:0 1rem 0 .5rem;
    width:100%;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: center;
`;

export const PosterHover = ({ item, index }) => {
    const [hover, setHover] = useState(false);

    const handleMouseEnter = () => {
        setHover(true);
    };
    const handleMouseLeave = () => {
        setHover(false);
    };

    if (item.swapItem) {
        return (
            <div key={index} className="swiper-slide" onClick={item.action}>
                <CarouselPosterSwapDiv background={item.uri} swapImg={item.swapItem} />
            </div>
        );
    }

    return (
        <Container
            className="swiper-slide"
            key={index}
            onClick={item.action}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <CarouselPosterDiv background={item.uri} z={1} />
            {item.hasReasonsToWatch && !item.swapItem ? (
                <>
                    <CarouselPosterScrim
                        style={{
                            display: !hover ? "none" : "initial",
                        }}
                        background={`${UI.color.black}${UI.color.transparancySuffix(0.7)}`}
                        z={2}
                    >
                        <HoveredContents>
                            <HoveredContentsItem key={item.reasonToWatch.type}>
                                <RTWTypeIcon
                                    type={item.reasonToWatch.type}
                                    style={{
                                        width: "1.5rem",
                                        marginLeft: "1rem",
                                    }}
                                />
                                <HoveredContentsItemText>
                                    <HTMLParse item={item.reasonToWatch.text} />
                                </HoveredContentsItemText>
                            </HoveredContentsItem>
                        </HoveredContents>
                    </CarouselPosterScrim>
                </>
            ) : null}
        </Container>
    );
};
