import React from "react";
const IconAwardSVG = (props) => {
    const color = props.color || "black";
    const { style } = props;
    return (
        <div style={style}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.65 7.65"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1">
                <path fill={color} d="M7.54,4.49a.37.37,0,0,1,.11.27A.32.32,0,0,1,7.57,5a.34.34,0,0,1-.2.13l-.93.23.08.23a2.45,2.45,0,0,0,.08.29c0,.1.06.2.08.29a1.15,1.15,0,0,1,0,.22.36.36,0,0,1-.11.25.34.34,0,0,1-.25.11.69.69,0,0,1-.22,0L5.85,6.6l-.29-.08-.23-.08-.23.93a.34.34,0,0,1-.13.2.35.35,0,0,1-.22.08.36.36,0,0,1-.26-.11l-.67-.68-.67.68a.33.33,0,0,1-.26.11.32.32,0,0,1-.21-.08.34.34,0,0,1-.13-.2l-.24-.93-.23.08-.29.08-.28.08a.74.74,0,0,1-.23,0A.34.34,0,0,1,1,6.61a.39.39,0,0,1-.1-.25.69.69,0,0,1,0-.22C1,6.05,1,6,1,5.85l.09-.29.08-.23L.27,5.1A.31.31,0,0,1,.08,5,.34.34,0,0,1,0,4.76a.37.37,0,0,1,.11-.27l.68-.67L.11,3.16A.37.37,0,0,1,0,2.89a.32.32,0,0,1,.08-.21.35.35,0,0,1,.19-.13l.94-.23-.08-.23L1,1.79A2.86,2.86,0,0,1,1,1.51a.73.73,0,0,1,0-.22A.33.33,0,0,1,1,1,.32.32,0,0,1,1.28.93a.79.79,0,0,1,.23,0L1.79,1l.29.09.23.08L2.55.28a.34.34,0,0,1,.13-.2A.32.32,0,0,1,2.89,0a.33.33,0,0,1,.26.11l.67.68L4.49.11A.36.36,0,0,1,4.74,0,.35.35,0,0,1,5,.07.33.33,0,0,1,5.1.28l.23.93.23-.08A1.61,1.61,0,0,0,5.85,1L6.14,1a.69.69,0,0,1,.22,0A.36.36,0,0,1,6.61,1a.33.33,0,0,1,.11.25,1.31,1.31,0,0,1,0,.22c0,.09,0,.19-.08.28l-.08.29c0,.1-.06.18-.08.24l.93.23a.34.34,0,0,1,.2.13.32.32,0,0,1,.08.21.37.37,0,0,1-.11.27l-.69.66Z" /></g></g></svg>
        </div>
    );
}

export default IconAwardSVG;
