import React from "react";
import styled, { css } from "styled-components";
import { UI } from "../constants"

export const Title = styled.div`
  font-size: ${UI.font.mediumsmall};
  font-weight: 400;
  color: rgba(255,255,255,0.7);
  line-height: 3rem;
  display:flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: fit-content;
  text-transform: uppercase;
  padding: 0.3rem 0
  border-bottom: solid thin ${props => props.borderColor}
  background: ${props => props.paletteColor}
  border-radius: 20px 20px 0px 0px;
`;
/*
&::before {
    font-size: ${UI.font.small}
    vertical-align: middle;
    content: "${props => props.contentTitle}";
    color:white;
    display:flex;
    justify-content: center;
    position: relative;
    width: 100%;
    height: fit-content;
    text-transform: uppercase;
    padding: 0.3rem 0
    border-bottom: solid thin ${props => props.borderColor}
*/

/**
 * AbsoluteBoundingBox is a styled.div, that has absolute positioning.
 */
const AbsoluteBoundingBox = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 20px 20px 0px 0px;
  background-repeat: no-repeat;
  background-size: cover;
`;
/**
 * BgBoundingBox (background bounding box) is a styled AbsoluteBoundingBox
 */
const slider = "12%"
export const BgBoundingBox = styled(AbsoluteBoundingBox)`
  background: linear-gradient(to bottom, ${props => props.paletteColor}, ${props => props.paletteColor} ${props => props.gradientHardStop || slider}, transparent ${props => props.gradientHardStop || slider}, transparent);
  width: 99.99%;
  height: 99.99%;
`;

// /**
//  * ScrimBoundingBox is a styled AbsoluteBoundingBox that sets the overlay over the BgBoundingBox
//  */
// const ScrimBoundingBox = styled(AbsoluteBoundingBox)`
//   background-color: rgba(0, 0, 0, 0.81);
// `;

/**
 * BoundingBox is a styled AbsoluteBoundingBox that represents the topmost layer.
 */
export const BoundingBox = styled(AbsoluteBoundingBox)`
  margin: ${UI.layout.modalMargin} 0;
}
`;

/**
 * Tabs is a styled.div
 */
export const Tabs = styled.div`
    display: grid;
    grid-template-columns: repeat(4, auto) 1fr;
    grid-column-gap: 4rem;
    grid-template-rows: auto;
    //margin-left: ${UI.layout.modalLeft};
    //margin-left: 1.8rem;
    margin-bottom: 1rem;
    padding: 1.6rem 3.6rem 0;
    background-color: ${props => props.paletteColor || "#ff000000"};
`;

/**
 * UnstyledTab is a functional component
 * @param {Object} param0 - the className, children and click handler
 */
export const UnstyledTab = ({ className, children, onClick }) => (
  <div className={className} onClick={onClick}>
    {children}
  </div>
);

/**
 * Tab is a styled UnstyledTab
 * color: ${props => (props.selected ? "white" : UI.color.gray)};
 */
export const Tab = styled(UnstyledTab)`
  color: ${props => (props.selected ? "white" : "rgba(255,255,255,0.7)")};
  border-bottom: ${props => (props.selected ? `solid white .2rem` : "none")};
  font-size: ${UI.font.mediumsmall};
  font-weight: ${props => (props.selected ? 700 : 400)};
  padding-bottom: 0.5rem;
  margin: 0.4rem 0 0 0;
  cursor: pointer;
  ${css`
    width: fit-content;
  `};
`;
