import React, { Component } from "react";
import styled from "styled-components";
import KeywordItem from "./KeywordItem";
import CategoryItemContainer from "./CategoryItemContainer";

import { UI, colorForCategory, NKEYWORDS } from "../constants";
const WRAPPER = "modal-table-wrapper";
const EXPANDED = "";
const WRAPPER_EXPANDED = `${WRAPPER} ${EXPANDED}`;
const CATEGORY_WRAPPER = "category-wrapper";
const CATEGORY_WRAPPER_EXPANDED = `${CATEGORY_WRAPPER} ${EXPANDED}`;
const MANY_CATEGORIES = "many-categories";
const IS_MORE = "is-more";
const VISIBILITY_THRESHOLD = 4;

/**
 * This amout of catgories does not need a scrollbar, when expand has been clicked
 */
const MANY_CATEGORIES_THRESHOLD = 7;
/**
 * When expand is clicked, there will be more categories that need to be scrolled into view.
 */
const IS_MORE_THRESHOLD = MANY_CATEGORIES_THRESHOLD + 1;
/**
 *
 * Add 'toAppend' to 'appendee' if length is greater then threshold.
 *
 * @param {Number} length
 * @param {Number} threshold
 * @param {String} appendee
 * @param {String} toAppend
 */
const APPEND = (length, threshold, appendee, toAppend) =>
  length > threshold ? `${appendee} ${toAppend}` : `${appendee}`;

const KeywordsSectionTitle = styled.div`
    font-size: ${UI.font.small};
    font-weight: ${UI.font.weight.regular}
    color: white;
    width: 98%;
    margin: 0 0 .4rem 0;
    display: flex;
`;

class ContentKeywords extends Component {
  state = {
    dropdownVisible: true
  };

  toggleDropdown = () => {
    // this.setState(state => ({
    //     dropdownVisible: !state.dropdownVisible
    // }));
  };
  render() {
    const categoriesLength = this.props.categories.length;
    const dropdownVisible = this.state.dropdownVisible;

    let wrapperClassName = dropdownVisible ? WRAPPER_EXPANDED : WRAPPER;
    wrapperClassName = APPEND(
      categoriesLength,
      IS_MORE_THRESHOLD,
      wrapperClassName,
      IS_MORE
    );
    let categoryClassName = dropdownVisible
      ? CATEGORY_WRAPPER_EXPANDED
      : CATEGORY_WRAPPER;

    categoryClassName = APPEND(
      categoriesLength,
      MANY_CATEGORIES_THRESHOLD,
      categoryClassName,
      MANY_CATEGORIES
    );

    return (
      <div>
        <KeywordsSectionTitle>
          {/* <div
                        className={
                            this.state.dropdownVisible
                                ? "arrow-right arrow-down"
                                : "arrow-right"
                        }
                        onClick={this.toggleDropdown}
                    >
                        <div className="caret-parent">
                            <div className="caret" />
                        </div>
                    </div> */}
          {/* STRING: Video Descriptors */}
          Video Descriptors
        </KeywordsSectionTitle>

        <div className={wrapperClassName}>
          <div className="category-keyword-wrapper">
            <div className={categoryClassName}>
              {this.props.categories.map((cat, index) => (
                <>
                  <CategoryItemContainer
                    visible={
                      index < VISIBILITY_THRESHOLD || this.state.dropdownVisible
                    }
                    key={10 + index}
                    category={cat.category}
                  >
                    <KeywordsByCategory
                      dropdownVisible={
                        index < VISIBILITY_THRESHOLD ||
                        this.state.dropdownVisible
                      }
                      key={100 + index}
                      category={cat.category}
                      keywords={[...cat.keywords]}
                      indexForColor={index}
                    />
                  </CategoryItemContainer>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/**
 * KeywordsByCategory is a functional components, with a StyledKeywords/KeywordItem hierarchy as children.
 * @param {Object} param0 the category and the kewords (Array)
 */
const KeywordsByCategory = ({
  indexForColor,
  category,
  keywords,
  dropdownVisible
}) => {
  return (
    <StyledKeywords
      style={{
        color: colorForCategory(category)
      }}
    >
      {keywords.slice(0, NKEYWORDS).map((keyword, index) => (
        <div key={`kwparent_${index}`} className="kwparent">
          <KeywordItem
            increaseSpecificity="lozenge"
            visible={dropdownVisible}
            key={`${category}_${index}`}
            category={category}
            tag={keyword.tag}
            weight={9}
            indexForColor={indexForColor}
            value={keyword.weight}
          />
        </div>
      ))}
    </StyledKeywords>
  );
};

/**
 * Keywords is a functional component
 * @param {Object} param0 className and children
 */
const Keywords = ({ className, children }) => (
  <div className={className}> {children}</div>
);

/**
 * StyledKeywords is a styled Keywords
 */
const StyledKeywords = styled(Keywords)`
  display: flex;
  line-height: ${UI.layout.lineHeight};
  flex-wrap: wrap;
`;

export default ContentKeywords;
