import React from "react";
import styled from "styled-components";
import { UI } from "../constants";

const navHeight = 5; // Not that it matters much, but know that everything's gonna be in `rem`!
const shadowHeight = 0.1 * navHeight;
const effectiveNavHeight = navHeight - shadowHeight; // The shadow's actually _interior_ to the div. This let's us just focus on the visibly "flat" part of the nav bar.
// const logoHeight = 0.65 * effectiveNavHeight;
// const logoMargin = (effectiveNavHeight - logoHeight) / 2; // `/ 2` because top & bottom margins match.

// Here's how these ☝️ calculations fit together:
//
// --------------  (not to scale 😋)                    \
//               }—— logoMargin \                        \
//       O       }—— logoHeight  ┝—— effectiveNavHeight   \
//               }—— logoMargin /                         }—— navHeight
// --------------                                        /
// ~~~~~~~~~~~~~~ ↖️—— shadowHeight                     /

const Home = styled.div`
  font-size: ${UI.font.medium};
`;

// Switched from img tag, with an svg src to a background with data uri because we can specify image/svg+xml as the mime type.
// const sideMargin = "4%";
// const Logo = styled.div`
//     background-image: url("data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNjcu
//     NiA2Ny42Ij48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6IzAwYWVlZjt9LmNscy0ye2ZpbGw6I2Zm
//     Zjt9PC9zdHlsZT48L2RlZnM+PHBhdGggaWQ9IlNWR0lEIiBjbGFzcz0iY2xzLTEiIGQ9Ik0yMTgu
//     OSwzMDUuM2EzMy44LDMzLjgsMCwxLDAsMzMuOC0zMy44LDMzLjg5LDMzLjg5LDAsMCwwLTMzLjgs
//     MzMuOCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIxOC45IC0yNzEuNSkiLz48cGF0aCBjbGFzcz0i
//     Y2xzLTIiIGQ9Ik0yNjQuNywzMjIuOWMwLDIuOS01LjQsNS4yLTEyLjEsNS4ycy0xMi4xLTIuMy0x
//     Mi4xLTUuMiw1LjQtNS4yLDEyLjEtNS4yLDEyLjEsMi40LDEyLjEsNS4yIiB0cmFuc2Zvcm09InRy
//     YW5zbGF0ZSgtMjE4LjkgLTI3MS41KSIvPjxwYXRoIGNsYXNzPSJjbHMtMiIgZD0iTTI2Ni41LDMw
//     MS40YTEzLjgsMTMuOCwwLDEsMS0xMy44LTEzLjgsMTMuODMsMTMuODMsMCwwLDEsMTMuOCwxMy44
//     IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMjE4LjkgLTI3MS41KSIvPjxwYXRoIGNsYXNzPSJjbHMt
//     MiIgZD0iTTI2OS45LDI4OC40YTMuNCwzLjQsMCwwLDEtMy41LDMuNCwzLjQ2LDMuNDYsMCwwLDEt
//     My41LTMuNCwzLjUsMy41LDAsMCwxLDcsMCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIxOC45IC0y
//     NzEuNSkiLz48L3N2Zz4=");
//     width: ${logoHeight}rem;
//     background-repeat: no-repeat;
//     cursor: pointer;
//     height: ${logoHeight}rem;
//     float: left;
//     margin-top: ${logoMargin}rem;
//     margin-top: 1rem;
//     margin-left: ${sideMargin};
// `;

const textMargin = 0.25 * effectiveNavHeight;
const Title = styled.div`
  padding-top: ${textMargin}rem;
  padding-top: 1.25rem;
  position: absolute;
  margin-left: 3.7rem;
  color: white;
  font-weight: 600;
  font-size: ${0.5 * effectiveNavHeight}rem;
  text-transform: uppercase;
  cursor: pointer;
`;

const profileStyles = {
  display: "grid",
  //gridTemplateColumns: "1fr 1fr",
  alignItems: "center",
  color: UI.color.gray,
  float: "right",
  //marginTop: `${textMargin}rem`,
  marginTop: `1.5rem`,
  // testing this top margin value as a fixed number
  marginRight: "0"
};
// const nameStyles = {
//     color: UI.color.nielsenBlue
// };
const ProfileMenu = ({ children }) => (
  <div style={profileStyles}>
    {/* <span>Hello, </span> */}
    {children}
  </div>
);

/*
  As explained here…

    https://www.styled-components.com/docs/basics#styling-any-components

  …the `className` and `children` params are automatically
  provided by `styled-components`. `children` is just the usual
  react children. `className` gets fed a unique ID created in
  such a way as to avoid name conflicts. There is a babel plugin
  that gives these things names based on their components, if
  seeing things like `class="sc-bxivhb WKblz"` drives you nuts.
*/
const Navigation = ({
  className,
  children,
  title,
  onNavClick,
  onTitleClick
}) => (
  <header className={className}>
    <Home>
      <span
        style={{
          width: "3.5rem",
          paddingTop: "1.25rem",
          position: "absolute"
        }}
        onClick={onNavClick}
      >
        &nbsp;
      </span>
      {/* <Logo onClick={onNavClick} /> */}
      <Title
        style={{
          marginLeft: "3.7rem"
        }}
      >
        {title}
      </Title>
      <Title onClick={onTitleClick} style={{ marginLeft: "3.7rem" }}>
        {title}
      </Title>
    </Home>
    <ProfileMenu>{children}</ProfileMenu>
  </header>
);

const StyledNavigation = styled(Navigation)`
  position: sticky;
  top: 0;
  z-index: 999;
  background: linear-gradient(rgb(24, 24, 24) 0%, rgb(62, 62, 62) 100%);
  background-repeat: no-repeat;
  box-shadow: 1px 1px 8px -1px rgb(0, 0, 0);
  height: ${navHeight}rem;
`;

export default StyledNavigation;
