import React from "react";
import styled from "styled-components";
import { UI } from "../constants";
import { RTWTypeIcon } from "./ReasonToWatchIncludes";
import { RTWTypeGeneratorSeparable, MACHINE_LEARNING, EDITORIAL } from "./ReasonToWatchIncludes";
import HTMLParse from "../HTMLParse";

const OuterBoundingBox = styled.section`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  height: 80%;
  overflow-y: auto;
  margin-left: 3vw;
  margin-top: 3.8rem;
`;

const InnerBoundingBox = styled.section`
  text-align: left;
  max-width: 75%;
`;

const TextDetails = styled.div`
  margin-bottom: 0.1rem;
  margin-right: 1.5vw;
  grid-area: reasontowatch;
`;

const Content = styled.div`
    font-size: ${UI.font.tiny}
    line-height: 1.6rem;
    color: white;
    font-weight: ${UI.font.weight.medium};
    padding-bottom: 0.5rem;
`;

const DEFAULT_MOTIVATION_COLOR = `#9EA4B8`;

const Motivation = styled.div`
  padding-bottom: 1.5rem;
  color: ${props => props.paletteColor || DEFAULT_MOTIVATION_COLOR};
  font-variant-numeric: lining-nums tabular-nums;
  font-size: ${UI.font.xtiny};
  font-style: normal;
  font-weight: ${UI.font.weight.regular};
  grid-area: motivation;
`;

const ReasonToWatchBoundingBox = styled.div`
    display: grid;
    grid-template-columns: 2% 4% 88% 5% 1%;
    background-color: rgb(255 255 255 / .1);
    border-radius: .5rem;
    margin: .5rem 0;
    padding-top: 1.5rem;
    padding-right: 3rem;
    grid-template-areas:
    ". icon reasontowatch generator ."
    ". icon motivation generator ."
`;

const IconBox = styled.div`
grid-area: icon;
`;

const GeneratorBoundingBox = styled.div`
grid-area: generator;
`;

const GeneratorBox = styled.div`
background-color: ${(props) => props.bgColor || "#5dbae2"};
border-radius: .2rem;
margin: .1rem .2rem;
padding: .1rem .5rem;
text-align: center;
`;

const BoxStyler = styled.div`
display: flex; 
`
const BOXCOLORS = {};
BOXCOLORS[MACHINE_LEARNING] = "#6c599e";
BOXCOLORS[EDITORIAL] = "#a19462";

export const ReasonsToWatchDetails = ({ paletteColor, data }) => {
  const updatedBoxColors = {};
  updatedBoxColors[MACHINE_LEARNING] = paletteColor.primary;
  updatedBoxColors[EDITORIAL] = paletteColor.primary;
  const reasonsToWatch = data.reasonsToWatch.map((rtwObj, index) => (
    <InnerBoundingBox key={index}>
      <ReasonToWatchBoundingBox>
        <IconBox>
          <RTWTypeIcon color={paletteColor.primary} type={rtwObj.type} />
        </IconBox>
        <TextDetails>
          <Content><HTMLParse item={rtwObj.text} /></Content>
        </TextDetails>
        <GeneratorBoundingBox>
          <BoxStyler>
            <RTWTypeGeneratorSeparable Component={GeneratorBox}
              boxColors={updatedBoxColors} type={rtwObj.type} />
          </BoxStyler>

        </GeneratorBoundingBox>
        <Motivation>{rtwObj.motivation}</Motivation>
      </ReasonToWatchBoundingBox>
    </InnerBoundingBox >
  ));

  return <OuterBoundingBox>{reasonsToWatch}</OuterBoundingBox>;
};

export default ReasonsToWatchDetails;
