import React from "react";
import PropTypes from "prop-types";
import Carousel from "../Carousel";
import HeroCarousel from "../HeroCarousel";
import styled from "styled-components";
import { UI } from "../constants";
import { PosterLandscape } from "../PosterLandscape";
import { SpaceFillingTitleFreePoster } from "../Poster";
import { StringClassedSpan } from "../i18n/strings";
import dict from "../i18n/stringdict"
import { FPSEP } from "../api/profile";
import { connect } from "react-redux";
import { ReasonsToWatchHeroCell } from "../HeroCell";
import { PosterHover } from "../PosterHover";
import { BasedOnPoster } from "../BasedOnPoster";
import { ClipsPoster } from "../ClipsPoster"

const BoundingBox = styled.section`
  width: 96%;
  position: relative;
  left: 4%;
  margin-top: 3rem;
  background: none;
`;

// put this back in Bounding box to get the divider
// border-top: 1px solid #979797;

const HeroBoundingBox = styled(BoundingBox)`
  left: 0;
  width: 100%;
  margin-top: 0;
`

const CarouselTitleContainer = styled.div`
  font-size: ${UI.font.small};
  padding: 1rem 0;
  color: white;
`;

const CarouselDot = styled.span`
  background: rgba(168, 168, 168, 1);
  height: 0.75rem;
  width: 0.75rem;
  display: flex;
  border-radius: 50%;
  margin-right: 0.5rem;
`;

const CarouselTitle = ({ lang, title }) => {
  if (title && lang) {
    const a = title.split(FPSEP);
    const en = a[0];
    const children = [];
    if (lang === "sv" && a.length === 2) {
      children[0] = (
        <span key="1" className="i18n carouselTitle primary firstletter">
          {a[1]}
        </span>
      );
      children[1] = (
        <span key="2" className="i18n carouselTitle secondary">
          {en}
        </span>
      );
    } else {
      children[0] = (
        <span key="1" className="i18n carouselTitle primary">
          {en}
        </span>
      );
    }
    return <CarouselTitleContainer>{children}</CarouselTitleContainer>;
  }
  return null;
};

const HeroModel = (programs, dispatch) => {

  return programs.map(program => {
    let retObj = {
      tmsId: program.tmsId,
      rootId: program.rootId,
      heroData: program.heroData,
      reasonToWatch: program.reasonToWatch,
      imageUri: program.imageUri,
      action: () => dispatch(program.action),
    };

    return retObj;
  });
};

const BasedOnModel = (programs, dispatch) => {

  return programs.map(program => {
    let retObj = {
      tmsId: program.tmsId,
      rootId: program.rootId,
      basedOnData: program.basedOnData,
      reasonToWatch: program.reasonToWatch,
      action: () => dispatch(program.action),
    };

    return retObj;
  });
};

const ClipsModel = (programs, dispatch) => {

  return programs.map(program => {
    let retObj = {
      tmsId: program.tmsId,
      rootId: program.rootId,
      clipsData: program.clipsData,
      reasonToWatch: program.reasonToWatch,
      action: () => dispatch(program.action),
    };

    return retObj;
  });
};

// put something here re data-src vs src for poster images...
const ViewModel = (programs, dispatch, lazyLoad, topCarousel) => {
  return programs.map(program => {
    let retObj = {
      tmsId: program.tmsId,
      rootId: program.rootId,
      uri: program.landscapeSixNine,
      hasPersonalizedImages: program.personalizedImages.length > 0,
      hasReasonsToWatch: false,
      hasVideoClips: program.videoClips.length > 0,
      action: () => dispatch(program.action),
      lazyLoad: lazyLoad
    };
    if (program.reasonToWatch !== undefined) {
      retObj.hasReasonsToWatch = true;
      retObj["reasonToWatch"] = program.reasonToWatch;
    }
    if (program.swapItem) {
      retObj["swapItem"] = program.swapItem;
    }
    if (topCarousel) {
      retObj.uri = program.imageUri;
    }
    return retObj;
  });
};

function mapStateToProps(state) {
  return {
    profile: state.profile
  };
}

const ProgramsCarousel = ({ advancedCategoriesData, popularItemsData, heroItemsData, basedOnItemsData, clipsItemsData, dispatch, lang, profile }) => {
  ;
  const thematicCollection = {
    carouselItems: [],
    carouselTitle: "Thematic Collections"
  };


  const nonCanonicalOrder = advancedCategoriesData && profile === "profile_2" && advancedCategoriesData.length === 6 && profile !== "profile_4"
  if (nonCanonicalOrder) {
    // Insert thematicCollection Carousel
    // advancedCategoriesData.splice(0, 0, thematicCollection);
    // Insert the popular carousel
    advancedCategoriesData.splice(0, 0, popularItemsData);
    advancedCategoriesData.push(thematicCollection);
  }
  const THEM_COL = "Thematic Collections";
  const REAS_WAT = "Watch Prompts"
  const POPULAR = dict.carouselTitle[lang];
  const BASEDON = dict.basedOnCarouselTitle[lang];
  const CLIPS = dict.clipsCarouselTitle[lang];
  const INSPIRING_REALITY = "Inspiring Reality Shows"
  const INSPIRING_ADVENTURE = "Inspiring Adventure Stories"


  const specialCasesNotAMicroGenre = [THEM_COL, REAS_WAT];
  const specialCasesGetsADot = [INSPIRING_ADVENTURE, INSPIRING_REALITY];
  const normalCarousel = (title) => !specialCasesNotAMicroGenre.includes(title);
  const getsADot = (title) => specialCasesGetsADot.includes(title);
  const whichPoster = (title) => {
    switch (title) {
      case THEM_COL:
        return "Thematic";
      case REAS_WAT:
        return ReasonsToWatchHeroCell;
      case POPULAR:
        return PosterHover;

      default:
        return PosterLandscape;
    }
  }


  const whichInParams = (title) => {
    switch (title) {
      case POPULAR:
        return { slidesPerGroup: 2, slidesPerView: 3.8 };
      case BASEDON:
        return { slidesPerGroup: 2, slidesPerView: 3.8 };
      case CLIPS:
        return {
          slidesPerGroup: 1, slidesPerView: 1.05, spaceBetween: 7,
          centeredSlides: false
        };
      default:
        return {};
    }
  }

  const lazyLoad = (title) => title !== POPULAR;

  const microGenres =
    advancedCategoriesData && advancedCategoriesData.length > 0
      ? advancedCategoriesData.map(item => (
        <div key={item.carouselTitle}>
          <BoundingBox>
            <div style={{ display: "flex", alignItems: "center" }}>
              {(getsADot(item.carouselTitle)) && (
                <CarouselDot />
              )}

              <CarouselTitle
                lang={lang}
                title={item.carouselTitle}
              />
            </div>
            <Carousel
              items={
                normalCarousel(item.carouselTitle)
                  ? ViewModel(item.carouselItems, dispatch, lazyLoad(item.carouselTitle), false)
                  : []
              }
              slideComponent={
                whichPoster(item.carouselTitle)
              }

              inParams={whichInParams(item.carouselTitle)}

            />
          </BoundingBox>
        </div>
      ))
      : "";

  const heroCarousel = (
    <HeroBoundingBox>
      <HeroCarousel
        items={
          (heroItemsData && heroItemsData.carouselItems ? HeroModel(heroItemsData.carouselItems, dispatch) : [])

        }
        slideComponent={ReasonsToWatchHeroCell}
      />
    </HeroBoundingBox>
  );

  const basedOnCarousel = (
    <BoundingBox>
      <CarouselTitleContainer>
        <StringClassedSpan
          className={`i18n carouselTitle primary ${(lang ===
            "sv" &&
            "firstletter") ||
            ""}`}
          lang={lang}
          which="basedOnCarouselTitle"
        />
      </CarouselTitleContainer>
      <Carousel
        items={
          (basedOnItemsData && basedOnItemsData.carouselItems ? BasedOnModel(basedOnItemsData.carouselItems, dispatch) : [])

        }
        slideComponent={BasedOnPoster}
        inParams={whichInParams(BASEDON)}
      />
    </BoundingBox>
  );

  const clipsCarousel = (
    <BoundingBox id="clipsCarousel">
      {/* <CarouselTitleContainer>
        <StringClassedSpan
          className={`i18n carouselTitle primary ${(lang ===
            "sv" &&
            "firstletter") ||
            ""}`}
          lang={lang}
          which="clipsCarouselTitle"
        />
      </CarouselTitleContainer> */}
      <Carousel
        items={
          (clipsItemsData && clipsItemsData.carouselItems ? ClipsModel(clipsItemsData.carouselItems, dispatch) : [])

        }
        slideComponent={ClipsPoster}
        inParams={whichInParams(CLIPS)}
      />
    </BoundingBox>
  );

  const topCarousel = (
    <BoundingBox>
      <CarouselTitleContainer>
        <StringClassedSpan
          className={`i18n carouselTitle primary ${(lang ===
            "sv" &&
            "firstletter") ||
            ""}`}
          lang={lang}
          which="carouselTitle"
        />
      </CarouselTitleContainer>
      <Carousel
        items={ViewModel(
          popularItemsData.carouselItems,
          dispatch,
          false,
          true
        )}
        slideComponent={SpaceFillingTitleFreePoster}
      />
    </BoundingBox>
  );

  return popularItemsData ? (
    <div>
      {lang === "en" ? heroCarousel : null}
      {lang === "sv" ? topCarousel : null}
      {microGenres[0]}
      {clipsCarousel}
      {basedOnCarousel}
      {microGenres.slice(1)}
    </div>
  ) : null

}
ProgramsCarousel.propTypes = {
  popularItemsData: PropTypes.object.isRequired
};
export default connect(mapStateToProps)(ProgramsCarousel);
