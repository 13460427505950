import React from "react";
import styled from "styled-components";
import ContentKeywords from "../ContentKeywords/ContentKeywords";

const Descriptor = ({ className, data }) => {
    const { categories } = data;
    return (
        <div className={className}>
            <ContentKeywords categories={categories} />
        </div>
    );
};

export const DescriptorDetails = styled(Descriptor)`
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    margin-left: 1.4rem;
    margin-top: 3.8rem;
`;

export default DescriptorDetails;
