import React from "react";
import styled from "styled-components";
import { UI } from "../constants";
import { StringSimple } from "../i18n/strings";

import { RTWTypeIcon } from "./ReasonToWatchIncludes"
import { MACHINE_LEARNING, EDITORIAL } from "./ReasonToWatchIncludes";
import { InnerBoundingBox, ReasonToWatchBoundingBoxSummary, IconBox, TextDetails, Content } from "./ReasonsToWatchStyles";
import { REASON_TO_WATCH_TYPE_CRITIC_REVIEW } from "../constants"
import DescriptorSummary from "./DescriptorSummary"
import HTMLParse from "../HTMLParse";



const Wrapper = styled.div`
  // display: flex;
  align-items: center;
  width: 100%;
  margin-top: 3.8rem;
`;

const MainGrid = styled.div`
  grid-area: program;
  display: grid;
  grid-template-areas:"title"
  "description"
  "keywords"
  "subtitle"
`;
//  "mgquote"
const ReasonsToWatchArea = styled.div`
  grid-area: reasonstowatcharea;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-template-rows: auto;
`;
const ReasonsToWatchString = styled.div`
grid-area: reasonstowatchheader
display:grid;
grid-template-columns: 1fr;
font-size: ${UI.font.tiny};
font-weight: 400;
margin:${props => props.top} 0 1rem;
color:white`;


// Using fixed sizes for title/subtitle/crew so small descriptions do not impact them.
// grid-template-rows: title subtitle crew description castcarousel

//http://hackingui.com/front-end/a-pure-css-solution-for-multiline-text-truncation/
// The pure css soln found there doesn't place the ... precisely - same as
// https://css-tricks.com/line-clampin/
const TitleFade = styled.div`
  max-height: 11.2rem; /* exactly three lines - for FF - line clamp gives us an ... on chrome*/
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
`;
const Title = styled.div`
  font-size: ${UI.font.large};
  font-weight: ${UI.font.weight.bold};
  margin-bottom: 0.5rem;
  color: white;
  line-height: 3rem;
`;

const Subtitle = styled.div`
    display: flex;
    font-size: ${UI.font.xtiny}
    color: ${UI.font.color.subtitle};
`;

const Separated = styled.span`
&::after {
    font-size: ${UI.font.small}
    vertical-align: middle;
    content: "\\00A0∙\\00A0";
}
`;

const UCSeparated = styled(Separated)`
text-transform:uppercase;
`;

const Genre = styled(UCSeparated)``;
const Type = styled(UCSeparated)``;
const Year = styled(UCSeparated)`
  &::after {
    content: "";
  }
`;

const Description = styled(TitleFade)`
    font-size: ${UI.font.small}
    font-weight: ${UI.font.weight.normal};
    line-height: 1.8rem;
    margin-bottom: 0.8rem;
    margin-right: 7.4rem;
    color: white;
    text-align:left;
    height: fit-content;
`;

const Program = ({ className, paletteColor, data }) => {
  const {
    title,
    year,
    description,
    genre,
    type,
    lang,
    imageUri,
    reasonsToWatch,
    categories
  } = data;
  const updatedBoxColors = {};
  updatedBoxColors[MACHINE_LEARNING] = paletteColor.primary;
  updatedBoxColors[EDITORIAL] = paletteColor.primary;

  /*
  191B25
  Background of the pop up should be : #22252F
  Icons are #363945
  
  */

  //https://www.geeksforgeeks.org/how-to-shuffle-an-array-using-javascript/

  const quoteHeaderMarginTop = "2.4rem"
  const _reasonsToWatchUI = reasonsToWatch.filter(r => r.type !== REASON_TO_WATCH_TYPE_CRITIC_REVIEW)
    .sort(() => Math.random() - 0.5)
    .map((rtwObj, index) => (
      <InnerBoundingBox style={{ maxWidth: "95%", justifySelf: "center" }} key={index}>
        <ReasonToWatchBoundingBoxSummary>
          <IconBox style={{ alignSelf: "center" }}>
            <RTWTypeIcon style={{ width: "4rem", height: "4rem" }} color={`#363945`} type={rtwObj.type} />
          </IconBox>
          <TextDetails id="TD" style={{ margin: 0, padding: ".5rem 0" }}>
            <Content style={{ paddingBottom: 0 }}><HTMLParse item={rtwObj.text} /></Content>
          </TextDetails>
        </ReasonToWatchBoundingBoxSummary>
      </InnerBoundingBox >
    ));

  const Rule = styled.hr`
  border: 1px #65697B solid;
  `

  const reasonsToWatchUI = _reasonsToWatchUI.slice(0, 3);

  const criticReview = reasonsToWatch.filter(r => r.type === REASON_TO_WATCH_TYPE_CRITIC_REVIEW).pop();

  return (
    <Wrapper>
      <div id="wrapperChild" className={className}>
        <MainGrid id="mainGrid">
          <TitleFade style={{ gridArea: "title" }}>
            <Title>{title || ""}</Title>
          </TitleFade>
          <Description style={{ gridArea: "description" }}>{description || ""}</Description>

          <DescriptorSummary style={{ gridArea: "keywords" }} rawDescriptors={categories} paletteColor={paletteColor} />
          <Subtitle style={{ gridArea: "subtitle" }}>

            <Genre>{genre.length > 0 ? genre.join(", ") : ""}</Genre>
            <Type>
              <StringSimple which={type} lang={lang} />
            </Type>
            <Year>{year || ""}</Year>
          </Subtitle>
        </MainGrid>

        {(criticReview && criticReview.text ?

          <TextDetails id="QUOTE" style={{ gridArea: "quote", marginTop: quoteHeaderMarginTop, marginBottom: 0 }}>
            <Rule />
            <Content style={{ padding: "1rem 11rem", textAlign: "center", color: `${paletteColor.primary}`, fontWeight: `${UI.font.weight.medium}`, fontSize: `${UI.font.tiny}` }}><HTMLParse item={criticReview.text} /></Content>
            <Rule />
          </TextDetails>

          : <div style={{ gridArea: "quote" }} />)}
        <ReasonsToWatchString top={quoteHeaderMarginTop} >{reasonsToWatchUI.length ? "Watch Prompts" : null}</ReasonsToWatchString>
        <ReasonsToWatchArea>
          {reasonsToWatchUI}
        </ReasonsToWatchArea>
        {(imageUri ?
          <img alt="" style={{ gridArea: "image", width: "auto", height: "19rem", borderRadius: "0.6250rem" }} src={imageUri} />
          : <div style={{ gridArea: "image" }} />)}
      </div>
    </Wrapper>
  );
};

//94 rem total
export const ProgramDetails = styled(Program)`
  display: grid;
  grid-template-columns: 3rem 70rem 18rem 3rem;
  grid-template-areas:
    ". program image ."
    ". subtitlearea image ."
    ". quote quote ."
    ". reasonstowatchheader . ."
    ". reasonstowatcharea reasonstowatcharea .";
`;

export default ProgramDetails;
