import { connect } from "react-redux";
import ThematicLanding from ".";
import store from "../store";

const mapStateToProps = state => ({
    dispatch: store.dispatch,
    lang: state.lang,
});

export default connect(mapStateToProps)(ThematicLanding);
