import React from "react";
const IconPeopleSVG = (props) => {

	const color = props.color || "black";
	const { style } = props
	return (
		<div style={style}>
			<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
				viewBox="0 0 9.2 7.7"  >
				<g id="XMLID_67_">
					<g>
						<path fill={color} d="m5.01,3.89c.09.01.22.03.38.05.16.02.34.04.51.06.18.02.34.05.5.07.15.03.27.05.34.07.11.03.23.1.35.21.12.11.22.23.32.37.1.14.18.28.24.43.06.15.09.28.09.4v1.91s-.07.04-.11.07c-.04.03-.09.07-.13.1-.05.03-.09.06-.14.08-.04.02-.08.03-.11.03H.48c-.12,0-.2-.04-.26-.11-.06-.07-.13-.13-.22-.18v-1.91c0-.12.03-.26.09-.4.06-.15.14-.29.24-.43.1-.13.2-.26.32-.37.12-.11.23-.18.35-.21.06-.02.17-.04.33-.07.16-.02.33-.05.51-.07.18-.02.35-.04.52-.06.16-.02.29-.04.38-.05-.3-.19-.54-.45-.71-.76-.17-.31-.26-.65-.26-1.02,0-.29.06-.56.17-.82s.26-.48.45-.67c.19-.19.41-.34.67-.46.25-.11.53-.17.82-.17s.56.06.82.17c.26.11.48.26.67.46.19.19.34.42.45.67.11.25.17.53.17.82,0,.36-.09.7-.26,1.01-.17.31-.41.57-.71.76Z"/>
					</g>
					<g>
					</g>
				</g>
			</svg>
		</div>
	);
}

export default IconPeopleSVG;
