import { connect } from "react-redux";
import GenreSearch from "./GenreSearch.js";
import store from "../store";

const mapStateToProps = state => ({
    showingCuration: state.showingCuration,
    dispatch: store.dispatch
});

export default connect(mapStateToProps)(GenreSearch);
