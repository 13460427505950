export default {
    "Comical|Friendship|Kindness|Light|Slice Of Life|Teamwork": [
        80,
        75,
        22,
        109,
        13
    ],
    "Comical|Friendship|Kindness|Light|Misadventures|Slice Of Life": [
        22,
        25,
        112,
        75,
        26
    ],
    "Comical|Friendship|Kindness|Light|Schemes|Teamwork": [
        80,
        112,
        75,
        109,
        74
    ],
    "Comical|Friendship|Kindness|Light|Misadventures|Schemes": [
        25,
        112,
        74,
        4,
        49
    ],
    "Absurdity|Comical|Friendship|Light|Slice Of Life|Teamwork": [
        15,
        25,
        113,
        75,
        112
    ],
    "Absurdity|Comical|Friendship|Light|Misadventures|Slice Of Life": [
        25,
        26,
        15,
        28,
        112
    ],
    "Absurdity|Comical|Friendship|Light|Schemes|Teamwork": [
        112,
        25,
        113,
        109,
        74
    ],
    "Absurdity|Comical|Friendship|Light|Misadventures|Schemes": [
        25,
        112,
        28,
        113,
        74
    ],
    "Comical|Kindness|Light|Sin|Slice Of Life|Teamwork": [22, 21, 80, 26, 40],
    "Comical|Kindness|Light|Misadventures|Sin|Slice Of Life": [
        25,
        22,
        26,
        40,
        107
    ],
    "Comical|Kindness|Light|Schemes|Sin|Teamwork": [21, 40, 49, 80, 47],
    "Comical|Kindness|Light|Misadventures|Schemes|Sin": [21, 25, 40, 107, 49],
    "Absurdity|Comical|Light|Sin|Slice Of Life|Teamwork": [15, 25, 40, 26, 21],
    "Absurdity|Comical|Light|Misadventures|Sin|Slice Of Life": [
        25,
        40,
        15,
        26,
        28
    ],
    "Absurdity|Comical|Light|Schemes|Sin|Teamwork": [21, 40, 25, 49, 47],
    "Absurdity|Comical|Light|Misadventures|Schemes|Sin": [25, 21, 40, 107, 28],
    "Comical|Friendship|Kindness|Satirical|Slice Of Life|Teamwork": [
        109,
        80,
        75,
        22,
        112
    ],
    "Comical|Friendship|Kindness|Misadventures|Satirical|Slice Of Life": [
        109,
        22,
        112,
        75,
        80
    ],
    "Comical|Friendship|Kindness|Satirical|Schemes|Teamwork": [
        109,
        80,
        112,
        75,
        13
    ],
    "Comical|Friendship|Kindness|Misadventures|Satirical|Schemes": [
        109,
        112,
        47,
        4,
        75
    ],
    "Absurdity|Comical|Friendship|Satirical|Slice Of Life|Teamwork": [
        109,
        112,
        113,
        75,
        4
    ],
    "Absurdity|Comical|Friendship|Misadventures|Satirical|Slice Of Life": [
        25,
        109,
        112,
        28,
        26
    ],
    "Absurdity|Comical|Friendship|Satirical|Schemes|Teamwork": [
        109,
        112,
        113,
        47,
        4
    ],
    "Absurdity|Comical|Friendship|Misadventures|Satirical|Schemes": [
        25,
        109,
        112,
        28,
        47
    ],
    "Comical|Kindness|Satirical|Sin|Slice Of Life|Teamwork": [
        22,
        109,
        21,
        47,
        80
    ],
    "Comical|Kindness|Misadventures|Satirical|Sin|Slice Of Life": [
        22,
        47,
        40,
        107,
        26
    ],
    "Comical|Kindness|Satirical|Schemes|Sin|Teamwork": [21, 47, 40, 109, 80],
    "Comical|Kindness|Misadventures|Satirical|Schemes|Sin": [
        21,
        47,
        40,
        107,
        25
    ],
    "Absurdity|Comical|Satirical|Sin|Slice Of Life|Teamwork": [
        47,
        40,
        109,
        21,
        15
    ],
    "Absurdity|Comical|Misadventures|Satirical|Sin|Slice Of Life": [
        25,
        40,
        47,
        26,
        28
    ],
    "Absurdity|Comical|Satirical|Schemes|Sin|Teamwork": [21, 47, 40, 109, 49],
    "Absurdity|Comical|Misadventures|Satirical|Schemes|Sin": [
        21,
        40,
        47,
        25,
        107
    ],
    "Charming|Friendship|Kindness|Light|Slice Of Life|Teamwork": [
        80,
        3,
        13,
        75,
        112
    ],
    "Charming|Friendship|Kindness|Light|Misadventures|Slice Of Life": [
        3,
        80,
        112,
        75,
        13
    ],
    "Charming|Friendship|Kindness|Light|Schemes|Teamwork": [80, 13, 3, 112, 75],
    "Charming|Friendship|Kindness|Light|Misadventures|Schemes": [
        3,
        112,
        80,
        13,
        74
    ],
    "Absurdity|Charming|Friendship|Light|Slice Of Life|Teamwork": [
        13,
        3,
        15,
        112,
        80
    ],
    "Absurdity|Charming|Friendship|Light|Misadventures|Slice Of Life": [
        25,
        3,
        26,
        15,
        112
    ],
    "Absurdity|Charming|Friendship|Light|Schemes|Teamwork": [
        13,
        112,
        3,
        103,
        109
    ],
    "Absurdity|Charming|Friendship|Light|Misadventures|Schemes": [
        25,
        112,
        28,
        3,
        74
    ],
    "Charming|Kindness|Light|Sin|Slice Of Life|Teamwork": [80, 3, 13, 83, 22],
    "Charming|Kindness|Light|Misadventures|Sin|Slice Of Life": [
        107,
        26,
        40,
        25,
        3
    ],
    "Charming|Kindness|Light|Schemes|Sin|Teamwork": [80, 21, 40, 13, 107],
    "Charming|Kindness|Light|Misadventures|Schemes|Sin": [107, 40, 21, 25, 47],
    "Absurdity|Charming|Light|Sin|Slice Of Life|Teamwork": [15, 40, 26, 25, 83],
    "Absurdity|Charming|Light|Misadventures|Sin|Slice Of Life": [
        25,
        40,
        15,
        107,
        26
    ],
    "Absurdity|Charming|Light|Schemes|Sin|Teamwork": [40, 21, 47, 25, 15],
    "Absurdity|Charming|Light|Misadventures|Schemes|Sin": [25, 40, 107, 47, 28],
    "Charming|Friendship|Kindness|Satirical|Slice Of Life|Teamwork": [
        80,
        3,
        109,
        13,
        75
    ],
    "Charming|Friendship|Kindness|Misadventures|Satirical|Slice Of Life": [
        3,
        109,
        80,
        112,
        75
    ],
    "Charming|Friendship|Kindness|Satirical|Schemes|Teamwork": [
        80,
        109,
        13,
        3,
        112
    ],
    "Charming|Friendship|Kindness|Misadventures|Satirical|Schemes": [
        109,
        3,
        112,
        80,
        13
    ],
    "Absurdity|Charming|Friendship|Satirical|Slice Of Life|Teamwork": [
        109,
        13,
        3,
        112,
        80
    ],
    "Absurdity|Charming|Friendship|Misadventures|Satirical|Slice Of Life": [
        109,
        3,
        112,
        28,
        26
    ],
    "Absurdity|Charming|Friendship|Satirical|Schemes|Teamwork": [
        109,
        13,
        112,
        3,
        47
    ],
    "Absurdity|Charming|Friendship|Misadventures|Satirical|Schemes": [
        109,
        112,
        47,
        28,
        3
    ],
    "Charming|Kindness|Satirical|Sin|Slice Of Life|Teamwork": [
        80,
        47,
        3,
        109,
        13
    ],
    "Charming|Kindness|Misadventures|Satirical|Sin|Slice Of Life": [
        107,
        47,
        40,
        3,
        26
    ],
    "Charming|Kindness|Satirical|Schemes|Sin|Teamwork": [47, 80, 21, 40, 109],
    "Charming|Kindness|Misadventures|Satirical|Schemes|Sin": [
        107,
        47,
        40,
        21,
        80
    ],
    "Absurdity|Charming|Satirical|Sin|Slice Of Life|Teamwork": [
        47,
        40,
        109,
        15,
        26
    ],
    "Absurdity|Charming|Misadventures|Satirical|Sin|Slice Of Life": [
        47,
        40,
        107,
        26,
        25
    ],
    "Absurdity|Charming|Satirical|Schemes|Sin|Teamwork": [47, 40, 21, 109, 107],
    "Absurdity|Charming|Misadventures|Satirical|Schemes|Sin": [
        47,
        40,
        107,
        25,
        28
    ]
};
