import React from "react";
import styled from "styled-components";
import { UI } from "../constants";
import { transparentize } from "polished";
import { BADGE_LETTER, BADGE_FLAG } from "../api/profile";

const OuterBoundingBox = styled.section`
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    height: 76vh;
    overflow-y: auto;
    margin-top: 3.8rem;
`;

const ItemBoundingBox = styled(OuterBoundingBox)`
    grid-template-columns: 1fr 1fr 1fr;
`;

const PortraitPImageBoundingBox = styled(ItemBoundingBox)`
    grid-column-gap: 7.5rem;
    margin: 0 7.5rem 0;
`;

const InnerBoundingBox = styled.section`
    text-align: center;
`;

const Image = styled.img`
    width: 80%;
    margin: 0 auto;
    filter: ${UI.shadow.soft};
`;

const Title = styled.div`
    font-size: ${UI.font.tiny};
    font-weight: ${UI.font.weight.bold};
    font-variant-caps: all-small-caps;
    color: ${props => props.paletteColor || UI.color.gray};
    padding-top: 0.8rem;
`;

const Description = styled.div`
    font-size: ${UI.font.tiny}
    line-height: 1.6rem;
    color: white;
`;
const TextDetails = styled.div`
    margin-bottom: 0.5rem;
    margin-left: 1.5vw;
    margin-right: 1.5vw;
`;
//26.3 10.2
const Badge = styled.p`
    font-size: ${UI.font.huge};
    font-weight: ${UI.font.weight.bold};
    color: ${UI.color.nielsenBlue}
    position: absolute;
    text-align: center;
    left: 16.3rem;
    top: 12.4rem;
    width: 3.5rem;
    height:3.5rem;
    border-radius: 50%;
    border: ${UI.color.nielsenBlue} solid 2px;
    background: ${transparentize(0.8, UI.color.nielsenBlue)};
    text-align: center;
`;

const PortraitBadgeMinImages = styled(Badge)`
    left: 25.2rem;
`;

const PortraitBadgeMaxImages = styled(Badge)`
    left: 15.1rem;
`;

const LandscapeBadgeMinImages = styled(Badge)`
    left: 26.3rem;
    top: 10.2rem;
`;

export const ImageDetails = ({ paletteColor, data }) => {
    /*
    kLOIqs {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 7.5rem;
    margin: 0 7.5rem 0;
}
    */
    const numImages = data.personalizedImages.length;
    const portrait = numImages
        ? Number.parseInt(data.personalizedImages[0].width) <
        Number.parseInt(data.personalizedImages[0].height)
        : false;

    let WhichBadge = Badge;

    // ItemBoundingBox for 3 landscape
    // PortraitPImageBoundingBox for 3 portrait
    // OuterBoundingBox for 5 portrait
    let WhichBox = ItemBoundingBox;
    if (portrait) {
        WhichBox = numImages > 3 ? OuterBoundingBox : PortraitPImageBoundingBox;
        WhichBadge =
            numImages > 3 ? PortraitBadgeMaxImages : PortraitBadgeMinImages;
    }
    else {
        WhichBadge = LandscapeBadgeMinImages;
    }

    const personalizedImages = data.personalizedImages.map(
        (imageObj, index) => (
            <InnerBoundingBox key={index}>
                <Image src={imageObj.uri} />
                {imageObj[BADGE_FLAG] ? (
                    <WhichBadge>{imageObj[BADGE_LETTER]}</WhichBadge>
                ) : (
                    ""
                )}
                {imageObj.attributes.map((attrs, idx) => (
                    <TextDetails key={idx}>
                        <Title paletteColor={paletteColor.primary}>{attrs.category || ""}</Title>
                        <Description>
                            {attrs.keywords.join(", ") || ""}
                        </Description>
                    </TextDetails>
                ))}
                <TextDetails />
            </InnerBoundingBox>
        )
    );

    return <WhichBox>{personalizedImages}</WhichBox>;
};

export const ClipDetails = ({ paletteColor, data }) => {
    const videoClips = data.videoClips.map((imageObj, index) => (
        <InnerBoundingBox key={index}>
            <Image src={imageObj.uri} />
            {imageObj.attributes.map((attrs, idx) => (
                <TextDetails key={idx}>
                    <Title paletteColor={paletteColor.primary}>{attrs.category || ""}</Title>
                    <Description>{attrs.keywords.join(", ") || ""}</Description>
                </TextDetails>
            ))}
            <TextDetails />
        </InnerBoundingBox>
    ));

    return <ItemBoundingBox>{videoClips}</ItemBoundingBox>;
};
