import React from "react";
import { connect } from "react-redux";
import Autosuggest from "react-autosuggest";
import ReactGA from "react-ga";
import _ from "lodash";
import store from "../store";
import { suggestionRequested } from "../actions";
import { StringSimple, StringSpan } from "../i18n/strings";
import SearchIconSVG from "./SearchIconSVG";

function mapStateToProps(state) {
    return {
        suggestions: state.fullscreen.suggestions,
        lang: state.lang
    };
}
class AutoComplete extends React.Component {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
        this.state = {
            showSearch: false,
            value: "",
            suggestions: []
        };
        this.props.term && this.props.term.length > 0
            ? (this.state.showSearch = true)
            : (this.state.showSearch = false);
    }

    onClick(event) {
        this.setState({ showSearch: true });
    }

    UNSAFE_componentWillMount() {
        this.onSuggestionsFetchRequested = _.debounce(
            this.onSuggestionsFetchRequested,
            500
        );
    }

    renderSuggestion = suggestion => {
        return null;
        // instead, show something in the drop-down (along w css fixes to display the dropdown)
        // <div className="result">
        //     <div>{suggestion.stitle}</div>
        //     <div className="shortCode">{suggestion.rootId}</div>
        // </div>
    };

    onBlur = () => {
        this.setState({ showSearch: false, value: "", suggestions: [] });
    };

    onChange = (_event, { newValue }) => {
        this.setState({ value: newValue });
    };

    onSuggestionsFetchRequested = ({ value, reason }) => {
        if (process.env.REACT_APP_GTAG) {
            ReactGA.event({
                category: "Search",
                action: "Fetch Suggestions"
            });
        }

        store.dispatch(suggestionRequested(value, true));
    };

    // this lifecycle method was essential to getting the same timing as the sample
    // which used to do the ajax directly within onSuggestionsFetchRequested
    componentDidUpdate(prevProps) {
        if (this.props.suggestions !== prevProps.suggestions) {
            this.setState({
                suggestions: this.props.suggestions || []
            });
        }
    }

    onSuggestionsClearRequested = () => {
        this.setState({ suggestions: [] });
    };

    onSuggestionSelected = (
        event,
        { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
    ) => {
        console.log(
            event,
            suggestion,
            suggestionValue,
            suggestionIndex,
            sectionIndex,
            method,
            "event,suggestion, suggestionValue, suggestionIndex, sectionIndex, method IN onSuggestionSelected"
        );
    };

    render() {
        const { showSearch, value, suggestions } = this.state;

        const inputProps = {
            //STRING: title
            placeholder: StringSimple({
                lang: this.props.lang,
                which: "searchPlaceholder"
            }),
            value,
            onChange: this.onChange,
            onBlur: this.onBlur,
            autoFocus: true
        };

        return (
            <div className="title-search" onClick={this.onClick}>
                <div className="search-container">
                    {showSearch ? (
                        <div>
                            <Autosuggest
                                suggestions={suggestions}
                                onSuggestionSelected={this.onSuggestionSelected}
                                onSuggestionsFetchRequested={
                                    this.onSuggestionsFetchRequested
                                }
                                onSuggestionsClearRequested={
                                    this.onSuggestionsClearRequested
                                }
                                getSuggestionValue={suggestion =>
                                    suggestion.stitle
                                }
                                renderSuggestion={this.renderSuggestion}
                                inputProps={inputProps}
                            />
                            <SearchIconSVG
                                clsName={"search-icon-svg"}
                                color={"black"}
                            />
                        </div>
                    ) : (
                        <div className="search-prompt">
                            <div className="search-prompt-copy">
                                <StringSpan
                                    lang={this.props.lang}
                                    which="searchPrompt"
                                />
                            </div>
                            <SearchIconSVG
                                clsName={"search-icon-svg"}
                                color={"white"}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(AutoComplete);
