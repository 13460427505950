export default [
    {
        cluster_number: 3,
        category:
            "Charming Tales of Friendship|F\u00f6rtrollande ber\u00e4ttelser om v\u00e4nskap",
        movies: [
            "MV004605670000",
            "SH027901870000",
            "MV012237190000",
            "MV004836280000",
            "MV005065870000",
            "MV004261720000",
            "MV009150410000",
            "MV005429790000",
            "MV004420480000",
            "MV005061570000",
            "MV004912580000",
            "MV005157550000"
        ],
        rmovies: [
            [
                10445,
                14389359,
                15176575,
                8767971,
                142038,
                136082,
                8173446,
                10356147,
                125484,
                10153355,
                161097,
                9410559
            ]
        ]
    },
    {
        cluster_number: 4,
        category:
            "Friends are all you need|V\u00e4nner \u00e4r allt man beh\u00f6ver",
        movies: [
            "MV009038970000",
            "MV004660450000",
            "MV007333960000",
            "MV004606010000",
            "MV005429790000",
            "MV005291910000",
            "MV004793620000",
            "MV005065130000",
            "MV005064440000",
            "MV004290630000",
            "MV004987760000",
            "SH016258570000"
        ],
        rmovies: [
            [
                159726,
                8431167,
                10784777,
                17041,
                10356147,
                14893,
                167014,
                15987,
                2099,
                14086,
                31890,
                183931
            ]
        ]
    },
    {
        cluster_number: 13,
        category:
            "Stories For Your Inner Child|Ber\u00e4ttelser f\u00f6r ditt inre barn",
        movies: [
            "MV009466290000",
            "MV004489540000",
            "MV010406800000",
            "MV005157550000",
            "MV012042940000",
            "MV008065900000",
            "SH020528080000",
            "MV009485310000",
            "MV012237190000",
            "MV004601400000",
            "MV012029190000",
            "MV006185030000"
        ],
        rmovies: [
            [
                12329215,
                9654784,
                12742429,
                9410559,
                16179913,
                12156883,
                11254470,
                9802505,
                15176575,
                1151,
                14999845,
                11020153
            ]
        ]
    },
    {
        cluster_number: 15,
        category: "Tales of the Absurd|Absurda ber\u00e4ttelser",
        movies: [
            "MV005030190000",
            "MV007721750000",
            "MV004440300000",
            "MV005192460000",
            "MV004676440000",
            "MV011071850000",
            "MV004447730000",
            "MV011122770000",
            "SH031648520000",
            "SH022711780000",
            "MV004257490000",
            "MV004909460000"
        ],
        rmovies: [
            [
                10122196,
                11459886,
                12872,
                12920,
                8346398,
                14619588,
                8081188,
                15461423,
                16596505,
                11921875,
                12943,
                11730
            ]
        ]
    },
    {
        cluster_number: 21,
        category: "Misadventures in Crime|Miss\u00f6den i brott",
        movies: [
            "MV004663600000",
            "MV004447620000",
            "MV004257490000",
            "MV005309210000",
            "MV007293660000",
            "MV012134970000",
            "MV005204540000",
            "MV004466380000",
            "MV004302290000",
            "MV012469280000",
            "MV004913780000",
            "MV004529820000"
        ],
        rmovies: [
            [
                9797492,
                8935056,
                12943,
                159728,
                17652,
                16299742,
                1677,
                7986003,
                15211,
                16749298,
                191478,
                33412
            ]
        ]
    },
    {
        cluster_number: 22,
        category:
            "It's Quite a Pleasant Story|Det \u00e4r en r\u00e4tt trevlig historia",
        movies: [
            "SH029198380000",
            "MV011641690000",
            "MV009485290000",
            "MV004436210000",
            "MV005497020000",
            "MV012112320000",
            "MV005748280000",
            "SH023782370000",
            "MV008230180000",
            "SH020796850000",
            "MV004440580000",
            "SH021279090000"
        ],
        rmovies: [
            [
                15268999,
                15600880,
                9802375,
                90362,
                21442,
                15449398,
                10780685,
                12442685,
                12316910,
                11337804,
                9622744,
                11510912
            ]
        ]
    },
    {
        cluster_number: 25,
        category: "Tales of the Naive|Ber\u00e4ttelser om de naiva",
        movies: [
            "MV010869890000",
            "MV004298680000",
            "SH031648520000",
            "MV006439970000",
            "MV012139380000",
            "MV010515190000",
            "SH016259900000",
            "MV004489520000",
            "MV009466290000",
            "MV004660450000",
            "MV012066840000",
            "SH031555850000"
        ],
        rmovies: [
            [
                14204692,
                163151,
                16596505,
                629,
                16303428,
                14724314,
                9507246,
                9654768,
                12329215,
                8431167,
                16214134,
                16540659
            ]
        ]
    },
    {
        cluster_number: 26,
        category: "Family Tales|Familjeber\u00e4ttelser",
        movies: [
            "MV008230180000",
            "SH020796850000",
            "SH026530840000",
            "SH026265930000",
            "MV010874790000",
            "SH020688090000",
            "MV010145600000",
            "MV008973580000",
            "MV006583980000",
            "MV004567280000",
            "MV004664810000",
            "MV009279500000"
        ],
        rmovies: [
            [
                12316910,
                11337804,
                13976497,
                13842523,
                15150693,
                11306776,
                13103550,
                8152,
                11224165,
                16296,
                9802520,
                13242656
            ]
        ]
    },
    {
        cluster_number: 28,
        category: "Absurd Adventures|Absurda \u00e4ventyr",
        movies: [
            "MV004665540000",
            "MV004546570000",
            "MV005175950000",
            "MV004454340000",
            "MV004859720000",
            "MV004665740000",
            "MV004395360000",
            "SH029198380000",
            "SH026265930000",
            "MV006439970000",
            "MV004298680000",
            "MV007293660000"
        ],
        rmovies: [
            [
                9803612,
                9694746,
                1816,
                3725,
                7164,
                9803855,
                7876919,
                15268999,
                13842523,
                629,
                163151,
                17652
            ]
        ]
    },
    {
        cluster_number: 40,
        category: "Sinful Comedy|Syndig komedi",
        movies: [
            "MV004302290000",
            "MV005865110000",
            "MV004447870000",
            "MV005337500000",
            "SH018924340000",
            "MV010313210000",
            "MV007524360000",
            "MV008255640000",
            "MV004663600000",
            "MV005175950000",
            "MV005309620000",
            "MV005079270000"
        ],
        rmovies: [
            [
                15211,
                7289,
                9628681,
                10907,
                10635672,
                14478111,
                11724311,
                6132,
                9797492,
                1816,
                539,
                194025
            ]
        ]
    },
    {
        cluster_number: 47,
        category: "That's So Funny!|Det d\u00e4r \u00e4r s\u00e5 roligt!",
        movies: [
            "MV008144360000",
            "MV004868870000",
            "MV005269710000",
            "MV008255800000",
            "MV010110240000",
            "MV005222900000",
            "MV004395360000",
            "MV005403160000",
            "MV004663430000",
            "MV011017660000",
            "MV005379940000",
            "SH027985700000"
        ],
        rmovies: [
            [
                11376954,
                9979529,
                4183,
                46611,
                14218257,
                11399,
                7876919,
                19172,
                9797365,
                6819,
                10432418,
                14664920
            ]
        ]
    },
    {
        cluster_number: 49,
        category:
            "It's Not Always About Winning|Det handlar inte alltid om att vinna",
        movies: [
            "MV004489520000",
            "SH016800140000",
            "MV005079270000",
            "MV012029190000",
            "MV004880190000",
            "MV004663560000",
            "MV005874490000",
            "MV005913350000",
            "MV008837880000",
            "SH022709220000",
            "MV004453510000",
            "MV004262270000"
        ],
        rmovies: [
            [
                9654768,
                9706005,
                194025,
                14999845,
                170979,
                9797441,
                181951,
                10106164,
                11561,
                12143623,
                9631272,
                9520863
            ]
        ]
    },
    {
        cluster_number: 74,
        category:
            "Tales of Sincerity and Bonds|Ber\u00e4ttelser om \u00e4rlighet och band",
        movies: [
            "MV005150640000",
            "MV005105230000",
            "MV004991160000",
            "MV004533400000",
            "MV008491470000",
            "MV004606010000",
            "MV004709380000",
            "MV010869890000",
            "MV008445680000",
            "MV005065870000",
            "MV005497020000",
            "MV005192510000"
        ],
        rmovies: [
            [
                8063040,
                8718241,
                177471,
                11977,
                12545749,
                17041,
                35032,
                14204692,
                11292,
                142038,
                21442,
                9695763
            ]
        ]
    },
    {
        cluster_number: 75,
        category:
            "Stories that Will Make Your Child Laugh|Ber\u00e4ttelser som kommer f\u00e5 ditt barn att skratta",
        movies: [
            "MV004420480000",
            "MV009485310000",
            "MV010542560000",
            "MV005913350000",
            "MV004552640000",
            "MV011979850000",
            "MV006625530000",
            "SH030185460000",
            "MV004665240000",
            "MV004261720000",
            "MV012042940000",
            "MV004721780000"
        ],
        rmovies: [
            [
                125484,
                9802505,
                7879823,
                10106164,
                9702192,
                16073666,
                3610120,
                15774740,
                9803497,
                136082,
                16179913,
                159726
            ]
        ]
    },
    {
        cluster_number: 80,
        category:
            "Stories That Are As Sweet Apple Pie|Ber\u00e4ttelser lika s\u00f6ta som s\u00f6t \u00e4ppelpaj",
        movies: [
            "SH018614200000",
            "SH028671610000",
            "MV005061570000",
            "MV004455260000",
            "MV005229390000",
            "MV010345160000",
            "MV006440480000",
            "MV005150640000",
            "MV005105230000",
            "MV004605670000",
            "SH023782370000",
            "MV005025050000"
        ],
        rmovies: [
            [
                10483568,
                15024569,
                10153355,
                5095,
                10300258,
                11695589,
                11155290,
                8063040,
                8718241,
                10445,
                12442685,
                9277169
            ]
        ]
    },
    {
        cluster_number: 83,
        category: "The Cost of Fitting In|Priset av att passa in",
        movies: [
            "MV004438010000",
            "MV004664810000",
            "MV010893420000",
            "MV008696620000",
            "MV006555940000",
            "MV008787560000",
            "MV005276360000",
            "MV007938540000",
            "MV007333960000",
            "MV010526190000",
            "MV004880190000",
            "MV010855410000"
        ],
        rmovies: [
            [
                9619654,
                9802520,
                11559,
                11394221,
                6079,
                496,
                8290371,
                7876903,
                10784777,
                83795,
                170979,
                15150849
            ]
        ]
    },
    {
        cluster_number: 103,
        category:
            "Overcoming Hard Times With Friends|Att \u00f6verkomma sv\u00e5ra tider med v\u00e4nner",
        movies: [
            "MV006185030000",
            "MV004601400000",
            "MV004987760000",
            "SH016195130000",
            "SH016218670000",
            "MV005256250000",
            "MV004676800000",
            "MV005689540000",
            "MV008065900000",
            "MV004665080000",
            "MV010542560000",
            "SH021177620000"
        ],
        rmovies: [
            [
                11020153,
                1151,
                31890,
                9484138,
                185354,
                3150,
                9815135,
                1987,
                12156883,
                9803413,
                7879823,
                11465950
            ]
        ]
    },
    {
        cluster_number: 107,
        category: "That Awkward Moment|Den d\u00e4r pinsamma stunden",
        movies: [
            "SH028865880000",
            "MV004909460000",
            "SH016200300000",
            "MV008230760000",
            "MV004263070000",
            "MV005204510000",
            "MV004440300000",
            "MV008255800000",
            "MV008144360000",
            "MV004981620000",
            "MV004531110000",
            "MV004441010000"
        ],
        rmovies: [
            [
                15098999,
                11730,
                184854,
                12317165,
                11991,
                8142,
                12872,
                46611,
                11376954,
                9561609,
                189479,
                159395
            ]
        ]
    },
    {
        cluster_number: 109,
        category: "Everyday Satire|Vardagssatir",
        movies: [
            "MV004262270000",
            "MV011641760000",
            "MV005431000000",
            "SH028077880000",
            "MV004665080000",
            "SH017832410000",
            "MV004261230000",
            "SH016187960000",
            "MV005865110000",
            "SH016246640000",
            "MV004563820000",
            "SH016200300000"
        ],
        rmovies: [
            [
                9520863,
                8396197,
                10480282,
                14729674,
                9803413,
                10153025,
                8717,
                186454,
                7289,
                184408,
                2568,
                184854
            ]
        ]
    },
    {
        cluster_number: 112,
        category: "A Friend In Deed|En sann v\u00e4nn",
        movies: [
            "MV005064440000",
            "MV011017660000",
            "MV004639710000",
            "MV005340320000",
            "MV010413050000",
            "MV007938540000",
            "MV008325610000",
            "MV010729830000",
            "MV010406800000",
            "MV004859720000",
            "MV004793620000",
            "MV004836280000"
        ],
        rmovies: [
            [
                2099,
                6819,
                8033989,
                416,
                13241560,
                7876903,
                12389024,
                14841600,
                12742429,
                7164,
                167014,
                8767971
            ]
        ]
    },
    {
        cluster_number: 113,
        category: "Coming Together|Samlas",
        movies: [
            "MV012241610000",
            "MV005050090000",
            "MV005042430000",
            "MV004912580000",
            "MV004255200000",
            "MV012261160000",
            "MV004839620000",
            "MV012261120000",
            "SH028077880000",
            "MV004639710000",
            "MV005047110000",
            "MV004436210000"
        ],
        rmovies: [
            [
                16441272,
                21441,
                9447567,
                161097,
                9316,
                16470356,
                34313,
                16470300,
                14729674,
                8033989,
                176527,
                90362
            ]
        ]
    }
];
