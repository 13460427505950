import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { closeModal } from "../actions";
import Modal from "react-responsive-modal";

/*
    Each of the keys below (showCloseIcon, open, styles,…)
    has a special meaning to the modal.

    Docs for these react-responsive-modal props here:
    https://github.com/pradel/react-responsive-modal#modal

    Note that we're not using `styled-components` here since
    the modal already has an opinionated way to do styles
 */
const mapStateToProps = state => ({
    showCloseIcon: true,
    open: state.modal !== null,
    styles: {
        closeButton: {
            top: "0.3rem"
        },
        closeIcon: {
            fill: "gray",
            cursor: "pointer",
            height: "3rem",
            width: "3rem",
        },
        modal: {
            maxWidth: "180rem",
            minWidth: "45rem",
            width: "90rem",
            backgroundClip: "padding-box",
            boxShadow: "0 12px 15px 0 rgba(0, 0, 0, 0.25)",
            background: "#22252F",
            padding: "2rem",
            borderRadius: "20px 20px 0 0",
            height: "82vh",
            maxHeight: "calc(100vh - 80px)",
            minHeight: "calc(45vh - 80px)",
            overflow: "auto"
        },
        overlay: {
            background: "hsla(196, 100%, 9%, 80%)"
        }
    }
});

const mapDispatchToProps = dispatch =>
    bindActionCreators({ closeModal }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Modal);
