/**
 * All queries to elasticsearch are thru here.
 */
import SearchConstants from "../SearchConstants";
import ElasticSearchUtils from "../ElasticSearchUtils";
import jsesc from "jsesc";
import { DEBUGLOG } from "../debug/debuglog";
import { NUM_TITLES } from "../constants";

/*
 * Boilerplate POST from MDN
 * https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
 */
const post = {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, cors, *same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, same-origin, *omit
    headers: {
        "Content-Type": "application/json; charset=utf-8"
        // "Content-Type": "application/x-www-form-urlencoded",
    },
    redirect: "follow", // manual, *follow, error
    referrer: "no-referrer" // no-referrer, *client
};

const DESC = "desc";
const ASC = "asc";
const NESTED = (parent, child, order) => {
    const o = {};
    o[`${parent}.${child}`] = {
        order: order,
        nested: {
            path: parent
        }
    };
    return o;
};

const SORT = {
    YEAR: order => NESTED("tms", "releaseYear", order),
    TITLE: order => NESTED("tms", "title", order),
    LEAF: order => ({ "leaf.keyword": { order: order } }),
    POP: order => NESTED("ads", "vps", order),
    SCORE: order => ({
        _score: {
            order: order
        }
    })
};

const FILTERS = {
    POPULAR: {
        nested: {
            _name: "pop_gt_0",
            path: "ads",
            query: {
                range: {
                    "ads.vps": {
                        gt: 0
                    }
                }
            }
        }
    },
    NOTPOPULAR: {
        nested: {
            _name: "pop_lte_0",
            path: "ads",
            query: {
                range: {
                    "ads.vps": {
                        lte: 0
                    }
                }
            }
        }
    }
};

const AGGS = {
    /*
     * This aggregation gets us all the languages that are in the result
     * it has no impact on the query result.
     */
    LANG: {
        lang: {
            nested: {
                path: "tms"
            },
            aggs: {
                lang: {
                    terms: {
                        field: "tms.lang",
                        size: 10,
                        min_doc_count: 1
                    }
                }
            }
        }
    }
};

const QUERIES = {
    MM_TITLE_CAST_CREW_HARD: query => ({
        multi_match: {
            query: query,
            _name: "multi_match_title_cast_crew_hard",
            operator: "and",
            fields: ["extsearch^4.0", "excasearch^4.0", "excrsearch^4.0"]
        }
    }),
    MM_TITLE_CAST_CREW_SOFT: query => ({
        multi_match: {
            query: query,
            _name: "multi_match_title_cast_crew_soft",
            operator: "and",
            fields: ["actitle^0.3", "accasearch^0.3", "accrsearch^0.3"]
        }
    })
};

/**
 * Escape the path, and turn it into JSON. jsesc will put some kind of quotation marks on
 * the string, so use backticks as the quote character, then strip them
 * (on the assumption that there will never be a backtick in the string itself).
 *
 * @param {String} raw the path or keyword to be escaped. May or may not have accented characters.
 */
const escapeCharacters = raw =>
    jsesc(raw, { json: true, quotes: "backtick" }).replace(/`/g, "");

/**
 * JSON.stringify will add an extra \ ahead of our \u escapes, so get rid of the additional one.
 * Elasticsearch will not respond to doubly escaped characters.
 *
 * @param {String/Object} data the js object to turn to a JSON string. Then remove the extra \
 */
const unEscapedStringify = data =>
    JSON.stringify(data).replace(/\\\\u/gi, "\\u");

/**
 * Post the query and return the response. Responsibility of caller to check for isError, and pick out the desired
 * fields from the returned json.
 * @param {String} query the endpoint to query
 * @param {*} body the body of the query. Assumes JSON.stringify or unEscapedStringify run already.
 */
const postIt = (query, body) => {
    let isError = false;
    let tmpPost = { body: body, ...post };
    tmpPost.headers = {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_GW_KEY
    };

    let result = fetch(query, tmpPost)
        .then(response => response.json())
        .then(json => {
            json.isError = false;
            return json;
        })
        .catch(error => {
            console.log(isError, error);
            isError = true;
        });

    if (isError) {
        result = {};
        result.isError = isError;
    }

    return result;
};

export default {
    /**
     * Ensures we're "talking to" the most up-to-date data.
     *
     * The "current index" is a system Greg put in place to
     * allow us to switch between various "indexes" in Elastic Search.
     * In general, you don't need to worry about this, as long
     * as it gets called once at the beginning of every session.
     */
    async requestCurrentIndices() {
        if (Object.keys(SearchConstants.indices).length === 0) {
            let isError = false;
            const result = await fetch(SearchConstants.indexQuery(), {
                headers: {
                    "Content-Type": "application/json",
                    "x-api-key": process.env.REACT_APP_GW_KEY
                }
            })
                .then(response => {
                    return response.json();
                })
                .catch(error => {
                    console.log("Error", error);
                    isError = true;
                    return {};
                });

            if (!isError) {
                SearchConstants.addIndices({ ...result });
            }
        }
    },

    /**
     * Uses the motivation_endpoint to return hits for a {motivation | tmsID} tuple.
     * @param {genre string} genre string, e.g., "Action"
     */
    async requestTileGraph(ids) {
        let result = [];
        let data = {
            query: {
                ids: {
                    type: "motivation",
                    values: ids
                }
            }
        };

        DEBUGLOG("requestTileGraph", ids, "start");
        const newItems = await postIt(
            SearchConstants.motivation_endpoint(),
            JSON.stringify(data)
        );

        if (!newItems.isError) {
            result = newItems.hits.hits.map(hit => {
                return hit._source;
            });
            DEBUGLOG("requestTileGraph", ids, "end");
        }

        return result;
    },

    /**
     * Uses the motivation_endpoint to do an 'match' query of multigenre strings.
     * @param {genre string} genre string, e.g., "Action"
     */
    async requestMotivationProgramIds(motivation) {
        let result = [];
        let data = {
            query: {
                match_phrase: {
                    viewer_motivation: motivation
                }
            },
            size: NUM_TITLES
        };

        DEBUGLOG("requestMotivationProgramIds", motivation, "start");
        const newItems = await postIt(
            SearchConstants.motivation_endpoint(),
            JSON.stringify(data)
        );

        if (!newItems.isError) {
            result = newItems.hits.hits.map(hit => {
                return hit._source.referral_title;
            });
            DEBUGLOG("requestMotivationProgramIds", motivation, "end");
        }

        return result;
    },

    /**
     * Uses the motivation_endpoint to do an 'match' query of multigenre strings.
     * @param {genre string} genre string, e.g., "Action"
     */
    async requestMotivationsGivenReferralId(refId) {
        let result = [];
        let data = {
            query: {
                term: {
                    referral_title: {
                        value: refId
                    }
                }
            }
        };

        DEBUGLOG("requestMotivationsGivenReferralId", refId, "start");
        const newItems = await postIt(
            SearchConstants.motivation_endpoint(),
            JSON.stringify(data)
        );

        if (!newItems.isError) {
            result = newItems.hits.hits.map(hit => {
                return hit._source;
            });
            DEBUGLOG("requestMotivationsGivenReferralId", refId, "end");
        }

        return result;
    },

    /**
     * Uses the tmstype_endpoint to do an 'match' query of multigenre strings.
     * @param {genre string} genre string, e.g., "Action"
     */
    async searchByGenre(genre) {
        let result = [];

        let data = {
            query: {
                match: {
                    multigenre: genre
                }
            },
            sort: [
                {
                    "ads.vps": {
                        order: "desc",
                        nested_path: "ads"
                    }
                },
                {
                    "tms.releaseYear": {
                        order: "desc",
                        nested_path: "tms"
                    }
                },
                {
                    "tms.title": {
                        order: "asc",
                        nested_path: "tms"
                    }
                }
            ],
            _source: {
                includes: ["ads", "tms", "tmsId"],
                excludes: ["tms.cast", "tms.crew", "tms.genre"]
            },
            size: 999
        };

        DEBUGLOG("searchByGenre", genre, "start");
        const newItems = await postIt(
            SearchConstants.tmstype_endpoint(),
            JSON.stringify(data)
        );

        if (!newItems.isError) {
            result = newItems.hits.hits.map(hit => {
                return hit._source;
            });
            DEBUGLOG("searchByGenre", genre, "end");
        }

        return result;
    },
    /**
     * Uses the tmstype_endpoint to do an 'ids' query of ids. The tmsId is used as _id in the index, too.
     * @param {Array} ids an array of tmsIds
     */
    async requestCarouselItems(ids) {
        let result = [];

        /*
            TODO: this will have to change to look like:
{
  "query": {
    "ids": {
      "values": ["MV006908010000","MV004900850000","MV004902290000"]
    }
  }
}
         */

        let data = {
            size: SearchConstants.carousel_query_size,
            query: {
                ids: {
                    type: "tmstype",
                    values: ids
                }
            },
            sort: [SORT.YEAR(DESC), SORT.TITLE(ASC)]
        };

        const newItems = await postIt(
            SearchConstants.tmstype_endpoint(),
            JSON.stringify(data)
        );

        if (!newItems.isError) {
            result = newItems.hits.hits.map(hit => {
                return hit._source;
            });
        }

        return result;
    },
    /**
     * Uses the tmstype_endpoint to do an 'simple_query_string' query of ids.
     * @param {Array} ids an array of rootIds
     */
    async requestCarouselItemsRoot(ids) {
        let result = [];

        let data = {
            size: SearchConstants.carousel_query_size,
            query: {
                nested: {
                    path: "tms",
                    query: {
                        simple_query_string: {
                            query: ids.join("|"),
                            fields: ["rootId"],
                            default_operator: "or"
                        }
                    }
                }
            },
            sort: [SORT.YEAR(DESC), SORT.TITLE(ASC)]
        };

        const newItems = await postIt(
            SearchConstants.tmstype_endpoint(),
            JSON.stringify(data)
        );

        if (!newItems.isError) {
            result = newItems.hits.hits.map(hit => {
                return hit._source;
            });
        }

        return result;
    },

    /**
     * Uses the category_endpoint to retrieve all the items of keyword, excluding id,
     * using a bool must/match, must_not/match query.
     * id is optional
     *
     * @param {String} rawKeyword find the items that match this
     * @param {String} id exclude the item that matches this tmsId
     */
    async fetchContentByKeyword(rawKeyword, id) {
        const keyword = escapeCharacters(rawKeyword);
        let result = [];
        /*
         * For a given keyword, fetch content marked at that level:
         * {"sort":[{"leaf.keyword":{"order":"asc"}}],"query":{"bool":{"must":[{"match":{"leaf.keyword":"Greed"}}]}},"size":200}
         */
        let data = {
            sort: [SORT.LEAF(ASC)],
            query: {
                bool: {
                    must: [
                        {
                            match: {
                                "leaf.keyword": keyword
                            }
                        }
                    ],
                    must_not: id
                        ? [
                              {
                                  match: {
                                      tmsId: id
                                  }
                              }
                          ]
                        : []
                }
            },
            size: SearchConstants.child_keywords_query_size
        };

        const newItems = await postIt(
            SearchConstants.category_endpoint(),
            unEscapedStringify(data)
        );
        if (!newItems.isError) {
            result = newItems.hits.hits.map(hit => {
                return hit._source;
            });
        }
        return result;
    },

    /**
     * Uses the category_endpoint to perform a terms aggregation to get all the paths for the category
     * @param {String} category One of Video Mood, Theme or Scenario
     */
    async fetchCategoryLeaves(category) {
        let result = { isError: true };
        let data = {
            aggs: {
                kw_paths: {
                    terms: {
                        field: "path",
                        order: { _key: "asc" },
                        include: "/" + category + "/.*",
                        size: 10000
                    }
                }
            },
            // make ES just return metadata not hits:
            size: 0
        };

        const buckets = await postIt(
            SearchConstants.category_endpoint(),
            JSON.stringify(data)
        );

        if (!buckets.isError) {
            result = buckets.aggregations.kw_paths.buckets;
        }

        return result;
    },

    /**
     * Uses the category_endpoint, and does a terms aggregation of a "category.keyword" field query.
     */
    async fetchKeywordEnabledCategories() {
        let result = { isError: true };

        let data = {
            aggs: {
                kw_enabled_categories: {
                    terms: {
                        field: "category.keyword",
                        order: { _key: "asc" }
                    }
                }
            },
            size: 0
        };

        const buckets = await postIt(
            SearchConstants.category_endpoint(),
            unEscapedStringify(data)
        );

        if (!buckets.isError) {
            result = buckets.aggregations.kw_enabled_categories.buckets;
        }

        return result;
    },

    /**
     * Uses the category_endpoint to count the entries of path.tree ending in path/keyword
     * Does not return programs whose count is 0 (a zero count record)
     *
     * @param {Array} params array of keyword and path
     */
    async fetchCategoryCount(params) {
        const [rawKeyword, rawPath] = [...params];
        const path = escapeCharacters(rawPath);
        const keyword = escapeCharacters(rawKeyword);
        let result = 0;
        let data = {
            query: {
                bool: {
                    filter: {
                        term: {
                            "path.tree":
                                "/" +
                                ElasticSearchUtils.getPartialPath(
                                    rawKeyword,
                                    path
                                ).join("/") +
                                "/" +
                                keyword
                        }
                    },
                    must_not: [
                        {
                            term: {
                                program_count: "0"
                            }
                        },
                        {
                            term: {
                                tmsId: "0"
                            }
                        },
                        {
                            term: {
                                rootId: "0"
                            }
                        }
                    ]
                }
            },
            size: 0 // size 0 just gets total counts not hits
        };

        const newItems = await postIt(
            SearchConstants.category_endpoint(),
            unEscapedStringify(data)
        );
        if (!newItems.isError) {
            result = newItems.hits.total.value;
        }

        return result;
    },

    /**
     * Use the category endpoint to find one instance of keyword, and get the child program counts.
     * @param {String} rawKeyword a single keyword
     */
    async fetchKeywordCount(rawKeyword) {
        const keyword = escapeCharacters(rawKeyword);
        let result = 0;
        const data = {
            query: {
                bool: {
                    must: [
                        {
                            match: {
                                "leaf.keyword": keyword
                            }
                        }
                    ]
                }
            },
            _source: "child_node_program_counts",
            size: 1
        };

        const newItems = await postIt(
            SearchConstants.category_endpoint(),
            unEscapedStringify(data)
        );

        if (!newItems.isError) {
            result = newItems.hits.hits.map(hit => {
                return hit._source.child_node_program_counts;
            });
        }
        return result[0];
    },

    /**
     * Uses the category_endpoint to perform a terms aggregation with regex, to,
     * for the final, leaf level, i.e., the clicked keyword's level,
     * fetch any available children
     *
     * @param {String} rawPath an item's keyword path
     */
    async fetchChildKeys(rawPath) {
        const path = escapeCharacters(rawPath).replace(/&/g, "\\&");
        let result = { isError: true };

        let data = {
            aggs: {
                kw_paths: {
                    terms: {
                        field: "path",
                        order: { _key: "asc" },
                        include:
                            path + SearchConstants.regex_for_child_keywords,
                        size: 10000
                    }
                }
            },
            // make ES just return metadata not hits:
            size: 0
        };

        result = await postIt(
            SearchConstants.category_endpoint(),
            unEscapedStringify(data)
        );

        if (!result.isError) {
            result = result.aggregations.kw_paths.buckets;
        }
        return result;
    },

    /**
     * Uses the category_endpoint to find the child_node_program_counts for rawPath
     *
     * @param {String} rawPath a path to a keyword or level
     */
    async fetchCountByPath(rawPath) {
        let result = 0;
        const path = escapeCharacters(rawPath);

        /*
         * For a given path, fetch total count:
         * {"query":{"bool":{"must":[{"match":{"path":"/Scenario/Personal Story/Against The Odds/Defying Expectations"}}]}},"_source":["child_node_program_counts"],"size":1}
         *
         */
        let data = {
            sort: [SORT.LEAF(ASC)],
            query: {
                bool: {
                    must: [
                        {
                            match: {
                                path: path
                            }
                        }
                    ]
                }
            },
            _source: ["child_node_program_counts"],
            // make ES just return 1 piece of metadata so we can pull child_node_program_counts:
            size: 1
        };

        const newItems = await postIt(
            SearchConstants.category_endpoint(),
            unEscapedStringify(data)
        );

        if (!newItems.isError) {
            result = newItems.hits.hits.map(hit => {
                return hit._source.child_node_program_counts;
            });
        }
        return result.pop();
    },

    /**
     * Uses the tmstype_endpoint to perform a query_string query with field and id, or one id
     * or several ids, of ids are sent in an array.
     *
     * @param {Array} params array of id and field ([id, field]), or an array
     * of an array of ids, and a field, to do a batch query ([[id], field])
     */
    async fetchProgDetailsByField(params) {
        const [id, field] = [...params];
        let result = { isError: true };
        let scrollId = 0;
        let queryTotal = 0;
        let current = 0;

        let data;
        let isPlural = id.constructor === Array;
        if (isPlural) {
            // size is supplied as a query param via uri
            data = {
                query: {
                    ids: {
                        type: field,
                        values: id
                    }
                }
            };
        } else {
            data = {
                query: {
                    query_string: {
                        default_field: field,
                        query: id
                    }
                }
            };
        }

        // scrolling query
        const newItems = await postIt(
            SearchConstants.tmstype_scroll_endpoint(),
            JSON.stringify(data)
        );

        if (newItems && !newItems.isError) {
            scrollId = newItems._scroll_id || null; // intentional null if not found in resultset
            queryTotal = newItems.hits.total.value;
            if (isPlural) {
                result = newItems.hits.hits.map(hit => hit._source);
            } else {
                result = newItems.hits.hits[0]._source;
            }
        } else {
            result = [];
        }
        current = result.length;
        let runningTotal = 0;
        let cumulative = [];

        while (runningTotal < queryTotal) {
            let nextResult = [];
            const nextItems = await postIt(
                SearchConstants.tmstype_scroll_endpoint(true),
                JSON.stringify({
                    scroll: SearchConstants["scrollInterval"],
                    scroll_id: scrollId
                })
            );
            if (nextItems && !nextItems.isError) {
                current = nextItems.hits.total.value;
                if (isPlural) {
                    nextResult = nextItems.hits.hits.map(hit => hit._source);
                } else {
                    nextResult = nextItems.hits.hits[0]._source;
                }
            } else {
                nextResult = [];
            }
            runningTotal = runningTotal + current;
            cumulative = result.concat(nextResult);
        }

        return cumulative;
    },

    /**
     * Uses the category_endpoint to get the count of all items with that tag, using a filter, and no
     * size to just get the count.
     *
     * @param {String} category one of Video Mood, Theme, Scenario
     * @param {String} path a keyword path, without a category
     */
    async fetchTopLevelCategoryCount(category, path) {
        let _path = escapeCharacters(path);
        let cookedPath = `/${category}/${_path}`;
        let result = 0;
        let data = {
            query: {
                bool: {
                    filter: {
                        term: {
                            "path.tree": cookedPath
                        }
                    }
                }
            },
            size: 0 // size 0 just gets total counts not hits
        };

        console.log(path);

        const newItems = await postIt(
            SearchConstants.category_endpoint(),
            unEscapedStringify(data)
        );

        if (!newItems.isError) {
            result = newItems.hits.total.value;
        }
        return result;
    },
    /**
     * Get all the categories at once, more or less. Contra fetchTopLevelCategoryCount
     * we get a count of 1 and the path,
     * in order to maintain the link between the count and the path.
     * @param {String} category the top level category
     * @param {Array} path the second level categories
     */
    async fetchTopLevelCategoryCountMany(category, path) {
        let mapped = path.map(pth => `/${category}/${escapeCharacters(pth)}`);
        let dataMap = mapped.map(mppd => {
            return unEscapedStringify({
                query: {
                    bool: {
                        filter: {
                            term: {
                                "path.tree": mppd
                            }
                        },
                        must_not: {
                            term: { tmsId: "0" }
                        }
                    }
                },
                _source: "path",
                size: 1
            });
        });
        let result = 0;

        const newItems = await Promise.all(
            dataMap.map(
                async d => await postIt(SearchConstants.category_endpoint(), d)
            )
        );

        if (newItems && !newItems.isError) {
            result = newItems.map(n => {
                return n.hits.hits[0]
                    ? {
                          total: n.hits.total.value,
                          path: n.hits.hits[0]._source.path
                      }
                    : null;
            });
        } else {
            result = [];
        }

        return result;
    },

    /**
     * Uses the category_endpoint, and does a terms aggregation of a path.tree wildcard query.
     * @param {Array} array of category and keyword
     */
    async fetchKeywordChildrenNoContent(params) {
        const [category, keyword] = [...params];
        let result = { isError: true };

        const pathTree = `/${category}*/${escapeCharacters(keyword)}`;
        // console.log(pathTree, "pathTree");
        let data = {
            aggs: {
                kw_paths: {
                    terms: {
                        field: "path"
                    }
                }
            },
            query: {
                wildcard: {
                    "path.tree": pathTree
                }
            },
            _source: ["path", "child_node_program_counts"],
            size: 0
        };

        const buckets = await postIt(
            SearchConstants.category_endpoint(),
            unEscapedStringify(data)
        );

        if (!buckets.isError) {
            result = buckets.aggregations.kw_paths.buckets;
        }

        return result;
    },

    /**
     *
     */
    async getAllGenreNames() {
        let result = {};
        let data = {
            aggs: {
                genre_pills: {
                    terms: {
                        field: "multigenre",
                        size: 1000,
                        min_doc_count: 1
                    }
                }
            },
            size: 0
        };

        const response = await postIt(
            SearchConstants.tmstype_endpoint(),
            unEscapedStringify(data)
        );

        if (!response.isError) {
            result = response;
        }

        const results = (result.aggregations &&
            result.aggregations.genre_pills &&
            result.aggregations.genre_pills.buckets) || [
            "Drama",
            "Comedy",
            "Documentary",
            "Reality",
            "Action",
            "Thriller",
            "Horror"
        ];

        return results.filter(i => {
            return i.key.length > 0;
        });
    },

    /**
     * The tmstype_endpoint has been indexed with an autocomplete, and a keyword analyzer.
     * 'ac[t,ca,cr]search' is a field with an autocomplete analyzer attached, to which the title cast and crew fields
     * have been mapped tp via a 'copy_to' directive.
     *
     * 'ex[t,ca,cr]search' is a field with a keyword analyzer attached, to which the title cast and crew fields
     * have been mapped tp via a 'copy_to' directive.
     *
     * This call searches for exact ('hard') and inexact ('soft') matches with popularity scores, then the same matches without
     * popularity scores.
     *
     * More popular exact items are placed before popular inexact items, followed by not popular exact items, with not popular
     * inexact items completing the results.
     *
     * @param {String} searchInput the title or piece of a title, cast member or crew member to search for
     * @see https://www.elastic.co/guide/en/elasticsearch/guide/master/_index_time_search_as_you_type.html
     * @see mapcore.json
     * @see settingscore.json
     * @see indexACmap.sh
     * @see tmssetmap.sh
     * @see tmsingest.sh
     * @see tmsbulk.sh
     *
     */
    async acexCastCrewTitleSearch(searchInput, sortxPop) {
        const searchTerm = escapeCharacters(searchInput);
        let size = SearchConstants.title_search_query_size;

        let result = {};
        let data = {
            query: {
                bool: {
                    must: [
                        /* Index 0, Query inserted below */
                        /* Index 1, Popularity filter to be inserted below. */
                    ],

                    must_not: [
                        /* Index 0, ids to skip inserted below */
                    ]
                }
            },
            sort: [SORT.YEAR(DESC), SORT.TITLE(ASC)]
        };

        if (sortxPop) {
            data.sort = [SORT.POP(DESC), SORT.SCORE(DESC), ...data.sort];
        }

        data.aggs = AGGS.LANG;

        /*
         * Destinations
         */
        const _response = {
            isError: true,
            hits: { total: 0, hits: [] },
            langs: []
        };
        result = { ..._response };
        let response = { ..._response };

        const RESPONSE_KEYS = {
            /*Exact match with popularity score*/
            RESPONSE_POPULAR_HARD: 0,
            /*Inexact match with popularity score*/
            RESPONSE_POPULAR_SOFT: 2,
            /*Exact match w/o popularity score*/
            RESPONSE_NOT_POPULAR_HARD: 1,
            /*Inexact match w/o popularity score*/
            RESPONSE_NOT_POPULAR_SOFT: 3
        };
        const responses = [];

        Object.keys(RESPONSE_KEYS).forEach(
            k => (responses[RESPONSE_KEYS[k]] = undefined)
        );

        let ids = [];
        const mapHits = hits => hits.map(h => h._id);
        const skipTheseIds = ids => ({
            terms: { tmsId: ids }
        });

        const goodQuery = (res, sz) =>
            res &&
            !res.isError &&
            res.hits &&
            res.hits.total.value > 0 &&
            res.hits.hits &&
            res.hits.hits.length <= sz;

        const executeQuery = async (scaffold, must, filter, must_not, size) => {
            scaffold.size = size;

            scaffold.query.bool.must[0] = must;

            if (filter) {
                scaffold.query.bool.must[1] = FILTERS.POPULAR;
            } else {
                scaffold.query.bool.must[1] = FILTERS.NOTPOPULAR;
            }

            scaffold.query.bool.must_not = [];
            if (must_not) {
                scaffold.query.bool.must_not = [must_not];
            }

            DEBUGLOG("scaffold", scaffold);
            DEBUGLOG(
                "unEscapedStringify(scaffold)",
                unEscapedStringify(scaffold)
            );
            DEBUGLOG(SearchConstants.tmstype_endpoint());

            return postIt(
                SearchConstants.tmstype_endpoint(),
                unEscapedStringify(scaffold)
            );
        };

        //
        let tmp = await executeQuery(
            data,
            QUERIES.MM_TITLE_CAST_CREW_HARD(searchTerm),
            true,
            undefined,
            size
        );
        DEBUGLOG("MM_TITLE_CAST_CREW_HARD1", tmp);

        if (goodQuery(tmp, size)) {
            size -= tmp.hits.hits.length;
            responses[RESPONSE_KEYS.RESPONSE_POPULAR_HARD] = tmp;
            ids = mapHits(tmp.hits.hits);
        }
        tmp = undefined;

        if (size > 0) {
            tmp = await executeQuery(
                data,
                QUERIES.MM_TITLE_CAST_CREW_SOFT(searchTerm),
                true,
                (ids.length && skipTheseIds(ids)) || undefined,
                size
            );
            DEBUGLOG("MM_TITLE_CAST_CREW_SOFT1", tmp);
        }

        if (goodQuery(tmp, size)) {
            size -= tmp.hits.hits.length;
            responses[RESPONSE_KEYS.RESPONSE_POPULAR_SOFT] = tmp;
            ids = [...ids, ...mapHits(tmp.hits.hits)];
        }
        tmp = undefined;

        if (size > 0) {
            tmp = await executeQuery(
                data,
                QUERIES.MM_TITLE_CAST_CREW_HARD(searchTerm),
                false,
                (ids.length && skipTheseIds(ids)) || undefined,
                size
            );
            DEBUGLOG("MM_TITLE_CAST_CREW_HARD2", tmp);
        }

        if (goodQuery(tmp, size)) {
            size -= tmp.hits.hits.length;
            responses[RESPONSE_KEYS.RESPONSE_NOT_POPULAR_HARD] = tmp;
            ids = [...ids, ...mapHits(tmp.hits.hits)];
        }

        tmp = undefined;

        if (size > 0) {
            tmp = await executeQuery(
                data,
                QUERIES.MM_TITLE_CAST_CREW_SOFT(searchTerm),
                false,
                (ids.length && skipTheseIds(ids)) || undefined,
                size
            );
            DEBUGLOG("MM_TITLE_CAST_CREW_SOFT2", tmp);
        }

        if (goodQuery(tmp, size)) {
            responses[RESPONSE_KEYS.RESPONSE_NOT_POPULAR_SOFT] = tmp;
        }

        tmp = undefined;

        /*
         * Collect all the responses that have results.
         */
        let goodResults = responses.filter(r => r);

        DEBUGLOG(goodResults);

        /*
         * If there are results, then collect the responses into the return object.
         */
        if (goodResults.length) {
            response.isError = false;
            // TODO: hits.total = {}; hits.total.value = cv.hits.total.value ?
            response.hits.total = goodResults.reduce(
                (acc, cv) => acc + cv.hits.total.value,
                0
            );

            response.hits.hits = goodResults.reduce(
                (acc, cv) => [...acc, ...cv.hits.hits],
                []
            );

            response.langs = Array.from(
                new Set(
                    goodResults.reduce(
                        (acc, cv) => [
                            ...acc,
                            ...cv.aggregations.lang.lang.buckets.map(b => b.key)
                        ],
                        []
                    )
                )
            );
        }

        if (!response.isError) {
            result = response;
        }

        return result;
    },
    /**
     * Uses the tmstype_endpoint to do an 'ids' query of ids. The tmsId is used as _id in the index, too.
     * @param {Array} ids an array of tmsIds
     * @param {integer} size how many items to request: best used for small (< 100) sizes
     */
    async requestPrograms(params) {
        const [ids, size] = [...params];
        // const ids = escapeCharacters(rawPath);
        // const size = escapeCharacters(rawKeyword);
        let result = [];

        let data = {
            size: size,
            query: {
                ids: {
                    type: "tmstype",
                    values: ids
                }
            },
            sort: [SORT.POP(DESC), SORT.YEAR(DESC), SORT.TITLE(ASC)]
        };

        const newItems = await postIt(
            SearchConstants.tmstype_endpoint(),
            JSON.stringify(data)
        );

        if (!newItems.isError) {
            result = newItems.hits.hits.map(hit => {
                return hit._source;
            });
        }

        return result;
    }
};
