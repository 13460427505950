/**
 * Dictionary of English and Swedish strings.
 */
import React from "react";
export default {
  profileGreeting: {
    //As in, "Hi, Firstname."
    sv: "Hej",
    en: "Hi"
  },
  logoutMessage: {
    //As in, the user is leaving the site
    sv: "Hej då!",
    en: "Goodbye!"
  },
  loginProcessing: {
    //As in, the user is entering the site, and the site is making accomodations
    sv: "Ett ögonblick...",
    en: "One moment, please..."
  },
  loginNoAccess: {
    //As in, the user is entering the site, and the site denies her.
    sv: "Du har inte tillgång till den här sidan.",
    en: "You do not have access to this site."
  },
  genreCuration: {
    //As in, this is a profile of the user's tastes (viewing preferences), based on interactions with content.
    sv: "Themes",
    en: "Themes"
  },
  genreCurationPrompt: {
    sv: "Filter by Genre",
    en: "Filter by Genre"
  },
  moodGrid: {
    sv: "Muud Grid",
    en: "Mood Grid"
  },
  tasteProfile: {
    //As in, this is a profile of the user's tastes (viewing preferences), based on interactions with content.
    sv: "Preferenser",
    en: "Taste Profile"
  },
  programDetails: {
    //As in, this is more information about a movie or TV show
    sv: "Programdetaljer",
    en: "Program Details"
  },
  imageOptions: {
    //As in, "Here are several images that complement a movie or TV show."
    sv: "Bildalternativ",
    en: "Image Options"
  },
  clipOptions: {
    //As in, "Here are several video clips that complement a movie or TV show."
    sv: "Videoklipp",
    en: "Video Clips"
  },
  castDetails: {
    sv: "Cast",
    en: "Cast"
  },
  rtwOptions: { en: "Watch Prompts", sv: "Watch Prompts" },
  rtwAward: { en: "Awards", sv: "Awards" },
  rtwConceptSource: { en: "Concept Source", sv: "Concept Source" },
  rtwCriticReview: { en: "Critic Reviews", sv: "Critic Reviews" },
  rtwMashup: { en: "Mashups", sv: "Mashups" },
  rtwPeopleBased: { en: "People Based", sv: "People Based" },

  videoDescriptors: {
    sv: "Video Descriptors",
    en: "Video Descriptors"
  },
  carouselTitle: {
    //As in, these are popular movies or TV shows that you should watch.
    sv: "Populära program du måste se",
    enthen: "Must Watch Popular Programs",
    en: "What to Watch Next"
  },
  heroCarouselTitle: {
    sv: "Watch Prompts",
    en: "Watch Prompts"
  },
  basedOnCarouselTitle: {
    sv: "Based on What You Like",
    en: "Based on What You Like"
  },
  clipsCarouselTitle: {
    sv: "Video Clips",
    en: "Video Clips"
  },
  searchPrompt: {
    //As in, a label for button that starts a search
    sv: "Sök",
    en: "Search"
  },
  searchPlaceholder: {
    //As in, a prompt to tell the user what they can search for
    sv: "Film- eller TV-titel",
    en: "Movie or TV title"
  },
  popularityScore: {
    sv: "Popularitetspoäng",
    en: "Popularity\u00A0Score"
  },
  MV: {
    sv: "Movie",
    en: "Movie"
  },
  SH: {
    sv: "TV Show",
    en: "TV Show"
  },
  castHeader: {
    sv: "CAST",
    en: "CAST"
  },
  /**
   * Produces a sentence that describes the user's tastes:
   *
   * Parker enjoys **Emotional** stories that feature **Temptation** and **Love Story** situations.
   *
   * @param {String} lang the current lang
   * @param {String} firstName the profile persona's first name
   * @param {String} mood the mood of stories firstName likes. Probably an adjective.
   * @param {String} theme the theme of the story, probably a noun
   * @param {String} scenario the scenario of the story, probably a noun.
   */
  profileMadLib: function (lang, firstName, mood, theme, scenario) {
    //English
    let ret = (
      <span>
        {firstName} enjoys <b>{mood}</b> stories that feature&nbsp;
        <b>{theme}</b> and <b>{scenario}</b> situations.
      </span>
    );

    // If Swedish:
    if (lang === "sv") {
      ret = (
        <span>
          {firstName} gillar <b>{mood}</b> berättelser som handlar om&nbsp;
          <b>{theme}</b> och <b>{scenario}</b> situationer.
        </span>
      );
    }

    return ret;
  },

  /**
   * Return a search result string, varying based on the language and the length of the results.
   * @param {String} lang the current language
   * @param {String/Number} length the length of the results
   */
  searchResultString: function (lang, length) {
    let resultStr = length === 1 ? "Result" : "Results";
    resultStr += " for";

    if (lang === "sv") {
      resultStr = "resultat för";
    }

    return `${length}\u00A0${resultStr}\u00A0`;
  }
};
