import React, { Component } from "react";
import KeywordLevel from "./KeywordLevel";
import KeywordItem, { WT_KWG } from "./KeywordItem";

import { UI } from "../constants";
import Spinner from "../Spinner";

const rootStyles = {
    margin: "5vh auto 0 auto",
    textAlign: "center"
};

class KeywordGraph extends Component {
    render() {
        const { dispatch } = this.props;
        if (!this.props.keywordGraphData) {
            return null;
        }
        const { levels, childKeywords, category } = this.props.keywordGraphData;

        return (
            <div style={rootStyles}>
                <div>
                    {levels && levels.length > 0 ? (
                        levels.map((level, index) => {
                            return (
                                <KeywordLevel
                                    key={`KeywordLevel_${index}`}
                                    data={{
                                        level: { ...level, category: category }
                                    }}
                                    dispatch={dispatch}
                                />
                            );
                        })
                    ) : (
                        <div title="No keywords found." />
                    )}
                </div>

                {levels.length === 1 && childKeywords.length === 0 && (
                    <Spinner />
                )}

                {childKeywords.length > 0 ? (
                    <div style={childKeywordBox(category)}>
                        {childKeywords.map(function(keyword, index) {
                            return (
                                <KeywordItem
                                    key={`KeywordItem_${index}`}
                                    category={category}
                                    tag={keyword.tag}
                                    count={keyword.count}
                                    weight={WT_KWG}
                                />
                            );
                        })}
                    </div>
                ) : (
                    ""
                )}
            </div>
        );
    }
}
export default KeywordGraph;

const childKeywordBox = () => {
    return {
        fontSize: UI.font.small,
        padding: "1.0vh",
        margin: "0 auto 5rem",
        display: "inline-block",
        maxWidth: "90vw"
    };
};
