import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { closeModal } from "../actions";
import Modal from "react-responsive-modal";

/*
    Each of the keys below (showCloseIcon, open, styles,…)
    has a special meaning to the modal.

    Docs for these react-responsive-modal props here:
    https://github.com/pradel/react-responsive-modal#modal

    Note that we're not using `styled-components` here since
    the modal already has an opinionated way to do styles
 */
const mapStateToProps = state => ({
    showCloseIcon: true,
    open: state.modalMoodGrid,
    styles: {
        closeIcon: {
            fill: "#A9A9A9",
            cursor: "pointer",
            height: "3rem",
            width: "3rem",
            paddingRight: "1rem"
        },
        modal: {
            backgroundClip: "padding-box",
            background: "none",
            boxShadow: "none",
            padding: "0",
            borderRadius: "0",
            maxWidth: "100%"
        }
    }
});

const mapDispatchToProps = dispatch =>
    bindActionCreators({ closeModal }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Modal);
