import { connect } from "react-redux";
import CategoryItem from "./CategoryItem";

const mapStateToProps = (state, mine) => {
    return {
        ...mine,
        wait: state.wait,
        clicked: state.clicked
    };
};

export default connect(mapStateToProps)(CategoryItem);
