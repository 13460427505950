import React, { useState, useEffect } from "react";
import store from "../store";
import ReactGA from "react-ga";
import {
  PERSONALIZED_IMAGES_SELECTED,
  VIDEO_DESCRIPTORS_SELECTED,
  PROGRAM_DETAILS_SELECTED,
  CAST_DETAILS_SELECTED,
  VIDEO_CLIPS_SELECTED,
  REASONS_TO_WATCH_SELECTED,
  PROGRAM_DETAILS_TAB_CLICKED,
  PERSONALIZED_IMAGES_TAB_CLICKED,
  VIDEO_CLIPS_TAB_CLICKED,
  VIDEO_DESCRIPTORS_TAB_CLICKED,
  CAST_DETAILS_TAB_CLICKED,
  REASONS_TO_WATCH_TAB_CLICKED
  // NO_PERSONALIZED
} from "../constants";
import {
  programDetailsTabClicked,
  personalizedImagesTabClicked,
  videoClipsTabClicked,
  reasonsToWatchTabClicked,
  videoDescriptorsTabClicked,
  castDetailsTabClicked
} from "../actions";
import ProgramDetails from "./ProgramDetails";
import CastDetails from "./CastDetails";
import { ImageDetails, ClipDetails } from "./ImageDetails";
import { ReasonsToWatchDetails } from "./ReasonsToWatchDetails";
import { BoundingBox, Tab, Tabs, Title } from "./ContentItemStyles"

import { StringSpan } from "../i18n/strings";
import DescriptorDetails from "./DescriptorDetails";

import { usePalette } from 'react-palette'
import Spinner from "../Spinner";

const ContentItemDetails = ({ modal, lang }) => {

  if (!modal) return null;

  const { selectedContent, selected } = modal;

  const { data, loading, error } = usePalette(selectedContent.imageUri);
  const [paletteColor, setPaletteColor] = useState({ primary: "transparent", secondary: "transparent" });
  useEffect(() => {

    if (!loading && !error) {
      setPaletteColor({ primary: data.vibrant, secondary: data.darkVibrant })
    }
  }, [loading])

  if (process.env.REACT_APP_GTAG) {
    ReactGA.event({
      category: "Content",
      action: "Viewing Program Details"
    });
  }

  const handleProgramClick = () => {
    if (process.env.REACT_APP_GTAG) {
      ReactGA.event({
        category: "Content",
        action: "Click Program Details Tab"
      });
    }
    store.dispatch(programDetailsTabClicked());
  };
  const handleImagesClick = () => {
    if (process.env.REACT_APP_GTAG) {
      ReactGA.event({
        category: "Content",
        action: "Click Personalized Images Tab"
      });
    }
    store.dispatch(personalizedImagesTabClicked());
  };
  const handleVideoClipsClick = () => {
    if (process.env.REACT_APP_GTAG) {
      ReactGA.event({
        category: "Content",
        action: "Click Video Clips Tab"
      });
    }
    store.dispatch(videoClipsTabClicked());
  };

  const handleReasonsToWatchClick = () => {
    if (process.env.REACT_APP_GTAG) {
      ReactGA.event({
        category: "Content",
        action: "Click Watch Prompts Tab"
      });
    }
    store.dispatch(reasonsToWatchTabClicked());
  };

  const handleVideoDescriptorsClick = () => {
    if (process.env.REACT_APP_GTAG) {
      ReactGA.event({
        category: "Content",
        action: "Click Video Descriptors Tab"
      });
    }
    store.dispatch(videoDescriptorsTabClicked());
  };

  const handleCastDetailsClick = () => {
    if (process.env.REACT_APP_GTAG) {
      ReactGA.event({
        category: "Content",
        action: "Click Cast Details Tab"
      });
    }
    store.dispatch(castDetailsTabClicked());
  };

  // determine what to display based on state
  let detailsToShow = null;
  const personalizedImages =
    selectedContent.personalizedImages &&
    selectedContent.personalizedImages.length;
  const videoClips =
    selectedContent.videoClips && selectedContent.videoClips.length > 0;
  const reasonsToWatch =
    selectedContent.reasonsToWatch &&
    selectedContent.reasonsToWatch.length > 0;

  // There will never be VC w/o PI
  switch (selected) {
    case PERSONALIZED_IMAGES_TAB_CLICKED:
      detailsToShow = PERSONALIZED_IMAGES_SELECTED;
      break;
    case VIDEO_DESCRIPTORS_TAB_CLICKED:
      detailsToShow = VIDEO_DESCRIPTORS_SELECTED;
      break;
    case VIDEO_CLIPS_TAB_CLICKED:
      detailsToShow = VIDEO_CLIPS_SELECTED;
      break;
    case REASONS_TO_WATCH_TAB_CLICKED:
      detailsToShow = REASONS_TO_WATCH_SELECTED;
      break;
    case PROGRAM_DETAILS_TAB_CLICKED:
      detailsToShow = PROGRAM_DETAILS_SELECTED;
      break;
    case CAST_DETAILS_TAB_CLICKED:
      detailsToShow = CAST_DETAILS_SELECTED;
      break;
    default:
      detailsToShow = PROGRAM_DETAILS_SELECTED;
      break;
  }

  const SelectedTab = ({ lang, which }) => (
    <Tab selected>
      <StringSpan lang={lang} which={which} />
    </Tab>
  );

  const UnSelectedTab = ({ lang, which, handler }) => (
    <Tab lang={lang} onClick={handler}>
      <StringSpan lang={lang} which={which} />
    </Tab>
  );

  const usImageTab = (
    <UnSelectedTab
      lang={lang}
      which="imageOptions"
      handler={handleImagesClick}
    />
  );
  const usClipTab = (
    <UnSelectedTab
      lang={lang}
      which="clipOptions"
      handler={handleVideoClipsClick}
    />
  );
  const usRTWatchTab = (
    <UnSelectedTab
      lang={lang}
      which="rtwOptions"
      handler={handleReasonsToWatchClick}
    />
  );
  const usDetailsTab = (
    <UnSelectedTab
      lang={lang}
      which="programDetails"
      handler={handleProgramClick}
    />
  );
  const usDescriptorsTab = (
    <UnSelectedTab
      lang={lang}
      which="videoDescriptors"
      handler={handleVideoDescriptorsClick}
    />
  );

  const usCastTab = (
    <UnSelectedTab
      lang={lang}
      which="castDetails"
      handler={handleCastDetailsClick}
    />
  );

  let deets = {
    PROGRAM_DETAILS_SELECTED: (
      <>
        <Tabs paletteColor={paletteColor.primary}>
          <SelectedTab lang={lang} which="programDetails" />
          {usCastTab}
          {usDescriptorsTab}
          {personalizedImages ? usImageTab : null}
          {videoClips ? usClipTab : null}
          {reasonsToWatch ? usRTWatchTab : null}
        </Tabs>
        <ProgramDetails paletteColor={paletteColor} data={selectedContent} />
      </>
    ),
    CAST_DETAILS_SELECTED: (
      <>
        <Tabs paletteColor={paletteColor.primary}>
          {usDetailsTab}
          <SelectedTab lang={lang} which="castDetails" />
          {usDescriptorsTab}
          {personalizedImages ? usImageTab : null}
          {videoClips ? usClipTab : null}
          {reasonsToWatch ? usRTWatchTab : null}
        </Tabs>
        <CastDetails paletteColor={paletteColor} data={selectedContent} />
      </>
    ),
    PERSONALIZED_IMAGES_SELECTED: (
      <>
        <Tabs paletteColor={paletteColor.primary}>
          {usDetailsTab}
          {usCastTab}
          {usDescriptorsTab}
          <SelectedTab lang={lang} which="imageOptions" />
          {videoClips ? usClipTab : null}
          {reasonsToWatch ? usRTWatchTab : null}
        </Tabs>
        <ImageDetails paletteColor={paletteColor} data={selectedContent} />
      </>
    ),
    VIDEO_CLIPS_SELECTED: (
      <>
        <Tabs paletteColor={paletteColor.primary}>
          {usDetailsTab}
          {usCastTab}
          {usDescriptorsTab}
          {usImageTab}
          <SelectedTab lang={lang} which="clipOptions" />
          {reasonsToWatch ? usRTWatchTab : null}
        </Tabs>
        <ClipDetails paletteColor={paletteColor} data={selectedContent} />
      </>
    ),
    VIDEO_DESCRIPTORS_SELECTED: (
      <>
        <Tabs paletteColor={paletteColor.primary}>
          {usDetailsTab}
          {usCastTab}
          <SelectedTab lang={lang} which="videoDescriptors" />
          {personalizedImages ? usImageTab : null}
          {videoClips ? usClipTab : null}
          {reasonsToWatch ? usRTWatchTab : null}
        </Tabs>
        <DescriptorDetails paletteColor={paletteColor} data={selectedContent} />
      </>
    ),
    REASONS_TO_WATCH_SELECTED: (
      <>
        <Tabs paletteColor={paletteColor.primary}>
          {usDetailsTab}
          {usCastTab}
          {usDescriptorsTab}
          {personalizedImages ? usImageTab : null}
          {videoClips ? usClipTab : null}
          <SelectedTab lang={lang} which="rtwOptions" />
        </Tabs>
        <ReasonsToWatchDetails paletteColor={paletteColor} data={selectedContent} />
      </>
    )
  }[detailsToShow];

  return (
    (!loading && !error ?
      <div>

        <BoundingBox id="BoundingBox" paletteColor={paletteColor.primary}>
          <Title borderColor={paletteColor.secondary} paletteColor={paletteColor.primary}>{selectedContent.title}</Title>
          {deets}
        </BoundingBox>

      </div> : <Spinner />)
  );
  // }
}

export default ContentItemDetails;
