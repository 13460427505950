import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import MoodSwitchModalContainer from "./MoodSwitchModalContainer";
import MoodTiles from "./MoodTiles";
import store from "../store";
import { moodBreadCrumbClicked } from "../actions";
import { MOOD_HOME, CLOSE_MODAL } from "../constants";
import { Poster } from "../Poster";
import MoodContainer from "./MoodContainer";
import BackgroundMoodShim from "./BackgroundMoodShim";

const BoundingBox = styled.section`
    position: relative;
    background: none;
`;
const TopBoundingBox = styled.section`
    z-index: 1;
    position: relative;
    background: none;
`;

// put something here re data-src vs src for poster images...
const ViewModel = (programs, lazyLoad) => {
    return programs.map(program => {
        return {
            tmsId: program.tmsId,
            rootId: program.rootId,
            uri: program.landscapeUri,
            title: program.title,
            hasPersonalizedImages:
                program.personalizedImages &&
                program.personalizedImages.length > 0,
            action: program.action,
            lazyLoad: lazyLoad
        };
    });
};

export default class MoodsCarousel extends Component {
    onCloseModal = () => {
        store.dispatch({
            type: CLOSE_MODAL
        });
    };

    handleBreadCrumbClick = (mood, idx) => {
        store.dispatch(moodBreadCrumbClicked(mood, idx));
    };

    handleMoodHomeClick = () => {
        store.dispatch({
            type: MOOD_HOME
        });
    };

    componentDidUpdate() {
        var element = document.getElementById("anchor");
        element.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
        });
    }

    render() {
        const {
            moodPath,
            moodItemsData,
            moodHomeSwitch,
            selectedMoodPath
        } = this.props;

        let breadCrumbs = (
            <>
                <div
                    className="mood-breadcrumb-initial"
                    onClick={() => this.handleMoodHomeClick()}
                >
                    {moodPath.length > 0 ? (
                        <div>
                            Home
                            <span className="">&nbsp;&gt;&nbsp;</span>
                        </div>
                    ) : (
                        <span>Home</span>
                    )}
                </div>
                <div className="mood-top-title">
                    {moodPath.map((m, idx) => {
                        if (idx === moodPath.length - 1) {
                            return (
                                <div key={idx} className="mood-breadcrumb">
                                    {/* <span>&nbsp;&gt;&nbsp;</span> */}
                                    <span className="final">
                                        {m.viewer_motivation}
                                    </span>
                                    <div id="anchor" />
                                </div>
                            );
                        } else
                            return (
                                <div
                                    className="mood-breadcrumb"
                                    key={idx}
                                    onClick={() =>
                                        this.handleBreadCrumbClick(m, idx)
                                    }
                                >
                                    <span>{m.viewer_motivation}</span>
                                    <span>&nbsp;&gt;&nbsp;</span>
                                </div>
                            );
                    })}
                </div>
            </>
        );

        // we could filter here per any business rules,
        // e.g. remove state.selectedMoodPathProgram
        const moodData = moodItemsData.programItems.slice(0, 6);

        let moodTiles =
            moodItemsData.graph && moodData && moodData.length > 0 ? (
                <BoundingBox>
                    <MoodTiles
                        items={ViewModel(
                            moodData,
                            false // turn off lazy loading
                        )}
                        moodOfOrigin={selectedMoodPath}
                        viewComponent={Poster}
                        graphs={moodItemsData.graph}
                    />
                </BoundingBox>
            ) : (
                <div />
            );

        const carouselTitle = (
            <TopBoundingBox>
                <div
                    style={{
                        color: "gray"
                    }}
                    className="top-mood-carousel"
                >
                    {breadCrumbs}
                </div>
            </TopBoundingBox>
        );

        return moodItemsData ? (
            <div style={{ position: "fixed" }}>
                {carouselTitle}
                <BackgroundMoodShim
                    opacity="0.8"
                    position="fixed"
                    top="5rem"
                    scrim={true}
                    abSwitch={moodHomeSwitch}
                />
                {moodTiles}
                <MoodSwitchModalContainer onClose={this.onCloseModal}>
                    <MoodContainer />
                </MoodSwitchModalContainer>
            </div>
        ) : null;
    }
}
MoodsCarousel.propTypes = {
    moodItemsData: PropTypes.object.isRequired
};
