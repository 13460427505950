import React, { Fragment } from "react";
import { Parser } from "html-to-react";

const parser = new Parser();


const HTMLParse = ({ item }) => {
    return <Fragment>{parser.parse(item)}</Fragment>
};

export default HTMLParse;
