import React, { Component } from "react";
import { connect } from "react-redux";
import "./message.scss";
import { StringSpan } from "../i18n/strings";

function mapStateToProps(state) {
    return {
        session: state.session,
        message: state.message,
        lang: state.lang
    };
}

class Message extends Component {
    render() {
        return (
            <div className="message-grand-container">
                <div className="message-container">
                    <p className="message">
                        <StringSpan
                            lang={this.props.lang}
                            which={this.props.message}
                        />
                    </p>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(Message);
