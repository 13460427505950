import React, { Component } from "react";
import _ from "lodash";
import ReactGA from "react-ga";
import onClickOutside from "react-onclickoutside";
import store from "../store";
import { TASTE_PROFILE_CLICKED } from "../constants";
import { FACE, HEADS, TAILS } from "../api/profile";
import Bubble from "./Bubble";
import TasteProfileSVG from "./TasteProfileSVG";
import CloseButtonSVG from "./CloseButtonSVG";
import CloseButtonWhiteSVG from "./CloseButtonWhiteSVG";
import styled from "styled-components";
import TasteProfileCSS from "./TasteProfile.module.scss";
import { StringTasteProfile } from "../i18n/strings";

const BUBBLEWEIGHTS = {
  40: "tiny",
  50: "xsmall",
  55: "small",
  60: "medium",
  65: "large",
  70: "xlarge",
  80: "xxlarge",
  100: "xxxlarge",
  12: "inspiring",
  32: "reality",
  15: "upLift",
  12.1: "margot",
  16.1: "powerful",
  16.2: "bonds",
  12.2: "life",
  15.1: "heroism",
  12.3: "apa",
  12.4: "alexandra",
  10: "johan",
  17: "light",
  18: "absurd",
  19: "sin",
  20: "misadventures",
  21: "schemes"
};

const TasteProfileButton = styled.div`
  position: relative;
  top: 0;
  left: 0;
  transform: scale(1.7);
  z-index: 1;
`;

const CloseTasteProfileButton = styled.div`
  position: absolute;
  top: -34%;
  left: 36%;
  margin-top: 0;
  transform: scale(0.08);
  z-index: 13;
  width: 100%;
`;

class TasteProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.state.lightsOn = false;
    if (process.env.REACT_APP_GTAG) {
      ReactGA.event({
        category: "Taste Profile",
        action: `Taste Profile Opened`
      });
    }
  }
  escFunction(event) {
    if (event.defaultPrevented) {
      return; // Do nothing if the event was already processed
    }

    switch (event.key) {
      case "Esc": // IE/Edge specific value
      case "Escape":
        // Close Taste Profile "esc" key press.
        store.dispatch({
          type: TASTE_PROFILE_CLICKED,
          showingTasteProfile: false
        });
        break;
      default:
        return; // Quit when this doesn't handle the key event.
    }

    // Cancel the default action to avoid it being handled twice
    event.preventDefault();
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  handleHover = () => {
    this.setState({ lightsOn: true });
  };

  handleHoverOff = () => {
    this.setState({ lightsOn: false });
  };

  handleClick = () => {
    store.dispatch({
      type: TASTE_PROFILE_CLICKED,
      showingTasteProfile: !this.props.showingTasteProfile
    });
  };

  /* HOC provided by react-onclickoutside 
       we use it to allow click outside TasteProfile closes the bubble
    */
  handleClickOutside = () => {
    if (this.props.showingTasteProfile) {
      store.dispatch({
        type: TASTE_PROFILE_CLICKED,
        showingTasteProfile: !this.props.showingTasteProfile
      });
    }
  };

  contents(
    profile,
    profileNames,
    showingTasteProfile,
    tasteProfileData,
    lightsOn
  ) {
    if (showingTasteProfile && tasteProfileData[profile].keywords.length > 0) {
      const kwBubbles = [
        ...tasteProfileData[profile].keywords,
        ...tasteProfileData[profile].genres
      ];
      const celebBubbles = tasteProfileData[profile].celebrities;

      const firstName = profileNames[profile].first;

      // get first mood element
      const moodElem = _.find(kwBubbles, ["category", "mood"]);
      const stories = moodElem[moodElem[FACE]];

      // get first theme element
      const featuringElem = _.find(kwBubbles, ["category", "theme"]);
      const featuring = featuringElem[featuringElem[FACE]];

      // get first scenario element
      const situationsElem = _.find(kwBubbles, ["category", "scenario"]);
      const situations = situationsElem[situationsElem[FACE]];

      // construct sentence
      //STRING: enjoys stories that feature and situations
      const sentence = profileNames ? (
        <StringTasteProfile
          lang={this.props.lang}
          firstName={firstName}
          mood={stories}
          theme={featuring}
          scenario={situations}
        />
      ) : null;
      // const sentence = profileNames ? (
      //     <div>
      //         {firstName} enjoys <b>{stories}</b> stories that feature{" "}
      //         <b>{featuring}</b> and <b>{situations}</b> situations.
      //     </div>
      // ) : null;

      // bubble machine
      // N.B. this order needs to be the same as the 'const bubbleToFlip' in in makeBubbleClickedRequest in sagas.js
      const bubbles = [...kwBubbles, ...celebBubbles].map((item, index) => (
        <Bubble
          key={index}
          id={index}
          size={BUBBLEWEIGHTS[item.weight]}
          category={item.category}
          currentFace={item.face}
          faces={[item[HEADS], item[TAILS]]}
          textColor={item.category}
        />
      ));

      return (
        <div>
          <div className={TasteProfileCSS.tasteProfile}>
            <CloseTasteProfileButton
              onMouseOver={this.handleHover}
              onMouseOut={this.handleHoverOff}
            >
              <img
                onClick={this.handleClick}
                alt="Close Taste Profile"
                src={lightsOn ? CloseButtonWhiteSVG : CloseButtonSVG}
              />
            </CloseTasteProfileButton>
            <div className={TasteProfileCSS["text-madlib"]}>{sentence}</div>
            {bubbles}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    const { lightsOn } = this.state;

    const {
      profile,
      profileNames,
      showingTasteProfile,
      tasteProfileData
    } = this.props;

    /*
     * The 'parent' class align <TasteProfileButton/>, and will contain contents once
     * the button is clicked and toggles showingTasteProfile true.
     */
    return (
      <div>
        <div className={TasteProfileCSS.parent}>
          {this.contents(
            profile,
            profileNames,
            showingTasteProfile,
            tasteProfileData,
            lightsOn
          )}
        </div>
        <TasteProfileButton>
          <img
            onClick={this.handleClick}
            alt="Taste Profile"
            src={TasteProfileSVG}
          />
        </TasteProfileButton>
      </div>
    );
  }
}

export default onClickOutside(TasteProfile);
