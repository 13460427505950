const data = {
    "Root For The Underdog": "Feel Lighthearted",
    "Be Heartwarmed": "Feel Lighthearted",
    "Laugh Out Loud": "Feel Lighthearted",
    "Be Inspired": "Feel Lighthearted",
    "Root For A Hero": "Feel Lighthearted",
    "Feel Patriotic": "Feel Lighthearted",
    "Be Amused": "Feel Lighthearted",
    "Make A Journey": "Feel Lighthearted",
    "Be Blown Away": "Feel Lighthearted",
    "Mend A Broken Heart": "Feel Lighthearted",
    "Feel Upbeat": "Feel Lighthearted",
    "Feel Whimsical": "Feel Lighthearted",
    "Feel Romance": "Be A Part Of A Love Story",
    "Follow My Heart": "Be A Part Of A Love Story",
    "Feel Butterflies In My Stomach": "Be A Part Of A Love Story",
    "Be Nostalgic": "Be A Part Of A Love Story",
    Sympathize: "Be A Part Of A Love Story",
    "See True Friendship": "Be A Part Of A Love Story",
    "Find A Place To Belong": "Be A Part Of A Love Story",
    "See Unexpected Love": "Be A Part Of A Love Story",
    "Take It Easy": "Chill",
    "Let Loose": "Chill",
    Smile: "Chill",
    "Enter The World Of Luxury": "Chill",
    "Take Comfort In The Familiar": "Chill",
    "Feel Awkward": "Chill",
    "Experience Beauty": "Chill",
    "Look At The Bright Side": "Chill",
    "Be Dysfunctional": "Chill",
    "Be Serious": "Face Reality",
    "Dive Into Personal Crisis": "Face Reality",
    "See A Dark World": "Face Reality",
    "Explore Corruption": "Face Reality",
    "Be Outraged": "Face Reality",
    "Win At Any Cost": "Face Reality",
    "Fight The Power": "Face Reality",
    "Look Within": "Face Reality",
    "Dive Into Reality": "Face Reality",
    "Feel Gloomy": "Face Reality",
    "Peek Behind The Curtain": "Face Reality",
    "Laugh AND Cry": "Face Reality",
    "Reconcile With The Past": "Face Reality",
    "See Personal Growth": "Discover Something New",
    "Explore New Horizons": "Discover Something New",
    "Celebrate Excellence": "Discover Something New",
    "Witness Determination": "Discover Something New",
    "Appreciate Honor": "Discover Something New",
    "See The World": "Discover Something New",
    "Expand My Mind": "Discover Something New",
    "See Dreams Come True": "Discover Something New",
    "Be Fascinated": "Discover Something New",
    "Live A Life Story": "Discover Something New",
    "Appreciate Leadership": "Discover Something New",
    "Journey To The Past": "Discover Something New",
    "See The Future": "Discover Something New",
    "Gain New Knowledge": "Discover Something New",
    "Fall Into Despair": "Cry",
    "Feel My Heart Break": "Cry",
    "Be Brought To Tears": "Cry",
    "Deal With Tragedy": "Cry",
    "Wallow In Sadness": "Cry",
    "Seek Forgiveness": "Cry",
    "Be Frightened": "Be Afraid",
    "Explore Obsession": "Be Afraid",
    "See What Shouldn't Be Seen": "Be Afraid",
    "Be The Bad Guy": "Be Afraid",
    "Be Shocked": "Be Afraid",
    "Be Horrified": "Be Afraid",
    "Be Offended": "Be Afraid",
    "Watch An Unstoppable Force": "Be Afraid",
    "Descend Into Madness": "Be Afraid",
    "Make An Escape": "Be On The Edge Of My Seat",
    "A Shot Of Adrenaline": "Be On The Edge Of My Seat",
    "Rage At The World": "Be On The Edge Of My Seat",
    "Take Revenge!": "Be On The Edge Of My Seat",
    "Seek Justice": "Be On The Edge Of My Seat",
    "Destroy!": "Be On The Edge Of My Seat",
    "Feel Danger": "Be On The Edge Of My Seat",
    "Wallow In Vulgarity": "Be On The Edge Of My Seat",
    "Revel In Controversy": "Be On The Edge Of My Seat",
    "Feel Tense": "Be On The Edge Of My Seat",
    "See Something Steamy": "Be On The Edge Of My Seat",
    "Have A Dark Laugh": "Be On The Edge Of My Seat",
    "Follow The Clues": "Be On The Edge Of My Seat",
    "Spiral Out Of Control": "Be On The Edge Of My Seat"
};

const exportable = {};

for (let key in data) {
    exportable[[key.toLowerCase().replace(/\s/g, "")]] = data[key]
        .toLowerCase()
        .replace(/\s/g, "");
}

export default exportable;
