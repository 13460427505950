import { authMessages, MESSAGE_WAIT } from "../actions";
export default {
  title: "Advanced Discovery Suite",
  profile: "profile_2",
  lang: "en",
  wait: false,
  placeholder: "/placeholder_4x4_xprt.png",
  profileNames: {},
  isGoogle: true,
  showingMoodCarouselPopup: false,
  moodCarouselPopupImage: null,
  showingTasteProfile: false,
  thematicContent: false,
  thematicSubModal: false,
  showingSearchResults: false,
  showingCuration: false,
  tasteProfileData: {
    profile_1: { keywords: [], celebrities: [], genres: [] },
    profile_2: { keywords: [], celebrities: [], genres: [] },
    profile_3: { keywords: [], celebrities: [], genres: [] },
    profile_4: { keywords: [], celebrities: [], genres: [] }
  },
  modal: null,
  term: "",
  latestGenres: [],
  genre: "",
  genreNameIndex: null,
  genreSearchResults: null,
  moodPath: [],
  moodHomeSwitch: true, // data set A === true; data set B === false
  moodHomeShowSVG: false,
  modalMoodSwitch: null,
  modalMoodGrid: false,
  fullscreen: {
    moodItemsData: [],
    popularItemsData: {},
    heroItemsData: [],
    basedOnItemsData: [],
    clipsItemsData: [],
    advancedCategoriesData: [],
    thematicCategoriesData: [],
    suggestions: []
  },
  session: { expires: 0, authenticated: false },
  message: authMessages[MESSAGE_WAIT],
  loggedIn: false,
  keywordEnabledCategories: []
};
