import React, { Component } from "react";
import styled from "styled-components";
import {
  OPEN_WOMEN,
  OPEN_MARVEL,
  OPEN_AGATHA,
  OPEN_POLITICS,
  OPEN_FACING,
  CLOSE_THEMATIC_LANDING,
  CLOSE_ALL_THEMATIC
} from "../constants";
import store from "../store";

const thematicArr = [
  {
    uri: "/thematic/celebrating-women.png",
    title: "CELEBRATING WOMEN",
    description: "A collection of media showcasing and honoring women"
  },
  {
    uri: "/thematic/marvel.png",
    title: "MARVEL",
    description:
      "A collection of works covering all Marvel Entertainment has to offer"
  },
  {
    uri: "/thematic/agatha-christie.png",
    title: "AGATHA CHRISTIE",
    description:
      "Solve the mystery with this collection of adaptations of works by Agatha Christie"
  },
  {
    uri: "/thematic/politics.png",
    title: "POLITICS",
    description:
      "See it from every angle with this collection dealing with the Politics that drive our world"
  },
  {
    uri: "/thematic/facing-reality.png",
    title: "FACING REALITY",
    description:
      "A collection of works for when you are in the mood to deal with the realities of the world"
  }
];

const CarouselHeaderTitle = styled.div`
  font-size: 2.5rem;
  font-family: Oxygen, sans-serif;
  padding: 1rem 0;
  color: white;
  font-weight: normal;
`;

const CarouselPosterDiv = styled.div`
  cursor: pointer;
  filter: drop-shadow(0 0.2rem 0.2rem rgba(0, 0, 0, 0.5));
  background: url(${props => props.img}) no-repeat;
  background-size: cover;
  height: 13.5rem !important;
  width: 58.5rem !important;
`;

const CarouselDescription = styled.div`
  font-size: 1.5rem;
  padding: 1rem 0;
  color: white;
  max-width: 30rem;
`;

const CarouselTitle = styled.div`
  font-size: 1.75rem;
  padding: 0;
  color: white;
  font-weight: bold;
`;

export default class ThematicLanding extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  openWomenPage(index) {
    store.dispatch({ type: CLOSE_THEMATIC_LANDING });
    store.dispatch({ type: CLOSE_ALL_THEMATIC });
    switch (index) {
      case 0:
        store.dispatch({ type: OPEN_WOMEN });
        break;
      case 1:
        store.dispatch({ type: OPEN_MARVEL });
        break;
      case 2:
        store.dispatch({ type: OPEN_AGATHA });
        break;
      case 3:
        store.dispatch({ type: OPEN_POLITICS });
        break;
      case 4:
        store.dispatch({ type: OPEN_FACING });
        break;
      default:
    }
  }

  render() {
    return (
      <>
        <div style={{ paddingTop: "2rem", textAlign: "end", width: "44%" }}>
          <CarouselHeaderTitle>Thematic Collections</CarouselHeaderTitle>
        </div>
        <div className="landing-wrapper">
          {thematicArr.map((item, index) => {
            return (
              <div
                style={{ position: "relative", margin: "1.75rem 0" }}
                key={index}
                onClick={() => this.openWomenPage(index)}
              >
                <CarouselPosterDiv img={item.uri} />
                <div
                  style={{ position: "absolute", left: "2rem", top: "2rem" }}
                >
                  <CarouselTitle>{item.title}</CarouselTitle>
                  <CarouselDescription>{item.description}</CarouselDescription>
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  }
}
