const pers_imgs_1 = require("./pers_imgs_1").default;
const pers_imgs_2 = require("./pers_imgs_2").default;
const pers_imgs_3 = require("./pers_imgs_3").default;
const pers_imgs_4 = require("./pers_imgs_4").default;
let personalizedImages = {
    profile_1: pers_imgs_1,
    profile_2: pers_imgs_2,
    profile_3: pers_imgs_3,
    profile_4: pers_imgs_4
};

module.exports = { personalizedImages };
