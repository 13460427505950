import React from "react";
import ReactGA from "react-ga";
import store from "../store";
import BackgroundMoodShim from "./BackgroundMoodShim";
import { moodQuadrantClicked } from "../actions";
import MoodHomeSVG from "./MoodHomeSVG";

class Mood extends React.Component {
    constructor(props) {
        super(props);
        this.handleMoodQuadrantClick = this.handleMoodQuadrantClick.bind(this);
    }

    getRandomIntInclusive = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min; //The maximum is inclusive and the minimum is inclusive
    };

    handleMoodQuadrantClick = mood => {
        if (process.env.REACT_APP_GTAG) {
            ReactGA.event({
                category: "Mood",
                action: `View Motivation Top Level Clicked`
            });
        }
        store.dispatch(moodQuadrantClicked(mood));
    };

    render() {
        const {
            moodHomeSwitch,
            vmHomeDisplayMap,
            moodHomeShowSVG
        } = this.props;

        let programs = [];
        if (
            vmHomeDisplayMap &&
            vmHomeDisplayMap.A &&
            vmHomeDisplayMap.A.length > 0 &&
            vmHomeDisplayMap.B &&
            vmHomeDisplayMap.B.length > 0
        ) {
            programs = moodHomeSwitch ? vmHomeDisplayMap.A : vmHomeDisplayMap.B;
        }

        const moodHomeBubbles = (
            <MoodHomeSVG
                action={this.handleMoodQuadrantClick}
                showSVG={moodHomeShowSVG}
            >
                {programs.map(m => {
                    return {
                        tmsId: "MV0",
                        viewer_motivation: m["viewer_motivation"],
                        color: m["color"],
                        region: m["region"],
                        gradient: m["gradient"]
                    };
                })}
            </MoodHomeSVG>
        );

        return (
            <div className="mood-container">
                <BackgroundMoodShim
                    opacity="0.2"
                    position="relative"
                    top="0"
                    scrim={false}
                    abSwitch={moodHomeSwitch}
                />
                <div className="mood-menu">{moodHomeBubbles}</div>
            </div>
        );
    }
}

export default Mood;
