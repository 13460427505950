import React from "react";
import store from "./store";
import { Provider } from "react-redux";
import { render } from "react-dom";

import App from "./App";
import registerServiceWorker from "./registerServiceWorker";

import "./intermediates/main.css";

const View = () => (
    <Provider store={store}>
        <App />
    </Provider>
);

render(<View />, document.getElementById("root"));
registerServiceWorker();
