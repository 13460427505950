import React from "react";
const IconMashupSVG = (props) => {
	const color = props.color || "black";
	const { style } = props
	return (
		<div style={style}>
			<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
				viewBox="0 0 9.2 7.7" style={{ "enableBackground:new": "0 0 9.2 7.7" }} >
				<path fill={color} d="M8.6,0.1c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.2,0.4v6.5c0,0.2-0.1,0.3-0.2,0.4C8.9,7.6,8.7,7.7,8.6,7.7h-8
	c-0.2,0-0.3-0.1-0.4-0.2C0.1,7.4,0,7.3,0,7.1V0.6c0-0.2,0.1-0.3,0.2-0.4s0.2-0.2,0.4-0.2H8.6L8.6,0.1z M1.7,0.8c0-0.1,0-0.1-0.1-0.1
	c0,0-0.1-0.1-0.1-0.1H0.8c-0.1,0-0.1,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1v0.8c0,0.1,0,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1h0.8
	c0.1,0,0.1,0,0.1-0.1c0,0,0.1-0.1,0.1-0.1V0.8z M1.7,2.6c0-0.1,0-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1H0.8c-0.1,0-0.1,0-0.1,0.1
	c0,0-0.1,0.1-0.1,0.1v0.8c0,0.1,0,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1h0.8c0.1,0,0.1,0,0.1-0.1c0,0,0.1-0.1,0.1-0.1V2.6z M1.7,4.4
	c0-0.1,0-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1H0.8c-0.1,0-0.1,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1v0.8c0,0.1,0,0.1,0.1,0.1
	c0,0,0.1,0.1,0.1,0.1h0.8c0.1,0,0.1,0,0.1-0.1s0.1-0.1,0.1-0.1V4.4z M1.7,6.2c0-0.1,0-0.1-0.1-0.1C1.6,6,1.6,6,1.5,6H0.8
	C0.7,6,0.7,6,0.6,6c0,0-0.1,0.1-0.1,0.1v0.8C0.6,7,0.6,7,0.6,7c0,0,0.1,0.1,0.1,0.1h0.8c0.1,0,0.1,0,0.1-0.1c0,0,0.1-0.1,0.1-0.1
	V6.2z M6.9,0.8c0-0.1,0-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1H2.5c-0.1,0-0.1,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1v2.5c0,0.1,0,0.1,0.1,0.1
	c0,0,0.1,0.1,0.1,0.1h4.2c0.1,0,0.1,0,0.1-0.1c0,0,0.1-0.1,0.1-0.1V0.8z M6.9,4.4c0-0.1,0-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1H2.5
	c-0.1,0-0.1,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1v2.5C2.3,7,2.3,7,2.3,7c0,0,0.1,0.1,0.1,0.1h4.2c0.1,0,0.1,0,0.1-0.1c0,0,0.1-0.1,0.1-0.1
	V4.4z M8.6,0.8c0-0.1,0-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1H7.6c-0.1,0-0.1,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1v0.8c0,0.1,0,0.1,0.1,0.1
	c0,0,0.1,0.1,0.1,0.1h0.8c0.1,0,0.1,0,0.1-0.1c0,0,0.1-0.1,0.1-0.1V0.8z M8.6,2.6c0-0.1,0-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1H7.6
	c-0.1,0-0.1,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1v0.8c0,0.1,0,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1h0.8c0.1,0,0.1,0,0.1-0.1
	c0,0,0.1-0.1,0.1-0.1V2.6z M8.6,4.4c0-0.1,0-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1H7.6c-0.1,0-0.1,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1v0.8
	c0,0.1,0,0.1,0.1,0.1s0.1,0.1,0.1,0.1h0.8c0.1,0,0.1,0,0.1-0.1c0,0,0.1-0.1,0.1-0.1V4.4z M8.6,6.2c0-0.1,0-0.1-0.1-0.1
	C8.5,6,8.4,6,8.4,6H7.6C7.6,6,7.5,6,7.5,6c0,0-0.1,0.1-0.1,0.1v0.8C7.4,7,7.4,7,7.5,7c0,0,0.1,0.1,0.1,0.1h0.8c0.1,0,0.1,0,0.1-0.1
	c0,0,0.1-0.1,0.1-0.1V6.2z"/>
			</svg>
		</div>
	);
}

export default IconMashupSVG;
