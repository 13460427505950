import React from "react";
import store from "../store";
import ReactGA from "react-ga";
import {
  TASTE_PROFILE_CLICKED,
  OPEN_THEMATIC_LANDING,
  CLOSE_ALL_THEMATIC,
  CLOSE_THEMATIC_LANDING
} from "../constants";
import { HOME, MOOD_JOURNEY_CLICKED } from "../constants";
import { userSelectClicked } from "../actions";
import TasteProfileContainer from "../TasteProfile/TasteProfileContainer";
import styled, { css } from "styled-components";
import { StringSpan } from "../i18n/strings";
import AutoComplete from "./AutoComplete";
import { SearchTermContext } from "./App";
import GenreSearchContainer from "../Curate/GenreSearchContainer";
import MoodGridContainer from "../Mood/MoodGridContainer";

// const StyledPersonaGrid = styled.div`
//     display: grid;
//     grid-template-columns: 17rem 12rem 10rem 4rem 10rem;
//     grid-template-rows: 4.1vh 0.1vh;
//     grid-template-areas:
//         "r1c0 r1c1 r1c2 r1c3 r1c4"
//         "r2c0 r2c1 r2c2 r2c3 r2c4";
//     color: white;
//     margin-right: 6ch;
// `;
// const PersonaGridCell = styled.div`
//     cursor: pointer;
//     align-self: center;
//     justify-self: center;
//     font-family: Roboto;
//     font-size: 2ch;
//     letter-spacing: 0.4px;
//     grid-area: r1c4;
// `;

// const SelectedPersona = styled(PersonaGridCell)`
//     font-weight: 400;
// `;

const Caret = styled.div`
   {
    border: solid white;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 0.275vw;
    transform: rotate(45deg);
    margin-bottom: 0.3vh;
  }
  ${css`
    width: fit-content;
  `};
`;

// const Button = styled(PersonaGridCell)`
//     cursor: pointer;
//     grid-area: r1c3;
//     align-self: center;
//     justify-self: start;
//     margin-left: 1.2rem;
// `;

// const GenreButton = styled(PersonaGridCell)`
//     cursor: pointer;
//     align-self: center;
//     justify-self: end;
// `;

// const GenreLabel = styled(PersonaGridCell)`
//     font-variant: all-small-caps;
//     font-size: 2ch;
//     white-space: nowrap;
//     margin-left: 3rem;
// `;

// const Label = styled(PersonaGridCell)`
//     font-variant: all-small-caps;
//     font-size: 2ch;
//     justify-self: end;
//     grid-area: r1c2;
//     white-space: nowrap;
// `;

const Personas = styled.div`
  letter-spacing: 0.4px;
  border-radius: 5px;
  background-color: #181818;
  box-shadow: 0 2.5px 4.5px 0 rgba(0, 0, 0, 0.5);
  ${css`
    width: fit-content;
  `};
`;

const Persona = styled.div`
  border-radius: 5px;
  background-color: #181818;
  padding: 1rem;
  font-size: 1.3rem;
  &:hover {
    background-color: #777777;
  }
`;

const PersonaText = styled.div`
  padding: 0;
`;

class UserForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleNameClick = this.handleNameClick.bind(this);
    this.handleCurateClick = this.handleCurateClick.bind(this);
    this.itemRef = React.createRef();
  }

  handleCurateClick = () => {
    if (process.env.REACT_APP_GTAG) {
      ReactGA.event({
        category: "Curation",
        action: `Curate Button Clicked`
      });
    }

    if (!this.props.thematicContent) {
      store.dispatch({ type: OPEN_THEMATIC_LANDING });
    } else if (this.props.thematicContent && this.props.thematicSubModal) {
      store.dispatch({ type: CLOSE_ALL_THEMATIC });
      store.dispatch({ type: OPEN_THEMATIC_LANDING });
    } else {
      store.dispatch({ type: CLOSE_THEMATIC_LANDING });
    }
  };

  handleChange(event) {
    store.dispatch(
      userSelectClicked(
        event.target.id,
        this.props.profileNames[event.target.id].lang
      )
    );
    if (event.target.id === "profile_5") {
      if (process.env.REACT_APP_GTAG) {
        ReactGA.event({
          category: "Mood",
          action: `Mood Journey Clicked`
        });
      }
      store.dispatch({
        type: MOOD_JOURNEY_CLICKED
      });
    } else
      store.dispatch({
        type: HOME
      });

    this.handleNameClick();
  }

  handleTasteProfileClick = () => {
    if (!this.props.showingTasteProfile === false) {
      if (process.env.REACT_APP_GTAG) {
        ReactGA.event({
          category: "Taste Profile",
          action: `Taste Profile Closed`
        });
      }
    }

    store.dispatch({
      type: TASTE_PROFILE_CLICKED,
      showingTasteProfile: !this.props.showingTasteProfile
    });
  };

  handleNameClick() {
    if (process.env.REACT_APP_GTAG) {
      ReactGA.event({
        category: "User",
        action: `User Select Clicked`
      });
    }
    let element = this.itemRef.current;
    element.classList.toggle("invisible");
  }
  render() {
    if (!this.props.user || !this.props.render) {
      return null;
    }

    if (
      !this.props.profileNames ||
      Object.keys(this.props.profileNames).length === 0
    ) {
      return null;
    }

    const skippedUsers = [this.props.user, "profile_1", "profile_3"];
    const names = this.props.profileNames;
    const keys = Object.keys(names);
    const options = keys
      .filter(k => skippedUsers.indexOf(k) < 0)
      .map((k, i) => {
        let topRadiusTruth = i === 0 ? true : false;
        let bottomRadiusTruth =
          i === keys.length - (skippedUsers.length + 1) // The skipped users + 1 for the selected user
            ? true
            : false;
        let style = {};
        if (topRadiusTruth) {
          style = {
            borderBottomLeftRadius: "0px",
            borderBottomRightRadius: "0px"
          };
        } else if (bottomRadiusTruth) {
          style = {
            ...style,
            borderTopLeftRadius: "0px",
            borderTopRightRadius: "0px"
          };
        } else {
          style = {
            borderTopLeftRadius: "0px",
            borderTopRightRadius: "0px",
            borderBottomLeftRadius: "0px",
            borderBottomRightRadius: "0px"
          };
        }

        return (
          <Persona key={`${k}${i}`} style={style}>
            <PersonaText id={k} onClick={this.handleChange} key={k}>{`${names[k].first
              }\u00A0${names[k].last}`}</PersonaText>
          </Persona>
        );
      });

    const moodOrTasteNavigation =
      this.props.user === "profile_5" ? (
        <div className="mood-grid-button-holder">
          <div className="Label" onClick={this.handleMoodGridIconClick}>
            <StringSpan lang={names[this.props.user].lang} which="moodGrid" />
          </div>
          <div className="Button">
            <MoodGridContainer />
          </div>
        </div>
      ) : (
        <div className="taste-profile-button-holder">
          <div className="Label" onClick={this.handleTasteProfileClick}>
            <StringSpan
              lang={names[this.props.user].lang}
              which="tasteProfile"
            />
          </div>
          <div className="Button">
            <TasteProfileContainer />
          </div>
        </div>
      );

    return (
      <div>
        <div className="StyledPersonaGrid">
          <SearchTermContext.Consumer>
            {value => <AutoComplete term={value} />}
          </SearchTermContext.Consumer>
          {/* Mood Grid or Taste Profile */}
          {moodOrTasteNavigation}
          {/* Genre Curation */}
          <div
            className="curate-button-holder"
            onClick={this.handleCurateClick}
          >
            <div className="GenreLabel">
              <StringSpan
                lang={names[this.props.user].lang}
                which="genreCuration"
              />
            </div>
            <div className="GenreButton">
              <GenreSearchContainer />
            </div>
          </div>

          <div className="SelectedPersona" onClick={this.handleNameClick}>
            {/* STRING: Hi */}
            <StringSpan
              lang={names[this.props.user].lang}
              which="profileGreeting"
            />
            {`,\u00A0${names[this.props.user].first}\u00A0\u00A0`}
            <Caret />
          </div>
          <div
            className="invisible"
            style={{
              gridColumnStart: "r2c3",
              gridColumnEnd: "r2c4",
              cursor: "pointer",
              marginTop: "5%",
              justifySelf: "end"
            }}
            ref={this.itemRef}
          >
            {!this.props.isTasteProfileShowing ? (
              <Personas>{options}</Personas>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
export default UserForm;
