/**
 * https://drive.google.com/drive/folders/1IJfvA_9n3G3_b70I5EgD1QJtJsq2sGiE
 */
const persona_1 = require("./persona_1").default;
const persona_2 = require("./persona_2").default;
const persona_3 = require("./persona_3").default;
const persona_4 = require("./persona_4").default;
const clusters = require("./clusters").default;
const swedish_clusters = require("./swedish_clusters").default;
const dict = require("../i18n/stringdict").default;

/*
 * Motivations Home Display...
 * Key: what Product wants to display
 * Value: what we need to use to look up dynamic Programs based on a similar Motivation in the db.
 * just assign as the `profile_5.moods()` return value.
 */
const motivations_home = {
  A: [
    {
      // northwest
      viewer_motivation: "Cry",
      color: "#F47709",
      region: { x: "25", y: "16.29" }
    },
    {
      // southeast
      viewer_motivation: "Chill",
      color: "#00AEEF",
      region: { x: "75", y: "66.6" }
    },
    {
      // east
      viewer_motivation: "Face Reality",
      color: "#8DC63F",
      region: { x: "87.5", y: "33.33" }
    },
    {
      // northeast
      viewer_motivation: "Be Afraid",
      color: "#FFB100",
      region: { x: "62.5", y: "17" }
    },
    {
      // southwest
      viewer_motivation: "Be A Part Of A Love Story",
      color: "#B21DAC",
      region: { x: "37.5", y: "66.6" }
    },
    {
      // west
      viewer_motivation: "Be On The Edge Of My Seat",
      color: "#DD0014",
      region: { x: "12.5", y: "50.37" }
    }
  ],
  B: [
    {
      viewer_motivation: "Be Afraid",
      color: "#FFB100",
      region: { x: "37.5", y: "66.6" } //"southwest",
    },
    {
      viewer_motivation: "Be A Part Of A Love Story",
      color: "#B21DAC",
      region: { x: "62.5", y: "17" } //"northeast",
    },
    {
      viewer_motivation: "Feel Lighthearted",
      color: "#07C6A0",
      region: { x: "12.5", y: "52" } //"west",
    },
    {
      viewer_motivation: "Discover Something New",
      color: "#BB4803",
      region: { x: "75", y: "66.6" } //"southeast",
    },
    {
      viewer_motivation: "Chill",
      color: "#00AEEF",
      region: { x: "25", y: "16.29" } //"northwest",
    },
    {
      viewer_motivation: "Be On The Edge Of My Seat",
      color: "#DD0014",
      region: { x: "87.5", y: "33.3" } //"east",
    }
  ]
};

/*
 * Keyword or celebrity
 */
const KW = "keywords";
const CE = "celebrites";
/*
 * Which face of the flipper. Can flag which is selected
 */
const HEADS = "heads";
const TAILS = "tails";
/*
 * Which is the selected face. (for view model)
 * Concatenate the selected faces (in alpha order)
 * with 'SEPARATOR' as the separator to generate the key for the "dictionary"
 */
const FACE = "face";
const FPSEP = "|";
/*
 * Constants for fields for the badge access.
 */
const BADGE_FLAG = "badgeFlag";
const BADGE_LETTER = "badgeLetter";
/*
 The fixed weights for the bubbles - proportional to the diameter.
 */
const BUBBLEWEIGHTS = {
  tiny: 40,
  xsmall: 50,
  small: 55,
  medium: 60,
  large: 65,
  xlarge: 70,
  xxlarge: 80,
  xxxlarge: 100,
  inspiring: 12,
  reality: 32,
  upLift: 15,
  margot: 12.1,
  powerful: 16.1,
  bonds: 16.2,
  life: 12.2,
  heroism: 15.1,
  apa: 12.3,
  alexandra: 12.4,
  johan: 10,
  light: 17,
  absurd: 18,
  sin: 19,
  misadventures: 20,
  schemes: 21
};



const POPULAR = {
  en: {
    JURY_DUTY: "SH046755990000",// 24256440	SH046755990000	Jury Duty	
    BASED_ON_TRUE: "SH047369640000",
    HARRY_WILD: "SH042434660000",// 21860853	SH042434660000	Harry Wild	
    SUCCESSION: "SH028752740000",// 15052900	SH028752740000	Sucession	
    POLITE_SOCIETY: "MV019157790000",// 23318684	MV019157790000	Polite Society	
    ABBOT: "SH038712170000",// 20001974	SH038712170000	Abbot Elementary	
    JOY_RIDE: "MV019980790000",// 24343038	MV019980790000	Joy Ride	
    DR_DEATH: "SH034230540000",
    LITTLE_WOMEN: "MV012684230000",
    CASTLE: "SH010855880000",
    TOTS_KILLER: "MV020626220000",// 25410218	MV020626220000	Totally Killer	
    KILLERS: "MV018922030000",// 22972321	MV018922030000	Killers of the Flower Moon	




    //Not used, but keeping so the vizmerch does not cause an error.
    RIVERDALE: "SH024345980000", //	6	12914589	SH024345980000	Riverdale	series	Y
    CRAZY_RICH_ASIANS: "MV010557310000", //	https://jira.gracenote.com/browse/STUDIOX-513
  },
  sv: {
    SOLSIDAN: "SH016216740000", // 1) Solsidan => SH016216740000, 9491726
    MORDEN_I_SANDHAMN: "SH016491750000", // 2) Morden i Sandhamn => SH016491750000, 9599270
    AD_ASTRA: "MV012722810000", // 3) 15724355	MV012722810000	Ad Astra
    ONCE_UPON_WOOD: "MV012559150000", // 4) 15226224	MV012559150000	Once Upon a Time... in Hollywood
    ACK_VARMLAND: "SH021279090000", // 5) Ack Värmland => SH021279090000, 11510912
    GASMAMMAN: "SH022971890000", // 6) Gåsmamman => SH022971890000, 12279316
    BRON: "SH016779830000", // 7) Bron => SH016779830000, 9206465
    BIG_BANG_THEORY: "SH016259210000", // 8) 185554	SH016259210000	The Big Bang Theory
    THE_EXPANSE: "SH025510410000", // 9) 12051214	SH025510410000	The Expanse
    ROCKETMAN: "MV012518890000", // 10) 16058879	MV012518890000	Rocketman
    DUNKIRK: "MV010481470000", // 11) Dunkirk => MV010481470000, 13153578
    MODUS: "SH022583890000" // 12) Modus => SH022583890000,12088476
  }
};
const POPULAR_EN = POPULAR.en;
const POPULAR_SV = POPULAR.sv;
const VIZMERCH = {};
const RIVERDALE_FP_1 = "all";
// https://docs.google.com/spreadsheets/d/1kfgBrF7t3RLk05UkNJORh2CVCELA3_9m_g2Eu4wNdic/edit#gid=0
// Default flipper faces are:
// "Determination|Heroism|Inspiring|Life Story|Personal Bonds|Powerful|Reality|Uplift|K.J. Apa|Margot Robbie"
// Flip: KJ Apa, Uplift, Personal Bonds to get to the new clip
const RIVERDALE_FP_2 =
  "Determination|Heroism|Inspiring|Life Story|Personal Challenge|Powerful|Reality|John Wesley Shipp|Margot Robbie";
VIZMERCH[POPULAR_EN.RIVERDALE] = [
  { fp: RIVERDALE_FP_1, clip: "Riverdale-Diner-5.gif" },
  { fp: RIVERDALE_FP_2, clip: "Riverdale-Frozen-Lake-640x360.gif" }
];
VIZMERCH[POPULAR_EN.CRAZY_RICH_ASIANS] = [
  { fp: "all", clip: "CRA-640x360.gif" }
];

/*
 * https://docs.google.com/spreadsheets/d/1YgKV1MSlapkXeELNxaF4T1d9P8R0r2PB2iTyykelyiE/edit#gid=1055383493
 */
const HERO = {
  en: {
    INSIDE_OUT: "MV021096320000",
    SHOGUN: "SH049487130000",
    BABY_REINDEER: "SH049692350000",
    BARBIE: "MV009483060000",
    CHALLENGERS: "MV018461390000",
    LUPIN: "SH037105560000",
  },
  sv: {
  }
};
const HERO_EN = HERO.en;
// eslint-disable-next-line
const HERO_SV = HERO.sv;


const BASED_ON = {
  en: {
    BOTTOMS: "MV019711390000",
    DARK_WINDS: "SH042755320000",
    ERIN_CARTER: "SH047772150000",
    HIT_MAN: "MV020776460000",
    HAUNTING: "MV019897830000",
    REBOOT: "SH043771670000",
    TWISTERS: "MV021289940000",
    SEARCH_PARTY: "SH024837600000",
    SHINING_GIRLS: "SH041947950000",
    WORST_PERSON: "MV016972150000"
  },
  sv: {
  }
};

const BASED_ON_EN = BASED_ON.en
// eslint-disable-next-line
const BASED_ON_SV = BASED_ON.sv;

const CLIPS = {
  en: {
    POKER_FACE: "SH045687440000",//23610846	SH045687440000	Poker Face	Clips
    MURDER_MYSTERY: "MV019750160000",//23904870	MV019750160000	Murder Mystery 2	Clips
    MISSING: "MV019015600000",//23122703	MV019015600000	Missing	Clips

  },
  sv: {}
}

const CLIPS_EN = CLIPS.en;
// eslint-disable-next-line
const CLIPS_SV = CLIPS.sv

let SearchConstants = require("../SearchConstants");
/*
 * BUBBLEWEIGHTS is readonly.
 */
Object.freeze(BUBBLEWEIGHTS);

let profile = {
  profile_1: {
    name: { first: "Parker", last: "Davis", badgeLetter: "P", lang: "en" },
    flippers: {
      celebrities: [
        {
          heads: "Cole Sprouse",
          tails: "Jesse L. Martin",
          weight: BUBBLEWEIGHTS.xsmall,
          face: HEADS,
          category: SearchConstants.categories.celebrity
        },
        {
          heads: "Chrissy Metz",
          tails: "Camila Mendes",
          weight: BUBBLEWEIGHTS.tiny,
          face: HEADS,
          category: SearchConstants.categories.celebrity
        }
      ],

      genres: [],

      keywords: [
        {
          heads: "Love Story",
          tails: "Misadventures",
          weight: BUBBLEWEIGHTS.xxxlarge,
          face: HEADS,
          category: SearchConstants.categories.scenario
        },
        {
          heads: "Dilemma",
          tails: "Serendipity",
          weight: BUBBLEWEIGHTS.xxlarge,
          face: HEADS,
          category: SearchConstants.categories.scenario
        },
        {
          heads: "Temptation",
          tails: "Dysfunction",
          weight: BUBBLEWEIGHTS.xlarge,
          face: HEADS,
          category: SearchConstants.categories.theme
        },
        {
          heads: "Connection",
          tails: "Challenge",
          weight: BUBBLEWEIGHTS.large,
          face: HEADS,
          category: SearchConstants.categories.theme
        },
        {
          heads: "Romantic",
          tails: "Emotional",
          weight: BUBBLEWEIGHTS.medium,
          face: HEADS,
          category: SearchConstants.categories.mood
        },
        {
          heads: "Tense",
          tails: "Humorous",
          weight: BUBBLEWEIGHTS.small,
          face: HEADS,
          category: SearchConstants.categories.mood
        }
      ]
    },
    dictionary: {
      firstCarousel: {
        en: [
          {
            vizmerch: VIZMERCH,
            category: dict.carouselTitle.en,
            movies: [

              POPULAR_EN.JURY_DUTY,
              POPULAR_EN.BASED_ON_TRUE,
              POPULAR_EN.HARRY_WILD,
              POPULAR_EN.SUCCESSION,
              POPULAR_EN.POLITE_SOCIETY,
              POPULAR_EN.ABBOT,
              POPULAR_EN.JOY_RIDE,
              POPULAR_EN.DR_DEATH,
              POPULAR_EN.LITTLE_WOMEN,
              POPULAR_EN.CASTLE,
              POPULAR_EN.TOTS_KILLER,
              POPULAR_EN.KILLERS
            ]
          }
        ],
        sv: [
          {
            vizmerch: {},
            category: dict.carouselTitle.sv,
            movies: [
              POPULAR_SV.SOLSIDAN,
              POPULAR_SV.MORDEN_I_SANDHAMN,
              POPULAR_SV.AD_ASTRA,
              POPULAR_SV.ONCE_UPON_WOOD,
              POPULAR_SV.ACK_VARMLAND,
              POPULAR_SV.BRON,
              POPULAR_SV.GASMAMMAN,
              POPULAR_SV.BIG_BANG_THEORY,
              POPULAR_SV.THE_EXPANSE,
              POPULAR_SV.ROCKETMAN,
              POPULAR_SV.DUNKIRK,
              POPULAR_SV.MODUS
            ]
          }
        ]
      },
      heroCarousel: {
        en: [
          {
            category: dict.heroCarouselTitle.en,
            movies: [
              HERO_EN.INSIDE_OUT,
              HERO_EN.BABY_REINDEER,
              HERO_EN.BARBIE,
              HERO_EN.CHALLENGERS,
              HERO_EN.LUPIN,
              HERO_EN.SHOGUN
            ]
          }
        ],
        sv: [
          {
            category: dict.heroCarouselTitle.sv,
            movies: []
          }
        ]
      },
      basedOnCarousel: {
        en: [
          {
            category: dict.basedOnCarouselTitle.en,
            movies: [
              BASED_ON_EN.SHINING_GIRLS,
              BASED_ON_EN.TWISTERS,
              BASED_ON_EN.SEARCH_PARTY,
              BASED_ON_EN.DARK_WINDS,
              BASED_ON_EN.BOTTOMS,
              BASED_ON_EN.HAUNTING,
              BASED_ON_EN.HIT_MAN,
              BASED_ON_EN.REBOOT,
              BASED_ON_EN.WORST_PERSON,
              BASED_ON_EN.ERIN_CARTER
            ]
          }
        ],
        sv: [
          {
            category: dict.basedOnCarouselTitle.sv,
            movies: []
          }
        ]
      },
      clipsCarousel: {
        en: [
          {
            category: dict.carouselTitle.en,
            movies: [
              CLIPS_EN.POKER_FACE,
              CLIPS_EN.MISSING,
              CLIPS_EN.MURDER_MYSTERY

            ]
          }
        ],
        sv: [
          {
            category: dict.carouselTitle.sv,
            movies: []
          }
        ]
      },
      getCarouselDefinitions: fingerprint => {
        let indices = persona_1[fingerprint];
        return indices.map(idx =>
          clusters.find(cluster => cluster.cluster_number === idx)
        );
      }
    }
  },
  profile_2: {
    name: { first: "Dylan", last: "Shea", badgeLetter: "D", lang: "en" },
    flippers: {
      celebrities: [
        {
          heads: "K.J. Apa",
          tails: "John Wesley Shipp",
          weight: BUBBLEWEIGHTS.apa,
          face: HEADS,
          category: SearchConstants.categories.celebrity
        },
        {
          heads: "Margot Robbie",
          tails: "Candice Patton",
          weight: BUBBLEWEIGHTS.margot,
          face: HEADS,
          category: SearchConstants.categories.celebrity
        }
      ],

      genres: [
        {
          heads: "Adventure",
          tails: "Reality",
          weight: BUBBLEWEIGHTS.reality,
          face: TAILS,
          category: SearchConstants.categories.genre
        }
      ],

      keywords: [
        {
          heads: "Powerful",
          tails: "Exciting",
          weight: BUBBLEWEIGHTS.powerful,
          face: HEADS,
          category: SearchConstants.categories.mood
        },
        {
          heads: "Inspiring",
          tails: "Tense",
          weight: BUBBLEWEIGHTS.inspiring,
          face: HEADS,
          category: SearchConstants.categories.mood
        },
        {
          heads: "Heroism",
          tails: "Loss",
          weight: BUBBLEWEIGHTS.heroism,
          face: HEADS,
          category: SearchConstants.categories.theme
        },
        {
          heads: "Uplift",
          tails: "Determination",
          weight: BUBBLEWEIGHTS.upLift,
          face: HEADS,
          category: SearchConstants.categories.theme
        },
        {
          heads: "Personal Bonds",
          tails: "Personal Challenge",
          weight: BUBBLEWEIGHTS.bonds,
          face: HEADS,
          category: SearchConstants.categories.scenario
        },
        {
          heads: "Life Story",
          tails: "Against The Odds",
          weight: BUBBLEWEIGHTS.life,
          face: HEADS,
          category: SearchConstants.categories.scenario
        }
      ]
    },

    dictionary: {
      getCarouselDefinitions: fingerprint => {
        let indices = persona_2[fingerprint];
        return indices.map(idx =>
          clusters.find(cluster => cluster.cluster_number === idx)
        );
      }
    }
  },
  profile_3: {
    name: { first: "Kim", last: "Lee", badgeLetter: "K", lang: "en" },
    flippers: {
      celebrities: [
        {
          heads: "Brad Pitt",
          tails: "Grant Gustin",
          weight: BUBBLEWEIGHTS.xsmall,
          face: HEADS,
          category: SearchConstants.categories.celebrity
        },
        {
          heads: "Kaley Cuoco",
          tails: "Lili Reinhart",
          weight: BUBBLEWEIGHTS.tiny,
          face: HEADS,
          category: SearchConstants.categories.celebrity
        }
      ],

      genres: [],

      keywords: [
        {
          heads: "Thrilling",
          tails: "Horrific",
          weight: BUBBLEWEIGHTS.xxxlarge,
          face: HEADS,
          category: SearchConstants.categories.mood
        },
        {
          heads: "Suspenseful",
          tails: "Mysterious",
          weight: BUBBLEWEIGHTS.xxlarge,
          face: HEADS,
          category: SearchConstants.categories.mood
        },
        {
          heads: "Injustice",
          tails: "Discovery",
          weight: BUBBLEWEIGHTS.xlarge,
          face: HEADS,
          category: SearchConstants.categories.theme
        },
        {
          heads: "Evil",
          tails: "Challenge",
          weight: BUBBLEWEIGHTS.large,
          face: HEADS,
          category: SearchConstants.categories.theme
        },
        {
          heads: "Chaos",
          tails: "Schemes",
          weight: BUBBLEWEIGHTS.medium,
          face: HEADS,
          category: SearchConstants.categories.scenario
        },
        {
          heads: "Escape",
          tails: "Duel",
          weight: BUBBLEWEIGHTS.small,
          face: HEADS,
          category: SearchConstants.categories.scenario
        }
      ]
    },

    dictionary: {
      getCarouselDefinitions: fingerprint => {
        let indices = persona_3[fingerprint];
        return indices.map(idx =>
          clusters.find(cluster => cluster.cluster_number === idx)
        );
      }
    }
  },
  profile_4: {
    name: { first: "Livia", last: "Nilsson", badgeLetter: "L", lang: "sv" },

    flippers: {
      celebrities: [
        {
          heads: "Johan Rheborg",
          tails: "Johan \u00d6stling",
          weight: BUBBLEWEIGHTS.johan,
          face: HEADS,
          category: SearchConstants.categories.celebrity
        },
        {
          heads: "Alexandra Rapaport",
          tails: "Josephine Bornebusch",
          weight: BUBBLEWEIGHTS.alexandra,
          face: HEADS,
          category: SearchConstants.categories.celebrity
        }
      ],

      genres: [],

      keywords: [
        {
          heads: "Comical",
          tails: "Charming",
          weight: BUBBLEWEIGHTS.reality,
          face: HEADS,
          category: SearchConstants.categories.mood
        },
        {
          heads: "Light",
          tails: "Satirical",
          weight: BUBBLEWEIGHTS.light,
          face: HEADS,
          category: SearchConstants.categories.mood
        },
        {
          heads: "Sin",
          tails: "Friendship",
          weight: BUBBLEWEIGHTS.sin,
          face: HEADS,
          category: SearchConstants.categories.theme
        },
        {
          heads: "Absurdity",
          tails: "Kindness",
          weight: BUBBLEWEIGHTS.absurd,
          face: HEADS,
          category: SearchConstants.categories.theme
        },
        {
          heads: "Schemes",
          tails: "Slice Of Life",
          weight: BUBBLEWEIGHTS.schemes,
          face: HEADS,
          category: SearchConstants.categories.scenario
        },
        {
          heads: "Misadventures",
          tails: "Teamwork",
          weight: BUBBLEWEIGHTS.misadventures,
          face: HEADS,
          category: SearchConstants.categories.scenario
        }
      ]
    },

    dictionary: {
      getCarouselDefinitions: fingerprint => {
        let indices = persona_4[fingerprint];
        return indices.map(idx =>
          swedish_clusters.find(
            cluster => cluster.cluster_number === idx
          )
        );
      }
    }
  },
  profile_5: {
    name: { first: "Avery", last: "Lyle", badgeLetter: "A", lang: "en" },
    quadrant: "Calm/Positive",

    moods: function () {
      return motivations_home;
    }
  }
};

Object.freeze(profile.profile_1.flippers);
Object.freeze(profile.profile_1.dictionary);

module.exports = {
  profile,
  KW,
  CE,
  HEADS,
  TAILS,
  FACE,
  FPSEP,
  BADGE_FLAG,
  BADGE_LETTER,
  POPULAR_EN
};
