import React from "react";
import store from "../store";
import { OPEN_MOOD_GRID_MODAL } from "../constants";

class MoodGridContainer extends React.Component {
    // eslint-disable-next-line
    constructor(props) {
        super(props);
    }

    handleClick = () => {
        store.dispatch({
            type: OPEN_MOOD_GRID_MODAL
        });
    };

    render() {
        return (
            <div className="mood-grid-container" onClick={this.handleClick}>
                <img alt="mood grid icon" src="./moodgrid_icon.png" />
            </div>
        );
    }
}

export default MoodGridContainer;
