import React from "react";
import Swiper from "react-id-swiper";
import styled from "styled-components";
import store from "./store";
import {
  OPEN_WOMEN,
  OPEN_MARVEL,
  OPEN_AGATHA,
  OPEN_POLITICS,
  OPEN_FACING,
  CLOSE_ALL_THEMATIC
} from "./constants";
import { PosterHover } from "./PosterHover";


const thematicArr = [
  {
    uri: "/thematic/celebrating-women.png",
    title: "CELEBRATING WOMEN",
    description: "A collection of media showcasing and honoring women"
  },
  {
    uri: "/thematic/marvel.png",
    title: "MARVEL",
    description:
      "A collection of works covering all Marvel Entertainment has to offer"
  },
  {
    uri: "/thematic/agatha-christie.png",
    title: "AGATHA CHRISTIE",
    description:
      "Solve the mystery with this collection of adaptations of works by Agatha Christie"
  },
  {
    uri: "/thematic/politics.png",
    title: "POLITICS",
    description:
      "See it from every angle with this collection dealing with the Politics that drive our world"
  },
  {
    uri: "/thematic/facing-reality.png",
    title: "FACING REALITY",
    description:
      "A collection of works for when you are in the mood to deal with the realities of the world"
  }
];

/*
 * Using background-image lets us set the mime-type image/svg+xml. Using base64 condenses the svg.
 * (The default mime type from copying an svg is image/svg, which doesn't render)
 * Manipulate CarouselArrow_next.svg (next) and CarouselArrow_Previous.svg (prev)
 * use uuencode -m [right|left]-chevron.svg /tmp/foo, and copy paste the result.
 */
// CarouselArrow_next.svg
const DivNext = styled.div`
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTA1cHgi
    IGhlaWdodD0iMjQ2cHgiIHZpZXdCb3g9IjAgMCAxMDUgMjQ2IiB2ZXJzaW9uPSIxLjEiIHhtbG5z
    PSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMu
    b3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA1MS4yICg1NzUxOSkg
    LSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+Q2Fy
    b3VzZWxBcnJvd19QcmV2aW91czwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNo
    LjwvZGVzYz4KICAgIDxkZWZzPgogICAgICAgIDxmaWx0ZXIgeD0iLTQwLjMlIiB5PSItMTAuMSUi
    IHdpZHRoPSIxODAuNiUiIGhlaWdodD0iMTI0LjglIiBmaWx0ZXJVbml0cz0ib2JqZWN0Qm91bmRp
    bmdCb3giIGlkPSJmaWx0ZXItMSI+CiAgICAgICAgICAgIDxmZU9mZnNldCBkeD0iMCIgZHk9IjUi
    IGluPSJTb3VyY2VBbHBoYSIgcmVzdWx0PSJzaGFkb3dPZmZzZXRPdXRlcjEiPjwvZmVPZmZzZXQ+
    CiAgICAgICAgICAgIDxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjQuNSIgaW49InNoYWRv
    d09mZnNldE91dGVyMSIgcmVzdWx0PSJzaGFkb3dCbHVyT3V0ZXIxIj48L2ZlR2F1c3NpYW5CbHVy
    PgogICAgICAgICAgICA8ZmVDb2xvck1hdHJpeCB2YWx1ZXM9IjAgMCAwIDAgMCAgIDAgMCAwIDAg
    MCAgIDAgMCAwIDAgMCAgMCAwIDAgMC41IDAiIHR5cGU9Im1hdHJpeCIgaW49InNoYWRvd0JsdXJP
    dXRlcjEiIHJlc3VsdD0ic2hhZG93TWF0cml4T3V0ZXIxIj48L2ZlQ29sb3JNYXRyaXg+CiAgICAg
    ICAgICAgIDxmZU1lcmdlPgogICAgICAgICAgICAgICAgPGZlTWVyZ2VOb2RlIGluPSJzaGFkb3dN
    YXRyaXhPdXRlcjEiPjwvZmVNZXJnZU5vZGU+CiAgICAgICAgICAgICAgICA8ZmVNZXJnZU5vZGUg
    aW49IlNvdXJjZUdyYXBoaWMiPjwvZmVNZXJnZU5vZGU+CiAgICAgICAgICAgIDwvZmVNZXJnZT4K
    ICAgICAgICA8L2ZpbHRlcj4KICAgIDwvZGVmcz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0i
    bm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIG9w
    YWNpdHk9IjAuNzQ3MzIxNDI5Ij4KICAgICAgICA8ZyBpZD0iQ2Fyb3VzZWxBcnJvd19QcmV2aW91
    cyIgZmlsdGVyPSJ1cmwoI2ZpbHRlci0xKSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNTAuNTAwMDAw
    LCAxMTguMDAwMDAwKSBzY2FsZSgtMSwgMSkgdHJhbnNsYXRlKC01MC41MDAwMDAsIC0xMTguMDAw
    MDAwKSB0cmFuc2xhdGUoMTcuMDAwMDAwLCA5LjAwMDAwMCkiIHN0cm9rZT0iI0ZGRkZGRiIgc3Ry
    b2tlLXdpZHRoPSIyMSI+CiAgICAgICAgICAgIDxwb2x5bGluZSBpZD0iUGF0aC0yLUNvcHktMiIg
    dHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzMuNTAwMDAwLCAxMDkuMDAwMDAwKSBzY2FsZSgtMSwgMSkg
    dHJhbnNsYXRlKC0zMy41MDAwMDAsIC0xMDkuMDAwMDAwKSAiIHBvaW50cz0iMCAwIDY3IDEwOSAw
    IDIxOCI+PC9wb2x5bGluZT4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==");
`;
// CarouselArrow_next.svg
const DivPrev = styled.div`
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTA1cHgi
    IGhlaWdodD0iMjQ2cHgiIHZpZXdCb3g9IjAgMCAxMDUgMjQ2IiB2ZXJzaW9uPSIxLjEiIHhtbG5z
    PSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMu
    b3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA1MS4yICg1NzUxOSkg
    LSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+Q2Fy
    b3VzZWxBcnJvd19QcmV2aW91cyBDb3B5IDM8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRo
    IFNrZXRjaC48L2Rlc2M+CiAgICA8ZGVmcz4KICAgICAgICA8ZmlsdGVyIHg9Ii00MC4zJSIgeT0i
    LTEwLjElIiB3aWR0aD0iMTgwLjYlIiBoZWlnaHQ9IjEyNC44JSIgZmlsdGVyVW5pdHM9Im9iamVj
    dEJvdW5kaW5nQm94IiBpZD0iZmlsdGVyLTEiPgogICAgICAgICAgICA8ZmVPZmZzZXQgZHg9IjAi
    IGR5PSI1IiBpbj0iU291cmNlQWxwaGEiIHJlc3VsdD0ic2hhZG93T2Zmc2V0T3V0ZXIxIj48L2Zl
    T2Zmc2V0PgogICAgICAgICAgICA8ZmVHYXVzc2lhbkJsdXIgc3RkRGV2aWF0aW9uPSI0LjUiIGlu
    PSJzaGFkb3dPZmZzZXRPdXRlcjEiIHJlc3VsdD0ic2hhZG93Qmx1ck91dGVyMSI+PC9mZUdhdXNz
    aWFuQmx1cj4KICAgICAgICAgICAgPGZlQ29sb3JNYXRyaXggdmFsdWVzPSIwIDAgMCAwIDAgICAw
    IDAgMCAwIDAgICAwIDAgMCAwIDAgIDAgMCAwIDAuNSAwIiB0eXBlPSJtYXRyaXgiIGluPSJzaGFk
    b3dCbHVyT3V0ZXIxIiByZXN1bHQ9InNoYWRvd01hdHJpeE91dGVyMSI+PC9mZUNvbG9yTWF0cml4
    PgogICAgICAgICAgICA8ZmVNZXJnZT4KICAgICAgICAgICAgICAgIDxmZU1lcmdlTm9kZSBpbj0i
    c2hhZG93TWF0cml4T3V0ZXIxIj48L2ZlTWVyZ2VOb2RlPgogICAgICAgICAgICAgICAgPGZlTWVy
    Z2VOb2RlIGluPSJTb3VyY2VHcmFwaGljIj48L2ZlTWVyZ2VOb2RlPgogICAgICAgICAgICA8L2Zl
    TWVyZ2U+CiAgICAgICAgPC9maWx0ZXI+CiAgICA8L2RlZnM+CiAgICA8ZyBpZD0iUGFnZS0xIiBz
    dHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVu
    b2RkIiBvcGFjaXR5PSIwLjc0NzMyMTQyOSI+CiAgICAgICAgPGcgaWQ9IkNhcm91c2VsQXJyb3df
    UHJldmlvdXMtQ29weS0zIiBmaWx0ZXI9InVybCgjZmlsdGVyLTEpIiB0cmFuc2Zvcm09InRyYW5z
    bGF0ZSgyMS4wMDAwMDAsIDkuMDAwMDAwKSIgc3Ryb2tlPSIjRkZGRkZGIiBzdHJva2Utd2lkdGg9
    IjIxIj4KICAgICAgICAgICAgPHBvbHlsaW5lIGlkPSJQYXRoLTItQ29weS0yIiB0cmFuc2Zvcm09
    InRyYW5zbGF0ZSgzMy41MDAwMDAsIDEwOS4wMDAwMDApIHNjYWxlKC0xLCAxKSB0cmFuc2xhdGUo
    LTMzLjUwMDAwMCwgLTEwOS4wMDAwMDApICIgcG9pbnRzPSIwIDAgNjcgMTA5IDAgMjE4Ij48L3Bv
    bHlsaW5lPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+");
`;

const CarouselPosterDiv = styled.div`
  cursor: pointer;
  filter: drop-shadow(0 0.2rem 0.2rem rgba(0, 0, 0, 0.5));
  background: url(${props => props.img}) no-repeat;
  background-size: cover;
  height: 13.5vw !important;
  width: 58.5vw !important;
`;

const CarouselTitle = styled.div`
  font-size: 1.75rem;
  padding: 0;
  color: white;
  font-weight: bold;
`;
const CarouselDescription = styled.div`
  font-size: 1.5rem;
  padding: 1rem 0;
  color: white;
  max-width: 30rem;
`;

class Carousel extends React.Component {
  openWomenPage(index) {
    store.dispatch({ type: CLOSE_ALL_THEMATIC });
    switch (index) {
      case 0:
        store.dispatch({ type: OPEN_WOMEN });
        break;
      case 1:
        store.dispatch({ type: OPEN_MARVEL });
        break;
      case 2:
        store.dispatch({ type: OPEN_AGATHA });
        break;
      case 3:
        store.dispatch({ type: OPEN_POLITICS });
        break;
      case 4:
        store.dispatch({ type: OPEN_FACING });
        break;
      default:
    }
  }

  render() {

    const { items, slideComponent, inParams, carouselType } = this.props;
    const overrideParams = inParams || {};
    const nav = {
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      renderPrevButton: () => <DivPrev className="swiper-button-prev" />,
      renderNextButton: () => <DivNext className="swiper-button-next" />
    }
    const _params = {
      mousewheel: {
        releaseOnEdges: true,
        forceToAxis: true,
        invert: true
      },
      preloadImages: false,
      lazy: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      slidesPerView: 5.5,
      slidesPerGroup: 5,
      roundLengths: true,
      keyboard: true,
      centerInsufficientSlides: true,
      spaceBetween: 10, // Big screens, e.g., 4k
      breakpoints: {
        // when window width is <= 480px
        480: {
          slidesPerView: 3,
          spaceBetween: 20
        },
        // when window width is <= 640px
        2000: {
          spaceBetween: 25
        }
      }
    };
    const themeMaticParams = {
      ...nav,
      slidesPerView: 1.55,
      slidesPerGroup: 1,
      spaceBetween: 5,
    };

    const themeMaticInteriorParamsLandscape = {
      ...nav,
      slidesPerView: 5.55,
      slidesPerGroup: 5,
      spaceBetween: 10
    };
    const themeMaticInteriorParamsSquare = {
      ...nav,
      slidesPerView: 6.55,
      slidesPerGroup: 6,
      spaceBetween: 10
    };
    const themeMaticInteriorParamsPoster = {
      ...nav,
      slidesPerView: 7,
      slidesPerGroup: 7,
      spaceBetween: 5
    };
    const themeMaticInteriorParamsCircle = {
      ...nav,
      slidesPerView: 5.55,
      slidesPerGroup: 5,
      spaceBetween: 30
    };
    const params = { ..._params, ...nav, ...overrideParams };

    /**
     * Map items into a FunctionalComponent SlideComponent
     * @param {[]} items 
     * @param {FC} SlideComponent 
     * @returns Array of SlideComponents
     */
    const slideMapperComponent = (items, SlideComponent) => {
      return items.map((item, index) => <SlideComponent item={item} key={index} />);
    };

    /**
     * Map items using a function call to slideComponent
     * @param {[]} items 
     * @returns Array of 'returns()' from slideComponent().
     */
    const slideMapperFunction = items => {
      return items.map((item, index) => slideComponent(item, index));
    };

    return (
      <div>
        {(() => {
          if (carouselType === "landscape") {
            return (
              <Swiper {...themeMaticInteriorParamsLandscape}>
                {slideMapperFunction(items)}
              </Swiper>
            );
          }
          if (carouselType === "square") {
            return (
              <Swiper {...themeMaticInteriorParamsSquare}>
                {slideMapperFunction(items)}
              </Swiper>
            );
          }
          if (carouselType === "poster") {
            return (
              <Swiper {...themeMaticInteriorParamsPoster}>
                {slideMapperFunction(items)}
              </Swiper>
            );
          }
          if (carouselType === "circle") {
            return (
              <Swiper {...themeMaticInteriorParamsCircle}>
                {slideMapperFunction(items)}
              </Swiper>
            );
          }

          if (slideComponent === "Thematic") {
            return (
              <Swiper {...themeMaticParams}>
                {thematicArr.map((item, index) => {
                  return (
                    <div
                      style={{ display: "flex" }}
                      key={index}
                      onClick={() => this.openWomenPage(index)}
                    >
                      <CarouselPosterDiv img={item.uri} />
                      <div
                        style={{
                          position: "absolute",
                          left: "2rem",
                          top: "2rem"
                        }}
                      >
                        <CarouselTitle>{item.title}</CarouselTitle>
                        <CarouselDescription>
                          {item.description}
                        </CarouselDescription>
                      </div>
                    </div>
                  );
                })}
              </Swiper>
            );
          }
          else {
            const ph = slideComponent === PosterHover;
            return <Swiper {...params}>{ph ? slideMapperComponent(items, slideComponent) : slideMapperFunction(items)}</Swiper>;
          }
        })()}
      </div>
    );
  }
}

export default Carousel;
