/**
 * The main app file. 2019-05-17
 */
import React, { Component } from "react";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import { injectGlobal } from "styled-components";
import styledNormalize from "styled-normalize";
import store from "../store";
import {
  UI,
  HOME,
  CLOSE_MODAL,
  LOGOUT,
  LOGIN,
  CLOSE_THEMATIC_LANDING,
  CLOSE_ALL_THEMATIC
} from "../constants";
import Navigation from "./Navigation";
import ModalContainer from "./ModalContainer";
import ProgramsCarouselContainer from "../ProgramsCarousel/ProgramsCarouselContainer";
import MoodsCarouselContainer from "../Mood/MoodsCarouselContainer";
import ContentItemDetailsContainer from "../ContentItemDetails/ContentItemDetailsContainer";
import KeywordGraphContainer from "../ContentKeywords/KeywordGraphContainer.js";
import UserFormContainer from "./UserFormContainer";
import Message from "./message";
import { Hub } from "aws-amplify";
import { config } from "../config";
import { authRedirect, authMessage, MESSAGE_BYE } from "../actions";
import SearchResults from "./SearchResults";
// import CurateContainer from "../Curate/CurateContainer";
import MoodContainer from "../Mood/MoodContainer";
import MoodGridModalContainer from "../Mood/MoodGridModalContainer";
import ThematicCarouselContainer from "../ThematicCarousel/ThematicCarouselContainer";
import ThematicLandingContainer from "../ThematicLanding/ThematicLandingContainer";
import styled from "styled-components";

/*
    Set globally-relevant styles here.
    Such styles tend to be rare, so this
    section should only rarely be modified.
    Font Size: https://www.sitepoint.com/community/t/html-css-font-size-best-practice/378153/13
*/
injectGlobal`
@import url('https://fonts.googleapis.com/css?family=${UI.font.defaultFamily
  }:100,300,400,500,700,900');
${styledNormalize}
    html {
        
        background-attachment: fixed;
        font-family: ${UI.font.defaultFamily}, Helvetica Neue, sans-serif;
        font-size: 100%;
        font-weight: 300;
        color: ${UI.color.clashing}; // Promotes explicit styling for all text!
        background-color: #000;

    }
`;

const CarouselFooter = styled.div`
  background: #3c4752;
  color: #d8d8d8;
  padding: 0.5rem 3.5rem;
  margin-top: 3rem;
`;

function mapStateToProps(state) {
  return {
    showingCarousels: "popularItemsData" in state.fullscreen,
    showingMoodCarousels: "moodItemsData" in state.fullscreen,
    showingTasteProfile: state.showingTasteProfile,
    profile: state.profile,
    isGoogle: state.isGoogle,
    profileNames: state.profileNames,
    session: state.session,
    authPayload: state.authPayload,
    keywords: state.fullscreen.keywordGraphData,
    showingSearchResults: state.showingSearchResults,
    showingCuration: state.showingCuration,
    showingMoodJourney: state.showingMoodJourney,
    term: state.term,
    womenModal: state.womenModal,
    marvelModal: state.marvelModal,
    agathaModal: state.agathaModal,
    politicsModal: state.politicsModal,
    facingModal: state.facingModal,
    thematicModal: state.thematicModal,
    thematicContent: state.thematicContent,
    thematicSubModal: state.thematicSubModal,
    showingAdvancedCarousels: "advancedCategoriesData" in state.fullscreen,
    showingHeroCarousel: "heroItemsData" in state.fullscreen,
    showingBasedOnCarousel: "basedOnItemsData" in state.fullscreen,
    showingClipsCarousel: "clipsItemsData" in state.fullscreen
  };
}

/**
 * We are using Context for the search box in Nav,
 * because it allows us to fit the Search feature seamlessly
 * into the existing component hierarchy with little need to
 * refactor.
 */
export const SearchTermContext = React.createContext("");

const womenHeader = "/thematic/hidden-figures-women-bg.png";
const marvelHeader = "/thematic/hidden-figures-marvel-bg.png";
const agathaHeader = "/thematic/agatha-christie-bg.png";
const politicsHeader = "/thematic/politics-bg.png";
const facingHeader = "/thematic/facing-reality-bg.png";

const filtData = [
  {
    women: [
      {
        carouselTitle: "WOMEN IN ACTION",
        carouselDesc: "Films and TV programs with tough women in action",
        carouselType: "landscape",
        carouselItems: [
          {
            imageUri: "/thematic/celebrating-women/alias-16x9.jpg"
          },
          {
            imageUri: "/thematic/celebrating-women/atomic-blonde-16x9.jpg"
          },
          {
            imageUri: "/thematic/celebrating-women/mad-max-16x9.jpg"
          },
          {
            imageUri: "/thematic/celebrating-women/miss-bala-16x9.jpg"
          },
          {
            imageUri: "/thematic/celebrating-women/mononoke-16x9.jpg"
          },
          {
            imageUri: "/thematic/celebrating-women/wonder-woman-16x9.jpg"
          }
        ]
      },
      {
        carouselTitle: "EMPOWERING WOMEN",
        carouselDesc: "Works showing women finding their voice",
        carouselType: "poster",
        carouselItems: [
          {
            imageUri: "/thematic/empowering-women/bend-it.jpg"
          },
          {
            imageUri: "/thematic/empowering-women/erin-brockavich.jpg"
          },
          {
            imageUri: "/thematic/empowering-women/hidden-figures.jpg"
          },
          {
            imageUri: "/thematic/empowering-women/moana.jpg"
          },
          {
            imageUri: "/thematic/empowering-women/promising-young-woman.jpg"
          },
          {
            imageUri: "/thematic/empowering-women/queen-of-katwe.jpg"
          },
          {
            imageUri: "/thematic/empowering-women/the-crown.jpg"
          }
        ]
      },
      {
        carouselTitle: "SHE WILL SURVIVE",
        carouselDesc:
          "Films featuring women fighting for survival against all odds",
        carouselType: "landscape",
        carouselItems: [
          {
            imageUri: "/thematic/she-will-survive/adrift-16x9.jpg"
          },
          {
            imageUri: "/thematic/she-will-survive/alien-16x9.jpg"
          },
          {
            imageUri: "/thematic/she-will-survive/bird-box-16x9.jpg"
          },
          {
            imageUri: "/thematic/she-will-survive/gravity-16x9.jpg"
          },
          {
            imageUri: "/thematic/she-will-survive/impossible-16x9.jpg"
          },
          {
            imageUri: "/thematic/she-will-survive/shallows-16x9.jpg"
          }
        ]
      },
      {
        carouselTitle: "WOMEN IN CHARGE",
        carouselDesc: "A collection of works featuring female directors",
        carouselType: "poster",
        carouselItems: [
          {
            imageUri: "/thematic/women-in-charge/booksmart.jpg"
          },
          {
            imageUri: "/thematic/women-in-charge/hurt-locker.jpg"
          },
          {
            imageUri: "/thematic/women-in-charge/killing-eve.jpg"
          },
          {
            imageUri: "/thematic/women-in-charge/lost-in-translation.jpg"
          },
          {
            imageUri: "/thematic/women-in-charge/lovecraft-country.jpg"
          },
          {
            imageUri: "/thematic/women-in-charge/nomadland.jpg"
          },
          {
            imageUri: "/thematic/women-in-charge/selma.jpg"
          }
        ]
      },
      {
        carouselTitle: "PODCASTS",
        carouselDesc: "Add to playlist",
        carouselType: "square",
        carouselItems: [
          {
            imageUri: "/thematic/podcasts/girlboss-radio.jpeg"
          },
          {
            imageUri: "/thematic/podcasts/manifestation-babe.jpeg"
          },
          {
            imageUri: "/thematic/podcasts/miraculous-mamas.jpeg"
          },
          {
            imageUri: "/thematic/podcasts/mom-secrets.jpg"
          },
          {
            imageUri: "/thematic/podcasts/rebel-girls.jpg"
          },
          {
            imageUri: "/thematic/podcasts/she.jpeg"
          },
          {
            imageUri: "/thematic/podcasts/the-guilty-feminist.jpeg"
          }
        ]
      },
      {
        carouselTitle: "OSCAR WINNING WOMEN OF COLOR",
        carouselDesc: "",
        carouselType: "circle",
        carouselItems: [
          {
            imageUri: "/thematic/oscars/chloe-zhao.jpg"
          },
          {
            imageUri: "/thematic/oscars/halle-berry.jpg"
          },
          {
            imageUri: "/thematic/oscars/jennifer-hudson.jpg"
          },
          {
            imageUri: "/thematic/oscars/lupita-nyongo.jpg"
          },
          {
            imageUri: "/thematic/oscars/penelope-cruz.jpg"
          },
          {
            imageUri: "/thematic/oscars/youn-yuh-jung.jpg"
          }
        ]
      },
      {
        carouselTitle: "MUSIC",
        carouselDesc: "Add to playlist",
        carouselType: "square",
        carouselItems: [
          {
            imageUri: "/thematic/music/beyonce.jpg"
          },
          {
            imageUri: "/thematic/music/big-yellow-taxi.jpg"
          },
          {
            imageUri: "/thematic/music/billie-eilish.jpg"
          },
          {
            imageUri: "/thematic/music/megan-thee.jpg"
          },
          {
            imageUri: "/thematic/music/senorita.jpg"
          },
          {
            imageUri: "/thematic/music/tina-turner.jpg"
          },
          {
            imageUri: "/thematic/music/telepatia.jpg"
          }
        ]
      }
    ]
  },
  {
    marvel: [
      {
        carouselTitle: "MARVEL CINEMATIC UNIVERSE",
        carouselDesc: "Movies and TV shows from the Marvel Cinematic Universe",
        carouselType: "landscape",
        carouselItems: [
          {
            imageUri: "/thematic/mcu/iron-man.jpg"
          },
          {
            imageUri: "/thematic/mcu/loki.jpg"
          },
          {
            imageUri: "/thematic/mcu/black-widow.jpg"
          },
          {
            imageUri: "/thematic/mcu/moon-knight.jpg"
          },
          {
            imageUri: "/thematic/mcu/eternals.jpg"
          },
          {
            imageUri: "/thematic/mcu/shang-chi.jpg"
          }
        ]
      },
      {
        carouselTitle: "MARVEL IN ANIMATION",
        carouselDesc: "Works showcasing Marvel's animated side",
        carouselType: "poster",
        carouselItems: [
          {
            imageUri: "/thematic/marvel/into-the-spiderverse.jpg"
          },
          {
            imageUri: "/thematic/marvel/marvel-rising.jpg"
          },
          {
            imageUri: "/thematic/marvel/modok.jpg"
          },
          {
            imageUri: "/thematic/marvel/ironman-armored.jpg"
          },
          {
            imageUri: "/thematic/marvel/spider-man.jpg"
          },
          {
            imageUri: "/thematic/marvel/guardians-animated.jpg"
          },
          {
            imageUri: "/thematic/marvel/big-hero-6.jpg"
          }
        ]
      },
      {
        carouselTitle: "MARVEL IN TELEVISION",
        carouselDesc:
          "TV shows dealing with different parts of the Marvel Universe",
        carouselType: "landscape",
        carouselItems: [
          {
            imageUri: "/thematic/marvel-television/agents-shield-16x9.jpg"
          },
          {
            imageUri: "/thematic/marvel-television/cloak-dagger-16x9.jpg"
          },
          {
            imageUri: "/thematic/marvel-television/daredevil-16x9.jpg"
          },
          {
            imageUri: "/thematic/marvel-television/legion-16x9.jpg"
          },
          {
            imageUri: "/thematic/marvel-television/runaways-16x9.jpg"
          },
          {
            imageUri: "/thematic/marvel-television/the-gifted-16x9.jpg"
          }
        ]
      },
      {
        carouselTitle: "BEYOND THE MCU",
        carouselDesc: "Marvel works outside of the MCU",
        carouselType: "poster",
        carouselItems: [
          {
            imageUri: "/thematic/beyond-mcu/blade.jpg"
          },
          {
            imageUri: "/thematic/beyond-mcu/daredevil.jpg"
          },
          {
            imageUri: "/thematic/beyond-mcu/deadpool.jpg"
          },
          {
            imageUri: "/thematic/beyond-mcu/fantastic-four.jpg"
          },
          {
            imageUri: "/thematic/beyond-mcu/ghostrider.jpg"
          },
          {
            imageUri: "/thematic/beyond-mcu/morbius.jpg"
          },
          {
            imageUri: "/thematic/beyond-mcu/punisher.jpg"
          }
        ]
      },
      {
        carouselTitle: "X-MEN IN FOCUS",
        carouselDesc: "Spotlighting the works of Marvel dealing with the X-MEN",
        carouselType: "landscape",
        carouselItems: [
          {
            imageUri: "/thematic/x-men/wolverine-x-men.jpg"
          },
          {
            imageUri: "/thematic/x-men/x-men-1992.jpg"
          },
          {
            imageUri: "/thematic/x-men/x-men-evolution.jpg"
          },
          {
            imageUri: "/thematic/x-men/x-men-first-class.jpg"
          },
          {
            imageUri: "/thematic/x-men/x-men-future-past.jpg"
          },
          {
            imageUri: "/thematic/x-men/x-men.jpg"
          }
        ]
      },
      {
        carouselTitle: "ALL ABOUT MARVEL",
        carouselDesc: "Podcasts discussing Marvel from every angle",
        carouselType: "square",
        carouselItems: [
          {
            imageUri: "/thematic/marvel-pods/beauty-da-beast.jpg"
          },
          {
            imageUri: "/thematic/marvel-pods/binge-mode.jpg"
          },
          {
            imageUri: "/thematic/marvel-pods/marvel-wastelanders.png"
          },
          {
            imageUri: "/thematic/marvel-pods/marvel-cinematic-universe.jpg"
          },
          {
            imageUri: "/thematic/marvel-pods/marvel-wastelanders.png"
          },
          {
            imageUri: "/thematic/marvel-pods/this-week-in-marvel.jpg"
          },
          {
            imageUri: "/thematic/marvel-pods/wolverine_the_lost_trail.jpg"
          }
        ]
      }
    ]
  },
  {
    agatha: [
      {
        carouselTitle: "AND THEN THERE WERE NONE",
        carouselDesc: "AKA Ten Little Indians",
        carouselType: "landscape",
        carouselItems: [
          {
            imageUri:
              "/thematic/and-then-there-were-none/10-little-16x9-green.jpeg"
          },
          {
            imageUri:
              "/thematic/and-then-there-were-none/10-little-16x9-red.jpeg"
          },
          {
            imageUri:
              "/thematic/and-then-there-were-none/10-little-girl-16x9.jpg"
          },
          {
            imageUri:
              "/thematic/and-then-there-were-none/10-little-map-16x9.jpg"
          },
          {
            imageUri: "/thematic/and-then-there-were-none/and-then-16x9.jpg"
          },
          {
            imageUri:
              "/thematic/and-then-there-were-none/and-then-modern-16x9.jpg"
          }
        ]
      },
      {
        carouselTitle: "HERCULE POIROT",
        carouselDesc: "",
        carouselType: "poster",
        carouselItems: [
          {
            imageUri: "/thematic/hercule-poirot/abc-murders.jpg"
          },
          {
            imageUri: "/thematic/hercule-poirot/acs-poirot.jpg"
          },
          {
            imageUri: "/thematic/hercule-poirot/death-on-nile-2022.jpg"
          },
          {
            imageUri: "/thematic/hercule-poirot/death-on-nile.jpg"
          },
          {
            imageUri: "/thematic/hercule-poirot/evil-under-sun.jpg"
          },
          {
            imageUri: "/thematic/hercule-poirot/orient-express-1974.jpg"
          },
          {
            imageUri: "/thematic/hercule-poirot/orient-express-2017.jpg"
          }
        ]
      },
      {
        carouselTitle: "MISS MARPLE",
        carouselDesc: "",
        carouselType: "poster",
        carouselItems: [
          {
            imageUri: "/thematic/miss-marple/acs-marple.jpg"
          },
          {
            imageUri: "/thematic/miss-marple/crackd.jpg"
          },
          {
            imageUri: "/thematic/miss-marple/miss-marple.jpg"
          },
          {
            imageUri: "/thematic/miss-marple/murder-foul.jpg"
          },
          {
            imageUri: "/thematic/miss-marple/murder-gallop.jpg"
          },
          {
            imageUri: "/thematic/miss-marple/murder-she-wrote.jpg"
          },
          {
            imageUri: "/thematic/miss-marple/nemesis-2007.jpg"
          }
        ]
      },
      {
        carouselTitle: "MISS MARPLE THROUGH THE YEARS",
        carouselDesc: "",
        carouselType: "circle",
        carouselItems: [
          {
            imageUri: "/thematic/through-the-years/angela-lansbury.jpg"
          },
          {
            imageUri: "/thematic/through-the-years/geraldine-mcewan.jpg"
          },
          {
            imageUri: "/thematic/through-the-years/helen-hayes.jpg"
          },
          {
            imageUri: "/thematic/through-the-years/joan-hickson.jpg"
          },
          {
            imageUri: "/thematic/through-the-years/julia-mckenzie.jpg"
          },
          {
            imageUri: "/thematic/through-the-years/margaret-rutherford.jpg"
          }
        ]
      },
      {
        carouselTitle: "OTHER FAMOUS AGATHA CHRISTIE WORKS",
        carouselDesc: "",
        carouselType: "landscape",
        carouselItems: [
          {
            imageUri: "/thematic/other-famous-works/crooked-house-2008-16x9.jpg"
          },
          {
            imageUri: "/thematic/other-famous-works/crooked-house-2017-16x9.jpg"
          },
          {
            imageUri: "/thematic/other-famous-works/endless-night-16x9.jpeg"
          },
          {
            imageUri: "/thematic/other-famous-works/le-crime-16x9.jpg"
          },
          {
            imageUri: "/thematic/other-famous-works/ordeal-innocence.jpeg"
          },
          {
            imageUri: "/thematic/other-famous-works/pale-horse-16x9.jpeg"
          }
        ]
      }
    ]
  },
  {
    politics: [
      {
        carouselTitle: "POLITICAL AMERICA",
        carouselDesc: "Works showing off the politics of America",
        carouselType: "landscape",
        carouselItems: [
          {
            imageUri: "/thematic/political-america/ides-march-16x9.jpg"
          },
          {
            imageUri: "/thematic/political-america/lincoln-16x9.jpg"
          },
          {
            imageUri: "/thematic/political-america/mr-smith-16x9.jpg"
          },
          {
            imageUri: "/thematic/political-america/mrs-america-16x9.jpg"
          },
          {
            imageUri: "/thematic/political-america/vice-16x9.jpg"
          },
          {
            imageUri: "/thematic/political-america/west-wing-16x9.jpg"
          }
        ]
      },
      {
        carouselTitle: "POLITICAL DOCUMENTARIES",
        carouselDesc: "This is Real Politics",
        carouselType: "poster",
        carouselItems: [
          {
            imageUri: "/thematic/political-documentaries/13th.jpg"
          },
          {
            imageUri: "/thematic/political-documentaries/boys-state.jpg"
          },
          {
            imageUri: "/thematic/political-documentaries/immigration-nation.jpg"
          },
          {
            imageUri: "/thematic/political-documentaries/journeys-george.jpg"
          },
          {
            imageUri: "/thematic/political-documentaries/last-men-aleppo.jpg"
          },
          {
            imageUri: "/thematic/political-documentaries/primary.jpg"
          },
          {
            imageUri: "/thematic/political-documentaries/the-fight.jpg"
          }
        ]
      },
      {
        carouselTitle: "POLITICS AND THE PRESS",
        carouselDesc:
          "Film and television programs showing the relationship between the press and politics",
        carouselType: "landscape",
        carouselItems: [
          {
            imageUri: "/thematic/politics-press/citizen-kane-16x9.jpg"
          },
          {
            imageUri: "/thematic/politics-press/divide-conquer-16x9.jpg"
          },
          {
            imageUri: "/thematic/politics-press/frost-nixon-16x9.jpg"
          },
          {
            imageUri: "/thematic/politics-press/nobody-speak-16x9.jpg"
          },
          {
            imageUri: "/thematic/politics-press/presidents-men-16x9.jpg"
          },
          {
            imageUri: "/thematic/politics-press/the-post-16x9.jpg"
          }
        ]
      },
      {
        carouselTitle: "POLITICAL COMEDIES",
        carouselDesc: "Politics are a joke",
        carouselType: "poster",
        carouselItems: [
          {
            imageUri: "/thematic/political-comedies/charlie-wilson.jpg"
          },
          {
            imageUri: "/thematic/political-comedies/death-of-stalin.jpg"
          },
          {
            imageUri: "/thematic/political-comedies/dr-strangelove.jpg"
          },
          {
            imageUri: "/thematic/political-comedies/election.jpg"
          },
          {
            imageUri: "/thematic/political-comedies/in-the-loop.jpg"
          },
          {
            imageUri: "/thematic/political-comedies/spin-city.jpg"
          },
          {
            imageUri: "/thematic/political-comedies/veep.jpg"
          }
        ]
      }
    ]
  },
  {
    facing: [
      {
        carouselTitle: "DIVE INTO A PERSONAL CRISIS",
        carouselDesc:
          "Works for when you are in the mood to be dropped into the personal crises of people",
        carouselType: "landscape",
        carouselItems: [
          {
            imageUri: "/thematic/personal-crisis/bojack-16x9.jpg"
          },
          {
            imageUri: "/thematic/personal-crisis/moonlight-16x9.jpg"
          },
          {
            imageUri: "/thematic/personal-crisis/shameless-16x9.jpg"
          },
          {
            imageUri: "/thematic/personal-crisis/the-worst-16x9.jpg"
          },
          {
            imageUri: "/thematic/personal-crisis/this-is-us-16x9.jpg"
          },
          {
            imageUri: "/thematic/personal-crisis/whiplash-16x9.jpg"
          }
        ]
      },
      {
        carouselTitle: "EXPLORE CORRUPTION",
        carouselDesc:
          "Films and TV shows for when you are in the mood to delve into the corruption of the world",
        carouselType: "poster",
        carouselItems: [
          {
            imageUri: "/thematic/explore-corruption/billions.jpg"
          },
          {
            imageUri: "/thematic/explore-corruption/briarpatch.jpg"
          },
          {
            imageUri: "/thematic/explore-corruption/narcos.jpg"
          },
          {
            imageUri: "/thematic/explore-corruption/ozark.jpg"
          },
          {
            imageUri: "/thematic/explore-corruption/pearson.jpg"
          },
          {
            imageUri: "/thematic/explore-corruption/sicario.jpg"
          },
          {
            imageUri: "/thematic/explore-corruption/wolf-wall-street.jpg"
          }
        ]
      },
      {
        carouselTitle: "RECONCILE WITH THE PAST",
        carouselDesc:
          "Works for when you are in the mood to watch people come to terms with their past",
        carouselType: "landscape",
        carouselItems: [
          {
            imageUri: "/thematic/reconcile/about-time-16x9.jpg"
          },
          {
            imageUri: "/thematic/reconcile/feel-good-16x9.jpg"
          },
          {
            imageUri: "/thematic/reconcile/queens-gambit-16x9.jpg"
          },
          {
            imageUri: "/thematic/reconcile/revenge-16x9.jpg"
          },
          {
            imageUri: "/thematic/reconcile/silver-linings-16x9.jpg"
          },
          {
            imageUri: "/thematic/reconcile/up-16x9.jpg"
          }
        ]
      },
      {
        carouselTitle: "BE SERIOUS",
        carouselDesc:
          "Works for when you are in the mood for something serious",
        carouselType: "poster",
        carouselItems: [
          {
            imageUri: "/thematic/be-serious/12-years.jpg"
          },
          {
            imageUri: "/thematic/be-serious/13th.jpg"
          },
          {
            imageUri: "/thematic/be-serious/apollo-11.jpg"
          },
          {
            imageUri: "/thematic/be-serious/call-me.jpg"
          },
          {
            imageUri: "/thematic/be-serious/danish-girl.jpg"
          },
          {
            imageUri: "/thematic/be-serious/darkest-hour.jpg"
          },
          {
            imageUri: "/thematic/be-serious/westworld.jpg"
          }
        ]
      }
    ]
  }
];

class App extends Component {
  constructor(props) {
    super(props);
    this.onTitleClick = this.onTitleClick.bind(this);
    if (process.env.REACT_APP_GTAG) {
      ReactGA.initialize(`${process.env.REACT_APP_GTAG}`);
      ReactGA.pageview("/home");
    }
    this.state = {
      setWomen: this.props.womenModal
    };
  }

  onCloseModal() {
    if (process.env.REACT_APP_GTAG) {
      ReactGA.event({
        category: "Content",
        action: "Close Modal"
      });
    }
    store.dispatch({
      type: CLOSE_MODAL
    });
  }
  onTitleClick() {
    if (process.env.REACT_APP_GTAG) {
      ReactGA.event({
        category: "User",
        action: "Navigation to Home"
      });
    }

    if (this.props.thematicContent) {
      store.dispatch({ type: CLOSE_ALL_THEMATIC });
      store.dispatch({ type: CLOSE_THEMATIC_LANDING });
    } else {
      store.dispatch({
        type: HOME
      });
    }
  }

  onNavClick(e) {
    // disable logout in production mode
    if (process.env.REACT_APP_INDEX_ENV === "prod") {
    } else {
      if (process.env.REACT_APP_GTAG) {
        ReactGA.event({
          category: "User",
          action: "Log Out"
        });
      }

      store.dispatch({ type: LOGOUT });
    }
  }

  componentDidMount() {
    Hub.listen("auth", data => {
      console.log("A new auth event has happened.");
      this.onAuthEvent(data);
    });
  }

  onAuthEvent(authPayload) {
    const { channel, payload } = authPayload;
    if (channel === "auth") {
      switch (payload.event) {
        case "signIn":
          if (process.env.REACT_APP_GTAG) {
            ReactGA.event({
              category: "User",
              action: "Log In"
            });
          }
          store.dispatch({ type: LOGIN });
          break;
        case "signOut":
        case "signIn_failure":
          if (process.env.REACT_APP_GTAG) {
            ReactGA.event({
              category: "User",
              action: "Log Out"
            });
          }
          store.dispatch(authMessage(MESSAGE_BYE));
          store.dispatch(
            authRedirect(config.authLoginUrl, {
              expires: 0,
              authenticated: false
            })
          );
          break;
        default:
          break;
      }
    }
  }

  render() {
    /*
     * Don't flash any content if the session is expired.
     */
    try {
      if (
        this.props.session.expires === 0 ||
        !this.props.session.authenticated
      ) {
        return <Message />;
      } else {
        console.log(
          `${process.env.REACT_APP_INDEX_ENV}@${process.env.REACT_APP_COMMIT}`
        );
      }
    } catch (e) {
      console.log(e);
      return null;
    }

    const MainContent = () => {
      document.getElementsByTagName("html")[0].style.backgroundImage =
        "#000";


      if (this.props.showingSearchResults) {
        return <SearchResults term={this.props.term} />;
      } else if (this.props.thematicModal) {
        return <ThematicLandingContainer />;
      }
      else if (this.props.womenModal) {
        document.getElementsByTagName("html")[0].style.backgroundImage =
          "linear-gradient(0deg, rgb(123 6 163) 0%, rgb(123, 6, 163) 100%)";
        return (
          <ThematicCarouselContainer
            data={filtData[0].women}
            title="Celebrating Women"
            titleDescription="A collection of media showcasing and honoring women"
          />
        );
      } else if (this.props.marvelModal) {
        document.getElementsByTagName("html")[0].style.backgroundImage =
          "linear-gradient(0deg, rgb(154, 6, 7) 0%, rgb(157 7 8) 100%)";
        return (
          <ThematicCarouselContainer
            data={filtData[1].marvel}
            title="Marvel"
            titleDescription="A collection of works covering all Marvel Entertainment has to offer"
          />
        );
      } else if (this.props.agathaModal) {
        document.getElementsByTagName("html")[0].style.backgroundImage =
          "linear-gradient(0deg, rgb(30 139 114) 0%, rgb(30 139 114) 100%)";
        return (
          <ThematicCarouselContainer
            data={filtData[2].agatha}
            title="Agatha Christie"
            titleDescription="Solve the mystery with this collection of adaptations of works by Agatha Christie"
          />
        );
      } else if (this.props.politicsModal) {
        document.getElementsByTagName("html")[0].style.backgroundImage =
          "linear-gradient(0deg, rgb(15 84 150) 0%, rgb(5 85 157) 100%)";
        return (
          <ThematicCarouselContainer
            data={filtData[3].politics}
            title="Politics"
            titleDescription="See it from every angle with this collection dealing with the politics that drives our world"
          />
        );
      } else if (this.props.facingModal) {
        document.getElementsByTagName("html")[0].style.backgroundImage =
          "linear-gradient(0deg, rgb(137 15 81) 0%, rgb(137 15 81) 100%)";
        return (
          <ThematicCarouselContainer
            data={filtData[4].facing}
            title="Facing Reality"
            titleDescription="A collection of works for when you are in the mood to deal with the realities of the world"
          />
        );
      } else if (this.props.showingMoodJourney) {
        return <MoodContainer />;
      } else if (
        this.props.showingCarousels &&
        (!this.props.showingCuration || !this.props.showingMoodJourney)
      ) {
        return <ProgramsCarouselContainer />;
      } else if (this.props.showingMoodCarousels) {
        return <MoodsCarouselContainer />;
        // return <MoodTiles />;
      } else {
        return <KeywordGraphContainer />;
      }
    };

    // account for internal vs external users in order to display/not the Mood Persona
    let profileNames = this.props.profileNames;

    const getColor = () => {
      if (this.props.womenModal) {
        return womenHeader;
      }
      if (this.props.marvelModal) {
        return marvelHeader;
      }
      if (this.props.agathaModal) {
        return agathaHeader;
      }
      if (this.props.politicsModal) {
        return politicsHeader;
      }
      if (this.props.facingModal) {
        return facingHeader;
      }
    };

    return (
      <>
        <div
          className="App"
          style={{
            backgroundImage: !this.props.thematicModal
              ? `url(${getColor()})`
              : "none"
          }}
        >
          <Navigation
            title="Advanced Discovery Suite"
            onNavClick={this.onNavClick}
            onTitleClick={this.onTitleClick}
          >
            {Object.keys(profileNames).length !== 0 ? (
              <SearchTermContext.Provider value={this.props.term}>
                <UserFormContainer
                  render={true} // persist on the top nav
                  user={this.props.profile}
                  profileNames={profileNames}
                  isTasteProfileShowing={this.props.showingTasteProfile}
                />
              </SearchTermContext.Provider>
            ) : null}
          </Navigation>
          <MainContent />
          <ModalContainer onClose={this.onCloseModal}>
            <ContentItemDetailsContainer />
          </ModalContainer>
          <MoodGridModalContainer onClose={this.onCloseModal}>
            <img
              alt="mood grid"
              src="./MoodGrid.png"
              style={{ width: "100%" }}
            />
          </MoodGridModalContainer>
        </div>
        {this.props.showingAdvancedCarousels && (
          <CarouselFooter>
            © 2000-present. Gracenote, Inc. All rights reserved.
          </CarouselFooter>
        )}
      </>
    );
  }
}

export default connect(mapStateToProps)(App);
