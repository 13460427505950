import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { usePalette } from "react-palette";

import HTMLParse from "./HTMLParse";
import DescriptorSummary from "./ContentItemDetails/DescriptorSummary";

const HeroCellContents = styled.div`
  width: 100vw;
  height: calc(100vh - 13rem);
  cursor: pointer;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)),
    linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)),
    url(${props => props.img}) no-repeat;
  background-size: cover;
  display: grid;
  grid-template-rows: 50% fit-content(20%) 30%;
`;

const HeroCellText = styled.p`
  cursor: inherit;
  font-size: 1.75rem;
  margin: 3rem 0 0 6rem;
  color: white;
  width: fit-content;
`;

const HeroCellGoTo = styled(HeroCellText)`
  margin: 1rem 0 0 7.2rem;
  border-radius: 0.6rem;
  padding: 0.5rem 1.0rem 0.5rem;
  background: ${props => props.color};
  height: fit-content;
  font-size: 1.5rem;
  font-weight: 700;
`;

const HeroCellDescriptionContainer = styled.div`
  margin: 7rem 0 0 6rem;
  max-width: 80%;
`;

const HeroCellDescription = styled(HeroCellText)`
  margin: 0;
  padding: 1rem 1.3rem 1rem;
  width: fit-content;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
`;

const HeroCellDescriptorSummary = styled.div`
  margin: 0;
  padding: 1rem 1.3rem 0.3rem;
  width: fit-content;
  align-items: center;
`

const HeroCellTitleImg = styled.img`
  cursor: inherit;
  margin: 0rem 0 0 4rem;
  padding: 1rem 1.3rem 1rem;
  transform: scale(0.85);
  transform-origin: top left;
`;

const AwardGrid = styled.div`
display:grid;
grid-template-columns:85vw 5vw 10vw
`
const Award = styled.div`padding: 1rem; position:relative`

export const ReasonsToWatchHeroCell = (item, index) => {
  const { data, loading, error } = usePalette(item.imageUri);
  const [paletteColor, setPaletteColor] = useState({
    primary: "#babdc930",
    secondary: "#babdc930"
  });
  useEffect(() => {
    if (!loading && !error) {
      setPaletteColor({ primary: data.vibrant, secondary: data.darkVibrant });
    }
  }, [loading]);

  const handler = (e) => {
    console.log(e.currentTarget, e.currentTarget.id, typeof e.currentTarget.id)
    if (e.currentTarget && e.currentTarget.id === "heroCellGoTo" && item.heroData.externalLink !== "") {
      e.stopPropagation()
      window.open(item.heroData.externalLink, "LUPIN FAST FIGMA")
    } else {
      item.action()
    }
  }

  return item && item.heroData ? (
    <div id="clickContainer" key={index} onClick={handler}>
      {/* Grid */}
      <HeroCellContents img={`${item.heroData.backdropImage.uri}`}>
        {/* Row 1 */}
        <HeroCellTitleImg
          src={`${item.heroData.titleTreatment.uri}?trim=true`}
        />
        {/* Row 2 */}
        {item.reasonToWatch && item.reasonToWatch.text ? (
          <HeroCellDescriptionContainer>
            <HeroCellDescription><HTMLParse item={item.reasonToWatch.text} /></HeroCellDescription>
          </HeroCellDescriptionContainer>
        ) : null}
        {item.heroData.rawCategories && item.heroData.rawCategories.length ? (
          <HeroCellDescriptionContainer>
            <HeroCellDescriptorSummary><DescriptorSummary style={{ margin: 0 }} rawDescriptors={item.heroData.rawCategories} paletteColor={paletteColor} /></HeroCellDescriptorSummary>
          </HeroCellDescriptionContainer>
        ) : null}
        {/* Row 3 */}
        <AwardGrid>
          <HeroCellGoTo
            id="heroCellGoTo"
            onClick={handler}
            color={paletteColor.primary}
          >{`Go to ${item.tmsId.startsWith("MV") ? "Movie" : "Show"
            }`}</HeroCellGoTo>
          {item.heroData.rawCategories && item.heroData.rawCategories.length ? (
            <Award>
              <div>
                <img src="/herocell/Awards-Icon.png" alt="" />
              </div>
            </Award>) : null}
        </AwardGrid>

      </HeroCellContents>
    </div>
  ) : null;
};
