import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";
import store from "../store";
import { categoryClicked } from "../actions";
import { UI } from "../constants";

/**
 * A 'Category' is a styled.div
 */
const Category = styled.div`
    font-size: ${UI.font.tiny};
    font-variant-caps: all-small-caps;
    font-weight: ${UI.font.weight.regular};
  	min-width: 20rem;
    line-height: 3rem;
    padding: 0.1rem 0 0.1rem;
`;

/*
 * Style object constants.
 */
const INITIAL_COLOR = "#808080";
const WAITING_COLOR = "#FF8080";
const INITIAL_CURSOR = "pointer";
const WAITING_CURSOR = "wait";
const CATKW_NOT_ENABLED_CURSOR = "default";
const INITIAL_STYLE = { color: INITIAL_COLOR, cursor: INITIAL_CURSOR };
const WAITING_STYLE = { color: WAITING_COLOR, cursor: WAITING_CURSOR };
const HALF_WAITING_STYLE = { color: INITIAL_COLOR, cursor: WAITING_CURSOR };
const CATKW_NOT_ENABLED_STYLE = {
    color: INITIAL_COLOR,
    cursor: CATKW_NOT_ENABLED_CURSOR
};
class CategoryItem extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.style = INITIAL_STYLE;
    }
    handleClick() {
        /*
         * Dispatch the click if there hasn't been a click.
         */
        if (this.style.cursor === INITIAL_CURSOR) {
            store.dispatch(categoryClicked(this.props.category, true));
        }
    }

    componentDidMount() {
        /*
         * Default to INITIAL_CURSOR
         *
         * If category is not found in the whitelist, set not enabled style (no pointer)
         */
        if (
            this.props.keywordEnabledCategories.indexOf(this.props.category) ===
            -1
        ) {
            this.style = CATKW_NOT_ENABLED_STYLE;
        }
    }

    componentDidUpdate(newProps) {
        /*
         * If _we_ were clicked, put the full waiting state.
         * If there was a click, but it wasn't _us_, then just set the waiting cursor.
         */
        if (newProps.wait === this.props.wait) {
            return false;
        }
        if (newProps.clicked !== this.props.category) {
            this.style = HALF_WAITING_STYLE;
            return true;
        } else if (newProps.clicked === this.props.category) {
            this.style = WAITING_STYLE;
            return true;
        }
        return false;
    }

    render() {
        const { category, children } = this.props;
       
        /*
         * Default to invisible.
         *
         * If the visible prop is true, set visible so it can
         * be animated to visible.
         */
        let className = "catkwinvisible";

        if (this.props.visible) {
            className = `${className} catkwvisible`;
        }

        return (
            <div style={{ display: "flex", alignItems: "flex-start" }}>
							<Category
								className={className}
								style={this.style}
								onClick={this.handleClick}
							>
							{category}
							</Category>
							{children}
            </div>
           
        );
    }
}

function mapStateToProps(state) {
    return {
        keywordEnabledCategories: state.keywordEnabledCategories
    };
}
export default connect(mapStateToProps)(CategoryItem);

CategoryItem.propTypes = {
    category: PropTypes.string.isRequired
};
