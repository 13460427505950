import React from "react";
import CurateButtonIconSVG from "./CurateButtonIconSVG.js";

class GenreSearch extends React.Component {
    render() {
        return (
            <div className="curate-icon-svg">
                <CurateButtonIconSVG alt="curate button" />
            </div>
        );
    }
}

export default GenreSearch;
