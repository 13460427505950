import _ from "lodash";
/**
 * Collect things that are commonly (> than one file) used w Elastic Search.
 */
const ElasticSearchUtils = {
    // This method returns just the portion of that path that each Levels graph
    // requires to perform its ES query
    getPartialPath: (keyword, fullPath) => {
        const path = _.compact(_.split(fullPath, "/"));
        const sp = path.findIndex(kw => kw === keyword);
        const partial_path = path.slice(0, sp);
        return partial_path;
    },
    getKeywordFromPath: fullPath => {
        const pathSplit = _.compact(_.split(fullPath, "/"));
        return pathSplit.pop();
    },
    getCategoryChildKeywords(buckets) {
        let child_keywords = [];

        child_keywords = buckets.map(hit => {
            const path = hit["key"];
            const kids = path
                .split("/")
                .splice(2, 1)
                .pop();
            return kids;
        });

        return _.uniq(child_keywords);
    }
};

export default ElasticSearchUtils;
