import React from "react";
import { connect } from "react-redux";
import { UI } from "../constants";
import styled from "styled-components";
import { SearchResultString } from "../i18n/strings";
import SearchResult from "./SearchResult";
import { DEBUGLOG } from "../debug/debuglog";

const BoundingBox = styled.section`
    width: 96%;
    position: relative;
    left: 4%;
    margin-top: 3%;
    margin-bottom: 5%;
    border-top: 1px solid #979797;
    background: none;
`;
const ResultsHeading = styled.div`
    padding: 1rem 0;
    color: white;
    font-size: ${UI.font.small};
    text-transform: capitalize;
    display: flex;
`;
const Title = styled.div`
    font-weight: ${UI.font.weight.bold};
`;

function mapStateToProps(state) {
    return {
        suggestions: state.fullscreen.suggestions8n,
        lang: state.lang,
        count: state.fullscreen.suggestionsCount
    };
}
class SearchResults extends React.Component {
    render() {
        let results = this.props.count;
        let listItems = [];

        if (results > 0) {
            const seenItems = new Set();
            const { langs, map } = this.props.suggestions;
            const myLang = this.props.lang;
            /*
             * put 'myLang' ahead of any other langs.
             */
            let otherLangs = langs.filter(l => l !== myLang);
            otherLangs.sort();
            otherLangs = [myLang, ...otherLangs];

            /**
             *
             * @param {String} arr items that match lang
             * @param {Set} set the set that contains the items we've already seen
             */
            const mapItems = (arr, set) => {
                if (set !== null) {
                    arr = arr.filter(_item => !set.has(_item.rootId));
                }

                return arr.map(_item =>
                    React.createElement(SearchResult, {
                        key: _item.tmsId,
                        item: _item
                    })
                );
            };

            /*
             * Put items that match myLang into seenItems
             */
            let temp = map.get(myLang) || [];
            temp.forEach(s => {
                seenItems.add(s.rootId);
            });

            /*
             * Put the otherLangs items, if they are not in seenSet
             */
            otherLangs.forEach(l => {
                // Might not be a lang that matches 'my lang' in the result.
                const a = map.get(l) || [];
                let set = l === myLang ? null : seenItems;
                temp = mapItems(a, set);
                listItems = [...listItems, ...temp];
                results = listItems.length;
            });
        }

        DEBUGLOG(
            `SearchResults: ${
                this.props.term
            } :+++++++++++++++++++++++++++++++++++++++++++++++++++`
        );
        return (
            <BoundingBox>
                <div className="search-results-metadata">
                    <ResultsHeading>
                        <SearchResultString
                            lang={this.props.lang}
                            length={results}
                        />
                        <Title>{this.props.term}</Title>
                    </ResultsHeading>
                </div>
                <div className="search-results-grid">{listItems}</div>
            </BoundingBox>
        );
    }
}

export default connect(mapStateToProps)(SearchResults);
