/**
 * All of our sagas.
 */

import { Auth } from "aws-amplify";
import _ from "lodash";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
  openModal,
  waitAfterClick,
  authRedirect,
  authSession,
  authMessage,
  MESSAGE_NO,
  MESSAGE_BYE,
  videoClipsTabClicked,
  reasonsToWatchTabClicked
} from "../actions";
import API from "../api/api.js";
import { personalizedImages } from "../api/personalizedImages";
import lzjs from "lzjs";
import {
  BADGE_FLAG,
  BADGE_LETTER,
  FACE,
  FPSEP,
  HEADS,
  profile as appProfile,
  TAILS
} from "../api/profile";
import { config, hasAccess } from "../config/";
import {
  ADDING_CHILD_KEYWORDS,
  ADD_ADVANCED_CATEGORIES_TO_LIST,
  ADD_POPULAR_ITEMS_TO_LIST,
  ADD_HERO_ITEMS_TO_LIST,
  ADD_BASED_ON_ITEMS_TO_LIST,
  ADD_CLIPS_ITEMS_TO_LIST,
  ADD_PROFILE_NAMES_TO_LIST,
  ADD_TASTE_PROFILE_ITEMS_TO_LIST,
  AUTH_REDIRECT,
  LOGOUT,
  LOGIN,
  BOOTSTRAP_KEYWORD_GRAPH_DATA,
  BUBBLE_CLICKED,
  CATEGORY_CLICKED,
  CLOSE_MODAL,
  CREATE_KEYWORD_GRAPH_DATA,
  FILLING_KEYWORD_GRAPH,
  HOME,
  KEYWORD_CLICKED,
  OPEN_MODAL,
  PERSONALIZED_IMAGES_TAB_CLICKED,
  REQUEST_POPULAR_ITEMS,
  REQUEST_HERO_ITEMS,
  REQUEST_BASED_ON_ITEMS,
  REQUEST_CLIPS_ITEMS,
  TASTE_PROFILE_CLICKED,
  UI,
  UPDATE_PERSONALIZED_IMAGES,
  SUGGESTION_REQUESTED,
  SUGGESTIONS_RECEIVED,
  SEARCH_RESULT_CLICKED,
  SORTED_KWCATEGORIES,
  FETCH_KW_ENABLED_CATEGORIES,
  GENRE_NAME_CLICKED,
  DISPLAY_GENRE_SEARCH_RESULTS,
  GET_ALL_GENRE_NAMES,
  UPDATE_MOOD_JOURNEY,
  MOOD_JOURNEY_CLICKED,
  MOOD_QUADRANT_CLICKED,
  MOOD_PATH_CLICKED,
  ADD_MOOD_ITEMS_TO_LIST,
  IS_GOOGLE,
  MOOD_BREAD_CRUMB_CLICKED
  // OPEN_WOMEN
} from "../constants";
import ESUtils from "../ElasticSearchUtils";
import SearchConstants from "../SearchConstants";
import {
  selectAuthSession,
  getFirstItem,
  getPlaceholder,
  getProfile,
  getSelectedContent,
  getTasteProfileData,
  selectAuthRedirect,
  selectLang,
  getGenreToSearch
  // getWomenPage,
} from "../selectors";
import additionalHeroData from "../api/additionalHeroData.json"
import additionalBasedOnData from "../api/additionalBasedOnData.json"
import additionalClipsData from "../api/additionalClipsData.json"

/* one root saga to bind them all */
export default function* rootSaga() {
  yield all([
    redirect(),
    logout(),
    login(),
    home(),
    fetchPopularItems(),
    fetchHeroItems(),
    fetchBasedOnItems(),
    fetchClipsItems(),
    keywordItemClicked(),
    fetchCategoryClicked(),
    personalizedImagesTabClicked(),
    videoClipsTabClicked(), //Might not need this.
    reasonsToWatchTabClicked(),
    fetchTasteProfileClicked(),
    bubbleClicked(),
    suggestionRequested(),
    searchResultClickedHandler(),
    genreNameClicked(),
    fetchMoodJourneyClicked(),
    fetchMoodQuadrantClicked(),
    fetchMoodPathClicked(),
    fetchMoodBreadCrumbClicked()
  ]);
}

/* Auth */

export function* redirect() {
  yield takeEvery(AUTH_REDIRECT, onAuthRedirect);
}

export function* logout() {
  yield takeEvery(LOGOUT, onLogout);
}

export function* login() {
  yield takeEvery(LOGIN, onLogin);
}

export function* onAuthRedirect() {
  const redirect = yield select(selectAuthRedirect);
  window.location.replace(redirect);
}

export async function* onLogout() {
  const _auth = Auth;

  try {
    await _auth.signOut();
  } catch (e) {
    console.log(e);
  } finally {
    yield put(authMessage(MESSAGE_BYE));
    yield put(
      authRedirect(config.authLoginUrl, {
        expires: 0,
        authenticated: false
      })
    );
  }
}

export function* onLogin() {
  console.log("onLogin");
  //https://flaviocopes.com/javascript-sleep/
  const propagateWait = async milliseconds => {
    await new Promise(resolve => setTimeout(resolve, milliseconds));
  };

  propagateWait(10000);

  const userAndCred = yield call(userAndCredentials);
  let session = yield select(selectAuthSession);

  //
  // Put session user's isGoogle status into state
  // https://jira.gracenote.com/browse/HART-11893
  //
  try {
    yield put({
      type: IS_GOOGLE,
      isGoogle: isGoogle(userAndCred)
    });
  } catch (e) {
    console.log(e, "ERROR");
    yield put({ type: "ERROR", payload: e });
  }

  /*
   * Increase the log out time for 'us' (Gracenote/Nielsen)
   * Put authenticated in the session, so the fullscreen will render
   */
  if (userAndCred.credentials.authenticated && !session.updated) {
    const ok = hasAccess(userAndCred);
    // If has access false, set new message for ui
    if (!ok) {
      yield put(authMessage(MESSAGE_NO));
    }
    session.authenticated = ok;
    session.updated = true;

    if (userAndCred.isUs) {
      session.expires_in = 24 * 3600;
      let expires = new Date().getTime() + session.expires_in * 1000;
      session.expires = expires;
    }
    yield put(authSession(session));
  }
}

/* watcher to catch and return all calls to boot screen */

export function* home() {
  yield takeEvery(HOME, makeBootstrapRequest);
}

export function* makeBootstrapRequest() {
  try {
    yield call(redirectToLoginScreen);
    yield call(API.requestCurrentIndices);
    yield call(fetchKeywordEnabledCategories);
    yield call(addProfileNames);
    yield call(popularItems);
    yield call(heroItems);
    yield call(basedOnItems);
    yield call(clipsItems);
    yield call(advancedCategories);
    yield call(getAllGenreNames);
  } catch (e) {
    yield put({ type: "ERROR", payload: e });
  }
}

/**
 * part of booting up is to retrieve the currently enabled categories
 */
export function* fetchKeywordEnabledCategories() {
  const enabledCategories = yield call(API.fetchKeywordEnabledCategories);

  let kwEnCats = [];
  for (const prop in enabledCategories) {
    kwEnCats.push(enabledCategories[prop].key);
  }

  try {
    yield put({
      type: FETCH_KW_ENABLED_CATEGORIES,
      categories: kwEnCats
    });
  } catch (e) {
    console.log(e, "ERROR");
    yield put({ type: "ERROR", payload: e });
  }
}

/**
 * genre search saga
 */

/* bootup time: gather latest Genres to populate Curation Tool genre pills */
export function* getAllGenreNames() {
  try {
    const genres = yield call(API.getAllGenreNames);
    yield put({
      type: GET_ALL_GENRE_NAMES,
      genres: genres
    });
  } catch (e) {
    console.log(e, "ERROR");
    yield put({ type: "ERROR", payload: e });
  }
}

/* watcher */
export function* genreNameClicked() {
  yield takeEvery(GENRE_NAME_CLICKED, doGenreSearch);
}

/* worker */
export function* doGenreSearch() {
  try {
    yield put(waitAfterClick(true, null));

    const genreNameToSearch = yield select(getGenreToSearch);
    const results = yield call(API.searchByGenre, genreNameToSearch);

    yield put({
      type: DISPLAY_GENRE_SEARCH_RESULTS,
      genreSearchResults: results
    });

    yield put(waitAfterClick(false, null));
  } catch (e) {
    console.log(e, "ERROR");
    yield put({ type: "ERROR", payload: e });
  }
}

/* mood journey clicked saga */
/* watcher */
export function* fetchMoodJourneyClicked() {
  yield takeEvery(MOOD_JOURNEY_CLICKED, startMoodJourney);
}

/* worker */
export function* startMoodJourney() {
  try {
    const moodSource = appProfile.profile_5.moods();
    let moods_A = [];
    for (const data of moodSource.A) {
      moods_A.push({
        viewer_motivation: data.viewer_motivation,
        color: data.color,
        region: data.region,
        gradient: "/gradient-1.png"
      });
    }

    let moods_B = [];
    for (const data of moodSource.B) {
      moods_B.push({
        viewer_motivation: data.viewer_motivation,
        color: data.color,
        region: data.region,
        gradient: "/gradient-2.png"
      });
    }

    yield put({
      type: UPDATE_MOOD_JOURNEY,
      vmHomeDisplayMap: { A: moods_A, B: moods_B }
    });
  } catch (e) {
    console.log(e, "ERROR");
    yield put({ type: "ERROR", payload: e });
  }
}

/* Viewer Motivations saga - clicks from within MoodTiles */
/* watcher */
export function* fetchMoodPathClicked() {
  yield takeEvery(MOOD_PATH_CLICKED, handleMoodPathClickedRequest);
}

/* Viewer Motivations saga - clicks from within MoodTiles */
/* watcher */
export function* fetchMoodBreadCrumbClicked() {
  yield takeEvery(MOOD_BREAD_CRUMB_CLICKED, handleMoodPathClickedRequest);
}

/* worker for both MOOD_PATH_CLICKED and MOOD_BREAD_CRUMB_CLICKED */
export function* handleMoodPathClickedRequest(action) {
  try {
    const id = [`${action.mood.tmsId}|${action.mood.viewer_motivation}`];
    const graph = yield call(API.requestTileGraph, id);

    const moods = {
      viewer_motivation: action.mood.viewer_motivation,
      tmsId: action.mood.tmsId,
      graph: graph[0].graph
    };

    //
    // craft a return object that contains hydrated list of the chosen viewer_motivation's graph
    // in "programItems" and the chosen graph itself (for the popup) in "graph"
    //
    let moodDataObject = {
      title: moods.viewer_motivation,
      tmsId: moods.tmsId,
      color: moods.color || "white",
      graph: moods.graph
    };

    // get data from API
    let moodProgramsIds = moods.graph.map(mg => mg.tmsId);
    const rawResults = yield call(API.requestPrograms, [
      moodProgramsIds,
      moodProgramsIds.length
    ]);

    // reorder the items in this carousel to match the incoming order
    let orderedResults = [];
    moodProgramsIds.forEach(item => {
      orderedResults.push(_.find(rawResults, ["tmsId", `${item}`]));
    });

    // this can have null items, remove them now
    const cleanOrderedResults = _.compact(orderedResults); // note this leaves the original indexes in place
    // for (let program of cleanOrderedResults) {
    //   program.tms.palette = yield call(paletteForProgram, program)
    // }
    moodDataObject["programItems"] = cleanOrderedResults.map(
      craftModalData,
      yield select(getPlaceholder)
    );
    yield put({
      type: ADD_MOOD_ITEMS_TO_LIST,
      moodItemsData: moodDataObject
    });
  } catch (e) {
    console.log(e, "ERROR");
    yield put({ type: "ERROR", payload: e });
  }
}

/* Viewer Motivations saga - clicks from Motivations Home */
/* watcher */
export function* fetchMoodQuadrantClicked() {
  yield takeEvery(MOOD_QUADRANT_CLICKED, handleMoodQuadrantClickedRequest);
}

/* worker */
export function* handleMoodQuadrantClickedRequest(action) {
  try {
    const vm = action.quadrantData;

    const id = [`${vm.tmsId}|${vm.viewer_motivation}`];
    const graph = yield call(API.requestTileGraph, id);

    const moods = {
      viewer_motivation: vm.viewer_motivation,
      tmsId: vm.tmsId,
      graph: graph[0].graph
    };

    //
    // craft a return object that contains hydrated list of the chosen viewer_motivation's graph
    // in "programItems" and the chosen graph itself (for the popup) in "graph"
    //
    let moodDataObject = {
      title: moods.viewer_motivation,
      tmsId: moods.tmsId,
      color: moods.color || "white",
      graph: moods.graph
    };

    // get data from API
    let moodProgramsIds = moods.graph.map(mg => mg.tmsId);
    const rawResults = yield call(API.requestCarouselItems, moodProgramsIds);

    // reorder the items in this carousel to match the incoming order
    let orderedResults = [];
    moodProgramsIds.forEach(item => {
      orderedResults.push(_.find(rawResults, ["tmsId", `${item}`]));
    });

    // this can have null items, remove them now
    const cleanOrderedResults = _.compact(orderedResults); // note this leaves the original indexes in place

    // for (let program of cleanOrderedResults) {
    //   program.tms.palette = yield call(paletteForProgram, program)
    // }
    moodDataObject["programItems"] = cleanOrderedResults.map(
      craftModalData,
      yield select(getPlaceholder)
    );
    yield put({
      type: ADD_MOOD_ITEMS_TO_LIST,
      moodItemsData: moodDataObject
    });
  } catch (e) {
    console.log(e, "ERROR");
    yield put({ type: "ERROR", payload: e });
  }
}

/* taste profile clicked saga */
/* watcher */
export function* fetchTasteProfileClicked() {
  yield takeEvery(TASTE_PROFILE_CLICKED, handleTasteProfileClickedRequest);
}

const getFlippersForProfile = (_currentProfile, _currentTasteProfileData) => {
  let selectedFlippers;
  // const currentProfile = yield select(getProfile);
  // const currentTasteProfileData = yield select(getTasteProfileData);
  if (
    _currentTasteProfileData[_currentProfile] &&
    _currentTasteProfileData[_currentProfile].length > 0
  ) {
    selectedFlippers = _currentTasteProfileData[_currentProfile];
  } else {
    selectedFlippers = appProfile[_currentProfile].flippers;
  }
  return selectedFlippers;
};
/* worker */
export function* handleTasteProfileClickedRequest() {
  try {
    let flipperKeywords = [];
    let flipperCelebrities = [];
    let flipperGenres = [];

    const selectedFlippers = getFlippersForProfile(
      yield select(getProfile),
      yield select(getTasteProfileData)
    );

    flipperCelebrities = selectedFlippers.celebrities;
    flipperKeywords = selectedFlippers.keywords;
    flipperGenres = selectedFlippers.genres;
    console.log(
      flipperKeywords,
      flipperCelebrities,
      flipperGenres,
      "flipperKeywords, flipperCelebrities, flipperGenres"
    );

    yield put({
      type: ADD_TASTE_PROFILE_ITEMS_TO_LIST,
      profile: yield select(getProfile),
      tasteProfileData: {
        keywords: flipperKeywords,
        celebrities: flipperCelebrities,
        genres: flipperGenres
      }
    });
    yield call(popularItems);
  } catch (e) {
    console.log(e, "ERROR");
    yield put({ type: "ERROR", payload: e });
  }
}

/* taste profile BUTTON clicked saga */
/* watcher */
export function* bubbleClicked() {
  yield takeEvery(BUBBLE_CLICKED, makeBubbleClickedRequest);
}

/* worker */
export function* makeBubbleClickedRequest(action) {
  try {
    const { id } = action;

    const currentProfile = yield select(getProfile);
    const currentTasteProfileData = yield select(getTasteProfileData);

    //N.B. this order needs to be the same as the 'const bubbles' in TasteProfile.js
    const bubbleToFlip = [
      ...currentTasteProfileData[currentProfile].keywords,
      ...currentTasteProfileData[currentProfile].genres,
      ...currentTasteProfileData[currentProfile].celebrities
    ][id];

    if (!bubbleToFlip) return;
    if (bubbleToFlip[FACE] === HEADS) {
      // toggle the face property
      bubbleToFlip[FACE] = TAILS;
    } else {
      bubbleToFlip[FACE] = HEADS;
    }

    // now populate the carousels again, knowing the fingerprint has been changed
    yield call(fingerprintUpdated);

    // ... this will persist the new Taste Profile that was flipped
  } catch (e) {
    console.log(e, "ERROR");
    yield put({ type: "ERROR", payload: e });
  }
}
/* another worker, in case we want to do more with fingerprints later */
export function* fingerprintUpdated() {
  try {
    yield call(popularItems);
    yield call(advancedCategories);
  } catch (e) {
    console.log(e, "ERROR");
    yield put({ type: "ERROR", payload: e });
  }
}

// async function paletteForProgram(program) {
//   return null;
//   // let backgroundUri = undefined;
//   // if (_.has(program.tms, "iconicImage.uri")) {
//   //   backgroundUri = program.tms.iconicImage.uri;
//   // } else {
//   //   return null;
//   // }

//   // return await Vibrant.from(backgroundUri).useQuantizer(Vibrant.Quantizer.WebWorker).getPalette().then(p => p)

// }

/**
 * advanced categories saga
 */
export function* advancedCategories() {
  try {
    const currentProfile = yield select(getProfile);
    //
    // NOTE: support for mood via constant global variable "profile"
    //
    if (currentProfile === "profile_5") {
      return []; // no use case for popular items carousel, yet, in Mood persona
    }
    let flipperKeywords = [];
    let flipperCelebrities = [];

    const selectedFlippers = getFlippersForProfile(
      yield select(getProfile),
      yield select(getTasteProfileData)
    );

    flipperCelebrities = selectedFlippers.celebrities;
    flipperKeywords = [
      ...selectedFlippers.keywords,
      ...selectedFlippers.genres
    ];

    // to get the current fingerprint, read faces
    const fp_items = flipperKeywords.map(item => {
      const key = item[FACE];
      return item[key];
    });

    const keywordFingerprint = fp_items.sort().join(FPSEP);

    // now add celebrities to the keyword container fingerprint
    // to make the full fingerprint for personalized images lookups
    const celeb_fp_items = flipperCelebrities.map(item => {
      const key = item[FACE];
      return item[key];
    });

    const celebFingerprint = celeb_fp_items.sort().join(FPSEP);

    const comboFingerprint = `${keywordFingerprint}|${celebFingerprint}`;
    // sort by alpha, delimit per the spec'd format
    // and query to get the movies for this cluster
    const dict_fp_values = appProfile[
      currentProfile
    ].dictionary.getCarouselDefinitions(fp_items.sort().join(FPSEP));

    // containers for the final carousels' data
    let advancedCategoriesData = [];
    let advancedCategoriesIds = [];
    let carouselTitle = "";

    // use movie data to populate carousels
    for (let item of dict_fp_values) {
      advancedCategoriesIds = item.movies.slice(
        0,
        SearchConstants.carousel_query_size
      );
      carouselTitle = item.category;

      const rawResults = yield call(
        API.requestCarouselItems,
        advancedCategoriesIds
      );
      // reorder the items in this carousel to match the incoming order
      let orderedResults = [];
      advancedCategoriesIds.forEach(item => {
        orderedResults.push(_.find(rawResults, ["tmsId", `${item}`]));
      });

      // this can have null items, remove them now
      const cleanOrderedResults = _.compact(orderedResults); // note this leaves the original indexes in place

      // Dont need this
      cleanOrderedResults.forEach(program =>
        addPersonalizedImageTo(program, currentProfile, comboFingerprint)
      );

      // for (let program of cleanOrderedResults) {
      //   program.tms.palette = yield call(paletteForProgram, program)
      // }

      const advancedCategoriesObj = {
        carouselTitle: carouselTitle,
        carouselItems: cleanOrderedResults.map(
          craftModalData,
          yield select(getPlaceholder)
        )
      };

      advancedCategoriesData.push(advancedCategoriesObj);
    }

    yield put({
      type: ADD_ADVANCED_CATEGORIES_TO_LIST,
      advancedCategoriesData: advancedCategoriesData
    });
  } catch (e) {
    console.log(e, "ERROR");
    yield put({ type: "ERROR", payload: e });
  }
}

export async function userAndCredentials() {
  const _auth = Auth;
  const credentials = { authenticated: false };
  const credentialError = { is: false, message: "Copacetic" };
  const user = {};
  const userError = { is: false, message: "Copacetic" };
  const custom = {};
  const isUs = true;
  const ret = {
    isUs,
    credentials,
    credentialError,
    user,
    userError,
    custom
  };

  try {
    ret.user = await _auth.currentAuthenticatedUser();
    ret.userError.is = false;
    try {
      ret.credentials = await _auth.currentCredentials();
      ret.credentialError.is = false;
    } catch (e) {
      ret.credentialError.is = true;
      ret.credentialError.message = e.message || e;
    }
  } catch (e) {
    ret.userError.is = true;
    ret.userError.message = e.message || e;
  }

  if (!ret.userError.is && !isGoogle(ret)) {
    ret.isUs = false;
    ret.custom = JSON.parse(
      lzjs.decompress(ret.user.attributes["custom:app:ads"])
    );
  }
  return ret;
}

/**
 * Redirect to login screen if we are expired, or we don't have the right uri, or session has been cleared.
 */
export function* redirectToLoginScreen() {
  let session = yield select(selectAuthSession);

  const expired = exp => new Date().getTime() > exp;
  const redirect = () => window.location.replace(config.authLoginUrl);
  const notcallback =
    window.location.pathname.indexOf(config.callbackAt) === -1;

  /*
   * If we are expired, or 'expired', redirect.
   */
  if (expired(session.expires) && notcallback) {
    yield put(authSession({ expires: 0, authenticated: false }));
    yield call(redirect);
  } else {
    let hashsession = {};
    let hash = window.location.hash || "";
    window.location.hash = "";

    /*
     * Find the fields in the hash and assign them into hashsession
     */
    if (hash && hash.indexOf("&") !== -1 && hash.indexOf("=") !== -1) {
      hash
        .substring(1)
        .split("&")
        .forEach(i => {
          let a = i.split("=");
          a.length === 2 && (hashsession[a[0]] = a[1]);
        });
      let expires = new Date().getTime() + hashsession.expires_in * 1000;
      hashsession.expires = expires;
      hashsession.authenticated = true;
      yield put(authSession(hashsession));
    } else {
      hashsession = yield select(selectAuthSession);
    }

    /*
     * If we are expired, or someone fiddled with the uri, we'll redirect.
     */
    if (expired(hashsession.expires)) {
      yield call(redirect);
    } else {
      /*
       * Generators must yield something.
       */
      yield call(() => { });
    }
  }
}

//TODO: remove the profile_count checking code when the Swedish persona is official.
// eslint-disable-next-line
const WANT_SWEDISH = 4;
const WANT_MOODS = 5;
// eslint-disable-next-line
const DONT_WANT_SWEDISH = 3;
const PROFILE_COUNT = WANT_MOODS;
export function* addProfileNames() {
  const profileNames = {};
  Object.keys(appProfile).forEach((k, i) => {
    if (i < PROFILE_COUNT) {
      profileNames[k] = appProfile[k].name;
    }
  });

  try {
    yield put({
      type: ADD_PROFILE_NAMES_TO_LIST,
      profileNames: profileNames
    });
  } catch (e) {
    console.log(e, "ERROR");
    yield put({ type: "ERROR", payload: e });
  }
}

/*v popular items saga v*/
/**
 * Popular items request, shorn of start and stop api requests
 */
export function* popularItems() {
  try {
    const currentProfile = yield select(getProfile);

    //
    // NOTE: support for mood via constant global variable "profile"
    //
    if (currentProfile === "profile_5") {
      return []; // no use case for popular items carousel, yet, in Mood persona
    }
    const comboFingerprint = yield call(getComboFingerprint);
    const lang = yield select(selectLang);

    let popularItemsIds = [];
    let carouselTitle = "";
    let vizmItems = {};
    // popularItems
    appProfile.profile_1.dictionary.firstCarousel[lang].forEach(item => {
      popularItemsIds = item.movies;
      carouselTitle = item.category;
      vizmItems = item.vizmerch;
    });

    const rawResults = yield call(API.requestCarouselItems, popularItemsIds);

    // reorder the items in this carousel to match the incoming order
    // N.B.: the tms.tmsId find can change to 'tmsId' once we reindex by tmsId.
    let orderedResults = [];
    popularItemsIds.forEach(item => {
      orderedResults.push(_.find(rawResults, ["tms.tmsId", `${item}`]));
    });

    orderedResults.forEach(i => {
      const swapItem = i && vizmItems[i.tmsId]; // Be sure 'i' isn't missing, else page will not load.

      if (swapItem !== undefined) {
        let tmpFp = swapItem.filter(si => si.fp === comboFingerprint);
        let tmpAll = swapItem.filter(si => si.fp === "all");
        if (tmpFp.length) {
          i["swapItem"] = tmpFp[0].clip;
        } else {
          i["swapItem"] = tmpAll[0].clip;
        }
      }
    });

    // this can have null items, remove them now
    const cleanOrderedResults = _.compact(orderedResults); // note this leaves the original indexes in place

    cleanOrderedResults.forEach(program =>
      addPersonalizedImageTo(program, currentProfile, comboFingerprint)
    );

    // for (let program of cleanOrderedResults) {
    //   program.tms.palette = yield call(paletteForProgram, program)
    // }

    const popularItemsData = {
      carouselTitle: carouselTitle,
      carouselItems: cleanOrderedResults.map(
        craftModalData,
        yield select(getPlaceholder)
      )
    };

    yield put({
      type: ADD_POPULAR_ITEMS_TO_LIST,
      popularItemsData: popularItemsData
    });
  } catch (e) {
    console.log(e, "ERROR");
    yield put({ type: "ERROR", payload: e });
  }
}

/* watcher */
export function* fetchPopularItems() {
  yield takeEvery(REQUEST_POPULAR_ITEMS, makePopularItemsApiRequest);
}

/* worker */
export function* makePopularItemsApiRequest() {
  try {
    yield call(popularItems);
  } catch (e) {
    console.log(e, "ERROR");
    yield put({ type: "ERROR", payload: e });
  }
}
/*^ popular items saga ^*/
/*v hero items saga v*/
/**
 * Popular items request, shorn of start and stop api requests
 */
export function* heroItems() {
  try {
    const currentProfile = yield select(getProfile);
    const lang = yield select(selectLang);

    //
    // Vet our profiles/lang
    //
    const NO_HERO = ["profile_5", "profile_4"]
    if (NO_HERO.includes(currentProfile) || lang === "sv") {
      return []; // no use case for based on in these profiles/lang
    }
    const additionalHeroData = yield call(getAdditionalHeroData);


    let heroItemsIds = [];
    let carouselTitle = "";
    // heroItems
    appProfile.profile_1.dictionary.heroCarousel[lang].forEach(item => {
      heroItemsIds = item.movies;
      carouselTitle = item.category;
    });

    const rawResults = yield call(API.requestCarouselItems, heroItemsIds);

    // reorder the items in this carousel to match the incoming order
    // N.B.: the tms.tmsId find can change to 'tmsId' once we reindex by tmsId.
    let orderedResults = [];
    heroItemsIds.forEach(item => {
      orderedResults.push(_.find(rawResults, ["tms.tmsId", `${item}`]));
    });

    // this can have null items, remove them now
    const cleanOrderedResults = _.compact(orderedResults); // note this leaves the original indexes in place

    // for (let program of cleanOrderedResults) {
    //   program.tms.palette = yield call(paletteForProgram, program)
    // }

    const heroItemsData = {
      carouselTitle: carouselTitle,
      carouselItems: cleanOrderedResults.map(
        craftModalData,
        yield select(getPlaceholder)
      )
    };

    heroItemsData.carouselItems.forEach(program => {
      additionalHeroData.forEach(ahd => {
        if (program.tmsId === ahd.tmsId) {
          program["heroData"] = { ...ahd.heroData };
        }
      })
    });

    yield put({
      type: ADD_HERO_ITEMS_TO_LIST,
      heroItemsData: heroItemsData
    });
  } catch (e) {
    console.log(e, "ERROR");
    yield put({ type: "ERROR", payload: e });
  }
}
/* watcher */
export function* fetchHeroItems() {
  yield takeEvery(REQUEST_HERO_ITEMS, makeHeroItemsApiRequest);
}

/* worker */
export function* makeHeroItemsApiRequest() {
  try {
    yield call(heroItems);
  } catch (e) {
    console.log(e, "ERROR");
    yield put({ type: "ERROR", payload: e });
  }
}
/*^ hero items saga ^*/

/*v based on items saga v*/
/**
 * Popular items request, shorn of start and stop api requests
 */
export function* basedOnItems() {
  try {

    const currentProfile = yield select(getProfile);
    const lang = yield select(selectLang);

    //
    // Vet our profiles and language.
    //
    const NO_BASEDON = ["profile_5", "profile_4"]
    if (NO_BASEDON.includes(currentProfile) || lang === "sv") {
      return []; // no use case for based on in these profiles/lang
    }
    const additionalBasedOnData = yield call(getAdditionalBasedOnData);


    let basedOnItemsIds = [];
    let carouselTitle = "";
    // basedOnItems
    appProfile.profile_1.dictionary.basedOnCarousel[lang].forEach(item => {
      basedOnItemsIds = item.movies;
      carouselTitle = item.category;
    });

    const rawResults = yield call(API.requestCarouselItems, basedOnItemsIds);

    // reorder the items in this carousel to match the incoming order
    // N.B.: the tms.tmsId find can change to 'tmsId' once we reindex by tmsId.
    let orderedResults = [];
    basedOnItemsIds.forEach(item => {
      orderedResults.push(_.find(rawResults, ["tms.tmsId", `${item}`]));
    });

    // this can have null items, remove them now
    const cleanOrderedResults = _.compact(orderedResults); // note this leaves the original indexes in place

    const basedOnItemsData = {
      carouselTitle: carouselTitle,
      carouselItems: cleanOrderedResults.map(
        craftModalData,
        yield select(getPlaceholder)
      )
    };

    basedOnItemsData.carouselItems.forEach(program => {
      additionalBasedOnData.forEach(ahd => {
        if (program.tmsId === ahd.tmsId) {
          program["basedOnData"] = { ...ahd.basedOnData };
        }
      })
    });

    yield put({
      type: ADD_BASED_ON_ITEMS_TO_LIST,
      basedOnItemsData: basedOnItemsData
    });
  } catch (e) {
    console.log(e, "ERROR");
    yield put({ type: "ERROR", payload: e });
  }
}

/* watcher */
export function* fetchBasedOnItems() {
  yield takeEvery(REQUEST_BASED_ON_ITEMS, makeBasedOnItemsApiRequest);
}

/* worker */
export function* makeBasedOnItemsApiRequest() {
  try {
    yield call(basedOnItems);
  } catch (e) {
    console.log(e, "ERROR");
    yield put({ type: "ERROR", payload: e });
  }
}
/*^ basedon items saga ^*/

/*v clips items saga v*/
/**
 * Popular items request, shorn of start and stop api requests
 */
export function* clipsItems() {
  try {

    const currentProfile = yield select(getProfile);
    const lang = yield select(selectLang);

    //
    // Vet our profiles and language.
    //
    const NO_CLIPS = ["profile_5", "profile_4"]
    if (NO_CLIPS.includes(currentProfile) || lang === "sv") {
      return []; // no use case for based on in these profiles/lang
    }
    const additionalClipsData = yield call(getAdditionalClipsData);

    ;
    let clipsItemsIds = [];
    let carouselTitle = "";
    // clipsItems
    appProfile.profile_1.dictionary.clipsCarousel[lang].forEach(item => {
      clipsItemsIds = item.movies;
      carouselTitle = item.category;
    });

    const rawResults = yield call(API.requestCarouselItems, clipsItemsIds);

    // reorder the items in this carousel to match the incoming order
    // N.B.: the tms.tmsId find can change to 'tmsId' once we reindex by tmsId.
    let orderedResults = [];
    clipsItemsIds.forEach(item => {
      orderedResults.push(_.find(rawResults, ["tms.tmsId", `${item}`]));
    });

    // this can have null items, remove them now
    const cleanOrderedResults = _.compact(orderedResults); // note this leaves the original indexes in place

    const clipsItemsData = {
      carouselTitle: carouselTitle,
      carouselItems: cleanOrderedResults.map(
        craftModalData,
        yield select(getPlaceholder)
      )
    };

    clipsItemsData.carouselItems.forEach(program => {
      additionalClipsData.forEach(ahd => {
        if (program.tmsId === ahd.tmsId) {
          program["clipsData"] = { ...ahd.clipsData };
        }
      })
    });

    yield put({
      type: ADD_CLIPS_ITEMS_TO_LIST,
      clipsItemsData: clipsItemsData
    });
  } catch (e) {
    console.log(e, "ERROR");
    yield put({ type: "ERROR", payload: e });
  }
}

/* watcher */
export function* fetchClipsItems() {
  yield takeEvery(REQUEST_CLIPS_ITEMS, makeClipsItemsApiRequest);
}

/* worker */
export function* makeClipsItemsApiRequest() {
  try {
    yield call(clipsItems);
  } catch (e) {
    console.log(e, "ERROR");
    yield put({ type: "ERROR", payload: e });
  }
}
/*^ clips items saga ^*/

/* show keyword graph saga */
/* watcher */
export function* keywordItemClicked() {
  yield takeEvery(KEYWORD_CLICKED, showKeywordGraphLoading);
}

export function* showKeywordGraphLoading(action) {
  const { category } = action;

  action.firstItem = yield select(getFirstItem);

  yield put({
    type: CLOSE_MODAL
  });

  // create initial kwg
  let kwgo = {
    category: category,
    childKeywords: [],
    levels: [categoryLevel(category)]
  };
  yield put({
    type: BOOTSTRAP_KEYWORD_GRAPH_DATA,
    keywordGraphData: kwgo
  });

  action.type = BOOTSTRAP_KEYWORD_GRAPH_DATA;
  action.kwgo = Object.create(kwgo);

  yield call(showKeywordGraph, action);
}

/* worker */
const MAX_LEVELS = 2; //No more than this many levels shown at a time.
function* showKeywordGraph(action) {
  const { keyword, category, kwgo, firstItem } = action;

  try {
    // we always put the clicked item into the array as the first element
    // so we recall which item it was that was clicked to show the current keyword using a selector

    let firstItemObj;
    let contents;
    /*
     * see if there is more than one item - if only one and it is also the first item,
     * the exclusion of it will break the loading of the page.
     */
    let count = yield call(API.fetchKeywordCount, keyword);

    if (firstItem) {
      firstItemObj = {
        action: { selectedContent: firstItem, type: OPEN_MODAL },
        imageUri: firstItem.imageUri,
        program: firstItem
      };
    }
    if (firstItem && count > 1) {
      // fetch content tagged with this keyword, exclude the item being displayed since we already know it
      contents = yield call(
        API.fetchContentByKeyword,
        keyword,
        firstItem.tmsId
      );
    } else {
      contents = yield call(API.fetchContentByKeyword, keyword);
    }
    const prunedContents = contents.filter(id => id.rootId !== "0");

    /*
     * fetch images and text into `details` for each piece of content
     */
    let ids = prunedContents.map(c => c.tmsId);
    if (count === 0 && firstItem) {
      ids.push(firstItem.tmsId);
    }

    const details = yield call(API.fetchProgDetailsByField, [ids, "tmstype"]);

    /*
     * Root id comparator
     * This use of root id is acceptable, as we are using rootId as a stand in for date: on balance,
     * smaller rootIds are earlier movies.
     */
    const compareFn = (a, b) => a.rootId.localeCompare(b.rootId);

    /*
     * Both result sets in rootId order
     */
    contents.sort(compareFn);
    details.sort(compareFn);

    /*
     * Create a map, with a `contents` as the key and a `details` as the value...
     */
    const contents_details = new Map();
    const length = prunedContents.length;

    // contents contains the ids of all the programs that share current keyword graph
    // details contains the fetched program data
    // if an id from contents is not found in the program data pushed to
    // the ES index we're using, we have to skip it
    for (let i = 0; i < length; i++) {
      let cd = _.find(details, { tmsId: prunedContents[i].tmsId });

      if (cd) {
        contents_details.set(prunedContents[i], cd);
      } else {
        console.log(
          "Warning: this id not found in index: ",
          prunedContents[i].tmsId
        );
      }
    }

    /*
     * iterate the map, craft desired structure for the data, populate `programs[]`
     * (Taking advantage of the fact the maps can have any value as their key, to combine `contents` and `details`)
     */
    const programs = [];
    for (let content_detail of contents_details) {
      let content = content_detail[0];
      let detail = content_detail[1];

      const uri =
        (_.has(detail, "tms.preferredImage.uri") &&
          detail.tms.preferredImage.uri) ||
        "";
      const zippedProgDetails = { ...detail, ...content };
      // for (let program of zippedProgDetails) {
      //   program.tms.palette = yield call(paletteForProgram, program)
      // }
      const finalObject = craftModalData(
        zippedProgDetails,
        yield select(getPlaceholder)
      );
      programs.push({
        program: finalObject,
        imageUri: uri,
        action: finalObject.action
      });
    }

    /*
     * put the first item at the head of programs, iff.
     */
    if (firstItemObj) {
      programs.unshift(firstItemObj);
    }

    // derive the current keyword graph's full path:
    // (if there are programs available)
    let path = "";
    if (programs.length < 1) {
      const paths = yield call(API.fetchKeywordChildrenNoContent, [
        category,
        keyword
      ]);

      const partialPath = ESUtils.getPartialPath(keyword, paths[0]["key"]);
      path = `/${partialPath.join("/")}/${keyword}`;
    } else {
      path = contents[0]["path"];
    }

    const levelNames = path.split("/").slice(MAX_LEVELS);

    // for each path level fetch total counts for that level
    const levels = [];
    for (let item of levelNames) {
      const total = yield call(API.fetchCategoryCount, [item, path]);
      levels.push({ tag: item, count: total });
    }

    // Put the programs at the level we'll actually be rendering…
    const insertLevel = levels[levels.length - 1];
    insertLevel.programs = programs;

    if (insertLevel.count === 0 && programs.length === 1) {
      insertLevel.count = 1;
    }

    yield call(fillKeywordGraph, { levels, kwgo, path });
  } catch (e) {
    console.log(e, "ERROR");
    yield put({ type: "ERROR", payload: e });
  }
}
const arrowStyle = (isLastKeyword, hasChildKeywords) => {
  if (isLastKeyword) {
    return hasChildKeywords ? UI.arrow.carousel : UI.arrow.off;
  } else {
    return UI.arrow.standard;
  }
};

// (disable warning about no yield)
// eslint-disable-next-line
function* getLevels(levels) {
  const highestLevel = 4;
  const levelCount = levels.length;

  return levels.map((level, index) => {
    const isLastKeyword = index === levelCount - 1;

    let tmp = level.count;

    /*
     * Always pass along the count, even if it is 0
     * (we have zero count records now, path elements that do not contain tagged items)
     */
    return {
      tag: level.tag,
      count: tmp >= 0 ? ` (${tmp})` : "",
      rawCount: tmp,
      programs: level.programs,
      size: highestLevel - levelCount + index,
      arrowStyle: arrowStyle(isLastKeyword, false)
    };
  });
}

// (disable warning about no yield)
// eslint-disable-next-line
function* getChildKeywords(path) {
  // for the final, leaf level, i.e., the clicked keyword's level,
  // fetch any available children
  const childKeyPaths = yield call(API.fetchChildKeys, path);

  // and fetch each child's counts
  const childCounts = [];
  for (let item of childKeyPaths) {
    const total = yield call(API.fetchCountByPath, item.key);
    childCounts.push({
      tag: ESUtils.getKeywordFromPath(item.key),
      count: total
    });
  }

  return childCounts;
}

function* fillKeywordGraph(action) {
  const { levels, kwgo, path } = action;

  const kwgLevels = yield* getLevels(levels);

  kwgLevels.unshift(kwgo.levels[0]);

  kwgo.levels = kwgLevels;

  yield put({
    type: FILLING_KEYWORD_GRAPH,
    keywordGraphData: Object.create(kwgo)
  });

  const childCounts = yield* getChildKeywords(path);

  if (childCounts.length !== 0) {
    let index = kwgo.levels.length - 1;
    kwgo.levels[index].arrowStyle = arrowStyle(true, true);
    kwgo.childKeywords = childCounts;
    yield put({
      type: ADDING_CHILD_KEYWORDS,
      keywordGraphData: Object.create(kwgo)
    });
  }
}

/* top level category click saga */
/* watcher */
export function* fetchCategoryClicked() {
  yield takeEvery(CATEGORY_CLICKED, makeCategoryClickedApiRequest);
}

/* worker */
export function* makeCategoryClickedApiRequest(action) {
  const category = action.category;
  const closeModal = action.closeModal || false;

  yield put(waitAfterClick(true, category));

  try {
    // get children data
    const categoryChildren = yield call(API.fetchCategoryLeaves, category);

    const childKeywords = ESUtils.getCategoryChildKeywords(
      categoryChildren,
      "getCategoryChildKeywords"
    );

    // convert into format we need to produce keyItems...
    // use the paths, which are: {category}/levelNames[i]
    // to read the total child keywords for each path
    // for each path level fetch total counts for that level
    const childCounts = [];
    const omg = yield call(
      API.fetchTopLevelCategoryCountMany,
      category,
      childKeywords
    );

    /*
     * Root id comparator
     */
    const compareFn = (a, b) => a.path.localeCompare(b.path);

    const omgCompact = _.compact(omg);

    /*
     * Results in path order
     */
    omgCompact.sort(compareFn);

    for (let o of omgCompact) {
      childCounts.push({
        tag: o.path.split("/")[2],
        count: o.total,
        category: category
      });
    }

    // save to state using the keywordData reducer
    yield put({
      type: CREATE_KEYWORD_GRAPH_DATA,
      keywordGraphData: {
        category: category,
        childKeywords: childCounts,
        levels: [categoryLevel(category)]
      }
    });

    yield put(waitAfterClick(false, null));
    if (closeModal) {
      yield put({
        type: CLOSE_MODAL
      });
    }
  } catch (e) {
    console.log(e, "ERROR");
    yield put({ type: "ERROR", payload: e });
  }
}

/* Personalized Images tab click saga */
export function* personalizedImagesTabClicked() {
  yield takeEvery(
    PERSONALIZED_IMAGES_TAB_CLICKED,
    addBadgesToPersonalizedImages
  );
}
/* worker */
export function* addBadgesToPersonalizedImages() {
  try {
    const profile_name = yield select(getProfile);
    const selected = yield select(getSelectedContent);

    //This rootId is acceptable, as it is for personalized image use.
    const personalizedImageIndex = selected.rootId;
    const badgeAble =
      personalizedImages[profile_name][personalizedImageIndex] || null;

    if (badgeAble) {
      const comboFingerprint = yield call(getComboFingerprint);
      const imageToBadge = badgeAble[comboFingerprint];

      // now can check the matching content item's fingerprints, and badge the URIs that match
      let updatedPersonalizedImages = [];
      selected.personalizedImages.forEach(imgObj => {
        const uriArray = imgObj.uri.split("/");
        const index = uriArray.length - 1;
        const uri = uriArray[index];

        const toBadge = imageToBadge === uri ? true : false;

        if (toBadge) {
          imgObj[BADGE_FLAG] = true;
          imgObj[BADGE_LETTER] = appProfile[profile_name].name[BADGE_LETTER];
          updatedPersonalizedImages.unshift(imgObj);
        } else {
          imgObj[BADGE_FLAG] = false;
          imgObj[BADGE_LETTER] = "";
          updatedPersonalizedImages.push(imgObj);
        }
      });

      // save the badgeAble personalizedImages data
      selected.personalizedImages = updatedPersonalizedImages;
      yield put({
        type: UPDATE_PERSONALIZED_IMAGES,
        selectedContent: { ...selected }
      });
    } else {
      console.log(
        personalizedImageIndex +
        " not found in personalized images for this profile...\n"
      );
    }
  } catch (e) {
    console.log(e, "ERROR");
    yield put({ type: "ERROR", payload: e });
  }
}

const addPersonalizedImageTo = (program, currentProfile, comboFingerprint) => {
  let personalizedImageIndex = program.rootId;
  if (
    personalizedImages[currentProfile][personalizedImageIndex] &&
    personalizedImages[currentProfile][personalizedImageIndex][comboFingerprint]
  ) {
    const imageUri = `${SearchConstants.OnConnect.query_uri_image_assets}${personalizedImages[currentProfile][personalizedImageIndex][
      comboFingerprint
    ]
      }`;
    // safe to swap in the personalized image
    program.tms.preferredImage.uri = imageUri;
    //For profile_2, the 'popular' carousel gets 16x9 images.
    if (currentProfile === "profile_2") {
      program.tms.sixteenNineImage.uri = imageUri;
    }
    return program;
  }
  return program;
};

const categoryLevel = category => ({
  tag: category,
  count: "",
  rawCount: "",
  programs: undefined,
  size: 2,
  arrowStyle: UI.arrow.standard
});

const craftModalData = (program, placeholder) => {

  /*
   * HART-3529
   * https://coderwall.com/p/ebqhca/javascript-sort-by-two-fields
   */
  if (_.has(program, "ads.ekp")) {
    /*
     * A zero count cms record will not have an entry in tmstypes.
     */
    program.ads.ekp.forEach(element => {
      element.keywords.sort((a, b) => {
        return b.wt - a.wt || a.tag.localeCompare(b.tag);
      });
    });
  }
  /*
   * Set up a skeleton to support the zero-count record.
   */
  const skeleton = {
    rootId: program.rootId,
    tmsId: program.tmsId,
    imageUri: undefined,
    year: undefined,
    title: undefined,
    description: undefined,
    personalizedImages: undefined,
    videoClips: undefined,
    reasonsToWatch: undefined,
    reasonToWatch: undefined,
    categories: undefined,
    genre: undefined,
    cast: undefined,
    crew: undefined,
    lang: undefined
  };
  if (program.swapItem) {
    skeleton["swapItem"] = program.swapItem;
  }

  let tmsads = {};

  if (_.has(program, "tms") && _.has(program, "ads")) {
    /*
     * This is a fully defined record that has both tms and ads data.
     */
    let backgroundUri = undefined;
    if (_.has(program.tms, "iconicImage.uri")) {
      backgroundUri = program.tms.iconicImage.uri;
    } else {
      backgroundUri = placeholder;
      console.log("ALERT", program.tms.title, "missing iconicImage");
    }

    let landscapeUri = undefined;
    if (_.has(program.tms, "lPreferredImage.uri")) {
      landscapeUri = program.tms.lPreferredImage.uri;
    } else {
      landscapeUri = placeholder;
      console.log("ALERT", program.tms.title, "missing landscapeUri");
    }
    /*
     * These elements are arrays of objects, with not useful keys.
     * Grab only the values.
     */
    const valuesIfPopulated = element =>
      (element && Object.values(element)) || [];
    tmsads = {
      imageUri: program.tms.preferredImage.uri,
      landscapeUri: landscapeUri,
      backgroundUri: backgroundUri,
      landscapeSixNine: program.tms.sixteenNineImage.uri,
      year: program.tms.releaseYear,
      title: program.tms.title,
      description: program.tms.longDescription || program.tms.shortDescription,
      personalizedImages: program.ads.personalizedImages,
      videoClips: program.ads.videoClips,
      reasonsToWatch: program.ads.reasonsToWatch,
      categories: program.ads.ekp.map(ekp => {
        return {
          category: ekp.category,
          keywords: ekp.keywords.map(keyword => {
            return {
              tag: keyword.tag,
              weight: keyword.wt
            };
          })
        };
      }),
      cast: valuesIfPopulated(program.tms.cast),
      crew: valuesIfPopulated(program.tms.crew),
      genre: valuesIfPopulated(program.tms.genre),
      type: program.tmsId.substring(0, 2),
      lang: program.tms.lang
    };

    if (program.ads.reasonsToWatch && program.ads.reasonsToWatch.length) {
      tmsads.reasonToWatch = program.ads.reasonsToWatch.find(
        i => i.highlight === true
      );
    }
  }

  // use business rule sorting of categories and replace current list with the sorted list
  const tmsadsSorted = sortCategories(SORTED_KWCATEGORIES, tmsads);
  tmsads["categories"] = tmsadsSorted;

  /*
   * Merge our skeleton and the more complete (possibly) objects
   */
  const withoutAction = { ...skeleton, ...tmsads };

  return {
    ...withoutAction,
    action: openModal(withoutAction)
  };
};

export function* getComboFingerprint() {
  let flipperKeywords = [];
  let flipperCelebrities = [];

  const selectedFlippers = getFlippersForProfile(
    yield select(getProfile),
    yield select(getTasteProfileData)
  );

  flipperCelebrities = selectedFlippers.celebrities;
  flipperKeywords = selectedFlippers.keywords;

  // to get the current fingerprint, read faces
  const fp_items = flipperKeywords.map(item => {
    const key = item[FACE];
    return item[key];
  });

  const keywordFingerprint = fp_items.sort().join(FPSEP);

  const celeb_fp_items = flipperCelebrities.map(item => {
    const key = item[FACE];
    return item[key];
  });

  const celebFingerprint = celeb_fp_items.sort().join(FPSEP);

  return `${keywordFingerprint}|${celebFingerprint}`;
}

export function* getAdditionalHeroData() {
  /*
 * Generators must yield something.
 */
  yield call(() => { });
  return additionalHeroData;
}

export function* getAdditionalBasedOnData() {
  /*
 * Generators must yield something.
 */
  yield call(() => { });
  return additionalBasedOnData;
}

export function* getAdditionalClipsData() {
  /*
 * Generators must yield something.
 */
  yield call(() => { });
  return additionalClipsData;
}

/* title search saga */
/* watcher */
export function* suggestionRequested() {
  yield takeEvery(SUGGESTION_REQUESTED, searchTitles);
}

export function* searchTitles(action) {
  try {
    const results = yield call(
      API.acexCastCrewTitleSearch,
      action.title,
      action.sortxPop
    );

    // Divvy the results up by language
    const source = results.hits.hits.map(h => {
      let s = h._source || {};
      s.score = h._score || 0;
      if (h.matched_queries) {
        s.matched_queries = h.matched_queries;
      }
      return s;
    });
    const langs = results.langs;

    const map = new Map();
    langs.forEach(l => {
      map.set(l, []);
    });
    source.forEach(s => {
      //Read the lang if present
      if (s.tms.lang && map.get(s.tms.lang)) {
        map.get(s.tms.lang).push(s);
      }
    });

    const divvied = { langs: langs, map: map };
    yield put({
      type: SUGGESTIONS_RECEIVED,
      term: action.title,
      count: source.length,
      rawResults: source,
      cookedResults: divvied
    });
  } catch (e) {
    console.log(e, "ERROR");
    yield put({ type: "ERROR", payload: e });
  }
}

export function* searchResultClickedHandler() {
  yield takeEvery(SEARCH_RESULT_CLICKED, launchModalFromSearchResult);
}

export function* launchModalFromSearchResult(action) {
  try {

    //action.prog.tms.palette = yield call(paletteForProgram, action.prog);
    const results = craftModalData(action.prog);

    // get sorted category and kw data
    const sortedKwCategories = sortCategories(SORTED_KWCATEGORIES, results);

    // replace current data w new data, if any
    results.categories = sortedKwCategories;

    yield put({
      type: OPEN_MODAL,
      selectedContent: results
    });
  } catch (e) {
    console.log(e, "ERROR");
    yield put({ type: "ERROR", payload: e });
  }
}

/**
 * Utility to check if internal user.
 * Aim is to not show Mood Persona to external demo users/customers
 * i.e., !isGoogle(userID) should not see Persona
 * @param {*} uc full user object from userAndCredentials()
 */

const isGoogle = uc => {
  return (
    uc.user.attributes.email.endsWith("nielsen.com") &&
    uc.user.username.startsWith("Google_")
  );
};

const sortCategories = (keywordCategories, results) => {
  /*
   * Sort categories according to current business rules
   * https://jira.gracenote.com/browse/HART-7571
   */

  let sortedKwCategories = new Array(keywordCategories.size);

  results["categories"].forEach(category => {
    const ordinal = keywordCategories.get(category.category);
    sortedKwCategories[ordinal] = category;
  });

  return _.compact(sortedKwCategories);
};
