/**
 * Collect things that are commonly (> than one file) used in search.
 *
 * Index history:
 * tmstypes_10_1 - made from samples/tmstypes.json
 * tmstypes_2957_1 - made from elasticsearch/ces_root_ids_data_formatted.json
 * tmstypes_2967_1 - made from tmstypes.json + ces_root_ids_data_formatted.json - place holder until there are personalized images
 */
const SearchConstants = {
    /*
     * Where we can get the adsindex from, that will tell us our base_url for the api gateway.
     * We need this during the migration from the shared account endpoint to the micro account endpoint
     */
    base_uri: "https://t2thapx8fh.execute-api.us-west-2.amazonaws.com/prod",
    indices: {},
    indexph: "##index##",
    regex_for_child_keywords:
        "/([A-Z1-9][a-\\u00FF.0-9']+){1}([ \\-&]?([A-Za-z1-9][a-\\u00FF.0-9']+){0,1}){0,4}",
    OnConnect: {
        api_key:
            "todo_create_a_.env_file_with_an_api_key_in_it_and_share_over_last_pass_if_we_need_onc_queries.",
        descriptionLang: "en",
        query_uri: "http://data.tmsapi.com/v1.1/movies/",
        query_uri_programs: "http://data.tmsapi.com/v1.1/programs/",
        query_uri_images: "http://data.tmsapi.com/v1.1/programs/",
        query_uri_image_assets: "https://ondemo.tmsimg.com/assets/"
    },
    /*
     * Used in profile.js. Ok that the BOD aren't represented here, as only these three categories,
     * plus celebrity are used in the taste profile. (as of 2019-03-28)
     */
    categories: {
        mood: "mood",
        scenario: "scenario",
        theme: "theme",
        celebrity: "celebrity",
        genre: "genre"
    },
    ES_Mappings: {
        mood: "Video Mood",
        scenario: "Scenario",
        theme: "Theme"
    },
    query_size: 20,
    carousel_query_size: 12,
    child_keywords_query_size: 10000,
    title_search_query_size: 104
};

/*
 * Local constants for the sandbox indices - now not maintained via adsi_adsindices.
 */
const LOC1 = "loc1";
const LOC2 = "loc2";
SearchConstants[LOC1] = {
    categories_index: "adsi_categories_chars_190k_1",
    tmstypes_index: "adsi_tmstype_sandbox_localhost1",
    motivations_index: "adsi_motivations"
};
SearchConstants[LOC2] = {
    categories_index: "adsi_categories_chars_190k_1",
    tmstypes_index: "adsi_tmstype_sandbox_localhost2",
    motivations_index: "adsi_motivations"
};

SearchConstants.indexQuery = () => {
    return `${SearchConstants.base_uri}/adsi_adsindices/adsindex/0/_source`;
};
SearchConstants.gateway = () => {
    return `${SearchConstants.base_uri}/${SearchConstants.indexph}/_search`;
};

// scroll constants
SearchConstants.scroll_interval = "1m";
SearchConstants.scroll_size = "1000";
SearchConstants.gateway_scroll = () => {
    return `${SearchConstants.base_uri}/${
        SearchConstants.indexph
    }/_search?scroll=${SearchConstants.scroll_interval}&size=${
        SearchConstants.scroll_size
    }`;
};
SearchConstants.gateway_scroll_next = () => {
    return `${SearchConstants.base_uri}/_search/scroll`;
};
SearchConstants.category_endpoint = () => {
    let index = "";
    switch (process.env.REACT_APP_INDEX_ENV) {
        case LOC1:
            index = SearchConstants.loc1.categories_index;
            break;
        case LOC2:
            index = SearchConstants.loc2.categories_index;
            break;
        default:
            index =
                SearchConstants.indices[process.env.REACT_APP_INDEX_ENV]
                    .categories_index;
            break;
    }

    return SearchConstants.gateway().replace(SearchConstants.indexph, index);
};
SearchConstants.tmstype_endpoint = () => {
    let index = "";
    switch (process.env.REACT_APP_INDEX_ENV) {
        case LOC1:
            index = SearchConstants.loc1.tmstypes_index;
            break;
        case LOC2:
            index = SearchConstants.loc2.tmstypes_index;
            break;
        default:
            index =
                SearchConstants.indices[process.env.REACT_APP_INDEX_ENV]
                    .tmstypes_index;
            break;
    }

    return SearchConstants.gateway().replace(SearchConstants.indexph, index);
};

SearchConstants.motivation_endpoint = () => {
    let index = "";
    switch (process.env.REACT_APP_INDEX_ENV) {
        case LOC1:
            index = SearchConstants.loc1.motivations_index;
            break;
        case LOC2:
            index = SearchConstants.loc2.motivations_index;
            break;
        default:
            index =
                SearchConstants.indices[process.env.REACT_APP_INDEX_ENV]
                    .motivations_index;
            break;
    }

    return SearchConstants.gateway().replace(SearchConstants.indexph, index);
};

/* the end point for scrolled search - must not have _

  @param: next -- if true we're in a scroll iteration, else we are starting the search
*/
SearchConstants.tmstype_scroll_endpoint = next => {
    let index = "";
    switch (process.env.REACT_APP_INDEX_ENV) {
        case LOC1:
            index = SearchConstants.loc1.tmstypes_index;
            break;
        case LOC2:
            index = SearchConstants.loc2.tmstypes_index;
            break;
        default:
            index =
                SearchConstants.indices[process.env.REACT_APP_INDEX_ENV]
                    .tmstypes_index;
            break;
    }

    if (next) {
        return SearchConstants.gateway_scroll_next().replace(
            SearchConstants.indexph,
            index
        );
    }

    return SearchConstants.gateway_scroll().replace(
        SearchConstants.indexph,
        index
    );
};

/**
 * The GET endpoint for images from the tmstypes_index. The index is set up to use the rootId as the index id: an
 * '_id' from a query against  the tmstypes_ _search endpoint (SearchConstants.tmstype_endpoint) is equivalent to a rootId from any
 * other query.
 */
SearchConstants.images_get_endpoint = id => {
    let index =
        SearchConstants.indices[process.env.REACT_APP_INDEX_ENV].tmstypes_index;
    let type = "tmstype";
    return `${SearchConstants.base_uri}/${index}/${type}/${id}/_source`;
};

SearchConstants.addIndices = indices => {
    SearchConstants.indices = indices;
};
//Seal the object so that fields we do not want are not added.
Object.seal(SearchConstants);

module.exports = SearchConstants;
