import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga";
import { colorForCategory, UI } from "../constants";
import styled from "styled-components";
import { connect } from "react-redux";
import store from "../store";
import { keywordClicked } from "../actions";
import { transparentize } from "polished";

const kwEnabledChooser = props => {
    if (
        props.keywordEnabledCategories &&
        props.keywordEnabledCategories.indexOf(props.category) !== -1
    ) {
        return "pointer";
    } else return "default";
};

const Keyword = styled.div`
    background-color: "#000";
    font-size: calc(10px + (18 - 10) * ((100vw - 300px) / (1600 - 300)));
    font-weight: ${UI.font.weight.regular};
    line-height: 1.5rem;
    padding: ${UI.layout.keywordVPadding} 0.9rem;
    margin: ${UI.layout.keywordVMargin} 0.7rem;
    border-radius: 1.5rem;
    border-color: ${props =>
        transparentize(
            opacityForWeight(props.weight, "border"),
            colorForCategory(props.category)
        )};
    border-style: solid;
    border-width: 0.15rem;

    cursor: ${props => kwEnabledChooser(props)};
    display: inline-block;
    color: ${props =>
        transparentize(
            opacityForWeight(props.weight, "text"),
            colorForCategory(props.category)
        )};
    transition: all 0.2s ease-in-out;
    :hover {
        ${props => {
        const ret = { transform: `scale(${props.scale})` };
        if (props.scale === 1) {
            ret.transform = "none";
        }

        return ret;
    }}
    }
    max-height: 1.6rem;
    white-space: nowrap;
`;

/**
 * The weights
 */
export const WT_KWG = -1;
const WT_MAX = 9;
const WT_MID = 7;
const WT_MIN = 5;
const VALID_WEIGHTS = [WT_MAX, WT_MID, WT_MIN, WT_KWG];
/**
 * Their opacity deltas from 1.0, to be used as arguments to transparentize.
 * @see https://polished.js.org/docs/#transparentize
 */

// const OPACITY_WT_MAX = 1.0;
// const OPACITY_WT_MID = 0.67;
// const OPACITY_WT_MIN = 0.45;
/*
 * Values for background, text, and border are subtracted from 1
 * by the 'transparentize()' function. (https://polished.js.org/docs/#transparentize)
 * e.g, if the background color is rgba(255,255,255, 1.0), passing 0.88 to transparentize
 * results in rgba(255,255,255, 0.12).
 * 0.33 results in an alpha of 0.67
 * 0.55 nets 0.45
 * and so on.
 */
const OPACITY_WT_KWG = { background: 0.88, text: 0, border: 0 };
const OPACITY_WT_MAX = { background: 0.88, text: 0, border: 0 };
const OPACITY_WT_MID = { background: 0.88, text: 0.4, border: 0.4 };
const OPACITY_WT_MIN = { background: 0.88, text: 0.7, border: 0.7 };
/**
 * Get the opacity that matches weight.
 * @param {int} weight return the opacity for the weight, which must be defined and a member of VALID_WEIGHTS
 */
const opacityForWeight = (weight, element) => {
    if (!weight || !VALID_WEIGHTS.includes(weight))
        return OPACITY_WT_MAX[element];

    switch (weight) {
        case WT_KWG:
            return OPACITY_WT_KWG[element];
        case WT_MIN:
            return OPACITY_WT_MIN[element];
        case WT_MID:
            return OPACITY_WT_MID[element];
        default:
            return OPACITY_WT_MAX[element];
    }
};

class KeywordItem extends Component {
    handleClick = () => {
        if (process.env.REACT_APP_GTAG) {
            ReactGA.event({
                category: "Keywords",
                action: "Keyword Clicked"
            });
        }

        store.dispatch(keywordClicked(this.props.tag, this.props.category));
    };

    render() {
        const {
            category,
            tag,
            weight,
            count,
            indexForColor,
            keywordEnabledCategories,
            value
        } = this.props;

        let countString = "";
        if (count || count === 0) {
            countString = ` (${count})`;
        }

        /*
         * If the visible prop exists (we came from the modal) and is true, set invisible so it can
         * be animated to visible.
         *
         * If not defined (kw graph), just set visible.
         */
        let className = "catkwvisible";
        if (undefined !== this.props.visible) {
            className = "catkwinvisible";
            if (this.props.visible) {
                className = `${className} catkwvisible`;
            }
        }

        /*
         * if this class is passed, add it doubled to make it be more specific than the intrinsic class that styled components adds.
         * See 'Issues with Specificity,' here: https://styled-components.com/docs/advanced#existing-css
         */
        if (this.props.increaseSpecificity) {
            className = `${this.props.increaseSpecificity} ${this.props.increaseSpecificity} ${className}`;
        }

        return (
            <Keyword
                className={`${className}`}
                onClick={
                    keywordEnabledCategories.indexOf(this.props.category) !== -1
                        ? this.handleClick
                        : null
                }
                scale={
                    keywordEnabledCategories.indexOf(this.props.category) !== -1
                        ? 1.1
                        : 1
                }
                keywordEnabledCategories={keywordEnabledCategories}
                category={category}
                weight={weight}
                indexForColor={indexForColor}>
                {/* style={{ borderColor: `${colorForCategory(category, indexForColor) || "white"}`, color: `${colorForCategory(category, indexForColor) || "white"}` }}
            > */}
                {tag}
                <span style={{ marginLeft: "1rem" }}>{countString}</span>
                {value}
            </Keyword>
        );
    }
}

function mapStateToProps(state) {
    return {
        keywordEnabledCategories: state.keywordEnabledCategories
    };
}
export default connect(mapStateToProps)(KeywordItem);

KeywordItem.propTypes = {
    category: PropTypes.string.isRequired,
    tag: PropTypes.string.isRequired,
    weight: PropTypes.number,
    count: PropTypes.number,
    keywordEnabledCategories: PropTypes.array
};
