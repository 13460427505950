export default {
    "Connection|Dilemma|Love Story|Romantic|Temptation|Tense": [
        0,
        31,
        17,
        37,
        10
    ],
    "Connection|Love Story|Romantic|Serendipity|Temptation|Tense": [
        0,
        33,
        29,
        17,
        10
    ],
    "Connection|Dilemma|Misadventures|Romantic|Temptation|Tense": [
        0,
        10,
        48,
        29,
        17
    ],
    "Connection|Misadventures|Romantic|Serendipity|Temptation|Tense": [
        29,
        10,
        84,
        17,
        0
    ],
    "Challenge|Dilemma|Love Story|Romantic|Temptation|Tense": [
        0,
        31,
        37,
        10,
        51
    ],
    "Challenge|Love Story|Romantic|Serendipity|Temptation|Tense": [
        10,
        51,
        94,
        17,
        29
    ],
    "Challenge|Dilemma|Misadventures|Romantic|Temptation|Tense": [
        10,
        0,
        31,
        37,
        51
    ],
    "Challenge|Misadventures|Romantic|Serendipity|Temptation|Tense": [
        10,
        84,
        29,
        19,
        51
    ],
    "Connection|Dilemma|Dysfunction|Love Story|Romantic|Tense": [
        0,
        37,
        41,
        42,
        48
    ],
    "Connection|Dysfunction|Love Story|Romantic|Serendipity|Tense": [
        33,
        41,
        94,
        17,
        29
    ],
    "Connection|Dilemma|Dysfunction|Misadventures|Romantic|Tense": [
        48,
        0,
        12,
        10,
        37
    ],
    "Connection|Dysfunction|Misadventures|Romantic|Serendipity|Tense": [
        84,
        29,
        12,
        33,
        10
    ],
    "Challenge|Dilemma|Dysfunction|Love Story|Romantic|Tense": [
        37,
        42,
        0,
        31,
        10
    ],
    "Challenge|Dysfunction|Love Story|Romantic|Serendipity|Tense": [
        94,
        33,
        10,
        37,
        26
    ],
    "Challenge|Dilemma|Dysfunction|Misadventures|Romantic|Tense": [
        10,
        37,
        26,
        30,
        42
    ],
    "Challenge|Dysfunction|Misadventures|Romantic|Serendipity|Tense": [
        84,
        10,
        26,
        19,
        94
    ],
    "Connection|Dilemma|Humorous|Love Story|Romantic|Temptation": [
        0,
        31,
        37,
        42,
        10
    ],
    "Connection|Humorous|Love Story|Romantic|Serendipity|Temptation": [
        33,
        10,
        0,
        94,
        31
    ],
    "Connection|Dilemma|Humorous|Misadventures|Romantic|Temptation": [
        10,
        0,
        6,
        31,
        37
    ],
    "Connection|Humorous|Misadventures|Romantic|Serendipity|Temptation": [
        10,
        84,
        6,
        33,
        29
    ],
    "Challenge|Dilemma|Humorous|Love Story|Romantic|Temptation": [
        31,
        37,
        42,
        10,
        0
    ],
    "Challenge|Humorous|Love Story|Romantic|Serendipity|Temptation": [
        10,
        94,
        33,
        31,
        37
    ],
    "Challenge|Dilemma|Humorous|Misadventures|Romantic|Temptation": [
        10,
        31,
        37,
        42,
        84
    ],
    "Challenge|Humorous|Misadventures|Romantic|Serendipity|Temptation": [
        10,
        84,
        19,
        94,
        29
    ],
    "Connection|Dilemma|Dysfunction|Humorous|Love Story|Romantic": [
        37,
        42,
        0,
        31,
        33
    ],
    "Connection|Dysfunction|Humorous|Love Story|Romantic|Serendipity": [
        33,
        94,
        37,
        10,
        41
    ],
    "Connection|Dilemma|Dysfunction|Humorous|Misadventures|Romantic": [
        12,
        6,
        37,
        10,
        42
    ],
    "Connection|Dysfunction|Humorous|Misadventures|Romantic|Serendipity": [
        84,
        12,
        6,
        33,
        10
    ],
    "Challenge|Dilemma|Dysfunction|Humorous|Love Story|Romantic": [
        37,
        42,
        31,
        10,
        94
    ],
    "Challenge|Dysfunction|Humorous|Love Story|Romantic|Serendipity": [
        94,
        33,
        37,
        10,
        42
    ],
    "Challenge|Dilemma|Dysfunction|Humorous|Misadventures|Romantic": [
        37,
        10,
        42,
        84,
        12
    ],
    "Challenge|Dysfunction|Humorous|Misadventures|Romantic|Serendipity": [
        84,
        10,
        19,
        94,
        12
    ],
    "Connection|Dilemma|Emotional|Love Story|Temptation|Tense": [
        0,
        51,
        82,
        31,
        17
    ],
    "Connection|Emotional|Love Story|Serendipity|Temptation|Tense": [
        51,
        82,
        0,
        17,
        29
    ],
    "Connection|Dilemma|Emotional|Misadventures|Temptation|Tense": [
        0,
        51,
        48,
        17,
        29
    ],
    "Connection|Emotional|Misadventures|Serendipity|Temptation|Tense": [
        29,
        51,
        34,
        84,
        17
    ],
    "Challenge|Dilemma|Emotional|Love Story|Temptation|Tense": [
        51,
        0,
        31,
        37,
        42
    ],
    "Challenge|Emotional|Love Story|Serendipity|Temptation|Tense": [
        51,
        94,
        29,
        17,
        27
    ],
    "Challenge|Dilemma|Emotional|Misadventures|Temptation|Tense": [
        51,
        0,
        27,
        31,
        10
    ],
    "Challenge|Emotional|Misadventures|Serendipity|Temptation|Tense": [
        51,
        84,
        29,
        27,
        10
    ],
    "Connection|Dilemma|Dysfunction|Emotional|Love Story|Tense": [
        82,
        0,
        37,
        41,
        51
    ],
    "Connection|Dysfunction|Emotional|Love Story|Serendipity|Tense": [
        82,
        41,
        51,
        34,
        94
    ],
    "Connection|Dilemma|Dysfunction|Emotional|Misadventures|Tense": [
        48,
        0,
        12,
        27,
        82
    ],
    "Connection|Dysfunction|Emotional|Misadventures|Serendipity|Tense": [
        34,
        84,
        29,
        27,
        12
    ],
    "Challenge|Dilemma|Dysfunction|Emotional|Love Story|Tense": [
        37,
        51,
        42,
        0,
        27
    ],
    "Challenge|Dysfunction|Emotional|Love Story|Serendipity|Tense": [
        51,
        94,
        27,
        15,
        82
    ],
    "Challenge|Dilemma|Dysfunction|Emotional|Misadventures|Tense": [
        27,
        37,
        26,
        21,
        51
    ],
    "Challenge|Dysfunction|Emotional|Misadventures|Serendipity|Tense": [
        84,
        27,
        86,
        26,
        51
    ],
    "Connection|Dilemma|Emotional|Humorous|Love Story|Temptation": [
        0,
        31,
        37,
        42,
        82
    ],
    "Connection|Emotional|Humorous|Love Story|Serendipity|Temptation": [
        82,
        0,
        94,
        33,
        31
    ],
    "Connection|Dilemma|Emotional|Humorous|Misadventures|Temptation": [
        0,
        6,
        31,
        37,
        10
    ],
    "Connection|Emotional|Humorous|Misadventures|Serendipity|Temptation": [
        84,
        6,
        29,
        10,
        19
    ],
    "Challenge|Dilemma|Emotional|Humorous|Love Story|Temptation": [
        31,
        37,
        42,
        0,
        10
    ],
    "Challenge|Emotional|Humorous|Love Story|Serendipity|Temptation": [
        94,
        31,
        37,
        10,
        51
    ],
    "Challenge|Dilemma|Emotional|Humorous|Misadventures|Temptation": [
        31,
        37,
        10,
        42,
        84
    ],
    "Challenge|Emotional|Humorous|Misadventures|Serendipity|Temptation": [
        84,
        10,
        19,
        86,
        94
    ],
    "Connection|Dilemma|Dysfunction|Emotional|Humorous|Love Story": [
        37,
        42,
        82,
        0,
        31
    ],
    "Connection|Dysfunction|Emotional|Humorous|Love Story|Serendipity": [
        82,
        94,
        33,
        37,
        41
    ],
    "Connection|Dilemma|Dysfunction|Emotional|Humorous|Misadventures": [
        12,
        6,
        37,
        42,
        84
    ],
    "Connection|Dysfunction|Emotional|Humorous|Misadventures|Serendipity": [
        84,
        12,
        6,
        19,
        86
    ],
    "Challenge|Dilemma|Dysfunction|Emotional|Humorous|Love Story": [
        37,
        42,
        31,
        94,
        0
    ],
    "Challenge|Dysfunction|Emotional|Humorous|Love Story|Serendipity": [
        94,
        37,
        42,
        86,
        84
    ],
    "Challenge|Dilemma|Dysfunction|Emotional|Humorous|Misadventures": [
        37,
        42,
        84,
        12,
        27
    ],
    "Challenge|Dysfunction|Emotional|Humorous|Misadventures|Serendipity": [
        84,
        19,
        86,
        23,
        94
    ]
};
