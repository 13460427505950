import { connect } from "react-redux";
import Mood from "./Mood";

const mapStateToProps = state => ({
    lang: state.lang,
    moodHomeSwitch: state.moodHomeSwitch,
    moodHomeShowSVG: state.moodHomeShowSVG,
    profile: state.profile,
    vmHomeDisplayMap: state.vmHomeDisplayMap
});

export default connect(mapStateToProps)(Mood);
