/**
 * The carousel clusters repeat themselves: collect them all here.
 */
export default [
  {
    category: "Determination in the face of adversity",
    cluster_number: 38,
    rmovies: [
      162826,
      15912804,
      16522218,
      185369,
      32735,
      29040,
      159408,
      12417188,
      13762579,
      13070002,
      11491087,
      8780438,
      9960168,
      8400760,
      15100879,
      35517,
      12764503,
      25745,
      11822480,
      89606,
      176796,
      172418,
      9651,
      8780620,
      8559465,
      165095,
      14552733,
      5041,
      13987581,
      173597,
      15339075,
      9915832
    ],
    movies: [
      "MV001802980000",
      "MV011907390000",
      "MV012306820000",
      "SH007973540000",
      "MV001390730000",
      "MV001166840000",
      "MV001715160000",
      "MV008353240000",
      "SH026116200000",
      "MV009101350000",
      "MV007175940000",
      "MV003681120000",
      "SH017355340000",
      "MV003411950000",
      "MV010822070000",
      "MV001550850000",
      "MV008768210000",
      "MV000932130000",
      "MV007656670000",
      "MV001622900000",
      "MV002153880000",
      "MV002048490000",
      "MV000214200000",
      "MV003681280000",
      "MV003516220000",
      "MV001913830000",
      "MV010377480000",
      "MV000086760000",
      "SH026559100000",
      "MV002084350000",
      "MV011024970000",
      "MV004798490000"
    ]
  },
  {
    category: "Tales of the unique",
    cluster_number: 56,
    rmovies: [
      13099464,
      14086,
      14264,
      32087,
      159408,
      12239793,
      161258,
      187477,
      162027,
      10549200,
      9889620,
      13445081,
      13061725,
      11367422,
      7854243,
      20882,
      13812616,
      8725825,
      14614669
    ],
    movies: [
      "SH024750580000",
      "MV000352380000",
      "MV000356820000",
      "MV001357600000",
      "MV001715160000",
      "MV008155820000",
      "MV001750910000",
      "SH008298780000",
      "MV001789990000",
      "MV005496980000",
      "MV004758050000",
      "MV010921780000",
      "MV009093470000",
      "MV006937080000",
      "SH011902590000",
      "MV000581010000",
      "SH026209060000",
      "MV003644400000",
      "MV010425470000"
    ]
  },
  {
    category: "Thrilling tales",
    cluster_number: 73,
    rmovies: [
      9155926,
      12902260,
      9259486,
      184746,
      182355,
      15855,
      29811,
      10854488,
      166035,
      159167,
      7719,
      8078013,
      28900,
      9364987,
      15446220,
      187411,
      17455,
      13949767,
      176365
    ],
    movies: [
      "SH015468900000",
      "SH024319180000",
      "MV003986710000",
      "SH004466040000",
      "MV002273100000",
      "MV000399750000",
      "MV001214320000",
      "MV005926760000",
      "MV001935860000",
      "MV001815450000",
      "MV000158920000",
      "MV002784330000",
      "MV001158300000",
      "MV004067230000",
      "MV011111510000",
      "MV002324580000",
      "MV000442430000",
      "MV009914330000",
      "MV002140200000"
    ]
  },
  {
    category: "Adventure Stories about Determination",
    cluster_number: 1001,
    rmovies: [
      184930,
      13592228,
      12633057,
      15686643,
      14008893,
      194893,
      17472277,
      534172,
      11546269,
      34483,
      166014,
      11642,
      11598208,
      26553,
      35100,
      12806300,
      10089022,
      8493689,
      19410,
      18225470
    ],
    movies: [
      "SH006819110000",
      "SH025761330000",
      "SH023731150000",
      "SH030017970000",
      "SH026601320000",
      "SH007749510000",
      "SH033346760000",
      "SH004638260000",
      "SH022166120000",
      "MV001485760000",
      "MV001969710000",
      "MV000271070000",
      "MV007328130000",
      "MV001000000000",
      "MV001522590000",
      "MV008813890000",
      "SH017677440000",
      "MV003471370000",
      "MV000507660000",
      "SH034952230000"
    ]
  },
  {
    category: "Adventure Stories about Heroism",
    cluster_number: 1003,
    rmovies: [
      20933661,
      17013073,
      17560244,
      18644345,
      19458781,
      19651051,
      13132769,
      183885,
      9263605,
      18320269,
      186516,
      10701949,
      9975633,
      12051214,
      186173,
      16808607,
      3526335,
      12900265,
      12402331,
      20342394
    ],
    movies: [
      "MV017467610000",
      "MV012688620000",
      "SH033537420000",
      "MV014390330000",
      "MV015863660000",
      "MV016070910000",
      "SH024821630000",
      "SH000039850000",
      "SH015693720000",
      "SH035160140000",
      "SH004563460000",
      "SH019059960000",
      "SH017392440000",
      "SH022504080000",
      "SH005086070000",
      "MV012506460000",
      "SH011491580000",
      "SH024314500000",
      "MV008339030000",
      "SH039354460000"
    ]
  },
  {
    category: "Defying odds and expectations",
    cluster_number: 24,
    rmovies: [
      9360990,
      16848332,
      8137,
      8395508,
      32086,
      12865299,
      173591,
      15066,
      19676,
      10570028,
      10863,
      8830065,
      9632096,
      191307,
      14283671
    ],
    movies: [
      "MV004061220000",
      "MV012533020000",
      "MV000171510000",
      "MV003408450000",
      "MV001357590000",
      "MV008887720000",
      "MV002084110000",
      "MV000375460000",
      "MV000514390000",
      "MV005522680000",
      "MV000251320000",
      "MV003716390000",
      "MV004455360000",
      "MV002372640000",
      "SH027243580000"
    ]
  },
  {
    category: "Reality Shows about Determination",
    cluster_number: 1013,
    rmovies: [
      12269476,
      10402125,
      18225470,
      17087446,
      18098908,
      8300528,
      12282782,
      185354,
      14927995,
      9388134,
      218841,
      12382662,
      246816,
      8967421,
      13894243,
      20223382,
      18103951,
      22145275,
      8342377,
      9902771
    ],
    movies: [
      "SH022952530000",
      "SH018402520000",
      "SH034952230000",
      "SH032597540000",
      "SH034669440000",
      "SH013286140000",
      "SH022979170000",
      "SH007331290000",
      "SH028412480000",
      "SH015972810000",
      "SH010549430000",
      "SH023188980000",
      "SH007670240000",
      "SH015048820000",
      "SH026362290000",
      "SH039161510000",
      "SH034680720000",
      "SH042993150000",
      "SH013384340000",
      "SH017219650000"
    ]
  },
  {
    category: "Reality Shows about Heroism",
    cluster_number: 1015,
    rmovies: [
      16693870,
      15729006,
      12591909,
      16932346,
      20838833,
      15744736,
      15498311,
      8459881,
      18298669,
      11053096,
      11636827,
      17568409,
      13708685,
      12761648,
      15617243,
      15440899,
      14279813,
      21645321,
      11532432,
      10848662
    ],
    movies: [
      "SH031830580000",
      "SH030096330000",
      "SH023645620000",
      "SH032276840000",
      "SH040570540000",
      "SH030129460000",
      "SH029653060000",
      "SH013680170000",
      "SH035113500000",
      "SH019931570000",
      "SH021551140000",
      "SH033547330000",
      "SH026028810000",
      "SH024009360000",
      "SH029876060000",
      "SH029538880000",
      "SH027234330000",
      "SH042029680000",
      "SH021320310000",
      "SH019399130000"
    ]
  },
  {
    category: "Heroes in action",
    cluster_number: 57,
    rmovies: [
      13132769,
      10951814,
      15854476,
      172364,
      492370,
      21028,
      183985,
      10255,
      9259486,
      35903,
      29821,
      183970,
      7989358,
      19526,
      7876199,
      12568,
      22650,
      8044701,
      89666
    ],
    movies: [
      "SH024821630000",
      "MV006074350000",
      "SH030348460000",
      "MV002045720000",
      "SH001160730000",
      "MV000587600000",
      "SH001271960000",
      "MV000231260000",
      "MV003986710000",
      "MV001580660000",
      "MV001214800000",
      "SH000038500000",
      "MV002725490000",
      "MV000510190000",
      "MV002659500000",
      "MV000316640000",
      "MV000709670000",
      "MV002761960000",
      "MV001623950000"
    ]
  },
  {
    category: "All-consuming passions",
    cluster_number: 59,
    rmovies: [
      16058879,
      8417791,
      16215863,
      11076479,
      15533079,
      3496658,
      15276,
      1783,
      10488558,
      29400,
      10569276,
      11849910,
      12926840,
      10750661,
      7997070,
      189892,
      7996887
    ],
    movies: [
      "MV011969730000",
      "MV003424420000",
      "MV012068390000",
      "MV006284690000",
      "MV011188280000",
      "SH011413890000",
      "MV000380920000",
      "MV000028820000",
      "MV005440720000",
      "MV001189600000",
      "MV005521350000",
      "MV007688230000",
      "SH024371720000",
      "MV005715140000",
      "MV002730620000",
      "MV002358330000",
      "MV002730560000"
    ]
  },
  {
    category: "Great Escapes",
    cluster_number: 83,
    rmovies: [
      11714985,
      22072,
      350,
      15659526,
      7938924,
      11659455,
      2520,
      4866,
      10837,
      15528384,
      3150,
      8344052,
      7916655,
      10944,
      14080689,
      11431313
    ],
    movies: [
      "MV007508520000",
      "MV000653830000",
      "MV000005690000",
      "MV011692860000",
      "MV002694230000",
      "MV007435740000",
      "MV000040990000",
      "MV000083390000",
      "MV000250570000",
      "MV011181120000",
      "MV000052150000",
      "MV003370610000",
      "MV002681100000",
      "MV000253300000",
      "MV010011190000",
      "MV007072050000"
    ]
  },
  {
    category: "Stories of Corruption",
    cluster_number: 62,
    rmovies: [
      15695777,
      14874555,
      16888110,
      16247537,
      9750036,
      12447110,
      14888207,
      15455,
      16693703,
      14422857,
      8563478,
      14414574,
      159678,
      15300134,
      11842649,
      9714467,
      35710,
      164405,
      43123,
      11897076,
      15966
    ],
    movies: [
      "SH030034260000",
      "SH028324070000",
      "MV012560740000",
      "SH030973940000",
      "SH016901190000",
      "MV008378230000",
      "SH028348770000",
      "MV000385830000",
      "MV012431560000",
      "SH027522730000",
      "SH013918690000",
      "MV010269460000",
      "MV001776650000",
      "MV010987520000",
      "MV007680360000",
      "MV004567880000",
      "MV001563470000",
      "MV001917890000",
      "MV000137230000",
      "MV007749660000",
      "MV000402850000"
    ]
  },
  {
    category: "Strength in the Face of Adversity",
    cluster_number: 85,
    rmovies: [
      8000042,
      16296544,
      14097537,
      14487471,
      16887,
      17310154,
      15339539,
      185354,
      13153578,
      19079,
      176340,
      2571,
      194534,
      13712899,
      13685648,
      9223724,
      8786394,
      8331813,
      175792,
      12443754
    ],
    movies: [
      "SH012322610000",
      "SH031073920000",
      "SH026834570000",
      "SH027658130000",
      "MV000426860000",
      "MV013094830000",
      "MV011025020000",
      "SH007331290000",
      "MV009187970000",
      "MV000497980000",
      "MV002139660000",
      "MV000041930000",
      "MV002428520000",
      "MV009729370000",
      "MV009712250000",
      "MV003962720000",
      "MV003685610000",
      "MV003555010000",
      "MV002125670000",
      "MV008375300000"
    ]
  },
  {
    category: "Saving the day by banding together",
    cluster_number: 98,
    rmovies: [
      11890927,
      11714883,
      481069,
      8949678,
      11742444,
      14738472,
      16163002,
      11779285,
      337789,
      12543972,
      12658931,
      18207,
      159729,
      172364,
      11714869,
      19491,
      14999845,
      13446354,
      12402331,
      7995627
    ],
    movies: [
      "MV007739790000",
      "MV007508410000",
      "SH001512240000",
      "SH015007490000",
      "SH021775920000",
      "MV010529320000",
      "SH030804520000",
      "SH021854510000",
      "SH001135130000",
      "MV008489260000",
      "MV008641510000",
      "MV000470290000",
      "MV001912120000",
      "MV002045720000",
      "MV007508350000",
      "MV000509120000",
      "MV010740180000",
      "MV009458440000",
      "MV008339030000",
      "MV002729890000"
    ]
  },
  {
    category: "Inspiring Reality Shows",
    cluster_number: 1016,
    rmovies: [
      13737255,
      3594600,
      19244583,
      17644076,
      12564950,
      10828374,
      16990773,
      11343925,
      7868708,
      18310089,
      17117934,
      13477085,
      10534807,
      11832375,
      19211969,
      18635114,
      14333123,
      18568753,
      15923452,
      13580180
    ],
    movies: [
      "SH026069290000",
      "SH005520800000",
      "SH037201050000",
      "SH033719770000",
      "SH023591200000",
      "SH019349780000",
      "SH032394280000",
      "SH020815840000",
      "SH011934460000",
      "SH035138820000",
      "SH032657360000",
      "SH025504070000",
      "SH018739050000",
      "SH021982500000",
      "SH037123390000",
      "SH035814870000",
      "SH027343120000",
      "SH035649730000",
      "SH030492490000",
      "SH025733670000"
    ]
  },
  {
    category: "Adventure Stories involving Personal Bonds",
    cluster_number: 1007,
    rmovies: [
      17560244,
      16091714,
      19458781,
      10376284,
      19549414,
      184930,
      10077400,
      186516,
      17553783,
      15528151,
      9471669,
      15686643,
      10701949,
      9975633,
      20538457,
      183919,
      188109,
      16135241,
      12402331,
      8648428
    ],
    movies: [
      "SH033537420000",
      "SH030692400000",
      "MV015863660000",
      "SH018336730000",
      "MV015958870000",
      "SH006819110000",
      "SH017649160000",
      "SH004563460000",
      "SH033522080000",
      "SH029715370000",
      "SH016164320000",
      "SH030017970000",
      "SH019059960000",
      "SH017392440000",
      "SH039770350000",
      "SH001273610000",
      "SH004468530000",
      "SH030762300000",
      "MV008339030000",
      "SH014122930000"
    ]
  },
  {
    category: "Inspiring Adventure Stories",
    cluster_number: 1004,
    rmovies: [
      9247,
      12628458,
      31890,
      14648331,
      16990773,
      259102,
      13742657,
      166757,
      16926237,
      15484124,
      13044225,
      9074748,
      3513,
      502708,
      16943353,
      13314422,
      8609785,
      11431313,
      9026955,
      170804
    ],
    movies: [
      "MV000203070000",
      "MV008600760000",
      "MV001345520000",
      "SH027960980000",
      "SH032394280000",
      "SH010557060000",
      "MV009753080000",
      "MV001909280000",
      "MV012575960000",
      "MV011144950000",
      "MV009072770000",
      "SH015428780000",
      "MV000058920000",
      "SH000702070000",
      "MV012588970000",
      "SH025170430000",
      "MV003548940000",
      "MV007072050000",
      "MV003845010000",
      "MV001996900000"
    ]
  },
  {
    category: "Adventure Stories involving A Personal Challenge",
    cluster_number: 1008,
    rmovies: [
      19688247,
      7931557,
      3542039,
      184732,
      18952562,
      18225470,
      3553501,
      12329215,
      13805148,
      27401,
      8989001,
      21056863,
      10569669,
      10019771,
      4809,
      11132479,
      184317,
      7849359,
      18126051,
      27970
    ],
    movies: [
      "MV016115890000",
      "SH012100200000",
      "MV002523450000",
      "SH004621440000",
      "MV015321560000",
      "SH034952230000",
      "MV002536070000",
      "MV008243920000",
      "MV009798100000",
      "MV001057000000",
      "SH015103710000",
      "SH041016830000",
      "MV005521900000",
      "SH017512410000",
      "MV000082490000",
      "MV006404810000",
      "SH002041460000",
      "SH011889340000",
      "SH034729250000",
      "MV001094620000"
    ]
  },
  {
    category: "Adventure Stories involving A Life Story",
    cluster_number: 1005,
    rmovies: [
      19994163,
      18320269,
      19396751,
      13351521,
      404181,
      18952562,
      9155926,
      18225470,
      13446354,
      161392,
      464282,
      33171,
      10975296,
      10777402,
      21647260,
      9548118,
      15398283,
      8127826,
      9418164,
      8527494
    ],
    movies: [
      "MV016449330000",
      "SH035160140000",
      "SH037546310000",
      "SH025252130000",
      "SH000175310000",
      "MV015321560000",
      "SH015468900000",
      "SH034952230000",
      "MV009458440000",
      "MV001818990000",
      "SH001728000000",
      "MV001413320000",
      "MV006106330000",
      "SH019218120000",
      "SH042032670000",
      "SH016361010000",
      "MV011077850000",
      "SH012793320000",
      "MV004125490000",
      "SH013842460000"
    ]
  },
  {
    category: "Reality Shows about Uplift",
    cluster_number: 1023,
    rmovies: [
      8295433,
      185738,
      3560383,
      8692405,
      9928145,
      16488525,
      9354925,
      185405,
      18644296,
      16692499,
      9806566,
      10819476,
      8422693,
      8531792,
      12517574,
      16853592,
      13737255,
      17776014,
      9045265,
      17951306
    ],
    movies: [
      "SH013271680000",
      "SH009675940000",
      "SH011581290000",
      "SH014228840000",
      "SH017277730000",
      "SH031465690000",
      "SH015899250000",
      "SH003267330000",
      "SH035840910000",
      "SH031827570000",
      "SH017031950000",
      "SH019326930000",
      "SH013588100000",
      "SH013851310000",
      "SH023504120000",
      "SH032155930000",
      "SH026069290000",
      "SH034059150000",
      "SH015237480000",
      "SH034394540000"
    ]
  },
  {
    category: "Reality Shows involving A Life Story",
    cluster_number: 1017,
    rmovies: [
      185738,
      18737696,
      185445,
      10835608,
      7923974,
      8835152,
      15575726,
      9656862,
      22288074,
      12287675,
      12602931,
      17508074,
      8531792,
      8261699,
      16793734,
      13737255,
      15180512,
      14092191,
      10379417,
      18225470
    ],
    movies: [
      "SH009675940000",
      "SH036047720000",
      "SH008151260000",
      "SH019366780000",
      "SH012079990000",
      "SH014734600000",
      "SH029802830000",
      "SH016660830000",
      "SH043280160000",
      "SH022988480000",
      "SH023667760000",
      "SH033419330000",
      "SH013851310000",
      "SH013187710000",
      "SH032021400000",
      "SH026069290000",
      "SH029037750000",
      "SH026823800000",
      "SH018343350000",
      "SH034952230000"
    ]
  },
  {
    category: "Bonding Emotionally",
    cluster_number: 23,
    rmovies: [
      16639970,
      16668307,
      11822480,
      185547,
      8541290,
      182388,
      8718307,
      193073,
      11520890,
      161258,
      9637175,
      3526636,
      10458811,
      8506961,
      10534345,
      192160,
      9970012,
      176338,
      7859
    ],
    movies: [
      "SH031726760000",
      "SH031775600000",
      "MV007656670000",
      "SH009309710000",
      "SH013870640000",
      "MV002274220000",
      "MV003636140000",
      "MV002400950000",
      "MV007208910000",
      "MV001750910000",
      "MV004463350000",
      "MV002503810000",
      "MV005407780000",
      "MV003480090000",
      "MV005488150000",
      "MV002385260000",
      "MV004858870000",
      "MV002139630000",
      "MV000163580000"
    ]
  },
  {
    category: "Resilience against adversity",
    cluster_number: 3,
    rmovies: [
      194893,
      13257200,
      2571,
      8400760,
      11006033,
      246816,
      11003170,
      11377318,
      13231,
      10172717,
      11132479,
      12484632,
      21441,
      9378681,
      9585817,
      5463,
      35756,
      13994715,
      12820555,
      10193694,
      10572338
    ],
    movies: [
      "SH007749510000",
      "SH025064290000",
      "MV000041930000",
      "MV003411950000",
      "MV006165250000",
      "SH007670240000",
      "MV006159440000",
      "MV006957960000",
      "MV000334070000",
      "MV005084040000",
      "MV006404810000",
      "MV008426320000",
      "MV000614850000",
      "MV004083320000",
      "MV004382190000",
      "MV000096170000",
      "MV001568290000",
      "MV009950090000",
      "MV008834010000",
      "MV005111040000",
      "MV005525470000"
    ]
  },
  {
    category: "Adventure Stories involving Going Against The Odds",
    cluster_number: 1000,
    rmovies: [
      194893,
      17472277,
      317056,
      9247,
      90599,
      7989909,
      12656320,
      12628458,
      11993845,
      14595356,
      175618,
      8847447,
      19527,
      8740063,
      10106164,
      8846,
      20518257,
      27593,
      192803,
      11569871
    ],
    movies: [
      "SH007749510000",
      "SH033346760000",
      "SH008570330000",
      "MV000203070000",
      "MV001652800000",
      "SH012295900000",
      "SH023789750000",
      "MV008600760000",
      "MV007877870000",
      "MV010413060000",
      "MV002118720000",
      "SH014763920000",
      "MV000510220000",
      "MV003654150000",
      "MV005011860000",
      "MV000190940000",
      "SH039718740000",
      "MV001069490000",
      "SH003709590000",
      "SH023732560000"
    ]
  },
  {
    category: "Bonding Through Dreams",
    cluster_number: 96,
    rmovies: [
      13901020,
      16950669,
      184911,
      7913853,
      8131216,
      13711757,
      9576,
      8554431,
      165058,
      9672390,
      10190255,
      8240780,
      36055,
      176362,
      12735934,
      12432741,
      184330,
      18517,
      10367270
    ],
    movies: [
      "SH026378920000",
      "MV012598490000",
      "SH005967020000",
      "SH012049480000",
      "MV002824300000",
      "SH026034670000",
      "MV000212270000",
      "MV003512660000",
      "MV001899890000",
      "MV004509790000",
      "MV005106510000",
      "MV003299520000",
      "MV001594350000",
      "MV002140180000",
      "MV008736210000",
      "MV008366450000",
      "SH000025440000",
      "MV000479990000",
      "MV005311400000"
    ]
  },
  {
    category: "Bittersweet Growth",
    cluster_number: 91,
    rmovies: [
      16681652,
      14418822,
      183918,
      10873,
      33568,
      162365,
      32966,
      10742239,
      3576736,
      547,
      33409,
      14173057,
      19182,
      14426291,
      10372574,
      8701268,
      8797072
    ],
    movies: [
      "MV012422780000",
      "SH027514560000",
      "SH001138850000",
      "MV000251560000",
      "MV001433300000",
      "MV001798210000",
      "MV001403810000",
      "MV005705360000",
      "SH011625740000",
      "MV000008630000",
      "MV001426420000",
      "MV010078160000",
      "MV000500940000",
      "MV010278590000",
      "MV005317390000",
      "MV003620400000",
      "MV003695290000"
    ]
  },
  {
    category: "Living with regrets",
    cluster_number: 20,
    rmovies: [
      10823479,
      17031866,
      9286982,
      15309450,
      10551643,
      10991238,
      13125225,
      12039,
      10840245,
      10798908,
      194952,
      12505192,
      14427559,
      18172,
      8097503
    ],
    movies: [
      "SH019337590000",
      "MV012705720000",
      "MV004006300000",
      "SH029286420000",
      "SH018764110000",
      "MV006137870000",
      "MV009161520000",
      "MV000283060000",
      "MV005896110000",
      "MV005802540000",
      "MV002435570000",
      "MV008447460000",
      "MV010279730000",
      "MV000468250000",
      "MV002797310000"
    ]
  },
  {
    category: "Personal deception",
    cluster_number: 21,
    rmovies: [
      14651974,
      22380,
      184544,
      13259415,
      15592224,
      15455,
      8679006,
      3561536,
      13578836,
      10016553,
      8578508,
      1398,
      10459399,
      159470,
      327,
      14124,
      14394225,
      24517
    ],
    movies: [
      "MV010459780000",
      "MV000680860000",
      "SH003173970000",
      "MV009294790000",
      "MV011636060000",
      "MV000385830000",
      "SH014195350000",
      "SH011583840000",
      "SH025730020000",
      "MV004914560000",
      "MV003528620000",
      "MV000022450000",
      "MV005408930000",
      "MV001700480000",
      "MV000005440000",
      "MV000353530000",
      "MV010254200000",
      "MV000826500000"
    ]
  },
  {
    category: "Lessons and Bonds",
    cluster_number: 94,
    rmovies: [
      186516,
      15444050,
      184650,
      16629651,
      8000042,
      15849524,
      9978206,
      8938,
      8036993,
      10209738,
      14427239,
      11132210,
      14158884,
      10232,
      9181989,
      18277,
      11248509,
      30404
    ],
    movies: [
      "SH004563460000",
      "MV011110340000",
      "SH003849930000",
      "MV012385120000",
      "SH012322610000",
      "MV011861920000",
      "SH017398160000",
      "MV000193450000",
      "SH012420400000",
      "MV005125340000",
      "MV010279620000",
      "MV006404640000",
      "SH026965380000",
      "MV000230130000",
      "SH015519500000",
      "MV000473370000",
      "SH020505430000",
      "MV001257080000"
    ]
  },
  {
    category: "Exciting tales of determination",
    cluster_number: 35,
    rmovies: [
      15260617,
      11819825,
      16988673,
      36056,
      7764,
      12939920,
      32988,
      9285937,
      12900236,
      8568682,
      20350,
      29811,
      9360879,
      182355,
      25570,
      8786097,
      13434106,
      8129788
    ],
    movies: [
      "SH029184310000",
      "MV007650590000",
      "MV012669130000",
      "MV001594380000",
      "MV000160240000",
      "MV008967580000",
      "MV001405470000",
      "MV004005770000",
      "SH024314400000",
      "MV003523280000",
      "MV000543570000",
      "MV001214320000",
      "MV004061200000",
      "MV002273100000",
      "MV000914060000",
      "MV003685240000",
      "MV009448040000",
      "MV002822510000"
    ]
  },
  {
    category: "Survival Of The Fittest",
    cluster_number: 46,
    rmovies: [
      186613,
      7829886,
      12676586,
      8568682,
      17148318,
      11442568,
      15107294,
      3499,
      10094,
      19312,
      8808792,
      12386379,
      12386523,
      14757170,
      12142275,
      181759,
      10172993
    ],
    movies: [
      "SH001039120000",
      "SH011839820000",
      "MV008661750000",
      "MV003523280000",
      "MV012809810000",
      "SH021127220000",
      "SH028884490000",
      "MV000058710000",
      "MV000225700000",
      "MV000504770000",
      "MV003702340000",
      "MV008320930000",
      "MV008321290000",
      "MV010545390000",
      "MV008048540000",
      "MV002261520000",
      "MV005084310000"
    ]
  },
  {
    category: "Revenge is a dish best served sweet",
    cluster_number: 43,
    rmovies: [
      15021502,
      14716055,
      11016518,
      24674,
      14892895,
      13512431,
      8131919,
      28877,
      32668,
      16466,
      3632501,
      8097568,
      9091179,
      14449060,
      10993,
      12798,
      32134,
      31105,
      9976600,
      11553908,
      29214,
      8609562
    ],
    movies: [
      "SH028661530000",
      "SH028052240000",
      "MV006178670000",
      "MV000833410000",
      "MV010656090000",
      "SH025576250000",
      "SH012805970000",
      "MV001157440000",
      "MV001387760000",
      "MV000415780000",
      "MV002601930000",
      "MV002797440000",
      "MV003881270000",
      "MV010293420000",
      "MV000254430000",
      "MV000323090000",
      "MV001360320000",
      "MV001299370000",
      "MV004864170000",
      "MV007267100000",
      "MV001177270000",
      "MV003548710000"
    ]
  },
  {
    category: "Terrifying Cruel Intentions",
    cluster_number: 26,
    rmovies: [
      16914466,
      1820,
      11751764,
      16286009,
      3345,
      32136,
      28898,
      169550,
      15839132,
      283,
      17605,
      194061,
      24688,
      160256,
      10231588,
      7848393,
      9707417,
      11132251,
      13452,
      11670095,
      30816
    ],
    movies: [
      "MV012568320000",
      "MV000029520000",
      "SH021800150000",
      "MV012122400000",
      "MV000056090000",
      "MV001360350000",
      "MV001158170000",
      "MV001965580000",
      "MV011855460000",
      "MV000004670000",
      "MV000446620000",
      "MV002415180000",
      "MV000833920000",
      "MV001705720000",
      "MV005148810000",
      "MV002645050000",
      "SH016802930000",
      "MV006404670000",
      "MV000340020000",
      "MV007448120000",
      "MV001282750000"
    ]
  },
  {
    category: "I did it all for Love",
    cluster_number: 17,
    rmovies: [
      187576,
      186700,
      4265,
      15506137,
      3542039,
      14197,
      18139,
      8012384,
      9415223,
      5791,
      17459,
      10700430,
      161007,
      183697,
      1187,
      12314
    ],
    movies: [
      "SH005440010000",
      "SH010752230000",
      "MV000072350000",
      "SH029758200000",
      "MV002523450000",
      "MV000355470000",
      "MV000466870000",
      "MV002740550000",
      "MV004120160000",
      "MV000104560000",
      "MV000442580000",
      "MV005671420000",
      "MV001809900000",
      "MV002303120000",
      "MV000018730000",
      "MV000219270000"
    ]
  },
  {
    category: "Losing grip on reality",
    cluster_number: 48,
    rmovies: [
      15398077,
      184034,
      16715070,
      16780674,
      19365,
      40,
      8807210,
      9259393,
      11238598,
      9222846,
      10229,
      23514,
      163197,
      7879939,
      180732,
      12465454,
      190884
    ],
    movies: [
      "SH029451760000",
      "SH000041230000",
      "MV012445410000",
      "MV012487530000",
      "MV000506370000",
      "MV000000430000",
      "SH014661780000",
      "MV003986580000",
      "MV006607790000",
      "MV003962120000",
      "MV000229380000",
      "MV000773810000",
      "MV001840360000",
      "MV002661540000",
      "MV002240100000",
      "SH023389030000",
      "MV002368230000"
    ]
  },
  {
    category: "Powerful Reality Shows",
    cluster_number: 1021,
    rmovies: [
      184250,
      18261123,
      185566,
      12287675,
      13737255,
      17804877,
      3594600,
      19244583,
      10860447,
      17456317,
      9942403,
      17644076,
      19294313,
      185351,
      19666743,
      17664618,
      12564950,
      218841,
      12830505,
      12382662
    ],
    movies: [
      "SH000170700000",
      "SH035028230000",
      "SH009324090000",
      "SH022988480000",
      "SH026069290000",
      "SH034128100000",
      "SH005520800000",
      "SH037201050000",
      "SH019438080000",
      "SH033314780000",
      "SH017311470000",
      "SH033719770000",
      "SH037332020000",
      "SH006114420000",
      "SH038021630000",
      "SH034315150000",
      "SH023591200000",
      "SH010549430000",
      "SH024170300000",
      "SH023188980000"
    ]
  },
  {
    category: "Reality Shows involving Personal Bonds",
    cluster_number: 1019,
    rmovies: [
      8295433,
      188610,
      21949843,
      12595843,
      186003,
      15207885,
      185445,
      302409,
      10835608,
      12332017,
      185439,
      9897386,
      8835152,
      8692405,
      8426222,
      8060009,
      22288074,
      17753896,
      9194492,
      18644296
    ],
    movies: [
      "SH013271680000",
      "SH010830320000",
      "SH042604920000",
      "SH023652880000",
      "SH010309010000",
      "SH029089410000",
      "SH008151260000",
      "SH011344190000",
      "SH019366780000",
      "SH023076890000",
      "SH006750820000",
      "SH017207110000",
      "SH014734600000",
      "SH014228840000",
      "SH013600540000",
      "SH012481560000",
      "SH043280160000",
      "SH034007400000",
      "SH015543320000",
      "SH035840910000"
    ]
  }
];
