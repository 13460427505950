/**
 * flame-icon.svg converted into a component (so colors,e.g, can be altered via props) _and_ then into
 * base64 so that it may be used as the src to an <img/> (<img/> can lay out differently than <div/>)
 * (converted by to_img_component.sh, with post processing)
 */
import React from "react";

class PopularityIconSVG extends React.Component {
    render = () => {
        const color = this.props.color || "black";
        const data = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"	 viewBox="0 0 55.8 94.8" style="enable-background:new 0 0 55.8 94.8;" xml:space="preserve"><path fill="${color}" d="M14.5,0c0,0,14.8,10.5,0,32C1.1,51.5-6.9,72.1,7.9,87.5c4.3,4.5,9.4,6.7,14.6,7.3C14.1,92.3,7.8,84,15.1,69	c4.5-9.2,15.7-20.1,11.9-32.9c0,0,10.1,12.7,3.2,32c-3.1,8.8,10.6,9.7,6.9,0.2c-2.2-5.8,10.6,11.5,3.7,21.5	c6.5-4.5,10.1-11.9,12.7-19.9C60.8,47.4,51.2,11.1,14.5,0z"/></svg>`;
        const data64 = `data:image/svg+xml;base64,${btoa(data)}`;
        return (
            <img
                className={this.props.className}
                alt={this.props.alt}
                onMouseEnter={this.props.onMouseEnter}
                onMouseLeave={this.props.onMouseLeave}
                src={data64}
            />
        );
    };
}

export default PopularityIconSVG;
