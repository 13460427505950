import React, { Component } from "react";
import { darken, desaturate, transparentize } from "polished";
import store from "../store";
import ReactGA from "react-ga";
import { UI, colorForCategory } from "../constants";
import Carousel from "../Carousel";
import { TitleFreePoster } from "../Poster";
import { keywordClicked, categoryClicked } from "../actions";
import styled, { css } from "styled-components";
export default class KeywordLevel extends Component {
    categoryClick = category => () => {
        if (process.env.REACT_APP_GTAG) {
            ReactGA.event({
                category: "Category",
                action: "Category Clicked"
            });
        }

        store.dispatch(categoryClicked(category));
    };

    keywordClick = (keyword, category) => () => {
        if (process.env.REACT_APP_GTAG) {
            ReactGA.event({
                category: "Keyword",
                action: "Keyword Clicked"
            });
        }

        store.dispatch(keywordClicked(keyword, category));
    };

    render() {
        const {
            category,
            tag,
            size,
            programs,
            count,
            rawCount,
            arrowStyle
        } = this.props.data.level;
        const dispatch = this.props.dispatch;

        const ViewModel = items => {
            return items.map(item => ({
                tmsId: item.program.tmsId,
                uri: item.imageUri,
                title: item.program.name,
                action: () => dispatch(item.action)
            }));
        };

        const keywordClick =
            category === tag
                ? this.categoryClick(this.props.data.level.category)
                : this.keywordClick(
                    this.props.data.level.tag,
                    this.props.data.level.category
                );

        const gotCarouselContents =
            programs &&
            programs.length > 0 &&
            programs[0].program.rootId !== "0";

        const EmptyCarousel = ({ childCount }) => {
            const emptyCarouselStyles = {
                color: "black",
                whiteSpace: "pre-wrap",
                height: "33vh",
                background: "#efefef url(/bg-no-content.png) no-repeat",
                backgroundSize: "cover",
                borderBottomLeftRadius: UI.layout.boxRadius,
                borderBottomRightRadius: UI.layout.boxRadius
            };

            const topLineStyles = {
                paddingTop: "5.2rem",
                fontWeight: 300,
                fontSize: "2.2rem" // Hard-coded because (as it turns out) modularScale returns values in `ems`, of all things. Haven't figured out what the problem is yet.
            };

            const bottomLineStyles = {
                paddingTop: "2rem",
                fontWeight: 300,
                fontSize: "1.8rem"
            };
            //HART-6292 -> "Browse *176* titles found below in child descriptors"
            return (
                <div style={emptyCarouselStyles}>
                    <div style={topLineStyles}>{`No titles tagged ${tag}`}</div>
                    <div style={bottomLineStyles}>
                        {`Browse`}
                        <span style={{ fontWeight: 700 }}>
                            &nbsp;{childCount}&nbsp;
                        </span>
                        {`${childCount === 1 ? "title" : "titles"
                            } found below in child descriptors`}
                    </div>
                </div>
            );
        };

        return (
            <React.Fragment>
                <FitContent style={keywordStyles(tag, category, size)}>
                    <div onClick={keywordClick}>
                        <div style={innerKeywordStyles(tag, category)}>
                            {tag}
                            {count}
                        </div>
                    </div>

                    {programs ? (
                        <div style={carouselBoundingBox(gotCarouselContents)}>
                            {gotCarouselContents ? (
                                <Carousel
                                    items={ViewModel(programs, dispatch)}
                                    slideComponent={TitleFreePoster}
                                />
                            ) : (
                                <EmptyCarousel
                                    keyword={tag}
                                    childCount={rawCount.level}
                                />
                            )}
                        </div>
                    ) : (
                        ""
                    )}
                </FitContent>
                <div style={arrowStyles(size, category, arrowStyle)} />
            </React.Fragment>
        );
    }
}

// A FitContent wrapper
const FitContent = styled.div`
    ${css`
        width: fit-content;
    `};
`;
// There's quite a lot going on here, style-wise,
// so I'm taking a first stab with inline styles so
// that we at least we have tighter style cohesion.
const isCategory = (keyword, category) => keyword === category;

const keywordStyles = (keyword, category, size) => {
    if (isCategory(keyword, category)) {
        return categoryStyles(category);
    } else {
        return levelStyles(category, size);
    }
};

const padding = ".5rem";
const innerKeywordStyles = (keyword, category) => {
    return {
        color: colorForCategory(category),
        padding: `${padding} 0`
    };
};

// The sizes don't _grow_ from 0, but _shrink_ from 3 (plus 4 for the category)
const fontSize = size => {
    switch (size) {
        case 4:
            return UI.font.huge;
        case 3:
            return UI.font.large;
        case 2:
            return UI.font.medium;
        case 1:
            return UI.font.small;
        case 0:
            return UI.font.tiny;
        default:
            return "200px"; // If we get here, it's a 🐛. This makes it obvious.
    }
};

const levelStyles = (category, currentSize) => {
    const width = currentSize => {
        const scaleFactor = 22;
        const maximumWidth = 90;
        const maxSize = 3;
        return `${maximumWidth - (maxSize - currentSize) * scaleFactor}vw`;
    };

    return {
        cursor: "pointer",
        fontSize: fontSize(currentSize),
        color: darken(0.25, desaturate(0.01, colorForCategory(category))),
        textAlign: "center",
        width: width(currentSize),
        margin: "0 auto",
        padding: "0",
        borderRadius: UI.layout.boxRadius,
        backgroundColor: transparentize(0.88, colorForCategory(category))
    };
};

const categoryStyles = category => {
    const catVmargin = 1;
    const catHmargin = 1.5 * catVmargin;
    return {
        cursor: "pointer",
        fontSize: fontSize(4),
        fontWeight: "medium",
        margin: "0 auto",
        fontVariantCaps: "all-small-caps",
        padding: `${catVmargin * 0}vh ${catHmargin}vh ${catVmargin}vh`,
        borderRadius: UI.layout.boxRadius,
        color: colorForCategory(category),
        backgroundColor: transparentize(0.88, colorForCategory(category))
    };
};

const carouselBoundingBox = gotCarouselContents => {
    return {
        background: "no-background",
        cursor: "auto", // Prevents hand appearing in gutter between images
        padding: !gotCarouselContents ? 0 : `10px 0` // 10px is hack to accomodate Swiper value I haven't yet figured out.
    };
};

const arrowStyles = (size, category, arrowStyle) => {
    const step = 2;
    const baseSize = 3;
    const borderWidth = size * step + baseSize;
    const arrowColor =
        arrowStyle === UI.arrow.off
            ? "transparent"
            : transparentize(0.88, colorForCategory(category));
    const marginBottom = `-${borderWidth / 4}rem`;

    return {
        margin: `0 auto ${marginBottom} auto`,
        width: "0",
        borderColor: "transparent",
        borderStyle: "solid",
        borderTopColor: arrowColor,
        borderWidth: `${borderWidth / 4.5}rem`,
        paddingBottom: "2vh"
    };
};
