import styled from "styled-components";
import { UI } from "../constants";


export const OuterBoundingBox = styled.section`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  height: 80%;
  overflow-y: auto;
  margin-left: 3vw;
`;

export const InnerBoundingBox = styled.section`
  text-align: left;
  max-width: 75%;
`;

export const TextDetails = styled.div`
  margin-bottom: 0.1rem;
  margin-right: 1.5vw;
  grid-area: reasontowatch;
`;

export const Content = styled.div`
    font-size: ${UI.font.tiny}
    line-height: 1.6rem;
    color: white;
    font-weight: ${UI.font.weight.medium};
    padding-bottom: 0.5rem;
    margin-left:1.5rem;
`;

export const Motivation = styled.div`
  padding-bottom: 1.5rem;
  color: ${props => props.paletteColor || `#5dbae2`};
  font-variant-numeric: lining-nums tabular-nums;
  font-size: ${UI.font.xtiny};
  font-style: normal;
  font-weight: ${UI.font.weight.regular};
  grid-area: motivation;
`;

export const ReasonToWatchBoundingBox = styled.div`
    display: grid;
    grid-template-columns: 2% 4% 88% 5% 1%;
    background-color: rgb(255 255 255 / .1);
    border-radius: .5rem;
    margin: .5rem 0;
    padding-top: 1.5rem;
    padding-right: 3rem;
    grid-template-areas:
    ". icon reasontowatch generator ."
    ". icon motivation generator ."
`;

export const ReasonToWatchBoundingBoxSummary = styled(ReasonToWatchBoundingBox)`
  grid-template-columns: 2% 22% 72% 4%;
  background-color: #191B25FF;
  padding:2rem;
  margin:0;
  grid-template-areas:
      ". icon reasontowatch .";
  min-height:5rem;
  max-height: 5rem;
  align-items:center;
`;

export const IconBox = styled.div`
grid-area: icon;
`;

export const GeneratorBoundingBox = styled.div`
grid-area: generator;
`;

export const GeneratorBox = styled.div`
background-color: ${(props) => props.bgColor || "#5dbae2"};
border-radius: .2rem;
margin: .1rem .2rem;
padding: .1rem .5rem;
text-align: center;
`;

export const BoxStyler = styled.div`
display: flex; 
`;