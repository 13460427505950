import React from "react";

export const ClipsPoster = (item, key) => {
    const handlePause = event => { event.target.pause(); event.target.currentTime = 0 }
    return (
        <video key={`clips_poster_${key}`} onClick={item.action} width="150vh" height="85vh" onMouseOver={event => event.target.play()} onMouseOut={event => handlePause(event)} >
            <source src={item.clipsData.carouselImage} type="video/mp4" />
        </video>
    );
};
