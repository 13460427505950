import { castArray } from "lodash";
const amplifyConfig = require("../amplify-config").default;

const {
    domain,
    redirectSignIn,
    redirectSignOut,
    responseType
} = amplifyConfig.Auth.oauth;
const clientId = amplifyConfig.Auth.userPoolWebClientId;

// Hosted UI Urls
const authLoginUrl = `https://${domain}/login?redirect_uri=${redirectSignIn}&response_type=${responseType}&client_id=${clientId}`;
const authLogoutUrl = `https://${domain}/logout?logout_uri=${redirectSignOut}&client_id=${clientId}`;
const callbackAt = "login";

export const config = {
    authLoginUrl,
    authLogoutUrl,
    callbackAt,
    amplify: amplifyConfig
};

/*
userAndCred.custom
21:17:59.891 {access: {…}, stage_access: {…}, dev_access: {…}}
21:18:05.980 userAndCred.custom.access
21:18:05.991 {enabled: true, time_range: Array(2)}
21:18:15.493 userAndCred.custom.access.time_range
21:18:15.503 (2) ["2019-04-22T16:19:23.839Z", "2020-04-21T16:19:23.839Z"]
*/
export const hasAccess = function(uc) {
    if (uc.isUs) {
        return true;
    }

    const accesses = {
        prod: "access",
        stage: "stage_access",
        dev: "dev_access"
    };
    const accessAttrName = accesses[process.env.REACT_APP_INDEX_ENV];

    const { enabled, time_range } = uc.custom[accessAttrName] || {};
    return enabled && inTimeRange(time_range);
};
export const inTimeRange = function(range) {
    const [start, end] = castArray(range);
    const now = new Date();
    const startDate = new Date(start || null);
    if (startDate > now) {
        return false;
    }

    if (end == null) {
        return true;
    }
    const endDate = new Date(end);
    if (endDate > now) {
        return true;
    }

    return false;
};
