import React from "react";
import ImgLazyLoad from "../App/ImgLazyLoad";
import {
    VM_HOME_BACKGROUND_IMAGES_A,
    VM_HOME_BACKGROUND_IMAGES_B
} from "../constants";

const BackgroundMoodShim = props => {
    const { abSwitch, opacity, position, top, scrim } = props;
    const backgroundImages = abSwitch
        ? VM_HOME_BACKGROUND_IMAGES_A
        : VM_HOME_BACKGROUND_IMAGES_B;
    const moodHomeImages =
        backgroundImages &&
        backgroundImages.map((i, idx) => {
            return (
                <div key={idx} className="mood-home-image">
                    <ImgLazyLoad src={i.uri} alt="front page mood tile" />
                </div>
            );
        });

    return (
        <div>
            {scrim ? (
                <div
                    className="mood-menu-insert"
                    style={{
                        opacity: `${opacity}`
                    }}
                />
            ) : null}
            <div
                className="mood-images"
                style={{ position: `${position}`, top: `${top}` }}
            >
                {moodHomeImages}
            </div>
        </div>
    );
};

export default BackgroundMoodShim;
