export const getFirstItem = state => {
    return state.modal ? state.modal.selectedContent : null;
};

export const getSelectedContent = state => {
    return state.modal ? state.modal.selectedContent : null;
};

export const getProfile = state => {
    return state.profile || null;
};

export const getGenreToSearch = state => {
    return state.genre || null;
};

export const getTasteProfileData = state => {
    return state.tasteProfileData || null;
};
/**
 * The session details are saved here.
 * @param {Object} state
 */
export const selectAuthSession = state => {
    return state.session || { expires: 0, authenticated: false };
};

/**
 * Get the auth event payload
 * @param {Object} state
 */
export const selectAuthPayload = state => state.authPayload || {};

/**
 * Get the auth credentials
 * @param {Object} state
 */
export const selectAuthCredentials = state => state.loggedIn || false;

/**
 * Get the auth redirect
 * @param {Object} state
 */
export const selectAuthRedirect = state => state.authRedirect || "";

/**
 * The empty placeholder, if we need one.
 * @param {Object} state
 */
export const getPlaceholder = state => state.placeholder;

export const getWomenPage = state => state.womenModal;

/**
 * Select the current lang
 * @param {Object} state
 */
export const selectLang = state => state.lang;
