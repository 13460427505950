import {
  ADDING_CHILD_KEYWORDS,
  ADD_ADVANCED_CATEGORIES_TO_LIST,
  ADD_POPULAR_ITEMS_TO_LIST,
  ADD_PROFILE_NAMES_TO_LIST,
  ADD_HERO_ITEMS_TO_LIST,
  ADD_BASED_ON_ITEMS_TO_LIST,
  ADD_CLIPS_ITEMS_TO_LIST,
  ADD_TASTE_PROFILE_ITEMS_TO_LIST,
  AUTH_MESSAGE,
  AUTH_REDIRECT,
  AUTH_SESSION,
  BOOTSTRAP_KEYWORD_GRAPH_DATA,
  BUBBLE_CLICKED,
  CLOSE_MODAL,
  CREATE_KEYWORD_GRAPH_DATA,
  FILLING_KEYWORD_GRAPH,
  HOME,
  LOGIN,
  LOGOUT,
  OPEN_MODAL,
  PERSONALIZED_IMAGES_TAB_CLICKED,
  PROGRAM_DETAILS_TAB_CLICKED,
  TASTE_PROFILE_CLICKED,
  UPDATE_PERSONALIZED_IMAGES,
  USER_SELECT_CLICKED,
  WAIT_AFTER_CLICK,
  SUGGESTIONS_RECEIVED,
  FETCH_KW_ENABLED_CATEGORIES,
  CURATION_CLICKED,
  GENRE_NAME_CLICKED,
  GENRE_NAMES_CLEARED,
  DISPLAY_GENRE_SEARCH_RESULTS,
  GET_ALL_GENRE_NAMES,
  MOOD_JOURNEY_CLICKED,
  UPDATE_MOOD_JOURNEY,
  ADD_MOOD_ITEMS_TO_LIST,
  MOOD_MORE_LIKE_THIS_CLICKED,
  OPEN_MOOD_SWITCH_MODAL,
  IS_GOOGLE,
  OPEN_MOOD_GRID_MODAL,
  MOOD_CAROUSEL_TITLE_CLICKED,
  MOOD_QUADRANT_CLICKED,
  MOOD_PATH_CLICKED,
  MOOD_PATH_HOME_CLICKED,
  MOOD_HOME,
  MOOD_BREAD_CRUMB_CLICKED,
  VIDEO_CLIPS_TAB_CLICKED,
  REASONS_TO_WATCH_TAB_CLICKED,
  VIDEO_DESCRIPTORS_TAB_CLICKED,
  CAST_DETAILS_TAB_CLICKED,
  OPEN_WOMEN,
  OPEN_MARVEL,
  OPEN_AGATHA,
  OPEN_POLITICS,
  OPEN_FACING,
  OPEN_THEMATIC_LANDING,
  CLOSE_THEMATIC_LANDING,
  CLOSE_ALL_THEMATIC,
  ADD_THEMATIC_CATEGORIES_TO_LIST
} from "../constants";

export default function (state = [], action) {
  switch (action.type) {
    case MOOD_CAROUSEL_TITLE_CLICKED:
      if (action.image) {
        return {
          ...state,
          showingMoodCarouselPopup: action.showingMoodCarouselPopup,
          moodCarouselPopupImage: action.image
        };
      } else
        return {
          ...state,
          showingMoodCarouselPopup: action.showingMoodCarouselPopup,
          moodCarouselPopupImage: null
        };
    case UPDATE_MOOD_JOURNEY:
      return {
        ...state,
        vmHomeDisplayMap: action.vmHomeDisplayMap
      };
    case MOOD_JOURNEY_CLICKED:
      return {
        ...state,
        genre: "",
        genreSearchResults: [],
        showingCuration: false,
        showingSearchResults: false,
        showingMoodJourney: true,
        fullscreen: {}
      };
    case ADD_MOOD_ITEMS_TO_LIST:
      return {
        ...state,
        showingMoodJourney: false,
        moreLikeThisMoods: "",
        modalMoodSwitch: null,
        fullscreen: {
          ...state.fullscreen,
          moodItemsData: { ...action.moodItemsData }
        }
      };
    case MOOD_MORE_LIKE_THIS_CLICKED:
      return {
        ...state,
        moreLikeThisMoods: action.moodsTitle
      };

    case GET_ALL_GENRE_NAMES:
      return {
        ...state,
        latestGenres: action.genres
      };
    case DISPLAY_GENRE_SEARCH_RESULTS:
      return {
        ...state,
        genreSearchResults: action.genreSearchResults
      };
    case GENRE_NAME_CLICKED:
      return {
        ...state,
        genre: action.genre,
        genreNameIndex: action.genreNameIndex,
        genreSearchResults: action.genreSearchResults
      };
    case GENRE_NAMES_CLEARED:
      return {
        ...state,
        genreNameIndex: null
      };
    case SUGGESTIONS_RECEIVED:
      return {
        ...state,
        term: action.term,
        genre: "",
        genreSearchResults: [],
        showingCuration: false,
        showingMoodJourney: false,
        showingSearchResults: true,
        fullscreen: {
          ...state.fullscreen,
          suggestions: action.rawResults,
          suggestions8n: action.cookedResults,
          suggestionsCount: action.count
        }
      };
    case BUBBLE_CLICKED:
      return {
        ...state,
        tasteProfileData: {
          ...state.tasteProfileData,
          [action.profile]: action.tasteProfileData
        }
      };
    case ADD_TASTE_PROFILE_ITEMS_TO_LIST:
      return {
        ...state,
        tasteProfileData: {
          ...state.tasteProfileData,
          [action.profile]: action.tasteProfileData
        }
      };
    case ADD_POPULAR_ITEMS_TO_LIST:
      return {
        ...state,
        fullscreen: {
          ...state.fullscreen,
          popularItemsData: { ...action.popularItemsData }
        }
      };
    case ADD_HERO_ITEMS_TO_LIST:
      return {
        ...state,
        fullscreen: {
          ...state.fullscreen,
          heroItemsData: { ...action.heroItemsData }
        }
      };
    case ADD_BASED_ON_ITEMS_TO_LIST:
      return {
        ...state,
        fullscreen: {
          ...state.fullscreen,
          basedOnItemsData: { ...action.basedOnItemsData }
        }
      };
    case ADD_CLIPS_ITEMS_TO_LIST:
      return {
        ...state,
        fullscreen: {
          ...state.fullscreen,
          clipsItemsData: { ...action.clipsItemsData }
        }
      };
    case ADD_ADVANCED_CATEGORIES_TO_LIST:
      return {
        ...state,
        fullscreen: {
          ...state.fullscreen,
          advancedCategoriesData: action.advancedCategoriesData
        }
      };

    case ADD_THEMATIC_CATEGORIES_TO_LIST:
      return {
        ...state,
        fullscreen: {
          ...state.fullscreen,
          thematicCategoriesData: action.thematicCategoriesData
        }
      };

    case ADD_PROFILE_NAMES_TO_LIST:
      return { ...state, profileNames: action.profileNames };

    case FETCH_KW_ENABLED_CATEGORIES:
      return { ...state, keywordEnabledCategories: action.categories };

    case HOME:
      const profile = state.profile;
      const showingMoodJourney = profile === "profile_5" ? true : false;
      return {
        ...state,
        fullscreen: {}, // Ah - this erases everything, so the bootstrapping cannot be separated from HOME.
        term: null,
        showingCuration: false,
        showingMoodJourney: showingMoodJourney,
        genre: "",
        genreNameIndex: null,
        genreSearchResults: null,
        showingSearchResults: false,
        moodHomeSwitch: !state.moodHomeSwitch, // flip the program set
        moodHomeShowSVG: false // for breadcrumb home action
      };
    case MOOD_HOME:
      const p = state.profile;
      const showing = p === "profile_5" ? true : false;
      return {
        ...state,
        fullscreen: {}, // this erases everything, so the bootstrapping cannot be separated from HOME.
        term: null,
        showingCuration: false,
        showingMoodJourney: showing,
        genre: "",
        genreNameIndex: null,
        genreSearchResults: null,
        showingSearchResults: false,
        moodHomeSwitch: action.home
          ? !state.moodHomeSwitch
          : state.moodHomeSwitch, // this keeps same program set, if we are NOT coming in from home view
        moodHomeShowSVG: true // for breadcrumb home action
      };
    case OPEN_MODAL:
      if (!state.showingTasteProfile)
        return {
          ...state,
          modal: {
            selectedContent: action.selectedContent,
            selected: PROGRAM_DETAILS_TAB_CLICKED //Set the inital state as if the program details tab was clicked
          }
        }; // eslint-disable-next-line
    case MOOD_QUADRANT_CLICKED: {
      return {
        ...state,
        moodPath: [action.quadrantData],
        selectedMoodPath: action.quadrantData.viewer_motivation
      };
    }
    case MOOD_PATH_CLICKED: {
      return {
        ...state,
        selectedMoodPath: action.mood,
        selectedMoodPathProgram: action.id, // redundant per current dataset; useful for filtering
        moodPath: [...state.moodPath, action.mood]
      };
    }
    case MOOD_BREAD_CRUMB_CLICKED: {
      // shallow copy
      const newState = state.moodPath.slice();

      // insert the new item at index
      newState.splice(action.index + 1);

      return {
        ...state,
        selectedMoodPath: action.mood,
        moodPath: newState
      };
    }
    case MOOD_PATH_HOME_CLICKED: {
      return {
        ...state,
        moodPath: [],
        selectedMoodPath: ""
      };
    }

    case OPEN_MOOD_SWITCH_MODAL:
      return {
        ...state,
        modalMoodSwitch: {
          selectedContent: action.selectedContent
        }
      }; // eslint-disable-next-line
    case OPEN_MOOD_GRID_MODAL:
      return {
        ...state,
        modalMoodGrid: !state.modalMoodGrid
      }; // eslint-disable-next-line
    case CLOSE_MODAL:
      return {
        ...state,
        modal: null,
        modalMoodSwitch: null,
        modalMoodGrid: false
      };
    case OPEN_WOMEN:
      return {
        ...state,
        womenModal: true,
        thematicContent: true,
        thematicSubModal: true
      };
    case OPEN_MARVEL:
      return {
        ...state,
        marvelModal: true,
        thematicContent: true,
        thematicSubModal: true
      };
    case OPEN_AGATHA:
      return {
        ...state,
        agathaModal: true,
        thematicContent: true,
        thematicSubModal: true
      };
    case OPEN_POLITICS:
      return {
        ...state,
        politicsModal: true,
        thematicContent: true,
        thematicSubModal: true
      };
    case OPEN_FACING:
      return {
        ...state,
        facingModal: true,
        thematicContent: true,
        thematicSubModal: true
      };
    case OPEN_THEMATIC_LANDING:
      return {
        ...state,
        thematicContent: true,
        thematicModal: true,
        thematicSubModal: false
      };
    case CLOSE_THEMATIC_LANDING:
      return {
        ...state,
        thematicModal: false,
        thematicContent: false,
        thematicSubModal: false
      };
    case CLOSE_ALL_THEMATIC:
      return {
        ...state,
        thematicContent: true,
        thematicSubModal: false,
        womenModal: false,
        marvelModal: false,
        agathaModal: false,
        politicsModal: false,
        facingModal: false
      };
    case BOOTSTRAP_KEYWORD_GRAPH_DATA:
    case CREATE_KEYWORD_GRAPH_DATA:
    case FILLING_KEYWORD_GRAPH:
    case ADDING_CHILD_KEYWORDS:
      return {
        ...state,
        showingCuration: false,
        showingSearchResults: false,
        fullscreen: {
          keywordGraphData: action.keywordGraphData
        }
      };
    case PROGRAM_DETAILS_TAB_CLICKED:
    case PERSONALIZED_IMAGES_TAB_CLICKED:
    case VIDEO_CLIPS_TAB_CLICKED:
    case VIDEO_DESCRIPTORS_TAB_CLICKED:
    case REASONS_TO_WATCH_TAB_CLICKED:
    case CAST_DETAILS_TAB_CLICKED:
      return {
        ...state,
        modal: {
          selected: action.type,
          selectedContent: { ...state.modal.selectedContent }
        }
      };
    case UPDATE_PERSONALIZED_IMAGES:
      return {
        ...state,
        modal: {
          ...state.modal,
          selectedContent: { ...action.selectedContent }
        }
      };

    case USER_SELECT_CLICKED:
      return {
        ...state,
        genre: "",
        genreSearchResults: [],
        profile: action.profile,
        lang: action.lang
      };

    case TASTE_PROFILE_CLICKED:
      return {
        ...state,
        showingTasteProfile: action.showingTasteProfile
      };

    case AUTH_SESSION:
      return {
        ...state,
        session: action.session
      };
    case AUTH_REDIRECT:
      return {
        ...state,
        authRedirect: action.authRedirect,
        session: action.session
      };
    case AUTH_MESSAGE:
      return {
        ...state,
        message: action.message
      };
    case LOGIN:
      return { ...state, loggedIn: true };
    case LOGOUT:
      return { ...state, loggedIn: false };
    case IS_GOOGLE:
      return { ...state, isGoogle: action.isGoogle };

    /*
     * Components listening to this, can change their UI.
     * Put something in clicked that the component will understand.
     */
    case WAIT_AFTER_CLICK:
      return {
        ...state,
        wait: action.wait,
        clicked: action.clicked
      };

    case CURATION_CLICKED:
      return {
        ...state,
        showingSearchResults: false,
        showingCuration: action.showingCuration
      };

    default:
      return state;
  }
}
