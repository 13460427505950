import React from "react";
import styled from "styled-components";

const CarouselPoster = styled.img`
    grid-area: 1 / 1
    width: 100%;
    cursor: pointer;
    filter: drop-shadow(0 0.2rem 0.2rem rgba(0, 0, 0, 0.5));
    opacity: 1;
`;

const CarouselPosterDiv = styled.div`
    grid-area: 1 / 1    
    cursor: pointer;
    filter: drop-shadow(0 0.2rem 0.2rem rgba(0, 0, 0, 0.5));
    opacity: 1;
    background:  url(${props => props.img}) no-repeat; 
    background-size: contain;   
    margin-bottom: 5px;
`;

export const BasedOnPoster = (item, key) => {

    if (item.lazyLoad) {
        return (
            <div key={key} onClick={item.action}>
                <CarouselPoster
                    data-src={item.basedOnData.carouselImage}
                    className="swiper-lazy basedon"
                />
                <div className="swiper-lazy-preloader swiper-lazy-preloader-white" />
            </div>
        );
    } else
        return item.swapItem ? (
            <div key={key} className="swap-landscape" onClick={item.action}>
                <div data-src={item.uri}>
                    <CarouselPosterDiv
                        className="entitled"
                        img={item.uri}
                        swapImg={item.swapItem}
                    />
                </div>
            </div>
        ) : (
            <div key={key} onClick={item.action}>
                <CarouselPoster src={item.basedOnData.carouselImage} className="basedon-size" />
            </div>
        );
};
