import { connect } from "react-redux";
import MoodsCarousel from "./MoodsCarousel";

const mapStateToProps = state => ({
    moreLikeThisMoods: state.moreLikeThisMoods,
    moodHomeSwitch: state.moodHomeSwitch,
    moodItemsData: state.fullscreen.moodItemsData,
    selectedMoodPath: state.selectedMoodPath,
    moodPath: state.moodPath,
    lang: state.lang
});

export default connect(mapStateToProps)(MoodsCarousel);
