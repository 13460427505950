import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { closeModal } from "../actions";
import Modal from "react-responsive-modal";

/*
    Each of the keys below (showCloseIcon, open, styles,…)
    has a special meaning to the modal.

    Docs for these react-responsive-modal props here:
    https://github.com/pradel/react-responsive-modal#modal

    Note that we're not using `styled-components` here since
    the modal already has an opinionated way to do styles
 */

//
// prepare media queries, but from within JS,
// so we use window.matchMedia so that we can figure out
// if we are on a larger display; note you can't just move the browser
// in and out from one size to another without reloading the Single Page App.
//
export const breakpoints = {
    desktopLg: 1440
};

const query = window.matchMedia(`(max-width: ${breakpoints["desktopLg"]}px)`);
const max_width = query.matches ? query.media : "fit-content";

const mapStateToProps = state => ({
    showCloseIcon: true,
    open: state.modalMoodSwitch !== "" && state.modalMoodSwitch !== null,
    styles: {
        closeIcon: {
            fill: "#A9A9A9",
            cursor: "pointer",
            height: "3rem",
            width: "3rem"
        },
        modal: {
            backgroundClip: "padding-box",
            boxShadow: "0 12px 15px 0 rgba(0, 0, 0, 0.25)",
            background: "#101010",
            padding: "2rem",
            borderRadius: "2rem",
            maxWidth: max_width
        },
        overlay: {
            background: "hsla(196, 100%, 9%, 80%)",
            top: "12rem",
            marginLeft: "20vw",
            marginRight: "3vw",
            borderRadius: "2rem",
            height: "fit-content",
            padding: "0"
        }
    }
});

const mapDispatchToProps = dispatch =>
    bindActionCreators({ closeModal }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Modal);
