export default {
  "Adventure|Against The Odds|Determination|Exciting|Heroism|Inspiring|Personal Bonds": [
    38,
    56,
    73,
    1001,
    1003,
    24
  ],
  "Against The Odds|Determination|Exciting|Heroism|Inspiring|Personal Bonds|Reality": [
    38,
    56,
    73,
    1013,
    1015,
    57
  ],
  "Adventure|Determination|Exciting|Heroism|Inspiring|Life Story|Personal Bonds": [
    73,
    1001,
    1003,
    59,
    56,
    83
  ],
  "Determination|Exciting|Heroism|Inspiring|Life Story|Personal Bonds|Reality": [
    73,
    1013,
    1015,
    59,
    56,
    38
  ],
  "Adventure|Against The Odds|Determination|Exciting|Heroism|Inspiring|Personal Challenge": [
    59,
    73,
    1001,
    1003,
    62,
    57
  ],
  "Against The Odds|Determination|Exciting|Heroism|Inspiring|Personal Challenge|Reality": [
    59,
    73,
    1015,
    1013,
    57,
    56
  ],
  "Adventure|Determination|Exciting|Heroism|Inspiring|Life Story|Personal Challenge": [
    59,
    85,
    73,
    1001,
    1003,
    62
  ],
  "Determination|Exciting|Heroism|Inspiring|Life Story|Personal Challenge|Reality": [
    59,
    73,
    85,
    1013,
    1015,
    62
  ],
  "Adventure|Against The Odds|Exciting|Heroism|Inspiring|Personal Bonds|Uplift": [
    56,
    38,
    73,
    98,
    24,
    1003
  ],
  "Against The Odds|Exciting|Heroism|Inspiring|Personal Bonds|Reality|Uplift": [
    56,
    38,
    73,
    98,
    1016,
    1015
  ],
  "Adventure|Exciting|Heroism|Inspiring|Life Story|Personal Bonds|Uplift": [
    98,
    73,
    56,
    1003,
    38,
    1007
  ],
  "Exciting|Heroism|Inspiring|Life Story|Personal Bonds|Reality|Uplift": [
    98,
    73,
    1016,
    38,
    56,
    1015
  ],
  "Adventure|Against The Odds|Exciting|Heroism|Inspiring|Personal Challenge|Uplift": [
    73,
    38,
    56,
    1003,
    24,
    59
  ],
  "Against The Odds|Exciting|Heroism|Inspiring|Personal Challenge|Reality|Uplift": [
    73,
    1016,
    24,
    38,
    1015,
    56
  ],
  "Adventure|Exciting|Heroism|Inspiring|Life Story|Personal Challenge|Uplift": [
    73,
    59,
    1003,
    1004,
    1008,
    1005
  ],
  "Exciting|Heroism|Inspiring|Life Story|Personal Challenge|Reality|Uplift": [
    73,
    1016,
    59,
    1015,
    1023,
    1017
  ],
  "Adventure|Against The Odds|Determination|Exciting|Inspiring|Loss|Personal Bonds": [
    38,
    23,
    56,
    73,
    24,
    83
  ],
  "Against The Odds|Determination|Exciting|Inspiring|Loss|Personal Bonds|Reality": [
    56,
    38,
    23,
    3,
    59,
    24
  ],
  "Adventure|Determination|Exciting|Inspiring|Life Story|Loss|Personal Bonds": [
    23,
    56,
    59,
    38,
    73,
    83
  ],
  "Determination|Exciting|Inspiring|Life Story|Loss|Personal Bonds|Reality": [
    38,
    73,
    23,
    59,
    3,
    83
  ],
  "Adventure|Against The Odds|Determination|Exciting|Inspiring|Loss|Personal Challenge": [
    59,
    24,
    56,
    73,
    23,
    62
  ],
  "Against The Odds|Determination|Exciting|Inspiring|Loss|Personal Challenge|Reality": [
    59,
    23,
    56,
    62,
    38,
    73
  ],
  "Adventure|Determination|Exciting|Inspiring|Life Story|Loss|Personal Challenge": [
    59,
    73,
    85,
    62,
    1001,
    1004
  ],
  "Determination|Exciting|Inspiring|Life Story|Loss|Personal Challenge|Reality": [
    59,
    62,
    85,
    73,
    1016,
    1013
  ],
  "Adventure|Against The Odds|Exciting|Inspiring|Loss|Personal Bonds|Uplift": [
    56,
    38,
    1007,
    1004,
    1000,
    23
  ],
  "Against The Odds|Exciting|Inspiring|Loss|Personal Bonds|Reality|Uplift": [
    38,
    56,
    1016,
    96,
    98,
    91
  ],
  "Adventure|Exciting|Inspiring|Life Story|Loss|Personal Bonds|Uplift": [
    1007,
    1004,
    20,
    91,
    21,
    38
  ],
  "Exciting|Inspiring|Life Story|Loss|Personal Bonds|Reality|Uplift": [
    1016,
    38,
    20,
    94,
    56,
    98
  ],
  "Adventure|Against The Odds|Exciting|Inspiring|Loss|Personal Challenge|Uplift": [
    1004,
    1000,
    73,
    21,
    38,
    56
  ],
  "Against The Odds|Exciting|Inspiring|Loss|Personal Challenge|Reality|Uplift": [
    1016,
    59,
    73,
    24,
    56,
    21
  ],
  "Adventure|Exciting|Inspiring|Life Story|Loss|Personal Challenge|Uplift": [
    1004,
    20,
    59,
    73,
    21,
    1008
  ],
  "Exciting|Inspiring|Life Story|Loss|Personal Challenge|Reality|Uplift": [
    1016,
    21,
    20,
    59,
    73,
    1017
  ],
  "Adventure|Against The Odds|Determination|Exciting|Heroism|Personal Bonds|Tense": [
    57,
    83,
    35,
    73,
    1001,
    1003
  ],
  "Against The Odds|Determination|Exciting|Heroism|Personal Bonds|Reality|Tense": [
    57,
    83,
    73,
    35,
    1015,
    1013
  ],
  "Adventure|Determination|Exciting|Heroism|Life Story|Personal Bonds|Tense": [
    83,
    85,
    35,
    73,
    1003,
    1001
  ],
  "Determination|Exciting|Heroism|Life Story|Personal Bonds|Reality|Tense": [
    83,
    73,
    35,
    85,
    1013,
    1015
  ],
  "Adventure|Against The Odds|Determination|Exciting|Heroism|Personal Challenge|Tense": [
    57,
    73,
    85,
    35,
    1003,
    1001
  ],
  "Against The Odds|Determination|Exciting|Heroism|Personal Challenge|Reality|Tense": [
    57,
    35,
    85,
    73,
    1013,
    1015
  ],
  "Adventure|Determination|Exciting|Heroism|Life Story|Personal Challenge|Tense": [
    85,
    35,
    73,
    1001,
    1003,
    57
  ],
  "Determination|Exciting|Heroism|Life Story|Personal Challenge|Reality|Tense": [
    85,
    73,
    35,
    1013,
    1015,
    46
  ],
  "Adventure|Against The Odds|Exciting|Heroism|Personal Bonds|Tense|Uplift": [
    57,
    98,
    73,
    56,
    38,
    1003
  ],
  "Against The Odds|Exciting|Heroism|Personal Bonds|Reality|Tense|Uplift": [
    98,
    73,
    57,
    1015,
    24,
    83
  ],
  "Adventure|Exciting|Heroism|Life Story|Personal Bonds|Tense|Uplift": [
    98,
    73,
    83,
    1003,
    1007,
    1005
  ],
  "Exciting|Heroism|Life Story|Personal Bonds|Reality|Tense|Uplift": [
    98,
    73,
    1015,
    83,
    1023,
    1017
  ],
  "Adventure|Against The Odds|Exciting|Heroism|Personal Challenge|Tense|Uplift": [
    57,
    73,
    24,
    1003,
    1000,
    1008
  ],
  "Against The Odds|Exciting|Heroism|Personal Challenge|Reality|Tense|Uplift": [
    57,
    73,
    24,
    1015,
    1023,
    85
  ],
  "Adventure|Exciting|Heroism|Life Story|Personal Challenge|Tense|Uplift": [
    85,
    73,
    1003,
    1008,
    1005,
    35
  ],
  "Exciting|Heroism|Life Story|Personal Challenge|Reality|Tense|Uplift": [
    73,
    85,
    1015,
    1023,
    1017,
    98
  ],
  "Adventure|Against The Odds|Determination|Exciting|Loss|Personal Bonds|Tense": [
    23,
    83,
    3,
    57,
    38,
    24
  ],
  "Against The Odds|Determination|Exciting|Loss|Personal Bonds|Reality|Tense": [
    3,
    23,
    83,
    57,
    56,
    24
  ],
  "Adventure|Determination|Exciting|Life Story|Loss|Personal Bonds|Tense": [
    83,
    3,
    85,
    46,
    35,
    23
  ],
  "Determination|Exciting|Life Story|Loss|Personal Bonds|Reality|Tense": [
    83,
    3,
    23,
    35,
    46,
    85
  ],
  "Adventure|Against The Odds|Determination|Exciting|Loss|Personal Challenge|Tense": [
    57,
    24,
    46,
    73,
    85,
    59
  ],
  "Against The Odds|Determination|Exciting|Loss|Personal Challenge|Reality|Tense": [
    57,
    85,
    46,
    3,
    59,
    35
  ],
  "Adventure|Determination|Exciting|Life Story|Loss|Personal Challenge|Tense": [
    85,
    35,
    59,
    46,
    83,
    3
  ],
  "Determination|Exciting|Life Story|Loss|Personal Challenge|Reality|Tense": [
    85,
    35,
    59,
    73,
    3,
    46
  ],
  "Adventure|Against The Odds|Exciting|Loss|Personal Bonds|Tense|Uplift": [
    1007,
    1000,
    73,
    57,
    3,
    91
  ],
  "Against The Odds|Exciting|Loss|Personal Bonds|Reality|Tense|Uplift": [
    91,
    23,
    24,
    83,
    98,
    73
  ],
  "Adventure|Exciting|Life Story|Loss|Personal Bonds|Tense|Uplift": [
    1007,
    43,
    21,
    94,
    73,
    26
  ],
  "Exciting|Life Story|Loss|Personal Bonds|Reality|Tense|Uplift": [
    20,
    21,
    91,
    73,
    83,
    94
  ],
  "Adventure|Against The Odds|Exciting|Loss|Personal Challenge|Tense|Uplift": [
    1000,
    21,
    26,
    24,
    43,
    73
  ],
  "Against The Odds|Exciting|Loss|Personal Challenge|Reality|Tense|Uplift": [
    17,
    24,
    21,
    57,
    73,
    48
  ],
  "Adventure|Exciting|Life Story|Loss|Personal Challenge|Tense|Uplift": [
    20,
    26,
    43,
    21,
    48,
    85
  ],
  "Exciting|Life Story|Loss|Personal Challenge|Reality|Tense|Uplift": [
    48,
    43,
    26,
    17,
    21,
    20
  ],
  "Adventure|Against The Odds|Determination|Heroism|Inspiring|Personal Bonds|Powerful": [
    38,
    56,
    73,
    1003,
    1001,
    1004
  ],
  "Against The Odds|Determination|Heroism|Inspiring|Personal Bonds|Powerful|Reality": [
    56,
    38,
    73,
    1016,
    1015,
    1013
  ],
  "Adventure|Determination|Heroism|Inspiring|Life Story|Personal Bonds|Powerful": [
    73,
    1001,
    1003,
    1004,
    62,
    59
  ],
  "Determination|Heroism|Inspiring|Life Story|Personal Bonds|Powerful|Reality": [
    73,
    1016,
    1013,
    1015,
    38,
    56
  ],
  "Adventure|Against The Odds|Determination|Heroism|Inspiring|Personal Challenge|Powerful": [
    59,
    62,
    73,
    1001,
    1003,
    1004
  ],
  "Against The Odds|Determination|Heroism|Inspiring|Personal Challenge|Powerful|Reality": [
    62,
    59,
    73,
    1016,
    1013,
    1015
  ],
  "Adventure|Determination|Heroism|Inspiring|Life Story|Personal Challenge|Powerful": [
    62,
    59,
    85,
    73,
    1001,
    1003
  ],
  "Determination|Heroism|Inspiring|Life Story|Personal Challenge|Powerful|Reality": [
    59,
    62,
    85,
    73,
    1016,
    1015
  ],
  "Adventure|Against The Odds|Heroism|Inspiring|Personal Bonds|Powerful|Uplift": [
    38,
    56,
    1004,
    73,
    1003,
    24
  ],
  "Against The Odds|Heroism|Inspiring|Personal Bonds|Powerful|Reality|Uplift": [
    38,
    56,
    1016,
    73,
    1015,
    21
  ],
  "Adventure|Heroism|Inspiring|Life Story|Personal Bonds|Powerful|Uplift": [
    1004,
    73,
    38,
    56,
    1003,
    98
  ],
  "Heroism|Inspiring|Life Story|Personal Bonds|Powerful|Reality|Uplift": [
    1016,
    73,
    38,
    1015,
    21,
    56
  ],
  "Adventure|Against The Odds|Heroism|Inspiring|Personal Challenge|Powerful|Uplift": [
    1004,
    73,
    24,
    1003,
    56,
    59
  ],
  "Against The Odds|Heroism|Inspiring|Personal Challenge|Powerful|Reality|Uplift": [
    1016,
    73,
    1015,
    62,
    21,
    56
  ],
  "Adventure|Heroism|Inspiring|Life Story|Personal Challenge|Powerful|Uplift": [
    1004,
    73,
    59,
    62,
    1003,
    69
  ],
  "Heroism|Inspiring|Life Story|Personal Challenge|Powerful|Reality|Uplift": [
    1016,
    73,
    1015,
    59,
    62,
    21
  ],
  "Adventure|Against The Odds|Determination|Inspiring|Loss|Personal Bonds|Powerful": [
    38,
    56,
    23,
    1004,
    3,
    59
  ],
  "Against The Odds|Determination|Inspiring|Loss|Personal Bonds|Powerful|Reality": [
    56,
    38,
    23,
    1016,
    73,
    62
  ],
  "Adventure|Determination|Inspiring|Life Story|Loss|Personal Bonds|Powerful": [
    1004,
    38,
    23,
    73,
    59,
    3
  ],
  "Determination|Inspiring|Life Story|Loss|Personal Bonds|Powerful|Reality": [
    1016,
    73,
    23,
    3,
    59,
    56
  ],
  "Adventure|Against The Odds|Determination|Inspiring|Loss|Personal Challenge|Powerful": [
    62,
    59,
    1004,
    38,
    56,
    23
  ],
  "Against The Odds|Determination|Inspiring|Loss|Personal Challenge|Powerful|Reality": [
    59,
    62,
    1016,
    73,
    38,
    56
  ],
  "Adventure|Determination|Inspiring|Life Story|Loss|Personal Challenge|Powerful": [
    59,
    62,
    1004,
    73,
    85,
    99
  ],
  "Determination|Inspiring|Life Story|Loss|Personal Challenge|Powerful|Reality": [
    59,
    62,
    1016,
    85,
    73,
    1021
  ],
  "Adventure|Against The Odds|Inspiring|Loss|Personal Bonds|Powerful|Uplift": [
    56,
    38,
    21,
    1004,
    69,
    1007
  ],
  "Against The Odds|Inspiring|Loss|Personal Bonds|Powerful|Reality|Uplift": [
    1016,
    56,
    38,
    21,
    1021,
    1004
  ],
  "Adventure|Inspiring|Life Story|Loss|Personal Bonds|Powerful|Uplift": [
    21,
    1004,
    1007,
    56,
    96,
    69
  ],
  "Inspiring|Life Story|Loss|Personal Bonds|Powerful|Reality|Uplift": [
    1016,
    21,
    1021,
    1004,
    38,
    94
  ],
  "Adventure|Against The Odds|Inspiring|Loss|Personal Challenge|Powerful|Uplift": [
    21,
    1004,
    1000,
    56,
    62,
    69
  ],
  "Against The Odds|Inspiring|Loss|Personal Challenge|Powerful|Reality|Uplift": [
    1016,
    21,
    1021,
    1004,
    59,
    73
  ],
  "Adventure|Inspiring|Life Story|Loss|Personal Challenge|Powerful|Uplift": [
    21,
    1004,
    20,
    62,
    59,
    69
  ],
  "Inspiring|Life Story|Loss|Personal Challenge|Powerful|Reality|Uplift": [
    1016,
    21,
    1021,
    1004,
    73,
    20
  ],
  "Adventure|Against The Odds|Determination|Heroism|Personal Bonds|Powerful|Tense": [
    73,
    1003,
    1001,
    83,
    56,
    23
  ],
  "Against The Odds|Determination|Heroism|Personal Bonds|Powerful|Reality|Tense": [
    73,
    1013,
    1015,
    3,
    23,
    56
  ],
  "Adventure|Determination|Heroism|Life Story|Personal Bonds|Powerful|Tense": [
    85,
    73,
    1001,
    1003,
    83,
    3
  ],
  "Determination|Heroism|Life Story|Personal Bonds|Powerful|Reality|Tense": [
    73,
    85,
    1015,
    1013,
    3,
    83
  ],
  "Adventure|Against The Odds|Determination|Heroism|Personal Challenge|Powerful|Tense": [
    73,
    85,
    1003,
    1001,
    57,
    62
  ],
  "Against The Odds|Determination|Heroism|Personal Challenge|Powerful|Reality|Tense": [
    73,
    85,
    1013,
    1015,
    62,
    59
  ],
  "Adventure|Determination|Heroism|Life Story|Personal Challenge|Powerful|Tense": [
    85,
    73,
    1001,
    1003,
    59,
    62
  ],
  "Determination|Heroism|Life Story|Personal Challenge|Powerful|Reality|Tense": [
    85,
    73,
    1013,
    1015,
    59,
    62
  ],
  "Adventure|Against The Odds|Heroism|Personal Bonds|Powerful|Tense|Uplift": [
    73,
    1003,
    21,
    56,
    38,
    1007
  ],
  "Against The Odds|Heroism|Personal Bonds|Powerful|Reality|Tense|Uplift": [
    73,
    1015,
    38,
    56,
    21,
    1023
  ],
  "Adventure|Heroism|Life Story|Personal Bonds|Powerful|Tense|Uplift": [
    73,
    21,
    1003,
    1007,
    1005,
    85
  ],
  "Heroism|Life Story|Personal Bonds|Powerful|Reality|Tense|Uplift": [
    73,
    21,
    1015,
    1023,
    1017,
    1019
  ],
  "Adventure|Against The Odds|Heroism|Personal Challenge|Powerful|Tense|Uplift": [
    73,
    1003,
    21,
    1000,
    1008,
    85
  ],
  "Against The Odds|Heroism|Personal Challenge|Powerful|Reality|Tense|Uplift": [
    73,
    1015,
    21,
    1023,
    85,
    57
  ],
  "Adventure|Heroism|Life Story|Personal Challenge|Powerful|Tense|Uplift": [
    85,
    73,
    1003,
    21,
    1008,
    1005
  ],
  "Heroism|Life Story|Personal Challenge|Powerful|Reality|Tense|Uplift": [
    73,
    85,
    1015,
    21,
    1023,
    1017
  ],
  "Adventure|Against The Odds|Determination|Loss|Personal Bonds|Powerful|Tense": [
    23,
    3,
    56,
    38,
    73,
    83
  ],
  "Against The Odds|Determination|Loss|Personal Bonds|Powerful|Reality|Tense": [
    23,
    3,
    83,
    56,
    38,
    73
  ],
  "Adventure|Determination|Life Story|Loss|Personal Bonds|Powerful|Tense": [
    3,
    23,
    85,
    73,
    83,
    1001
  ],
  "Determination|Life Story|Loss|Personal Bonds|Powerful|Reality|Tense": [
    3,
    73,
    83,
    85,
    23,
    1013
  ],
  "Adventure|Against The Odds|Determination|Loss|Personal Challenge|Powerful|Tense": [
    59,
    85,
    23,
    3,
    62,
    73
  ],
  "Against The Odds|Determination|Loss|Personal Challenge|Powerful|Reality|Tense": [
    62,
    73,
    23,
    59,
    3,
    85
  ],
  "Adventure|Determination|Life Story|Loss|Personal Challenge|Powerful|Tense": [
    85,
    59,
    3,
    73,
    62,
    1001
  ],
  "Determination|Life Story|Loss|Personal Challenge|Powerful|Reality|Tense": [
    85,
    59,
    3,
    62,
    73,
    1013
  ],
  "Adventure|Against The Odds|Loss|Personal Bonds|Powerful|Tense|Uplift": [
    21,
    1007,
    1000,
    3,
    56,
    23
  ],
  "Against The Odds|Loss|Personal Bonds|Powerful|Reality|Tense|Uplift": [
    21,
    48,
    38,
    56,
    91,
    23
  ],
  "Adventure|Life Story|Loss|Personal Bonds|Powerful|Tense|Uplift": [
    21,
    1007,
    26,
    48,
    94,
    73
  ],
  "Life Story|Loss|Personal Bonds|Powerful|Reality|Tense|Uplift": [
    21,
    48,
    91,
    26,
    3,
    20
  ],
  "Adventure|Against The Odds|Loss|Personal Challenge|Powerful|Tense|Uplift": [
    21,
    1000,
    73,
    48,
    17,
    26
  ],
  "Against The Odds|Loss|Personal Challenge|Powerful|Reality|Tense|Uplift": [
    21,
    26,
    17,
    48,
    73,
    1023
  ],
  "Adventure|Life Story|Loss|Personal Challenge|Powerful|Tense|Uplift": [
    21,
    73,
    85,
    20,
    48,
    26
  ],
  "Life Story|Loss|Personal Challenge|Powerful|Reality|Tense|Uplift": [
    21,
    20,
    73,
    17,
    85,
    48
  ]
};
