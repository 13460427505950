import { connect } from "react-redux";
import store from "../store";
import UserForm from "./UserForm";

const mapStateToProps = state => ({
  thematicContent: state.thematicContent,
  thematicModal: state.thematicModal,
  thematicSubModal: state.thematicSubModal,
  dispatch: store.dispatch
});

export default connect(mapStateToProps)(UserForm);
