import {
  REQUEST_POPULAR_ITEMS,
  REQUEST_HERO_ITEMS,
  REQUEST_BASED_ON_ITEMS,
  REQUEST_CLIPS_ITEMS,
  OPEN_MODAL,
  CLOSE_MODAL,
  KEYWORD_CLICKED,
  CATEGORY_CLICKED,
  PROGRAM_DETAILS_TAB_CLICKED,
  PERSONALIZED_IMAGES_TAB_CLICKED,
  VIDEO_CLIPS_TAB_CLICKED,
  REASONS_TO_WATCH_TAB_CLICKED,
  UPDATE_PERSONALIZED_IMAGES,
  USER_SELECT_CLICKED,
  BUBBLE_CLICKED,
  WAIT_AFTER_CLICK,
  AUTH_REDIRECT,
  AUTH_MESSAGE,
  AUTH_SESSION,
  SUGGESTION_REQUESTED,
  SUGGESTIONS_RECEIVED,
  SEARCH_RESULT_CLICKED,
  FETCH_KW_ENABLED_CATEGORIES,
  CURATION_CLICKED,
  UPDATE_MOOD_JOURNEY,
  MOOD_QUADRANT_CLICKED,
  MOOD_MORE_LIKE_THIS_CLICKED,
  MOOD_PATH_CLICKED,
  MOOD_PATH_HOME_CLICKED,
  VIDEO_DESCRIPTORS_TAB_CLICKED,
  CAST_DETAILS_TAB_CLICKED,
  MOOD_BREAD_CRUMB_CLICKED,
  MOOD_HOME,
  OPEN_WOMEN,
  OPEN_MARVEL,
  OPEN_AGATHA,
  OPEN_POLITICS,
  OPEN_FACING,
  OPEN_THEMATIC_LANDING,
  CLOSE_THEMATIC_LANDING,
  CLOSE_ALL_THEMATIC
} from "../constants";

export const moodHomeCenterCircleClicked = (home = true) => ({
  type: MOOD_HOME,
  home
});

export const moreMoodsLikeThisClicked = moodsTitle => ({
  type: MOOD_MORE_LIKE_THIS_CLICKED,
  moodsTitle
});

export const updateMoodJourney = moods => ({
  type: UPDATE_MOOD_JOURNEY,
  moods
});

export const moodQuadrantClicked = quadrantData => ({
  type: MOOD_QUADRANT_CLICKED,
  quadrantData
});

export const moodPathClicked = (mood, id) => ({
  type: MOOD_PATH_CLICKED,
  mood,
  id
});

export const moodBreadCrumbClicked = (mood, index) => ({
  type: MOOD_BREAD_CRUMB_CLICKED,
  mood,
  index
});

export const moodPathHomeClicked = () => ({
  type: MOOD_PATH_HOME_CLICKED
});

export const curationClicked = showingCuration => ({
  type: CURATION_CLICKED,
  showingCuration
});

export const fetchKeywordEnabledCategories = categories => {
  return {
    type: FETCH_KW_ENABLED_CATEGORIES,
    categories
  };
};

export const requestPopularItems = () => {
  return {
    type: REQUEST_POPULAR_ITEMS
  };
};

export const requestHeroItems = () => {
  return {
    type: REQUEST_HERO_ITEMS
  };
};

export const requestBasedOnItems = () => {
  return {
    type: REQUEST_BASED_ON_ITEMS
  };
};

export const requestClipsItems = () => {
  return {
    type: REQUEST_CLIPS_ITEMS
  };
};

export const openModal = selectedContent => ({
  type: OPEN_MODAL,
  selectedContent
});

export const closeModal = () => ({
  type: CLOSE_MODAL
});

export const openWomen = () => ({
  type: OPEN_WOMEN
});

export const openMarvel = () => ({
  type: OPEN_MARVEL
});

export const openAgatha = () => ({
  type: OPEN_AGATHA
});

export const openPolitics = () => ({
  type: OPEN_POLITICS
});

export const openFacing = () => ({
  type: OPEN_FACING
});

export const openThematicLanding = () => ({
  type: OPEN_THEMATIC_LANDING
});

export const closeThematicLanding = () => ({
  type: CLOSE_THEMATIC_LANDING
});

export const closeAllThematic = () => ({
  type: CLOSE_ALL_THEMATIC
});

export const keywordClicked = (keyword, category) => ({
  type: KEYWORD_CLICKED,
  keyword,
  category
});

export const categoryClicked = (category, closeModal) => ({
  type: CATEGORY_CLICKED,
  category,
  closeModal
});

export const waitAfterClick = (wait, clicked) => ({
  type: WAIT_AFTER_CLICK,
  wait: wait,
  clicked: clicked
});

export const programDetailsTabClicked = () => ({
  type: PROGRAM_DETAILS_TAB_CLICKED
});

export const personalizedImagesTabClicked = () => ({
  type: PERSONALIZED_IMAGES_TAB_CLICKED
});

export const videoClipsTabClicked = () => ({
  type: VIDEO_CLIPS_TAB_CLICKED
});

export const reasonsToWatchTabClicked = () => ({
  type: REASONS_TO_WATCH_TAB_CLICKED
});

export const videoDescriptorsTabClicked = () => ({
  type: VIDEO_DESCRIPTORS_TAB_CLICKED
});

export const castDetailsTabClicked = () => ({
  type: CAST_DETAILS_TAB_CLICKED
})

export const personalizedImagesBadgeAdded = personalizedImages => ({
  type: UPDATE_PERSONALIZED_IMAGES,
  personalizedImages
});

export const userSelectClicked = (profile, lang) => ({
  type: USER_SELECT_CLICKED,
  profile,
  lang
});

export const bubbleClicked = id => ({
  type: BUBBLE_CLICKED,
  id
});

/**
 * Auth
 */
export const MESSAGE_WAIT = "wait";
export const MESSAGE_NO = "no";
export const MESSAGE_BYE = "bye";

export const authMessages = {
  wait: "loginProcessing",
  no: "loginNoAccess",
  bye: "logoutMessage"
};

export const authMessage = message => ({
  type: AUTH_MESSAGE,
  message: authMessages[message]
});
export const authRedirect = redirect => ({
  type: AUTH_REDIRECT,
  authRedirect: redirect,
  session: { expires: 0, authenticated: false }
});
export const authSession = session => ({
  type: AUTH_SESSION,
  session: session
});
/**
 * Search
 */
export const suggestionRequested = (title, sortxPop) => ({
  type: SUGGESTION_REQUESTED,
  title,
  sortxPop
});

export const suggestionsReceived = (term, results) => ({
  type: SUGGESTIONS_RECEIVED,
  term,
  results
});

export const searchResultClicked = prog => ({
  type: SEARCH_RESULT_CLICKED,
  prog
});
