import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import reducers from "../reducers";
import initialState from "./initial-state";
import sagas from "./sagas";
import { HOME } from "../constants";

import Amplify from "aws-amplify";
import { config } from "../config";

const sagaMiddleware = createSagaMiddleware();

const middleware = [sagaMiddleware];
const enhancers = [];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    reducers,
    initialState,
    composeEnhancers(applyMiddleware(...middleware), ...enhancers)
);

Amplify.configure(config.amplify);

sagaMiddleware.run(sagas);

store.dispatch({
    type: HOME
});

export default store;
