import React from "react";
import styled from "styled-components";
import { UI } from "./constants";

const CarouselPoster = styled.img`
    grid-area: 1 / 1
    width: 100%;
    cursor: pointer;
    filter: drop-shadow(0 0.2rem 0.2rem rgba(0, 0, 0, 0.5));
    opacity: 1;
`;

const CarouselPosterNoClick = styled(CarouselPoster)`
  cursor: default;
`;

const CarouselPosterDiv = styled.div`
    grid-area: 1 / 1    
    cursor: pointer;
    filter: drop-shadow(0 0.2rem 0.2rem rgba(0, 0, 0, 0.5));
    opacity: 1;
    background:  url(${props => props.img}) no-repeat; 
    background-size: contain;   
    margin-bottom: 5px;
`;

const CarouselPosterSwapDiv = styled(CarouselPosterDiv)`
  transition-delay: 0.1s;
  transition-timing-function: cubic-bezier(0, 1, 1, 0);
  &:hover {
    width: 22.2vw !important;
    background: url(${props => props.swapImg});
    background-size: cover;
  }
`;

const dotStyles = {
  color: UI.color.nielsenBlue,
  marginLeft: ".5rem"
};
/**
 * Circle options:
 * • BULLET
 * ● BLACK CIRCLE
 * ⬤ BLACK LARGE CIRCLE
 * const BULLET = "\u2022";
 * const BLACK_LARGE_CIRCLE = "\u2B24";
 */
const BLACK_CIRCLE = "\u25CF";
const PI_DOT = BLACK_CIRCLE;
const ProgramTitle = ({ className, _, title, showDot }) => (
  <div>
    <span className={className}>{title}</span>
    {showDot ? <span style={dotStyles}>{PI_DOT}</span> : ""}
  </div>
);

const StyledProgramTitle = styled(ProgramTitle)`
  color: ${UI.color.gray};
  font-size: ${UI.font.tiny};
  padding: 0.1rem;
  cursor: pointer;
`;

const StyledCastName = styled(StyledProgramTitle)`
  color:white;
  font-size: ${UI.font.tiny};
  cursor: default;
  text-align:center;
`;

// const altText = item => {
//     return `Poster "${item.title || item.uri || ``}"`;
// };

//
// if item lazy true then do data-src
// and keep swiper-lazy classnames
//

export const Poster = (item, key, className) => {
  if (!className) {
    className = "spacefilling";
  }
  if (item.thematic === "landscape") {
    return (
      <div key={key}>
        <CarouselPosterNoClick src={item.uri} className="thematic-landscape" />
      </div>
    );
  }
  if (item.thematic === "square") {
    return (
      <div key={key}>
        <CarouselPosterNoClick src={item.uri} className="thematic-square" />
      </div>
    );
  }
  if (item.thematic === "poster") {
    return (
      <div key={key}>
        <CarouselPosterNoClick src={item.uri} className="thematic-poster" />
      </div>
    );
  }
  if (item.thematic === "circle") {
    return (
      <div key={key}>
        <CarouselPosterNoClick src={item.uri} className="thematic-circle" />
      </div>
    );
  }
  if (item.lazyLoad) {
    return (
      <div key={key} onClick={item.action}>
        <CarouselPoster
          data-src={item.uri}
          className={`swiper-lazy ${className}`}
        />
        <div className="swiper-lazy-preloader swiper-lazy-preloader-white" />
        <StyledProgramTitle
          title={item.title}
          showDot={item.hasPersonalizedImages}
        />
      </div>
    );
  } else
    return item.swapItem ? (
      <div key={key} className="swap" onClick={item.action}>
        <div data-src={item.uri}>
          <CarouselPosterDiv
            className={className}
            img={item.uri}
            swapImg={item.swapItem}
          />
        </div>
        <StyledProgramTitle
          title={item.title}
          showDot={item.hasPersonalizedImages}
        />
      </div>
    ) : (
      <div key={key} onClick={item.action}>
        <CarouselPoster src={item.uri} className={className} />
        <StyledProgramTitle
          title={item.title}
          showDot={item.hasPersonalizedImages}
        />
      </div>
    );
};

/**
 * This poster is designed to expand to accomodate a video clip
 * @param {Object} item - a program item, probably that has been filtered by ViewModel
 * @param {*} key - for react's sake
 * @returns a div suitable for use as a swiper-slide.
 */
export const SpaceFillingTitleFreePoster = (item, key) => {
  const className = "spacefilling";
  if (item.lazyLoad) {
    return (
      <div key={key} onClick={item.action}>
        <CarouselPoster
          data-src={item.uri}
          className={`swiper-lazy ${className}`}
        />
        <div className="swiper-lazy-preloader swiper-lazy-preloader-white" />
      </div>
    );
  } else
    return item.swapItem ? (
      <div key={key} className="swap" onClick={item.action}>
        <div data-src={item.uri}>
          <CarouselPosterSwapDiv
            className={className}
            img={item.uri}
            swapImg={item.swapItem}
          />
        </div>
      </div>
    ) : (
      <div key={key} className="swap" onClick={item.action}>
        <CarouselPosterDiv img={item.uri} className={`${className}`} />
      </div>
    );
};

export const TitleFreePoster = (item, key) => {
  return (
    <div key={key} onClick={item.action}>
      <CarouselPoster data-src={item.uri} className="swiper-lazy untitled" />
      <div className="swiper-lazy-preloader swiper-lazy-preloader-white" />
    </div>
  );
};

export const CastPoster = (item, key) => {
  return (
    <div key={key}>
      <CarouselPosterNoClick
        data-src={
          (item.celebrityImage && item.celebrityImage.uri) ||
          `/personPlaceholder.png`
        }
        className="swiper-lazy entitledperson"
      />
      <div className="swiper-lazy-preloader swiper-lazy-preloader-white" />
      <StyledCastName title={item.name} showDot={false} />
    </div>
  );
};

/**
 * This poster is intended to be used outside of a <Carousel/>.
 * @param {*} item a data item
 * @param {*} key a domain key
 * @returns 
 */
export const CastPosterFree = (item, key) => {
  return (
    <div style={{ textAlign: "center" }} key={key}>
      <CarouselPosterNoClick
        src={
          (item.celebrityImage && item.celebrityImage.uri) ||
          `/personPlaceholder.png`
        }
        className="entitledperson"
      />
      {/* <div className="swiper-lazy-preloader swiper-lazy-preloader-white" /> */}
      <StyledCastName title={item.name} showDot={false} />
    </div>
  );
};
