import React from "react";
import styled from "styled-components";
import { CastPosterFree } from "../Poster";

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 95%;
  height 75%;
  overflow-y:auto;
  margin-top: 3.8rem;
`;

const CastGrid = styled.div`
display:flex;
flex-wrap:wrap;
justify-content: space-evenly;
align-items: flex-end;
margin: 0 2rem 0;
gap:2rem;
`;

const PosterDiv = styled.div`
margin-bottom:0.3rem
`;

const Cast = ({ data }) => {
  const {
    cast,
    // lang,
  } = data;
  const numCast = cast.length;


  return (
    <Wrapper>
      {numCast > 0 && (
        <CastGrid>

          {cast.map((c, i) => <PosterDiv key={c.name.replaceAll(" ", "")}>{CastPosterFree(c, i)}</PosterDiv>)}

        </CastGrid>)}
    </Wrapper>
  );
};

export const CastDetails = styled(Cast)`
  display: grid;
  grid-template-columns: 5% 85.3rem 5%;
  grid-template-areas:
    ". program ."
    ". castarea .";
`;

export default CastDetails;
