import { connect } from "react-redux";
import KeywordGraph from "./KeywordGraph.js";
import store from "../store";

const mapStateToProps = state => ({
    keywordGraphData: state.fullscreen.keywordGraphData,
    dispatch: store.dispatch
});

export default connect(mapStateToProps)(KeywordGraph);
