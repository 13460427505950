/**
 * Display a placeholder until the final image loads.
 */
import React from "react";

class ImgOnLoad extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            src: ""
        };
    }

    componentDidMount = () => {
        this.setState({ src: this.props.placeholder });
        if (this.props.image) {
            this.loader = new Image();
            this.loader.onload = this.imageOnLoad;
            this.loader.src = this.props.image;
        }
    };

    componentWillUnmount = () => {
        if (this.loader) {
            //Remove the listener to stop set state on umounted component errors.
            this.loader.onload = null;
        }
    };

    imageOnLoad = () => {
        if (this.state.src !== this.loader.src) {
            this.setState({ src: this.loader.src });
        }
    };

    render() {
        let props = { src: this.state.src }; // Must have a src.
        if (this.props.style) {
            props.style = this.props.style;
        }
        if (this.props.alt) {
            props.alt = this.props.alt;
        }
        if (this.props.width && this.props.height) {
            props.width = this.props.width;
            props.height = this.props.height;
        }

        // "img elements must have an alt prop, either with meaningful text,
        //  or an empty string for decorative images"
        // eslint-disable-next-line
        return <img {...props} className={this.props.className} />;
    }
}

export default ImgOnLoad;
