/**
 * Helpers to fetch strings from stringdict, based on language.
 */
import React from "react";
import dict from "./stringdict";
/**
 * Return a string from the dictionary in a <span/>
 *
 * @param {Object} props the props object, must contain lang and which members
 */
export const StringSimple = props => dict[props.which][props.lang];

/**
 * Return a string from the dictionary in a <span/>
 *
 * @param {Object} props the props object, must contain lang and which members
 */
export const DynamicStringSimple = props => props.lang;

/**
 * Return a string directly from the dictionary, wrapped in a span.
 *
 * @param {Object} props the props object, must contain lang and which members
 */
export const StringSpan = props => <span>{StringSimple(props)}</span>;

/**
 * Return a string directly from the dictionary, wrapped in a span, with a className.
 *
 * @param {Object} props the props object, must contain className, lang and which members
 */
export const StringClassedSpan = props =>
  props.dynamic ? (
    <span className={props.className}>{DynamicStringSimple(props)}</span>
  ) : (
    <span className={props.className}>{StringSimple(props)}</span>
  );

/**
 * Produces a sentence that describes the user's tastes:
 *
 * Parker enjoys **Emotional** stories that feature **Temptation** and **Love Story** situations.
 *
 * @param {Object} props shall contain:
 * @name _lang_ the current lang
 * @name _firstName_ the profile persona's first name
 * @name _mood_ the mood of stories firstName likes. Probably an adjective.
 * @name _theme_ the theme of the story, probably a noun
 * @name _scenario_ the scenario of the story, probably a noun.
 */
export const StringTasteProfile = props =>
  dict.profileMadLib(
    props.lang,
    props.firstName,
    props.mood,
    props.theme,
    props.scenario
  );

/**
 * Return a search result string, varying based on the language and the length of the results.
 *
 * @param {Object} props shall contain:
 * @name _lang_ the current language
 * @name _length_ the length of the results
 */
export const SearchResultString = props =>
  dict.searchResultString(props.lang, props.length);
