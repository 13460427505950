import React from "react";
// <?xml version="1.0" encoding="utf-8"?>
// <!-- Generator: Adobe Illustrator 23.0.4, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
// <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
// 	 viewBox="0 0 1750 1750" style="enable-background:new 0 0 1750 1750;" xml:space="preserve">
// <style type="text/css">
// 	.st0{fill-rule:evenodd;clip-rule:evenodd;stroke:#FFFFFF;stroke-width:94.5;}
// 	.st1{fill:#00ADEE;}
// </style>
// <title>TP icon</title>
// <desc>Created with Sketch.</desc>
// <g>
// 	<g id="icon">
// 		<g id="TP-icon" transform="translate(48.000000, 48.000000)">
// 			<g id="TP-Badge">
// 				<circle id="black" class="st0" cx="827" cy="827" r="827"/>
// 			</g>
// 		</g>
// 	</g>
// </g>
// <polygon class="st1" points="876.4,1298.6 642.2,950.8 408.1,602.9 876.4,602.9 1344.7,602.9 1110.5,950.8 "/>
// </svg>

class CurateButtonIconSVG extends React.Component {
    render = () => {
        //const color = this.props.color || "black";
        const data = `<?xml version="1.0" encoding="utf-8"?>
        <!-- Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 1750 1750" style="enable-background:new 0 0 1750 1750;" xml:space="preserve">
        <style type="text/css">
            .st0{stroke:#FFFFFF;stroke-width:94.5;}
            .st1{fill:#00ADEE;}
        </style>
        <title>TP icon</title>
        <desc>Created with Sketch.</desc>
        <g>
            <g id="icon">
                <g id="TP-icon" transform="translate(48.000000, 48.000000)">
                    <g id="TP-Badge">
                        <circle id="black" class="st0" cx="827" cy="827" r="827"/>
                    </g>
                </g>
            </g>
        </g>
        <path class="st1" d="M740,1385.7h270v-180H740V1385.7z M267.5,531.2v180h1215v-180H267.5z M470,1048.9h810V869H470V1048.9z"/>
        </svg>`;
        const data64 = `data:image/svg+xml;base64,${btoa(data)}`;
        return <img src={data64} alt={this.props.alt} />;
    };
}
export default CurateButtonIconSVG;
