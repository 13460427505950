import { modularScale, hsl } from "polished";

export const MOOD_HOME = "MOOD_HOME";
export const MOOD_CAROUSEL_TITLE_CLICKED = "MOOD_CAROUSEL_TITLE_CLICKED";
export const OPEN_MOOD_GRID_MODAL = "OPEN_MOOD_GRID_MODAL";
export const IS_GOOGLE = "IS_GOOGLE";
export const OPEN_MOOD_SWITCH_MODAL = "OPEN_MOOD_SWITCH_MODAL";
export const MOOD_MORE_LIKE_THIS_CLICKED = "MOOD_MORE_LIKE_THIS_CLICKED";
export const UPDATE_MOOD_JOURNEY = "UPDATE_MOOD_JOURNEY";
export const MOOD_JOURNEY_CLICKED = "MOOD_JOURNEY_CLICKED";
export const MOOD_QUADRANT_CLICKED = "MOOD_QUADRANT_CLICKED";
export const MOOD_PATH_CLICKED = "MOOD_PATH_CLICKED";
export const MOOD_PATH_HOME_CLICKED = "MOOD_PATH_HOME_CLICKED";
export const MOOD_BREAD_CRUMB_CLICKED = "MOOD_BREAD_CRUMB_CLICKED";
export const ADD_MOOD_ITEMS_TO_LIST = "ADD_MOOD_ITEMS_TO_LIST";
export const GET_ALL_GENRE_NAMES = "GET_ALL_GENRE_NAMES";
export const DISPLAY_GENRE_SEARCH_RESULTS = "DISPLAY_GENRE_SEARCH_RESULTS";
export const GENRE_NAME_CLICKED = "GENRE_NAME_CLICKED";
export const GENRE_NAMES_CLEARED = "GENRE_NAMES_CLEARED";
export const CURATION_CLICKED = "CURATION_CLICKED";
export const SUGGESTION_REQUESTED = "SUGGESTION_REQUESTED";
export const SUGGESTIONS_RECEIVED = "SUGGESTIONS_RECEIVED";
export const SEARCH_RESULT_CLICKED = "SEARCH_RESULT_CLICKED";
export const REQUEST_POPULAR_ITEMS = "REQUEST_POPULAR_ITEMS";
export const ADD_POPULAR_ITEMS_TO_LIST = "ADD_POPULAR_ITEMS_TO_LIST";
export const ADD_ADVANCED_CATEGORIES_TO_LIST =
  "ADD_ADVANCED_CATEGORIES_TO_LIST";
export const ADD_PROFILE_NAMES_TO_LIST = "ADD_PROFILE_NAMES_TO_LIST";

export const REQUEST_HERO_ITEMS = "REQUEST_HERO_ITEMS";
export const REQUEST_BASED_ON_ITEMS = "REQUEST_BASED_ON_ITEMS";
export const REQUEST_CLIPS_ITEMS = "REQUEST_CLIPS_ITEMS";
export const ADD_HERO_ITEMS_TO_LIST = "ADD_HERO_ITEMS_TO_LIST";
export const ADD_BASED_ON_ITEMS_TO_LIST = "ADD_BASED_ON_ITEMS_TO_LIST";
export const ADD_CLIPS_ITEMS_TO_LIST = "ADD_CLIPS_ITEMS_TO_LIST";

export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";

export const KEYWORD_CLICKED = "KEYWORD_CLICKED";
export const CATEGORY_CLICKED = "CATEGORY_CLICKED";

export const CREATE_KEYWORD_GRAPH_DATA = "CREATE_KEYWORD_GRAPH_DATA";
export const BOOTSTRAP_KEYWORD_GRAPH_DATA = "BOOTSTRAP_KEYWORD_GRAPH_DATA";
export const FILLING_KEYWORD_GRAPH = "FILLING_KEYWORD_GRAPH";
export const ADDING_CHILD_KEYWORDS = "ADDING_CHILD_KEYWORDS";

export const PROGRAM_DETAILS_TAB_CLICKED = "PROGRAM_DETAILS_TAB_CLICKED";
export const VIDEO_DESCRIPTORS_TAB_CLICKED = "VIDEO_DESCRIPTORS_TAB_CLICKED";
export const CAST_DETAILS_TAB_CLICKED = "CAST_DETAILS_TAB_CLICKED";
export const PERSONALIZED_IMAGES_TAB_CLICKED =
  "PERSONALIZED_IMAGES_TAB_CLICKED";
export const VIDEO_CLIPS_TAB_CLICKED = "VIDEO_CLIPS_TAB_CLICKED";
export const REASONS_TO_WATCH_TAB_CLICKED = "REASONS_TO_WATCH_TAB_CLICKED";
export const UPDATE_PERSONALIZED_IMAGES = "UPDATE_PERSONALIZED_IMAGES";

/* these are used by the ContentItemDetails component to pick a child to display */
export const PROGRAM_DETAILS_SELECTED = "PROGRAM_DETAILS_SELECTED";
export const CAST_DETAILS_SELECTED = "CAST_DETAILS_SELECTED";
export const VIDEO_DESCRIPTORS_SELECTED = "VIDEO_DESCRIPTORS_SELECTED";
export const PERSONALIZED_IMAGES_SELECTED = "PERSONALIZED_IMAGES_SELECTED";
export const VIDEO_CLIPS_SELECTED = "VIDEO_CLIPS_SELECTED";
export const REASONS_TO_WATCH_SELECTED = "REASONS_TO_WATCH_SELECTED";
export const NO_PERSONALIZED = "NO_PERSONALIZED";

/* user dropdown */
export const USER_SELECT_CLICKED = "USER_SELECT_CLICKED";
export const TASTE_PROFILE_CLICKED = "TASTE_PROFILE_CLICKED";

/* taste profile */
export const ADD_TASTE_PROFILE_ITEMS_TO_LIST =
  "ADD_TASTE_PROFILE_ITEMS_TO_LIST";

export const BUBBLE_CLICKED = "BUBBLE_CLICKED";

export const WAIT_AFTER_CLICK = "WAIT_AFTER_CLICK";

/* oauth */
export const AUTH_SESSION = "AUTH_SESSION";
/* UUM/Amplify */
export const AUTH_REDIRECT = "AUTH_REDIRECT";
export const AUTH_MESSAGE = "AUTH_MESSAGE";
export const LOGOUT = "LOGOUT";
export const LOGIN = "LOGIN";
export const FETCH_KW_ENABLED_CATEGORIES = "FETCH_KW_ENABLED_CATEGORIES";

export const OPEN_WOMEN = "OPEN_WOMEN";
export const OPEN_MARVEL = "OPEN_MARVEL";
export const OPEN_AGATHA = "OPEN_AGATHA";
export const OPEN_POLITICS = "OPEN_POLITICS";
export const OPEN_FACING = "OPEN_FACING";
export const OPEN_THEMATIC_LANDING = "OPEN_THEMATIC_LANDING";
export const CLOSE_THEMATIC_LANDING = "CLOSE_THEMATIC_LANDING";
export const CLOSE_ALL_THEMATIC = "CLOSE_ALL_THEMATIC";
export const ADD_THEMATIC_CATEGORIES_TO_LIST =
  "ADD_THEMATIC_CATEGORIES_TO_LIST";

// Reasons To Watch Types
export const REASON_TO_WATCH_TYPE_AWARD = "award";
export const REASON_TO_WATCH_TYPE_CONCEPT_SOURCE = "concept_source";
export const REASON_TO_WATCH_TYPE_CRITIC_REVIEW = "critic_review";
export const REASON_TO_WATCH_TYPE_MASHUP = "mashup";
export const REASON_TO_WATCH_TYPE_PEOPLE = "people";

export const reasonTypeToDictKeyMap = new Map([
  [REASON_TO_WATCH_TYPE_AWARD, "rtwAward"],
  [REASON_TO_WATCH_TYPE_CONCEPT_SOURCE, "rtwConceptSource"],
  [REASON_TO_WATCH_TYPE_CRITIC_REVIEW, "rtwCriticReview"],
  [REASON_TO_WATCH_TYPE_MASHUP, "rtwMashup"],
  [REASON_TO_WATCH_TYPE_PEOPLE, "rtwPeopleBased"]
]);

/**
 * What request will we make after the saga is running
 */
export const HOME = "HOME";

const fontScale = multiplier => {
  // Adjust based on 1 root "em" (see App.js, where this value is probably
  // still set to 1vw, or 1% of the view width)
  return modularScale(multiplier, 1, "majorThird");
};

export const SORTED_KWCATEGORIES = new Map([
  ["Video Mood", 0],
  ["Scenario", 1],
  ["Theme", 2],
  ["Character", 3],
  ["Subject - Issues", 4],
  ["Subject - Milieus", 5],
  ["Subject - Personal Issues", 6],
  ["Subject - Specific Beings & Groups", 7],
  ["Subject - Specific Events", 8],
  ["Subject - Specific Locations", 9],
  ["Subject - Practices", 10],
  ["Setting - Place", 11],
  ["Setting - Occasions", 12],
  ["Setting - Time Period", 13],
  ["Concept Source", 14]
]);

// controls number of keywords shown horizontally in the modal:
export const NKEYWORDS = 7;

// for background images grid
export const NUM_TITLES = 48;

export const UI = Object.freeze({
  color: {
    nielsenBlue: "#00aeef",
    gradientLight: "#3e3e3e",
    gradientDark: "#181818",
    gray: "#89898b",
    black: "#000000",
    transparancySuffix: (t) => Math.ceil((t * 255)).toString(16),
    clashing: hsl(111, 1.0, 0.45),
    // Use 'clashing' to make obvious when anything hasn't
    // _explicitly_ had its color set.
    keywords: {
      0: hsl(272, 0.69, 0.65),
      1: hsl(60, 0.8, 0.71),
      2: hsl(40, 0.34, 0.52),
      3: hsl(99, 0.79, 0.67),
      4: hsl(331, 0.89, 0.68),
      5: hsl(331, 0.89, 0.68),
      6: hsl(203, 0.79, 0.66),
      7: hsl(203, 0.79, 0.66),
      8: hsl(203, 0.79, 0.66),
      9: hsl(23, 1, 0.7)
    }
  },
  font: {
    // See https://polished.js.org/docs/#modularscale
    // and https://polished.js.org/docs/#modularscaleratio
    baseline: fontScale(0),
    xtiny: fontScale(0.5),
    tiny: fontScale(1),
    mediumsmall: fontScale(1.5),
    small: fontScale(2),
    smallmedium: fontScale(1.5),
    medium: fontScale(3),
    large: fontScale(4),
    huge: fontScale(5),
    iconFamily: "Material+Symbols+Rounded",
    defaultFamily: "Roboto",
    weight: {
      light: 100,
      regular: 400,
      medium: 500,
      bold: 700
    },
    color: {
      subtitle: "#BDBEC1FF"
    }
  },
  shadow: {
    soft: "drop-shadow(0 0.2rem 1rem rgba(0, 0, 0, 0.5))"
  },
  arrow: {
    off: "off",
    standard: "middle-arrow",
    carousel: "leaf-arrow"
  },
  layout: {
    boxRadius: "4px",
    // These two values currently control the text baselines in the modal
    keywordVMargin: "0.5rem",
    keywordVPadding: "0.2rem",
    modalMargin: 0,
    modalLeft: "3.4rem",
    lineHeight: "2.8rem"
  }
});

export const colorForCategory = (category, indexForColor) => {
  if (SORTED_KWCATEGORIES.get(category) === undefined) {
    return UI.color.clashing;
  }

  if (indexForColor) {
    return UI.color.keywords[
      indexForColor % Object.keys(UI.color.keywords).length
    ];
  } else
    return UI.color.keywords[
      SORTED_KWCATEGORIES.get(category) % Object.keys(UI.color.keywords).length
    ];
};

export const VM_HOME_BACKGROUND_IMAGES_B = [
  {
    id: "12543830",
    uri: "https://ondemo.tmsimg.com/assets/assets/p12543830_v_h9_am.jpg"
  },
  {
    id: "16356852",
    uri: "https://ondemo.tmsimg.com/assets/assets/p16356852_v_h9_ab.jpg"
  },
  {
    id: "16584944",
    uri: "https://ondemo.tmsimg.com/assets/assets/p16584944_v_h9_ab.jpg"
  },
  {
    id: "8193232",
    uri: "https://ondemo.tmsimg.com/assets/assets/p8193232_v_h9_aa.jpg"
  },
  {
    id: "12386480",
    uri: "https://ondemo.tmsimg.com/assets/assets/p12386480_v_h9_ab.jpg"
  },
  {
    id: "12854971",
    uri: "https://ondemo.tmsimg.com/assets/assets/p12854971_v_h9_aa.jpg"
  },
  {
    id: "26553",
    uri: "https://ondemo.tmsimg.com/assets/assets/p26553_v_h9_aa.jpg"
  },
  {
    id: "8696245",
    uri: "https://ondemo.tmsimg.com/assets/assets/p8696245_v_h9_aa.jpg"
  },
  {
    id: "35003",
    uri: "https://ondemo.tmsimg.com/assets/assets/p35003_v_h9_aa.jpg"
  },
  {
    id: "15054143",
    uri: "https://ondemo.tmsimg.com/assets/assets/p15054143_v_h9_ab.jpg"
  },
  {
    id: "87313",
    uri: "https://ondemo.tmsimg.com/assets/assets/p87313_v_h9_aa.jpg"
  },
  {
    id: "16236207",
    uri: "https://ondemo.tmsimg.com/assets/assets/p16236207_v_h9_ab.jpg"
  },
  {
    id: "32829",
    uri: "https://ondemo.tmsimg.com/assets/assets/p32829_v_h9_aa.jpg"
  },
  {
    id: "18517",
    uri: "https://ondemo.tmsimg.com/assets/assets/p18517_v_h9_af.jpg"
  },
  {
    id: "28877",
    uri: "https://ondemo.tmsimg.com/assets/assets/p28877_v_h9_af.jpg"
  },
  {
    id: "34715",
    uri: "https://ondemo.tmsimg.com/assets/assets/p34715_v_h9_aa.jpg"
  },
  {
    id: "12872",
    uri: "https://ondemo.tmsimg.com/assets/assets/p12872_v_h9_aa.jpg"
  },
  {
    id: "8414185",
    uri: "https://ondemo.tmsimg.com/assets/assets/p8414185_v_h9_ae.jpg"
  },
  {
    id: "14568731",
    uri: "https://ondemo.tmsimg.com/assets/assets/p14568731_v_h9_ac.jpg"
  },
  {
    id: "32974",
    uri: "https://ondemo.tmsimg.com/assets/assets/p32974_v_h9_ab.jpg"
  },
  {
    id: "8410544",
    uri: "https://ondemo.tmsimg.com/assets/assets/p8410544_v_h9_ab.jpg"
  },
  {
    id: "159836",
    uri: "https://ondemo.tmsimg.com/assets/assets/p159836_v_h9_aa.jpg"
  },
  {
    id: "14939189",
    uri: "https://ondemo.tmsimg.com/assets/assets/p14939189_v_h9_ac.jpg"
  },
  {
    id: "13663846",
    uri: "https://ondemo.tmsimg.com/assets/assets/p13663846_v_h9_ag.jpg"
  },
  {
    id: "16974",
    uri: "https://ondemo.tmsimg.com/assets/assets/p16974_v_h9_ad.jpg"
  },
  {
    id: "179969",
    uri: "https://ondemo.tmsimg.com/assets/assets/p179969_v_h9_aa.jpg"
  },
  {
    id: "8174",
    uri: "https://ondemo.tmsimg.com/assets/assets/p8174_v_h9_aa.jpg"
  },
  {
    id: "15791706",
    uri: "https://ondemo.tmsimg.com/assets/assets/p15791706_v_h9_ab.jpg"
  },
  {
    id: "13579498",
    uri: "https://ondemo.tmsimg.com/assets/assets/p13579498_v_h9_aa.jpg"
  },
  {
    id: "26586",
    uri: "https://ondemo.tmsimg.com/assets/assets/p26586_v_h9_aa.jpg"
  },
  {
    id: "15444050",
    uri: "https://ondemo.tmsimg.com/assets/assets/p15444050_v_h9_ad.jpg"
  },
  {
    id: "8640396",
    uri: "https://ondemo.tmsimg.com/assets/assets/p8640396_v_h9_aa.jpg"
  },
  {
    id: "8395508",
    uri: "https://ondemo.tmsimg.com/assets/assets/p8395508_v_h9_ac.jpg"
  },
  {
    id: "21455",
    uri: "https://ondemo.tmsimg.com/assets/assets/p21455_v_h9_aa.jpg"
  },
  {
    id: "11806131",
    uri: "https://ondemo.tmsimg.com/assets/assets/p11806131_v_h9_aa.jpg"
  },
  {
    id: "30099",
    uri: "https://ondemo.tmsimg.com/assets/assets/p30099_v_h9_aa.jpg"
  },
  {
    id: "90362",
    uri: "https://ondemo.tmsimg.com/assets/assets/p90362_v_h9_aa.jpg"
  },
  {
    id: "16624270",
    uri: "https://ondemo.tmsimg.com/assets/assets/p16624270_v_h9_ab.jpg"
  },
  {
    id: "34930",
    uri: "https://ondemo.tmsimg.com/assets/assets/p34930_v_h9_ad.jpg"
  },
  {
    id: "28720",
    uri: "https://ondemo.tmsimg.com/assets/assets/p28720_v_h9_aa.jpg"
  },
  {
    id: "33094",
    uri: "https://ondemo.tmsimg.com/assets/assets/p33094_v_h9_ab.jpg"
  },
  {
    id: "24465",
    uri: "https://ondemo.tmsimg.com/assets/assets/p24465_v_h9_aa.jpg"
  },
  {
    id: "23343",
    uri: "https://ondemo.tmsimg.com/assets/assets/p23343_v_h9_ad.jpg"
  },
  {
    id: "9872448",
    uri: "https://ondemo.tmsimg.com/assets/assets/p9872448_v_h9_aa.jpg"
  },
  {
    id: "36158",
    uri: "https://ondemo.tmsimg.com/assets/assets/p36158_v_h9_aa.jpg"
  },
  {
    id: "15766692",
    uri: "https://ondemo.tmsimg.com/assets/assets/p15766692_v_h9_ae.jpg"
  },
  {
    id: "8129775",
    uri: "https://ondemo.tmsimg.com/assets/assets/p8129775_v_h9_aa.jpg"
  },
  {
    id: "8238161",
    uri: "https://ondemo.tmsimg.com/assets/assets/p8238161_v_h9_aa.jpg"
  }
];

export const VM_HOME_BACKGROUND_IMAGES_A = [
  {
    id: "8640396",
    uri: "https://ondemo.tmsimg.com/assets/assets/p8640396_v_h9_aa.jpg"
  },
  {
    id: "12417188",
    uri: "https://ondemo.tmsimg.com/assets/assets/p12417188_v_h9_aa.jpg"
  },
  {
    id: "14603082",
    uri: "https://ondemo.tmsimg.com/assets/assets/p14603082_v_h9_ac.jpg"
  },
  {
    id: "23343",
    uri: "https://ondemo.tmsimg.com/assets/assets/p23343_v_h9_ad.jpg"
  },
  {
    id: "11806131",
    uri: "https://ondemo.tmsimg.com/assets/assets/p11806131_v_h9_aa.jpg"
  },
  {
    id: "30099",
    uri: "https://ondemo.tmsimg.com/assets/assets/p30099_v_h9_aa.jpg"
  },
  {
    id: "14568731",
    uri: "https://ondemo.tmsimg.com/assets/assets/p14568731_v_h9_ac.jpg"
  },
  {
    id: "21455",
    uri: "https://ondemo.tmsimg.com/assets/assets/p21455_v_h9_aa.jpg"
  },
  {
    id: "9872448",
    uri: "https://ondemo.tmsimg.com/assets/assets/p9872448_v_h9_aa.jpg"
  },
  {
    id: "25574",
    uri: "https://ondemo.tmsimg.com/assets/assets/p25574_v_h9_ad.jpg"
  },
  {
    id: "15456641",
    uri: "https://ondemo.tmsimg.com/assets/assets/p15456641_v_h9_ab.jpg"
  },
  {
    id: "33094",
    uri: "https://ondemo.tmsimg.com/assets/assets/p33094_v_h9_ab.jpg"
  },
  {
    id: "8174",
    uri: "https://ondemo.tmsimg.com/assets/assets/p8174_v_h9_aa.jpg"
  },
  {
    id: "15791706",
    uri: "https://ondemo.tmsimg.com/assets/assets/p15791706_v_h9_ab.jpg"
  },
  {
    id: "166244",
    uri: "https://ondemo.tmsimg.com/assets/assets/p166244_v_h9_aa.jpg"
  },
  {
    id: "23069",
    uri: "https://ondemo.tmsimg.com/assets/assets/p23069_v_h9_ab.jpg"
  },
  {
    id: "14939189",
    uri: "https://ondemo.tmsimg.com/assets/assets/p14939189_v_h9_ac.jpg"
  },
  {
    id: "13663846",
    uri: "https://ondemo.tmsimg.com/assets/assets/p13663846_v_h9_ag.jpg"
  },
  {
    id: "8238161",
    uri: "https://ondemo.tmsimg.com/assets/assets/p8238161_v_h9_aa.jpg"
  },
  {
    id: "12543830",
    uri: "https://ondemo.tmsimg.com/assets/assets/p12543830_v_h9_am.jpg"
  },
  {
    id: "195165",
    uri: "https://ondemo.tmsimg.com/assets/assets/p195165_v_h9_ab.jpg"
  },
  {
    id: "32974",
    uri: "https://ondemo.tmsimg.com/assets/assets/p32974_v_h9_ab.jpg"
  },
  {
    id: "12118906",
    uri: "https://ondemo.tmsimg.com/assets/assets/p12118906_v_h9_aa.jpg"
  },
  {
    id: "181640",
    uri: "https://ondemo.tmsimg.com/assets/assets/p181640_v_h9_ae.jpg"
  },
  {
    id: "28877",
    uri: "https://ondemo.tmsimg.com/assets/assets/p28877_v_h9_af.jpg"
  },
  {
    id: "34715",
    uri: "https://ondemo.tmsimg.com/assets/assets/p34715_v_h9_aa.jpg"
  },
  {
    id: "12386480",
    uri: "https://ondemo.tmsimg.com/assets/assets/p12386480_v_h9_ab.jpg"
  },
  {
    id: "12854971",
    uri: "https://ondemo.tmsimg.com/assets/assets/p12854971_v_h9_aa.jpg"
  },
  {
    id: "8034490",
    uri: "https://ondemo.tmsimg.com/assets/assets/p8034490_v_h9_aa.jpg"
  },
  {
    id: "16356852",
    uri: "https://ondemo.tmsimg.com/assets/assets/p16356852_v_h9_ab.jpg"
  },
  {
    id: "16584944",
    uri: "https://ondemo.tmsimg.com/assets/assets/p16584944_v_h9_ab.jpg"
  },
  {
    id: "15766692",
    uri: "https://ondemo.tmsimg.com/assets/assets/p15766692_v_h9_ae.jpg"
  },
  {
    id: "35901",
    uri: "https://ondemo.tmsimg.com/assets/assets/p35901_v_h9_aa.jpg"
  },
  {
    id: "29423",
    uri: "https://ondemo.tmsimg.com/assets/assets/p29423_v_h9_ae.jpg"
  },
  {
    id: "32829",
    uri: "https://ondemo.tmsimg.com/assets/assets/p32829_v_h9_aa.jpg"
  },
  {
    id: "18517",
    uri: "https://ondemo.tmsimg.com/assets/assets/p18517_v_h9_af.jpg"
  },
  {
    id: "8129775",
    uri: "https://ondemo.tmsimg.com/assets/assets/p8129775_v_h9_aa.jpg"
  },
  {
    id: "15054143",
    uri: "https://ondemo.tmsimg.com/assets/assets/p15054143_v_h9_ab.jpg"
  },
  {
    id: "87313",
    uri: "https://ondemo.tmsimg.com/assets/assets/p87313_v_h9_aa.jpg"
  },
  {
    id: "15326465",
    uri: "https://ondemo.tmsimg.com/assets/assets/p15326465_v_h9_ab.jpg"
  },
  {
    id: "35710",
    uri: "https://ondemo.tmsimg.com/assets/assets/p35710_v_h9_ah.jpg"
  },
  {
    id: "8696245",
    uri: "https://ondemo.tmsimg.com/assets/assets/p8696245_v_h9_aa.jpg"
  },
  {
    id: "8395508",
    uri: "https://ondemo.tmsimg.com/assets/assets/p8395508_v_h9_ac.jpg"
  },
  {
    id: "12934420",
    uri: "https://ondemo.tmsimg.com/assets/assets/p12934420_v_h9_aa.jpg"
  },
  {
    id: "159836",
    uri: "https://ondemo.tmsimg.com/assets/assets/p159836_v_h9_aa.jpg"
  },
  {
    id: "16783",
    uri: "https://ondemo.tmsimg.com/assets/assets/p16783_v_h9_ab.jpg"
  },
  {
    id: "90362",
    uri: "https://ondemo.tmsimg.com/assets/assets/p90362_v_h9_aa.jpg"
  },
  {
    id: "8193232",
    uri: "https://ondemo.tmsimg.com/assets/assets/p8193232_v_h9_aa.jpg"
  }
];
