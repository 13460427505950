import { connect } from "react-redux";
import ProgramsCarousel from "../ProgramsCarousel";
import store from "../store";

const mapStateToProps = state => ({
    popularItemsData: state.fullscreen.popularItemsData,
    heroItemsData: state.fullscreen.heroItemsData,
    basedOnItemsData: state.fullscreen.basedOnItemsData,
    clipsItemsData: state.fullscreen.clipsItemsData,
    advancedCategoriesData: state.fullscreen.advancedCategoriesData,
    dispatch: store.dispatch,
    lang: state.lang
});

export default connect(mapStateToProps)(ProgramsCarousel);
