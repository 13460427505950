/**
 * Display a placeholder until the final image loads.
 */
import React from "react";

class ImgLazyLoad extends React.Component {
    render() {
        let props = { src: this.props.src }; // Must have a src.
        if (this.props.style) {
            props.style = this.props.style;
        }
        if (this.props.width && this.props.height) {
            props.width = this.props.width;
            props.height = this.props.height;

            //'lazy' requires dimensions. If the w and h are odd for the layout, pass a style that compensates,
            //like style = { maxWidth: "100%", height: "auto" };
            if (this.props.style) {
                props.style = this.props.style;
            }
        } else {
            // console.log("width height", this.props.alt, this.props.src);
        }
        if (this.props.loading) {
            props.loading = this.props.loading;
        }

        if (this.props.lowsrc) {
            props.lowsrc = this.props.lowsrc;
        }

        const poster = iProps => (
            <img
                {...iProps}
                alt={iProps.alt || ""} //ES lint can't see the alt if it is in {props}
                className={this.props.className}
            />
        );

        let ret = poster(props);

        if (this.props.placeholder) {
            let placeholderProps = {
                ...props,
                src: this.props.placeholder,
                alt: "placeholder",
                style: {
                    maxWidth: "100%",
                    height: "auto"
                },
                loading: "eager"
            };
            props.style = {
                ...props.style,
                position: "absolute",
                top: 0,
                left: 0
            };
            ret = (
                <>
                    {poster(placeholderProps)}
                    {poster(props)}
                </>
            );
        }

        return ret;
    }
}

export default ImgLazyLoad;
