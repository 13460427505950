import { connect } from "react-redux";
import TasteProfile from "./TasteProfile";
import store from "../store";

const mapStateToProps = state => ({
    profile: state.profile,
    profileNames: state.profileNames,
    showingTasteProfile: state.showingTasteProfile,
    tasteProfileData: state.tasteProfileData,
    dispatch: store.dispatch,
    lang: state.lang
});

export default connect(mapStateToProps)(TasteProfile);
