import React from "react";
import ColorCirclesMap from "../api/ColorCirclesMap";
import HomeColorsMap from "../api/HomeColorsMap";

class WordBubbleSVG extends React.Component {
    render = () => {
        const motivations = this.props.children;
        const { data } = this.props;
        const displayMap = data.displayMap;
        // console.log(displayMap, "displayMap");
        // console.log(data, "data");
        // console.log(motivations, "motivations");

        const motivations0 = motivations[0].toLowerCase().replace(/\s/g, "");
        const motivations1 = motivations[1].toLowerCase().replace(/\s/g, "");
        const motivations2 = motivations[2].toLowerCase().replace(/\s/g, "");

        return (
            <div className="svg-overlay">
                <svg
                    width="100%"
                    height="100%"
                    viewBox="0 0 100 100"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect
                        onClick={() => data.modalAction(data.modalPayload)}
                        rect=""
                        x="0"
                        y="4"
                        width="6.25rem"
                        height="4.75rem"
                        fill="#FFFFFF00"
                    />
                    <line
                        id="line1"
                        x1={displayMap.lines.line1.x1}
                        y1={displayMap.lines.line1.y1}
                        x2={displayMap.lines.line1.x2}
                        y2={displayMap.lines.line1.y2}
                        stroke="#C2C2C2"
                        strokeWidth=".2"
                        strokeDasharray="1"
                    />
                    <line
                        id="line2"
                        x1={displayMap.lines.line2.x1}
                        y1={displayMap.lines.line2.y1}
                        x2={displayMap.lines.line2.x2}
                        y2={displayMap.lines.line2.y2}
                        stroke="#C2C2C2"
                        strokeWidth=".2"
                        strokeDasharray="1"
                    />
                    <line
                        id="line3"
                        x1={displayMap.lines.line3.x1}
                        y1={displayMap.lines.line3.y1}
                        x2={displayMap.lines.line3.x2}
                        y2={displayMap.lines.line3.y2}
                        stroke="#C2C2C2"
                        strokeWidth=".2"
                        strokeDasharray="1"
                    />
                    <image
                        x="48"
                        y="48"
                        width="5px"
                        height="5px"
                        href="/gradient-1.png"
                        clipPath="url(#GradientCircle)"
                    />
                    <defs>
                        <clipPath id="GradientCircle">
                            <circle cx="48" cy="48" r="11.1" fill="#FFFFFF" />
                        </clipPath>
                    </defs>
                    <circle
                        cx="48"
                        cy="48"
                        r="11.1"
                        stroke="white"
                        strokeWidth=".2"
                        fill="#FFFFFF"
                        opacity="0"
                    />
                    <circle
                        onClick={() =>
                            data.action(motivations[1], data.programId)
                        }
                        id="yellow"
                        cx={displayMap.circles.yellow.cx}
                        cy={displayMap.circles.yellow.cy}
                        r="11.1"
                        stroke="white"
                        strokeWidth=".2"
                        fill={HomeColorsMap[ColorCirclesMap[motivations1]]}
                    >
                        <set
                            attributeName="r"
                            to="12"
                            begin="yellow.mouseover"
                            end="yellow.mouseout"
                        />
                    </circle>
                    <circle
                        onClick={() =>
                            data.action(motivations[2], data.programId)
                        }
                        id="blue"
                        cx={displayMap.circles.blue.cx}
                        cy={displayMap.circles.blue.cy}
                        r="11.1"
                        stroke="white"
                        strokeWidth=".2"
                        fill={HomeColorsMap[ColorCirclesMap[motivations2]]}
                    >
                        <set
                            attributeName="r"
                            to="12"
                            begin="blue.mouseover"
                            end=" blue.mouseout"
                        />
                    </circle>
                    <circle
                        onClick={() =>
                            data.action(motivations[0], data.programId)
                        }
                        id="orange"
                        cx={displayMap.circles.orange.cx}
                        cy={displayMap.circles.orange.cy}
                        r="11.1"
                        stroke="white"
                        strokeWidth=".2"
                        fill={HomeColorsMap[ColorCirclesMap[motivations0]]}
                    >
                        <set
                            attributeName="r"
                            to="12"
                            begin="orange.mouseover"
                            end="orange.mouseout"
                        />
                    </circle>

                    {motivations.map((m, idx) => {
                        // place the words in circles
                        let x,
                            y = "";
                        switch (idx) {
                            // orange
                            case 0:
                                x = displayMap.words[idx].x;
                                y = displayMap.words[idx].y;
                                break;
                            // yellow
                            case 1:
                                x = displayMap.words[idx].x;
                                y = displayMap.words[idx].y;
                                break;
                            // blue
                            case 2:
                                x = displayMap.words[idx].x;
                                y = displayMap.words[idx].y;
                                break;
                            default:
                                break;
                        }

                        // figure out word placement within each circle
                        const re = /(.{1,13})(?:\s|$)/;
                        const motives = m.split(re).filter(f => f !== "");

                        return (
                            <text
                                key={`${x}_${y}`}
                                x="0"
                                y={y}
                                fontSize="0.17em"
                                fill="white"
                                dominantBaseline="middle"
                                textAnchor="middle"
                                fontFamily="Roboto, sans-serif"
                                fontStyle="normal"
                                pointerEvents="none"
                            >
                                {motives.map((mm, i) => {
                                    if (i === 0) {
                                        let dy = "0";
                                        if (motives.length > 2) {
                                            dy = "-3.25";
                                        } else if (motives.length > 1) {
                                            dy = "-1";
                                        }

                                        return (
                                            <tspan key={`${i}`} x={x} dy={dy}>
                                                {mm}
                                            </tspan>
                                        );
                                    } else {
                                        return (
                                            <tspan key={`${i}`} x={x} dy="3.25">
                                                {mm}
                                            </tspan>
                                        );
                                    }
                                })}
                            </text>
                        );
                    })}
                </svg>
            </div>
        );
    };
}

export default WordBubbleSVG;
