/**
 * ThematicCarousel
 * This is what is loaded when clicking on an entry in the 'Thematic Collections' carousel on 
 * the homepage
 */
import React, { Component } from "react";
import Carousel from "../Carousel";
import styled from "styled-components";
import { UI } from "../constants";
import { Poster } from "../Poster";
import { FPSEP } from "../api/profile";

const BoundingBox = styled.section`
  width: 100%;
  position: relative;
  left: 0;
  border-top: 1px solid #979797;
  background: none;
`;

const CarouselHeaderTitle = styled.div`
  font-size: 4rem;
  font-family: Oxygen, sans-serif;
  padding: 0;
  color: white;
`;
const CarouselHeaderDescription = styled.div`
  font-size: 1.5rem;
  font-family: Oxygen, sans-serif;
  padding: 0.5rem 0;
  color: white;
  margin-bottom: 8rem;
  // max-width: 30rem;
`;

const CarouselTitleContainer = styled.div`
  font-size: ${UI.font.small};
  font-family: Oxygen, sans-serif;
  padding: 1rem 0;
  color: white;
`;

const CarouselTitle = ({ lang, title }) => {
  if (title && lang) {
    const a = title.split(FPSEP);
    const en = a[0];
    const children = [];
    if (lang === "sv" && a.length === 2) {
      children[0] = (
        <span key="1" className="i18n carouselTitle primary firstletter">
          {a[1]}
        </span>
      );
      children[1] = (
        <span key="2" className="i18n carouselTitle secondary">
          {en}
        </span>
      );
    } else {
      children[0] = (
        <span key="1" className="i18n carouselTitle primary">
          {en}
        </span>
      );
    }
    return <CarouselTitleContainer>{children}</CarouselTitleContainer>;
  }
  return null;
};

const ViewModel = (programs, dispatch, lazyLoad, type) => {
  return programs.map(program => {
    let retObj = {
      tmsId: program.tmsId,
      rootId: program.rootId,
      uri: program.imageUri,
      action: () => null,
      lazyLoad: lazyLoad,
      thematic: type
    };
    return retObj;
  });
};

export default class ThematicCarousel extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const {
      dispatch,
      // thematicCategoriesData,
      data,
      title,
      titleDescription,
      lang
    } = this.props;

    console.log("lang======", lang);

    const microGenres = data.map((item, index) => (
      <BoundingBox key={index} className={`carousel-section-${index}`}>
        <div style={{ display: "flex", alignItems: "baseline" }}>
          <CarouselTitle lang="en" title={item.carouselTitle} />
          <span
            style={{
              marginLeft: "1rem",
              color: "white",
              fontFamily: "Oxygen,sans-serif"
            }}
          >
            {item.carouselDesc}
          </span>
        </div>
        <Carousel
          items={ViewModel(
            item.carouselItems,
            dispatch,
            false,
            item.carouselType
          )}
          slideComponent={Poster}
          carouselType={item.carouselType}
        />
      </BoundingBox>
    ));

    // const microGenres =
    // 	thematicCategoriesData && thematicCategoriesData.length > 0
    // 		? thematicCategoriesData.map(item => (
    // 				<div key={item.carouselTitle}>
    // 					<BoundingBox>
    // 							<CarouselTitle
    // 									lang={this.props.lang}
    // 									title={item.carouselTitle}
    // 							/>

    // 							<Carousel
    // 									items={ViewModel(item.carouselItems, dispatch, true)}
    // 									slideComponent={Poster}
    // 							/>
    // 					</BoundingBox>
    // 				</div>
    // 			))
    // 		: "";

    return (
      <>
        <div className="carousel-wrapper">
          <div className="carousel-header">
            <CarouselHeaderTitle>{title}</CarouselHeaderTitle>
            <CarouselHeaderDescription
              style={{
                maxWidth:
                  this.props.title === "Agatha Christie"
                    ? "36vw"
                    : this.props.title === "Politics"
                    ? "33vw"
                    : "none",
                lineHeight:
                  this.props.title === "Agatha Christie" ||
                  this.props.title === "Politics"
                    ? 1.5
                    : "normal"
              }}
            >
              {titleDescription}
            </CarouselHeaderDescription>
          </div>
          {microGenres}
        </div>
      </>
    );
  }
}
