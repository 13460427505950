import React from "react";
const IconCriticReviews = (props) => {
    const color = props.color || "black";
    const { style } = props
    return (
        <div style={style}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.18 7.65"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1">
                <path fill={color}
                    d="M4.59,0A5.72,5.72,0,0,1,6.37.27,4.74,4.74,0,0,1,7.83,1a3.52,3.52,0,0,1,1,1.06,2.49,2.49,0,0,1,0,2.61,3.64,3.64,0,0,1-1,1.06,4.74,4.74,0,0,1-1.46.72,5.72,5.72,0,0,1-1.78.27,6.25,6.25,0,0,1-.87-.06,4.87,4.87,0,0,1-2,.92l-.24,0-.27,0a.15.15,0,0,1-.13,0,.24.24,0,0,1-.07-.12.15.15,0,0,1,0-.13l.1-.09.2-.22a.89.89,0,0,0,.18-.24,1.72,1.72,0,0,0,.14-.33A2.33,2.33,0,0,0,1.8,6,4,4,0,0,1,.49,4.87,2.57,2.57,0,0,1,.36,2.05,3.69,3.69,0,0,1,1.35,1,4.69,4.69,0,0,1,2.8.27,5.78,5.78,0,0,1,4.59,0Z" /></g></g></svg>
        </div >
    );
}

export default IconCriticReviews;
